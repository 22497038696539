import React from 'react';
import LogoFacturation from "../../Styles/Pictures/logo_facturation.png";
import Fleche from '../../Styles/Pictures/down-arrow.svg';
class FacImage extends React.Component{
    render(){
        return(
            <div className="facturation">
                <img className="logofac" src={LogoFacturation} alt=""></img>
                <img className="flechehaut2"src={Fleche} alt=""></img>
                <label>Facturation</label>
            </div>
        );
    }
}
export default FacImage; 