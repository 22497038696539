import React from "react";
import BaseTable, { Column } from "react-base-table";
// import 'react-base-table/styles.css'

class TestTable extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      iterator: 0,
    };
  }
  render() {
    const column = [
      {
        key: "title1",
        dataKey: "title1",
        width: 300,
        flexGrow: 1,
        flexShrink: 0,
      },
      {
        key: "value1",
        dataKey: "value1",
        width: 300,
        align: Column.Alignment.CENTER,
        flexGrow: 2,
        cellRenderer: ({ rowData, rowIndex }) => {
          if (
            rowIndex !== 1 &&
            rowIndex !== 2 &&
            rowIndex !== 6 &&
            rowIndex !== 10 &&
            rowIndex !== 13 &&
            rowIndex !== 18
          )
            return (
              <div>
                <label>Oui</label>
                <input
                  type="checkbox"
                  name="answer"
                  value={1}
                  onChange={(event) => this.props.handleChange(event, rowIndex)}
                  checked={
                    Object.values(this.props.valueArray)[rowIndex].answer != 1
                      ? false
                      : true
                  }
                />
                <label>Non</label>
                <input
                  type="checkbox"
                  name="answer"
                  value={0}
                  onChange={(event) => this.props.handleChange(event, rowIndex)}
                  checked={
                    Object.values(this.props.valueArray)[rowIndex].answer != 0
                      ? false
                      : true
                  }
                />
                <label>Observation</label>
                <input
                  type="checkbox"
                  name="observation"
                  onChange={(event) => {
                    this.props.handleChange(event, rowIndex);
                  }}
                  checked={
                    Object.values(this.props.valueArray)[rowIndex].observation
                  }
                />
              </div>
            );
        },
      },
      {
        key: "title2",
        dataKey: "title2",
        width: 300,
        flexGrow: 3,
      },
      {
        key: "value2",
        dataKey: "value2",
        width: 300,
        flexGrow: 4,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowIndex }) => {
          if (
            rowIndex !== 1 &&
            rowIndex !== 4 &&
            rowIndex !== 8 &&
            rowIndex !== 11 &&
            rowIndex !== 15 &&
            rowIndex !== 17 &&
            rowIndex !== 20
          )
            return (
              <div>
                <label>Oui</label>
                <input
                  type="checkbox"
                  name="answer"
                  value={1}
                  onChange={(event) =>
                    this.props.handleChange(event, rowIndex + 20)
                  }
                  checked={
                    Object.values(this.props.valueArray)[rowIndex + 20]
                      .answer != 1
                      ? false
                      : true
                  }
                />
                <label>Non</label>
                <input
                  type="checkbox"
                  name="answer"
                  value={0}
                  onChange={(event) =>
                    this.props.handleChange(event, rowIndex + 20)
                  }
                  checked={
                    Object.values(this.props.valueArray)[rowIndex + 20]
                      .answer != 0
                      ? false
                      : true
                  }
                />
                <label>Observation</label>
                <input
                  type="checkbox"
                  name="observation"
                  onChange={(event) => {
                    this.props.handleChange(event, rowIndex + 20);
                  }}
                  checked={
                    Object.values(this.props.valueArray)[rowIndex + 20]
                      .observation
                  }
                />
              </div>
            );
        },
      },
    ];
    const data = [
      {
        id: 1,
        title1: "Avez-vous connaissance d'un protocole COVID-19",
        title2: "Souhaitez-vous le valider avec CSPS-MO"
      },
      {
        id: 2,
        title1: "Port de masques obligatoire sur le chantier RESPECTER"
      },
      {
        id: 3,
        title1: "Limiter au niveau le plus bas possible le nombre de salariés",
        title2: "A mettre en place"
      },
      {
        id: 4,
        title1: "Existante",
        title2: "Propre à l’entreprise"
      },
      {
        id: 5,
        title1: "A mettre en place",
        title2: "Comment comptez-vous protéger vos salariés pendant la période COVID-19"
      },
      {
        id: 6,
        title1: "Protections prévues",
        title2: "A mettre en place"
      },
      {
        id: 7,
        title1: "Le lieu pour recevoir vos compagnons",
        title2: "Masques- gants- lunettes"
      },
      {
        id: 8,
        title1: "Existant",
        title2: "Fourni par le Maître d’ouvrage"
      },
      {
        id: 9,
        title1: "Raccordé",
        title2: "Allez-vous demander une attestation de vaccination à vos salariés"
      },
      {
        id: 10,
        title1: "Point d'eau prévu avec robinet dissocié ",
        title2: "A mettre en place"
      },
      {
        id: 11,
        title1: "Mesures d'hygiène prévues",
        title2: "Propre à l’entreprise"
      },
      {
        id: 12,
        title1: "Existantes",
        title2: "Lors de votre intervention seriez-vous confronté à la Co-activité"
      },
      {
        id: 13,
        title1: "Visibles, facile à contrôler",
        title2: "Zone protégée prévue"
      },
      {
        id: 14,
        title1: "Trousse de premiers soins",
        title2: "A mettre en place"
      },
      {
        id: 15,
        title1: "Elle est visible",
        title2: "Contrainte d’accès"
      },
      {
        id: 16,
        title1: "Dans la base vie",
        title2: "Faire respecter la distanciation de 1m 50 à vos salariés"
      },
      {
        id: 17,
        title1: "Dans le camion",
        title2: "A mettre en place"
      },
      {
        id: 18,
        title1: "A mettre à proximité",
        title2: "P.P.S.P.S à joindre avec mention spéciale COVID-19 sous 15 jours"
      },
      {
        id: 19,
        title1: "Mise en place des gestes barrières",
        title2: "Existant"
      },
      {
        id: 20,
        title1: "Collectifs existants",
        title2: "Mission proposée par SPS"
      },
    ];

    var spanIndex = 2;
    column[0].colSpan = ({ rowData, rowIndex }) => 2;
    column[0].align = Column.Alignment.CENTER;
    column[2].colSpan = ({ rowData, rowIndex }) => 2;
    column[2].align = Column.Alignment.CENTER;

    const rowRenderer = ({ rowData, rowIndex, cells, columns }) => {
      const span = columns[spanIndex].colSpan({ rowData, rowIndex });
      if (
        rowIndex === 2 ||
        rowIndex === 6 ||
        rowIndex === 10 ||
        rowIndex === 13 ||
        rowIndex === 18
      ) {
        let width = cells[0].props.style.width;
        for (let i = 1; i < span; i++) {
          width += cells[0 + i].props.style.width;
          cells[0 + i] = null;
        }
        const style = {
          ...cells[0].props.style,
          width,
          color: "rgb(204, 102, 0)",
          fontStyle: "italic",
          whiteSpace: "normal",
          fontWeight: "bold",
          border: "1px solid black",
        };
        cells[0] = React.cloneElement(cells[0], { style });
      } else if (
        rowIndex === 4 ||
        rowIndex === 8 ||
        rowIndex === 11 ||
        rowIndex === 15 ||
        rowIndex === 17 ||
        rowIndex === 20
      ) {
        let width = cells[2].props.style.width;
        for (let i = 1; i < span; i++) {
          width += cells[2 + i].props.style.width;
          cells[2 + i] = null;
        }
        const style = {
          ...cells[2].props.style,
          width,
          color: "rgb(204, 102, 0)",
          fontStyle: "italic",
          whiteSpace: "normal",
          fontWeight: "bold",
          border: "1px solid black",
        };
        cells[2] = React.cloneElement(cells[2], { style });
      } else if (rowIndex === 1) {
        let width = cells[0].props.style.width;
        for (let i = 1; i < 4; i++) {
          width += cells[0 + i].props.style.width;
          cells[0 + i] = null;
        }
        const style = {
          ...cells[0].props.style,
          width,
          color: "red",
          border: "1px solid black",
        };
        cells[0] = React.cloneElement(cells[0], { style });
      }
      return cells;
    };

    return (
      <div className="testTable">
        <BaseTable
          rowKey="id"
          columns={column}
          width={1200}
          height={1000}
          data={data}
          headerHeight={0}
          rowRenderer={rowRenderer}
        />
      </div>
    );
  }
}
export default TestTable;
