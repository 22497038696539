import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';

class ConfSaveDialog extends React.Component { 
  constructor(props){
    super(props)
    this.state={
      mdp:""
    }
    this.handleChange=this.handleChange.bind(this)
  }
  handleChange(event){
    this.setState({[event.target.name]:event.target.value})
  }
    render() {
      return (
        <div>
        <Dialog open={this.props.open} onClose={() => this.props.setOpen(false)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Sauvegarde Tableau Quota d'heures</DialogTitle>
          <DialogContent>
            <DialogContentText>
                Veuillez confirmer que vous avez les droits pour sauvegarder les données du tableau pour le dossier {this.props.dossierValue} ?
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="mdp"
              type="password"
              defaultValue={this.state.mdp}
              label="Mot de Passe"
              onChange={this.handleChange}
              fullWidth
          />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.setOpen(false)} color="primary">
              Annuler la Sauvegarde
            </Button>
            <Button onClick={() => {
              if(this.state.mdp=="andre1950"){
                this.props.onConfirm()
                this.props.setOpen(false)
              }
            }
            } color="primary">
              Sauvegarder
            </Button>
          </DialogActions>
        </Dialog>  
        </div>       
      )
    }
  }
  const mapStateToProps = state => ({
    dossierValue : state.devis.dossierValue,
    dossier: state.dossier.items
  });
export default connect(mapStateToProps)(ConfSaveDialog)