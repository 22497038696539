import React from "react";
import ReactTable from 'react-table-6';
import { connect } from 'react-redux';
import { getDossier } from "../Utils"

class AffaireTable  extends React.Component{
    constructor(props){
        super(props)
        this.state={ 
        }
    }
    render(){
        const avancement = [0,10,20,30,45,50,60,75,85,100]
        const { dossier, dossierValue} = this.props
        const TheadComponent = props => null;
        const columns = [
            {
                Header: '',
                accessor: 'title1',
            },{
                Header : '',
                accessor : 'value1',
                Cell: (props) =>{
                    if(props.row._index===1) return <div><select name="avancement" 
                        style={{ height:"100%", width:"90%", pointerEvents : "visible"
                        , textAlign :"center", fontSize:"14px"}}
                        value={this.props.avancement}
                        onChange={this.props.handleChange}>
                        {avancement.map((item, index) =>{
                            return <option value={item} key={index}>{item}</option>
                        })}
                        </select>
                        <label> %</label>
                        </div>
                    else return <label>{getDossier(dossier,dossierValue).operation}</label>
                }
            },{
                Header: '',
                accessor: 'title2',
            },{
                Header : '',
                accessor : 'value2',
                Cell: (props) =>{
                    if(props.row._index===1) return <input name="date"
                        style={{ border:"none", height:"100%", width:"100%", pointerEvents : "visible", backgroundColor:"transparent"
                        , textAlign :"center", fontSize:"14px"}}
                        type="date"
                        value={this.props.date}
                        onChange={this.props.handleChange}
                        autoComplete="off"/> 
                        else return <span>{dossierValue}</span>
                }
            }
        ]
        const data = [
            {
                title1:"Opération",
                value1:getDossier(dossier,dossierValue).operation,
                title2:"N°",
            },{
                title1:"Avancement Chantier",
                value1:"" ,
                title2:"Date",
            }
        ]
        return(
            <div className="afftable">
                  <ReactTable
                      data={data} 
                      columns={columns}
                      defaultPageSize = {2}
                      TheadComponent={TheadComponent}
                      //pageSizeOptions = {[3, 6]}
                    />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    dossierValue : state.devis.dossierValue,
    dossier: state.dossier.items,
  });
export default connect(mapStateToProps)(AffaireTable);