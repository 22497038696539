export const initialCompletedWorks = {
  object: "",
  reference: "",
  via: "",
  asof: "",
  annex: ""
}

export const completedWorksConst = [
  {
    object: "Voile par passes",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Gros œuvre",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: "" 
  },
  {
    object: "Électricité + platine des noms (interphone) + luminaire",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Gaines électrique CFA/C10 + armoire générale",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Blocs BAES / BAEH",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Désenfumage",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Porte de garage/ PARKING",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "VB et VH",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "VMC",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Conduite de GAZ Arrivée + Plan de distribution",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Pompe de relevage",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Accès en toiture terrasse inaccessible",
    reference: "Les plans et fiches techniques sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Étanchéité",
    reference: "Les plans et fiches techniques sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Platerie / isolation + Menuiseries intérieures",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Ravalement",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Menuiseries extérieures",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Ascenseur avec plans et fiches techniques",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Bardage Couverture et Zinguerie",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Serrurerie / Métallerie",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Revêtement de sol",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Carrelage",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Peinture",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Parquet",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Extincteurs",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Espace vert",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "Cuve de rétention",
    reference: "Toutes les fiches techniques sont disponibles dans les DOE aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  },
  {
    object: "PMR",
    reference: "Les plans sont annexés au présent DIUO conformément aux articles du code du travail L4532-16, L4532-95 à 98.",
    via: "",
    asof: "N.C",
    annex: ""
  }
];