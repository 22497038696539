import React from "react";
import { connect } from "react-redux";
import { newDossierValue, newNpropValue } from "../redux/actions/devisActions";
import { fetchDossier } from "../redux/actions/dossierActions";
import { changeAmendmentValue, fetchEcheancesByNdossier } from "../redux/actions/echeancesActions";
import { changeFacIndexValue } from "../redux/actions/facActions";
import { changeContractFacAmendmentNumber, changeFacAmendmentNumber } from "../redux/actions/facAmendmentActions";

class NumDoss extends React.Component { 
  constructor(props) {
    super(props);
    this.handleDossierChange = this.handleDossierChange.bind(this);
  }
  /**
   * Initializes component data
   */
  // async componentDidMount() {
  //   const { dossierValue, dossier, devisrea, deviscon, echeances, entreprise } = this.props;

  //   if (!dossier.length) {
  //     await this.props.dispatch(fetchDossier());
  //     await this.props.dispatch(fetchContact());
  //   } else {
  //     await this.props.dispatch(newDossierValue(dossierValue));
  //   }

  //   if (!entreprise.length) {
  //     await this.props.dispatch(fetchEntreprise());
  //   }

  //   if (!deviscon.length) {
  //     await this.props.dispatch(fetchDevis());
  //   }

  //   if (!devisrea.length) {
  //     await this.props.dispatch(fetchDevis());
  //   }

  //   if (!echeances.length) {
  //     await this.props.dispatch(fetchEcheances());
  //   }
  //   if (dossierValue != "") {
  //     await this.props.dispatch(fetchEcheancesByNdossier(dossierValue));
  //   }
  //   await this.props.dispatch(changeFacIndexValue(1))
  //   await this.props.dispatch(fetchAllFac())
  // }

  async componentDidUpdate(prevProps) {
    const { dossierValue, dossier, deviscon, echeances, facAmendmentsByDoss } = this.props;
    if (dossier.length !== prevProps.dossier.length || dossierValue != prevProps.dossierValue || JSON.stringify(deviscon) !== JSON.stringify(prevProps.deviscon)) {
      await this.handleCheckIndexExist();
      await this.handleCheckContractFacAmendmentIndexExist();
      await this.props.dispatch(fetchDossier());
      await this.props.dispatch(changeAmendmentValue(1));
    }
    if (dossierValue != prevProps.dossierValue || echeances != prevProps.echeances) {
      await this.props.dispatch(newNpropValue(1));
      await this.props.dispatch(fetchEcheancesByNdossier(dossierValue))
    }
    if (facAmendmentsByDoss != prevProps.facAmendmentsByDoss) {
      await this.handleCheckFacAmendmentIndexExist();
    }
  }

  handleCheckIndexExist() {
    const { dossierValue, factures, facindex } = this.props;
    const sortedFactures = [...factures].filter((item) => item.ndossier == dossierValue).sort((a, b) => a.new_nfac.localeCompare(b.new_nfac))
    if (facindex > sortedFactures.length) {
      this.props.dispatch(changeFacIndexValue(1))
    }
  }

  handleCheckContractFacAmendmentIndexExist() {
    const { dossierValue, contractFacAmendments } = this.props;
    const sortedContractFacAmendment = [...contractFacAmendments].filter((item) => item.ndossier == dossierValue)
    if (sortedContractFacAmendment.length === 0) {
      this.props.dispatch(changeContractFacAmendmentNumber(0))
    } else this.props.dispatch(changeContractFacAmendmentNumber(1))
  }

  handleCheckFacAmendmentIndexExist() {
    const { facAmendmentsByDoss } = this.props;
    if (facAmendmentsByDoss.length === 0) {
      this.props.dispatch(changeFacAmendmentNumber(0))
    } else this.props.dispatch(changeFacAmendmentNumber(1))
  }

  /**
   * Change le numéro de dossier et mettant en avant la première proposition
   * @param {*} event
   */
  handleDossierChange(event) {
    const { nprop } = this.props;
    this.props.dispatch(newDossierValue(event.target.value));
    this.props.dispatch(newNpropValue(nprop));
    if (typeof this.props.handleResetValueArray === "function") {
      this.props.handleResetValueArray();
    }
  }
  /**
   * Change le numéro de la proposition
   * @param {*} event
   */
  handleNpropChange(value) {
    this.props.dispatch(newNpropValue(value));
  }

  handleAmendmentChange(event) {
    this.props.dispatch(changeAmendmentValue(event.target.value));
  }

  handleFactureChange(event) {
    this.props.dispatch(changeFacIndexValue(Number(event.target.value)));
  }

  handleChangeContractFacAmendmentIndex(event) {
    this.props.dispatch(changeContractFacAmendmentNumber(+event.target.value));
  }

  handleChangeFacAmendmentIndex(event) {
    this.props.dispatch(changeFacAmendmentNumber(+event.target.value));
  }

  showProp(i, item) {
    return (
      <option value={item.nprop} key={i}>
        {item.validation != 1 ? `Proposition N° ${item.nprop}` : `Proposition N° ${item.nprop} validée`}
      </option>
    )
  }

  showAmendment(i, item) {
    return (
      <option value={item.amendment} key={i}>
        {`Avenant N° ${item.amendment}`}
      </option>
    )
  }

  showContractFacAmendment(i, item) {
    return (
      <option value={item.amendment_number} key={i}>
        {`Avenant Facture N° ${item.amendment_number}`}
      </option>
    )
  }

  showFacAmendment(i, item) {
    const [split1, split2] = item.nfacture.split(" ")
    return (
      <option value={i + 1} key={i}>
        {`AR${i + 1} - ${split1} A-${split2}`}
      </option>
    )
  }

  showFactureIndex(nfacture, i, payment_method = 0) {
    const prefix = payment_method === 0 ? 'R' : payment_method === 26 ? i < 2 ? 'C' : 'R' : i === 0 ? 'C' : 'R';
    const index = payment_method === 0 ? `${i + 1}` : payment_method === 26 ? i + (i < 2 ? 1 : -1) : i === 0 ? i + 1 : i;

    return (
      <option value={i + 1} key={i}>
        {`${prefix}${index} - ${nfacture}`}
      </option>
    );
  }

  render() {
    const { dossier, deviscon, dossierValue, nprop, type, proposition, echeances, amendment, factures, allfacture, facindex, contractFacAmendments, contractFacAmendmentIndex, facAmendmentIndex, facAmendmentsByDoss } = this.props;
    const sortedDeviscon = [...deviscon].filter((item) => item.ndossier == dossierValue).sort((a, b) => a.nprop - b.nprop);
    const sortedEcheances = [...echeances].filter((item) => item.ndossier == dossierValue).sort((a, b) => a.amendment - b.amendment);
    const sortedFactures = [...factures].filter((item) => item.ndossier == dossierValue).sort((a, b) => a.new_nfac.localeCompare(b.new_nfac));
    const sortedAllFactures = [...allfacture].filter((item) => item.ndossier == dossierValue);
    const sortedFacAmendment = [...facAmendmentsByDoss].sort((a, b) => a.numclient.localeCompare(b.numclient));
    const sortedContractFacAmendment = [...contractFacAmendments].filter((item) => item.ndossier == dossierValue).sort((a, b) => a.amendment_number - b.amendment_number);

    return (
      <div className="ndossier">
        <label>N° Dossier : </label>
        <select
          id="lang"
          onChange={(event) => this.handleDossierChange(event)}
          value={dossierValue}
        >
          {dossier.map(({ ndossier }, i) => (
            <option key={i} value={ndossier}>
              {ndossier}
            </option>
          ))}
        </select>

        {type == "devis" &&
          (proposition !== undefined
            ? proposition
            : true) && (
            <select
              id="langProp"
              onChange={(event) => this.handleNpropChange(event.target.value)}
              value={nprop}
            >
              {sortedDeviscon.map((item, i) => {
                return this.showProp(i, item);
              })}
            </select>
          )}

        {type == "contrat" && (
          <select
            id="langAmendment"
            onChange={(event) => this.handleAmendmentChange(event)}
            value={amendment}
          >
            {sortedEcheances.map((item, i) => {
              return this.showAmendment(i, item);
            })}
          </select>
        )}

        {type == "facture" && sortedFactures.length > 0 && (
          <>
            <label> Facture : </label>
            <select
              id="langFacture"
              onChange={(event) => this.handleFactureChange(event)}
              value={facindex == 0 ? 1 : facindex}
            >
              {sortedFactures.map(({ new_nfac }, i) => {
                return this.showFactureIndex(new_nfac, i);
              })}
            </select>
          </>
        )}

        {type == "contractFacAmendment" && (
          <select
            id="contractfacAmendment"
            onChange={(event) => this.handleChangeContractFacAmendmentIndex(event)}
            value={contractFacAmendmentIndex}
          >
            {sortedContractFacAmendment.map((item, i) => {
              return this.showContractFacAmendment(i, item);
            })}
          </select>
        )}

        {type == "facAmendment" && (
          <>
            <label> Facture : </label>
            <select
              id="facAmendment"
              onChange={(event) => this.handleChangeFacAmendmentIndex(event)}
              value={facAmendmentIndex}
            >
              {sortedFacAmendment.map((item, i) => {
                return this.showFacAmendment(i, item);
              })}
            </select>
          </>
        )}

        {type == "reminder" && sortedAllFactures.length > 0 && (
          <>
            <label> Facture : </label>
            <select
              id="langFacture"
              onChange={(event) => this.handleFactureChange(event)}
              value={facindex == 0 ? 1 : facindex}
            >
              {sortedAllFactures.map(({ nfacture, payment_method }, i) => {
                return this.showFactureIndex(nfacture, i, payment_method);
              })}
            </select>
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  allfacture: state.facture.allfacture,
  factures: state.facture.items,
  facindex: state.facture.facindex,
  devisrea: state.devis.devisrea,
  deviscon: state.devis.deviscon,
  dossierValue: state.devis.dossierValue,
  nprop: state.devis.nprop,
  dossier: state.dossier.items,
  echeances: state.echeances.items,
  amendment: state.echeances.amendment,
  contractFacAmendments: state.facAmendment.contractFacAmendments,
  facAmendments: state.facAmendment.facAmendments,
  facAmendmentsByDoss: state.facAmendment.facAmendmentsByDoss,
  contractFacAmendmentIndex: state.facAmendment.contractFacAmendmentIndex,
  facAmendmentIndex: state.facAmendment.facAmendmentIndex,
});
export default connect(mapStateToProps)(NumDoss);
