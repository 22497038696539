import React from "react";
import BaseTable, { Column } from "react-base-table"
import 'react-base-table/styles.css'

class ConvEchPlat extends React.Component{
    constructor(props){
        super(props)
        this.state={
            iterator:0
        }
    }
    render(){ 
        const column = [
            {
                key: 'box1',
                dataKey: 'box1',
                width: 300,
                flexGrow:1,
                flexShrink:0,
                cellRenderer: ({cellData,rowIndex}) => (<div>
                    <input type="checkbox" name="conv" 
                    onChange={(event) => this.props.handleChange(event,rowIndex)} 
                    checked={this.props.valueArray[rowIndex]}/>
                    {cellData}
                </div>)
              },
        ]
        const data =[
            {
                box1: "Echafaudage(s) tubulaires(s)",
            },
            {
                box1: "Echafaudage(s) roulant(s)",
            },
            {
                box1: "Plate(s)-formes suspendue(s) motorisée(s)",
            },
            {
                box1: "Plate(s)-forme(s) suspendue(s) mue(s) à bras",
            },
            {
                box1: "Plate(s)-forme(s) sur mât(s)",
            },
            {
                box1: "Plate(s)-forme(s) suspendue(s) mue(s) à bras",
            },
        ]
        return(
            <div className="testTable">
                <BaseTable  columns={column} width={400} height={300} data={data} headerHeight={0}>
                </BaseTable>
            </div>
        )
    }
}
export default ConvEchPlat