import React from "react";
import "../../../Styles/RJ/SuiviEffectifs.scss";
import Header from "../../Header/Header";
import DocMenu from '../../DocMenu';
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import GenPdf from "../../GenPdf";
import { genPDF, getPDF } from "../Utils";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import RJMenu from "../RJMenu";
import { fetchRJ } from "../../../redux/actions/rjActions";
import { changeTitleName, changePdfFileName, changeTypeFileName } from "../../../redux/actions/emailAction";
import EffectifsTable from "./EffectifsTable";
import CircularProgress from "@material-ui/core/CircularProgress";

const emptyValues = { 
  company: '',
  average: 0,
  janvier: 0,
  fevrier: 0,
  mars: 0,
  avril: 0,
  mai: 0,
  juin: 0,
  juillet: 0,
  aout: 0,
  septembre: 0,
  octobre: 0,
  novembre: 0,
  decembre: 0
};

class SuiviEffectifs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYear: new Date().getFullYear(),
      advancement: 0,
      lastYearWorkforce: false,
      disableButton: false,
      hasUpdated: false,
      isOpen: false,
      valueArray: this.resitValueArray(),
      valueArrayLastYear: this.resitValueArray(),
    };
  }
  componentDidMount() {
    const { rjValue } = this.props;
    if (rjValue === undefined || !rjValue.workforce) {
      this.firstPage()
    } else if (JSON.parse(rjValue.workforce) !== undefined) {
      this.initialState();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { rjValue } = this.props;
    if (rjValue !== undefined && rjValue !== prevProps.rjValue) {
      this.initialState();
    } else if (!this.state.hasUpdated && rjValue === undefined) {
      this.setState({
        valueArray: this.resitValueArray(),
        valueArrayLastYear: this.resitValueArray(),
        hasUpdated: true,
      });
    }
  }

  resitValueArray() {
    return Array.apply(null, Array(21)).map(function (x, i) {
      return {
        company: i == 20 ? "TOTAL" : "",
        average: 0,
        janvier: 0,
        fevrier: 0,
        mars: 0,
        avril: 0,
        mai: 0,
        juin: 0,
        juillet: 0,
        aout: 0,
        septembre: 0,
        octobre: 0,
        novembre: 0,
        decembre: 0,
      };
    });
  }

  initialState() {
    this.props.dispatch(changePdfFileName(""))
    const { rjValue } = this.props;
    this.setState({
      advancement: rjValue.advancement,
      valueArray: JSON.parse(rjValue.workforce).length
        ? JSON.parse(rjValue.workforce)
        : this.resitValueArray(),
      valueArrayLastYear: rjValue.prev_workforce !== null
        ? JSON.parse(rjValue.prev_workforce)
        : this.resitValueArray(),
      hasUpdated: false
    });
  }

  sumByMonth(array, month) {
    let result = 0;
    for (let i = 0; i < array.length - 1; i++) {
      if (array[i].company != "") {
        result += parseInt(array[i][month] != "" ? array[i][month] : 0);
      }
    }
    return result;
  }

  sumAverageByCompany(array) {
    let result = 0;
    for (let i = 0; i < array.length - 1; i++) {
      result += parseInt(array[i].average != "" ? array[i].average : 0);
    }
    return result;
  }

  averageByCompany(array, index) {
    let result = 0;
    let nb = 0;
    for (let month in array[index]) {
      if (month !== "company" && month !== "average" && array[index][month] != 0) {
        result += parseInt(array[index][month] != "" ? array[index][month] : 0);
        nb++;
      }
    }
    result = result / nb;
    return isNaN(Math.ceil(result)) ? 0 : Math.ceil(result);
  }

  handleChangeValue = (event, index) => {
    const { name, value } = event.target;
    let newValueArray = [...this.state.valueArray];

    if (name === "company") {
      if (value === "") {
        newValueArray[index] = emptyValues;
        for (let month in newValueArray[index]) {
          if (month !== "company" && month !== "average") {
            newValueArray[newValueArray.length - 1][month] = this.sumByMonth(newValueArray, month);
          }
        }
        newValueArray[newValueArray.length - 1].average = this.sumAverageByCompany(newValueArray);
      } else {
        newValueArray[index][name] = value;
      }
    } else {
      if (isNaN(value)) return;
      newValueArray[index][name] = value !== "" ? +value : 0;
      newValueArray[index].average = this.averageByCompany(newValueArray, index);
      newValueArray[newValueArray.length - 1].average = this.sumAverageByCompany(newValueArray);
      newValueArray[newValueArray.length - 1][name] = this.sumByMonth(newValueArray, name);
    }
    this.setState({ valueArray: cloneDeep(newValueArray) });
  };

  handleChangeLastYearValue = (event, index) => {
    const { name, value } = event.target;
    let newValueArray = [...this.state.valueArrayLastYear];
 
    if (name === "company") {
      if (value === "") {
        newValueArray[index] = emptyValues;
        for (let month in newValueArray[index]) {
          if (month !== "company" && month !== "average") {
            newValueArray[newValueArray.length - 1][month] = this.sumByMonth(newValueArray, month);
          }
        }
        newValueArray[newValueArray.length - 1].average = this.sumAverageByCompany(newValueArray);
      } else {
        newValueArray[index][name] = value;
      }
    } else {
      if (isNaN(value)) return;
      newValueArray[index][name] = value !== "" ? +value : 0;
      newValueArray[index].average = this.averageByCompany(newValueArray, index);
      newValueArray[newValueArray.length - 1].average = this.sumAverageByCompany(newValueArray);
      newValueArray[newValueArray.length - 1][name] = this.sumByMonth(newValueArray, name);
    }
    this.setState({ valueArrayLastYear: cloneDeep(newValueArray) });
 };

  handleValidClick = async () => {
    const { rjValue, dossierValue } = this.props;
    this.setState({ disableButton: true });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: dossierValue,
        numero: rjValue.numero,
        workforce: this.state.valueArray,
        prev_workforce: this.state.valueArrayLastYear,
      }),
    };
    this.setState({ loading: true });
    await fetch("/api/rj/workforce", requestOptions)
      .then(async (res) => {
        if (res.ok) {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await this.props.dispatch(fetchRJ(this.props.dossierValue));
    await this.setState({ disableButton: false });
  };

  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  handleGenClick = async () => {
    const { rjValue, dossierValue, valueArrayLastYear } = this.props;
    this.setOpen(true);
    this.setState({ disableButton: true, file: null })
    await this.handleValidClick();
    const lastYearAverage = valueArrayLastYear[valueArrayLastYear.length - 1].average
    let blob = await genPDF(dossierValue, rjValue.numero, lastYearAverage);
    if (blob) {
      this.setState({ file: blob });
    }
    await this.setState({ disableButton: false })
  };

  handleShowPdf = async () => {
    const { rjValue, dossierValue } = this.props;
    this.setState({ disableButton: true })
    this.setOpen(true);
    this.setState({ file: null });
    let response = await getPDF(dossierValue, rjValue.numero, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    } else this.setOpen(false)
    await this.setState({ disableButton: false })
  };

  handleGetPdfFile = async () => {
    this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, this.props.rjValue.numero, 'pdf');

    if (response.blob) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, this.props.rjValue.numero, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  firstPage = () => {
    history.push("/rj/evaluation");
  };
  nextPage = () => {
    history.push("/rj/validation");
  };
  prevPage = () => {
    history.push("/rj/contacts");
  };

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`Registre Journal`))
  }

  render() {
    const { rjValue } = this.props;
    const { currentYear, advancement, valueArray, valueArrayLastYear, disableButton, lastYearWorkforce, file, isOpen } = this.state;

    return (
      <div className="rj-suivi">
        <Header />
        <DocMenu
          file={file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Registre Journal (Suivi des effectifs)</h1>
        <RJMenu
          advancement={advancement}
          handleResetValueArray={""}
        />
        <div className="data">
          <input className="last-year-check" type="checkbox" onChange={() => this.setState({ lastYearWorkforce: !lastYearWorkforce })} checked={lastYearWorkforce} /> Les effectifs de l'année {currentYear - 1}
          <h3>Année {currentYear}</h3>
          <EffectifsTable
            valueArray={valueArray}
            companyList={rjValue !== undefined && rjValue.contacts ? JSON.parse(rjValue.contacts) : []}
            handleChangeValue={this.handleChangeValue}
          />
          {valueArray[valueArray.length - 1].average != 0 && (
            <div style={{ margin: "10px" }}>
              <span>Année {currentYear}</span> - <span style={{ color: "red" }}>Hommes / jours: </span>
              <span style={{ fontWeight: "bold" }}>
                {valueArray[valueArray.length - 1]
                  .average * 264}
              </span>
              <p>
                Le calcul en hommes/jours se déifnit par : Nb mois x Nb jours
                travaillés x Nb salariés
              </p>
              <p>
                Ceci revient à :{" "}
                <span style={{ color: "red" }}>
                  12 X 22 X{" "}
                  {
                    valueArray[valueArray.length - 1]
                      .average
                  }{" "}
                  ={" "}
                  <span style={{ fontWeight: "bold" }}>
                    {valueArray[valueArray.length - 1]
                      .average * 264}{" "}
                  </span>
                  hommes/jours
                </span>
              </p>
            </div>
          )}
          {lastYearWorkforce && (
            <>
              <h3>Année {currentYear - 1}</h3>
              <EffectifsTable
                valueArray={valueArrayLastYear}
                companyList={rjValue !== undefined && rjValue.contacts ? JSON.parse(rjValue.contacts) : []}
                handleChangeValue={this.handleChangeLastYearValue}
              />
            </>
          )}
          {valueArrayLastYear[valueArrayLastYear.length - 1].average != 0 && (
            <div className={lastYearWorkforce ? "" : "workforce-last-year"} style={{ margin: "10px" }}>
              <span>Année {currentYear - 1}</span> - <span style={{ color: "red" }}>Hommes / jours: </span>
              <span style={{ fontWeight: "bold" }}>
                {valueArrayLastYear[valueArrayLastYear.length - 1]
                  .average * 264}
              </span>
              <p>
                Le calcul en hommes/jours se déifnit par : Nb mois x Nb jours
                travaillés x Nb salariés
              </p>
              <p>
                Ceci revient à :{" "}
                <span style={{ color: "red" }}>
                  12 X 22 X{" "}
                  {
                    valueArrayLastYear[valueArrayLastYear.length - 1]
                      .average
                  }{" "}
                  ={" "}
                  <span style={{ fontWeight: "bold" }}>
                    {valueArrayLastYear[valueArrayLastYear.length - 1]
                      .average * 264}{" "}
                  </span>
                  hommes/jours
                </span>
              </p>
            </div>
          )}

          <Button
            color="primary"
            variant="contained"
            onClick={this.handleValidClick}
            disabled={disableButton}
          >
            Enregistrer
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleGenClick}
            disabled={disableButton}
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleShowPdf}
            disabled={disableButton}
          >
            Afficher le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowLeft />}
            onClick={this.prevPage}
            disabled={disableButton}
          >
            Page précedente
          </Button>
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowRight />}
            onClick={this.nextPage}
            disabled={disableButton}
          >
            Validation
          </Button>
          {disableButton && (
            <CircularProgress className="spinner" color="inherit" />
          )}
        </div>
        <GenPdf
          file={file}
          isOpen={isOpen}
          closeDoc={() => this.setOpen(false)}
          scale={1}
          pageNumber={rjValue !== undefined ? rjValue.nbfloor + 4 : 5}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  rj: state.rj.items,
  rjValue: state.rj.rjValue,
  photos: state.rj.photos,
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
});
export default connect(mapStateToProps)(SuiviEffectifs);
