import React, { useState } from "react";
import Header from "../../Header/Header";
import VICMenu from "../VICMenu";
import { Button } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import "../../../Styles/VIC/Page8.scss";
import { genPDF, getClient, getLotNbrPages, getPDF } from "../Utils";
import { fetchVIC } from "../../../redux/actions/vicActions";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import SignatureCanvas from "react-signature-canvas";
import GenPdf from "../../GenPdf";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import DocMenu from "../../DocMenu";

class Page8 extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      valueArray: Array.apply(null, Array(11)).map(function (x, i) {
        return false;
      }),
      file: null,
      isOpen: false,
      hdebut: "",
      hfin: "",
      lu1: false,
      lu2: false,
      loading: false,
      disableButton: false,
    };
    this.sigProp = React.createRef();
    this.sigUtil = React.createRef();
  }
  componentDidMount() {
    this.loadData();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.lotValue !== prevProps.lotValue ||
      this.props.dossierValue !== prevProps.dossierValue ||
      this.props.entrepriseValue !== prevProps.entrepriseValue
    ) {
      this.loadData();
    }
  }
  loadData() {
    this.props.dispatch(changePdfFileName(""))
    var vic = this.props.vic.filter((vic) => {
      if (
        this.props.dossierValue == vic.ndossier &&
        this.props.lotValue == vic.lots
      ) {
        if (this.props.entrepriseValue !== undefined) {
          var entreprise = JSON.parse(this.props.entrepriseValue);
          if (entreprise.id == vic.identreprise) {
            return true;
          }
        }
      } else return false;
    })[0];
    if (vic !== undefined) {
      var page8 = JSON.parse(vic.compte_rendu).page8;
      var convPage2 = JSON.parse(vic.compte_rendu).convPage2;
      var horaires = JSON.parse(vic.compte_rendu).horaires;
      if (page8 !== undefined) {
        this.setState({ valueArray: page8 });
      } else if (convPage2 !== undefined) {
        this.setState({ valueArray: convPage2 });
      } else {
        this.setState({
          valueArray: Array.apply(null, Array(11)).map(function (x, i) {
            return false;
          }),
        });
      }
      if (horaires !== undefined && horaires.length !== 0) {
        this.setState({ hdebut: horaires[0] });
        this.setState({ hfin: horaires[1] });
      } else {
        this.setState({ hdebut: "" });
        this.setState({ hfin: "" });
      }
    } else {
      this.setState({
        valueArray: Array.apply(null, Array(11)).map(function (x, i) {
          return false;
        }),
      });
      this.setState({ hdebut: "" });
      this.setState({ hfin: "" });
    }
    this.setState({ file: null });
  }
  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }
  handleChange(event, index) {
    this.state.valueArray[index] = event.target.checked;
    this.setState(this.state.valueArray);
  }
  async handleValidClick(entreprise) {
		this.setState({ disableButton: true });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: this.props.dossierValue,
        lotValue: this.props.lotValue,
        identreprise: entreprise.id,
        page8: this.state.valueArray,
        horaires: [this.state.hdebut, this.state.hfin],
        sigUtil: this.sigUtil.current.getTrimmedCanvas().toDataURL(),
        lu1: this.state.lu1,
        sigProp: this.sigProp.current.getTrimmedCanvas().toDataURL(),
        lu2: this.state.lu2,
        nbrPages: getLotNbrPages(this.props.lots, this.props.lotValue),
      }),
    };
    this.setState({ loading: true });
    fetch("/api/vic/page8", requestOptions)
      .then(async (res) => {
        if (res.ok) {
					await this.props.dispatch(fetchVIC(this.props.dossierValue));
          await this.setState({ loading: false });
					await this.setState({ disableButton: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async handleGenClick(entreprise) {
    this.setOpen(true);
    this.setState({ file: null });
    let blob = await genPDF(
      this.props.dossierValue,
      entreprise.id,
      this.props.lotValue,
      getLotNbrPages(this.props.lots, this.props.lotValue),
      getClient(this.props)
    );
    if (blob) {
      this.setState({ file: blob });
    }
  }
  async handleShowPdf() {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'pdf');

      if (response.blob.size > 100) {
        this.setState({ file: response.blob, isOpen: true });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  }

  handleGetPdfFile = async () => {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'pdf');

      if (response.blob.size > 100) {
        this.setState({ file: response.blob });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  };

  async handleGetWordFile() {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'word');

      if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this.setState({ file: response.blob });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  }

  clear1() {
    this.sigProp.current.clear();
  }
  clear2() {
    this.sigUtil.current.clear();
  }
  handleCheckBox(event) {
    this.setState({ [event.target.name]: event.target.checked });
  }
  handleHours(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  prevPage = () => {
    history.push("/vic/page7");
  };
  disableSaveButton(entreprise) {
    if (entreprise.id === undefined) return true;
    if (this.state.loading) return true;
    return false;
  }

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`VIC`))
  }

  render() {
    var entreprise = "";
    if (
      this.props.entrepriseValue !== undefined &&
      this.props.entrepriseValue !== ""
    ) {
      entreprise = JSON.parse(this.props.entrepriseValue);
    }
    return (
      <div className="page8">
        <Header />
        <DocMenu
          file={this.state.file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Visite d'Inspection Commune (Page 8)</h1>
        <VICMenu />
        <div className="data">
          <h4 style={{ textDecoration: "underline" }}>
            Conditions d'utilisations
          </h4>
          <p>
            <input
              type="checkbox"
              name="answer"
              onChange={(event) => this.handleChange(event, 0)}
              checked={this.state.valueArray[0]}
            />
            L’entreprise propriétaire tient à disposition sur le site les
            justificatifs de contrôles et vérifications en cours de validité ;
          </p>
          <p>
            <input
              type="checkbox"
              name="answer"
              onChange={(event) => this.handleChange(event, 1)}
              checked={this.state.valueArray[1]}
            />
            Les entreprises déclarent avoir souscrit une police d’assurance
            couvrant les responsabilités civiles et tous les dommages pouvant
            résulter des activités exercées ;
          </p>
          <p>
            <input
              type="checkbox"
              name="answer"
              onChange={(event) => this.handleChange(event, 2)}
              checked={this.state.valueArray[2]}
            />
            Les entreprises veilleront à ce que le planning d’utilisation ainsi
            que les formations légales des entreprises soient à jour et
            affichées ;
          </p>
          <p>
            <input
              type="checkbox"
              name="answer"
              onChange={(event) => this.handleChange(event, 3)}
              checked={this.state.valueArray[3]}
            />
            L’entreprise utilisatrice s’engage à respecter les consignes
            générales de sécurités, les consignes particulières, les consignes
            spécifiques du site et toutes les règles, codes, lois applicables
            qu’elle s’engage à appliquer.
          </p>
          <p>
            <input
              type="checkbox"
              name="answer"
              onChange={(event) => this.handleChange(event, 4)}
              checked={this.state.valueArray[4]}
            />
            L’entreprise utilisatrice s’engage à respecter les consignes
            d’utilisation délivrées par le fabricant de l’équipement (notice du
            constructeur/fabricant) et d’informer l’ensemble de son personnel
            sur les modalités pratiques propres au chantier et à l’usage de
            l’équipement ; les recommandations du CRAM.
          </p>
          <p>
            <input
              type="checkbox"
              name="answer"
              onChange={(event) => this.handleChange(event, 5)}
              checked={this.state.valueArray[5]}
            />
            L’entreprise utilisatrice a délivré les attestations de compétences
            à l’utilisation en sécurité d’un matériel de levage à chacun de ses
            salariés qui ont en outre reçus les informations et les formations
            adaptées.{" "}
          </p>
          <p>
            <input
              type="checkbox"
              name="answer"
              onChange={(event) => this.handleChange(event, 6)}
              checked={this.state.valueArray[6]}
            />
            L’entreprise a informé ses salariés que, sans cette autorisation,
            ils ne pourront pas utiliser l’équipement.
          </p>
          <p>
            <input
              type="checkbox"
              name="answer"
              onChange={(event) => this.handleChange(event, 7)}
              checked={this.state.valueArray[7]}
            />
            Si un dysfonctionnement est relevé par l’entreprise utilisatrice,
            celle-ci s’engage à informer expressément dans un délai de 1 heure
            l’entreprise propriétaire pour permettre à cette dernière de prendre
            des mesures correctives qui s’imposent ;
          </p>
          <p>
            <input
              type="checkbox"
              name="answer"
              onChange={(event) => this.handleChange(event, 8)}
              checked={this.state.valueArray[8]}
            />
            Toute modification de tout ou partie du matériel mis à disposition
            est interdite ;{" "}
          </p>
          <p>
            <input
              type="checkbox"
              name="answer"
              onChange={(event) => this.handleChange(event, 9)}
              checked={this.state.valueArray[9]}
            />
            Toute cession du matériel dont la société (nommer l’entreprise
            propriétaire) est propriétaire est interdite ;
          </p>
          <p>
            <input
              type="checkbox"
              name="answer"
              onChange={(event) => this.handleChange(event, 10)}
              checked={this.state.valueArray[10]}
            />
            Toute mise à disposition du matériel au profil d’une autre
            entreprise que celle mentionnée à la présente convention est
            interdite.
          </p>
          <div className="hours">
            <label>Horaire Journaliers : </label>
            <label>Heure Début : </label>
            <input
              type="time"
              name="hdebut"
              value={this.state.hdebut}
              onChange={(event) => this.handleHours(event)}
            />
            <label>Heure Fin : </label>
            <input
              type="time"
              name="hfin"
              value={this.state.hfin}
              onChange={(event) => this.handleHours(event)}
            />
          </div>
          <div className="sigProp">
            <label>Signature Propriétaire :</label>
            <SignatureCanvas
              ref={this.sigProp}
              penColor="black"
              backgroundColor="rgb(255,255,255)"
              canvasProps={{ width: 200, height: 100, className: "sigProp" }}
            />
            <Button onClick={this.clear1.bind(this)}>Effacer</Button>
            <div className="lu">
              <input
                type="checkbox"
                name="lu1"
                checked={this.state.lu1}
                onChange={(event) => this.handleCheckBox(event)}
              />
              <label>lu et approuvé</label>
            </div>
          </div>
          <br />
          <div className="sigUtil">
            <label>Signature Utilisatrice :</label>
            <SignatureCanvas
              ref={this.sigUtil}
              penColor="black"
              backgroundColor="rgb(255,255,255)"
              canvasProps={{ width: 200, height: 100, className: "sigUtil" }}
            />
            <Button onClick={this.clear2.bind(this)}>Effacer</Button>
            <div className="lu">
              <input
                type="checkbox"
                name="lu2"
                checked={this.state.lu2}
                onChange={(event) => this.handleCheckBox(event)}
              />
              <label>lu et approuvé</label>
            </div>
          </div>
          <br />
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleValidClick(entreprise)}
            disabled={this.disableSaveButton(entreprise)}
          >
            Enregistrer
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleGenClick(entreprise)}
            disabled={this.state.disableButton || entreprise.id === undefined ? true : false}
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleShowPdf(entreprise)}
            disabled={this.state.disableButton || entreprise.id === undefined ? true : false}
          >
            Afficher le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowLeft />}
            onClick={this.prevPage}
            disabled={this.state.disableButton}
          >
            Page précedente
          </Button>
          {this.state.disableButton && <CircularProgress className="spinner" color="success" />}
        </div>
        <GenPdf
          file={this.state.file}
          isOpen={this.state.isOpen}
          closeDoc={() => this.setOpen(false)}
          scale={1}
          pageNumber={8}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
  lotValue: state.vic.lotValue,
  lots: state.vic.lots,
  vic: state.vic.items,
  contact: state.contact.items,
  entreprise: state.entreprise.items,
  entrepriseValue: state.entreprise.entrepriseValue,
});
export default connect(mapStateToProps)(Page8);
