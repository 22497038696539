import React, { useState, useEffect } from 'react';
import '../Styles/Notifications.scss';
import { connect } from "react-redux";
import axios from "axios";
import check from "../Styles/Pictures/check.png";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from './Header/Header';
import { convertDate } from './Utils';
import history from './Routes/history';
import Button from '@material-ui/core/Button';
import "../Styles/GererNotif.scss";
import { useParams } from 'react-router-dom'


export default function Notifications() { 
    const [data, setData] = useState([]);
    const { idnotif } = useParams()
    // console.log(idnotif)

    useEffect(() => {
        axios.post('http://localhost:3000/notifMesssage', { idnotif })
            .then((res) => {
                setData(res.data)
                // console.log(res.data);
            })
            .catch((err) => console.log(err));
    }, []);


    /*handleMessage(event) {
        axios.get("/notifMesssage", { message: this.state.message })
            .then((res) => {
                console.log('/notifMesssage');
            })
        //event.preventDefault();
    }
    */


    return (
        <div >
            <Header />
            <div className='containterNotif' >
                {data.map((notif, index) => {
                    return <div key={index}>
                        <div className='scopetype'>
                            <h1 className='h1'>{notif.type}</h1>
                        </div>
                        <h2 className='h2'>Envoyé par [ {notif.sender} ] </h2>
                        <p className='p'>Message : {notif.message}</p>
                        <h3 className='h3'>Fait le {convertDate(notif.date)}</h3>
                        <h4 className='h4'>à {notif.heure} </h4>
                        <img className="h5" src={check} alt=""></img>


                    </div>;
                })}

                <Button style={{ backgroundColor: '#ff8c69', color: '#FFFFFF' }} className='buttonmail'
                    variant="contained" color="primary" onClick={() => (history.push("/devis/pdf/mail"))}>Répondre par mail</Button>

            </div>
        </div>
    )

}

/*const mapStateToProps = state => ({
    user: state.user.items,
    notifs: state.notif.items,
});
export default connect(mapStateToProps)(notifications)*/
