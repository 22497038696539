import React from 'react';
import * as EmailValidator from "email-validator"
import '../../Styles/LoginPage/Login.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
class RegisterForm extends React.Component{
   constructor(props) {
      super(props);
  
      this.state = {
        errorMessage:"",
        confirmMessage:"",
        file:null,
        email: "",
        password: "",
        confPass:"",
        name: "",
        surname: "",
        showPass:false, 
        formErrors: {
          email: "",
          password: ""
        }
      }
      this.handleChange=this.handleChange.bind(this)
    }
    handleFileChange(event){
      this.setState({ file: event.target.files[0] }); 
    }
    handleChange(event){
      this.setState({[event.target.name]:event.target.value})
    }
    hideOrShow(){
      this.setState({showPass:!this.state.showPass})
    }
    handleSubmit(event){
      if(EmailValidator.validate(this.state.email) && /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{6,}$/gm.test(this.state.password)
      && this.state.name!=="" && this.state.surname!=="" && this.state.confPass==this.state.password){
          const formData = new FormData()
        formData.append("email",this.state.email)
        formData.append("password",this.state.password)
        formData.append("fname",this.state.name)
        formData.append("lname",this.state.surname)
        formData.append("image",this.state.file)
        const requestOptions = {
          method: 'POST',
          // headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
          body: formData
          };  
          fetch("/api/register",requestOptions)
          .then(async(res) => {
            if(res.status==403){
              this.setState({confirmMessage:""})
              this.setState({errorMessage:await res.text()})
            }
            if(res.status==201) {
              this.setState({errorMessage:""})
              this.setState({confirmMessage:await res.text()})
            }
          })
          .catch(err => this.setState({errorMessage: err}))
      }
      else {
        this.setState({errorMessage:"Les informations ne sont pas correctement rentrées"})
        this.setState({confirmMessage:""})
      }
    }
   render(){
      const { formErrors } = this.state;
        return(
            <div className="form"> 
                   <br />
                  <div className="mail">
                     <label for="email">Email</label> <br /> <br />
                     <input type="text" class="form-control"  name="email" onChange={this.handleChange}  />
                 {formErrors.email.length > 0 && (
                <span className="errorMessage">{formErrors.email}</span>
              )}
                  </div><br /> 
                  <div className="nom">
                    <label>Nom</label> <br /> <br />
                    <input type="text" name="surname" onChange={this.handleChange}/><br /> <br />
                </div> 
                  <div className="prenom">
                    <label>Prénom</label> <br /> <br />
                    <input type="text" name="name" onChange={this.handleChange}/></div> <br />
                  <div className="pwd">
                     <label for="password">Mot de Passe</label> <br /> <br />
                     <input type={!this.state.showPass ? "password" : "text"} class="form-control" id="password" name="password"
                onChange={this.handleChange}/>
                      <span onClick={this.hideOrShow.bind(this)}><FontAwesomeIcon icon={!this.state.showPass ? faEye : faEyeSlash}/></span>
                <br /><br />
                <label>Confirmation Mot de Passe</label> <br /> <br />
                     <input   className={formErrors.password.length > 0 ? "error" : null} type="password" class="form-control" id="password" name="confPass"   noValidate
                onChange={this.handleChange}/> <br />
                  </div>
                  <br />
                  <div className="upload_Image">
                    <input className="image" type="file" onChange={this.handleFileChange.bind(this)}/>
                  </div>
                  <button className="btn btn-primary" onClick={this.handleSubmit.bind(this)}>Register</button>
                  {this.state.errorMessage!=="" ? <div style={{color:"red"}}>{this.state.errorMessage}</div> : ""}
                  {this.state.confirmMessage!=="" ? <div style={{color:"green"}}>{this.state.confirmMessage}</div> : ""}
            </div>
        );
    }
}
export default RegisterForm;