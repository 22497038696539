import React from "react";
import { connect } from "react-redux"
import { Button, Popper } from "@material-ui/core";
class PopperLevels extends React.Component{
    constructor(props){
        super(props)
        this.state={
        }
    }
    render(){
        return(
            <Popper 
                open={this.props.open}
                anchorEl={this.props.anchorEl}
                placement={"right"}
                >
                <div ref={this.props.levelsWrapper} className="popover-levels">
                    <Button variant="contained" style={{backgroundColor:"red"}}
                     onClick={(event) => this.props.handleLevelClick(event,this.props.index,"fort")}>
                        Fort
                    </Button>
                    <Button variant="contained" style={{backgroundColor:"purple"}}
                     onClick={(event) => this.props.handleLevelClick(event,this.props.index,"pondéré")}>
                        Pondéré
                    </Button>
                    <Button variant="contained" style={{backgroundColor:"yellow"}}
                     onClick={(event) => this.props.handleLevelClick(event,this.props.index,"moyen")}>
                        Moyen
                    </Button>
                    <Button variant="contained" style={{backgroundColor:"green"}}
                     onClick={(event) => this.props.handleLevelClick(event,this.props.index,"faible")}>
                        Faible
                    </Button>
                </div>
            </Popper>
        )
    }
}
const mapStateToProps = state => ({
	dossierValue: state.devis.dossierValue,
	dossier : state.dossier.items,
  });
  export default connect(mapStateToProps)(PopperLevels);