import React from "react";
import { connect } from "react-redux";
import { newEntrepriseValue } from "../../redux/actions/entrepriseActions";

const EntrepriseVICSList = ({ companies, entrepriseValue, dispatch }) => {

    const handleEntrepriseChange = async (event) => {
        await dispatch(newEntrepriseValue(event.target.value));
    };
    
    return ( 
        <div className="entrepriselist">
            <label>Entreprise : </label>
            <select onChange={handleEntrepriseChange} value={typeof entrepriseValue === "object" ? JSON.stringify(entrepriseValue) : entrepriseValue}>
                {companies.map((item) => {
                    return (
                        <option value={JSON.stringify(item)} key={item.id}>
                            {item.nom}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

const mapStateToProps = (state) => ({
    companies: state.vic.companies,
    lots: state.vic.lots,
    entrepriseValue: state.entreprise.entrepriseValue
});

export default connect(mapStateToProps)(EntrepriseVICSList);
