export const FETCH_QUOTAS_BEGIN = "FETCH_QUOTAS_BEGIN";
export const FETCH_QUOTAS_SUCCESS = "FETCH_QUOTAS_SUCCESS";
export const FETCH_QUOTAS_FAILURE = "FETCH_QUOTAS_FAILURE";
export function fetchQuotas() { 
  return async (dispatch) => {
    dispatch(fetchQuotasBegin());
    return fetch("/api/quota/getQuotas")
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchQuotasSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchQuotasFailure(error)));
  };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const fetchQuotasBegin = () => ({
  type: FETCH_QUOTAS_BEGIN,
});

export const fetchQuotasSuccess = (quota) => ({
  type: FETCH_QUOTAS_SUCCESS,
  payload: { quotas: quota },
});

export const fetchQuotasFailure = (error) => ({
  type: FETCH_QUOTAS_FAILURE,
  payload: { error },
});
