import { Button } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import React from "react";
import { connect } from "react-redux";
import Header from "../Header/Header";
import GenPdf from "../GenPdf";
import { genPDF, getPDF } from "./Utils";
import DocMenu from "../DocMenu";
import RJMenu from "./RJMenu";
import YesNoDialog from "../YesNoDialog";
import { fetchRJ, newRjValue } from "../../redux/actions/rjActions";
import { changeTitleName, changePdfFileName } from "../../redux/actions/emailAction";
import "../../Styles/RJ/Validation.scss";

class RJValidation extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      advancement: 0,
      file: null,
      disableButton: false,
      isOpen: false,
      openDialog: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    if (
      this.props.rjValue !== undefined &&
      this.props.rjValue.workforce
    ) {
      this.setState({ advancement: this.props.rjValue.advancement });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.rjValue !== undefined &&
      this.props.rjValue !== prevProps.rjValue
    ) {
      this.props.dispatch(changePdfFileName(""))
      this.setState({ advancement: this.props.rjValue.advancement });
    }
  }

  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  setOpen(open) {
    this.setState({ isOpen: open });
  }

  // filterRJ = () => {
  //   return this.props.rj.filter((rj) => {
  //     if (rj.ndossier == this.props.dossierValue) return true;
  //     else return false;
  //   });
  // };

  suppRJ = async (idRJ) => {
    this.setState({ disableButton: true });
    this.handleClose();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ id: idRJ, numero: this.props.rjValue.numero, ndossier: this.props.dossierValue }),
    };
    fetch("/api/rj/suppRJ", requestOptions).catch((err) => {
      console.log(err);
    });
    await this.props.dispatch(fetchRJ(this.props.dossierValue));
    const newRj = this.props.rj[this.props.rj.length - 1];
    await this.props.dispatch(newRjValue(newRj));
    await this.setState({ disableButton: false });

  }

  handleGenClick = async () => {
    this.setState({ disableButton: true });
    this.setOpen(true);
    this.setState({ file: null });
    let blob = await genPDF(this.props.dossierValue, this.props.rjValue.numero);
    if (blob) {
      this.setState({ file: blob });
    }
    await this.setState({ disableButton: false });
  };

  handleShowPdf = async () => {
    this.setState({ disableButton: true });
    this.setOpen(true);
    this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, this.props.rjValue.numero, 'pdf');
    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    } else this.setOpen(false)
    await this.setState({ disableButton: false });
  };

  handleGetPdfFile = async () => {
    this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, this.props.rjValue.numero, 'pdf');

    if (response.blob) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, this.props.rjValue.numero, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`Registre Journal`))
  }

  render() {
    const { rjValue } = this.props
    const { advancement, disableButton, file, openDialog, isOpen } = this.state

    var idRJ = null;
    if (rjValue !== undefined) {
      idRJ = rjValue.id;
    }

    return (
      <div className="rj-validation">
        <Header />
        <DocMenu
          file={file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Validation du Registre Journal</h1>
        <RJMenu
          advancement={rjValue !== undefined ? advancement : 0}
          handleResetValueArray={""}
        />
        <div className="data">
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleGenClick()}
            disabled={
              rjValue === undefined || disableButton
            }
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleShowPdf()}
            disabled={
              rjValue === undefined || disableButton
            }
          >
            Afficher le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={
              rjValue === undefined || disableButton
            }
          >
            Valider le RJ
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleClickOpen}
            disabled={
              rjValue === undefined || disableButton
            }
          >
            Supprimer le RJ
          </Button>
          {disableButton && <CircularProgress className="spinner" color="inherit" />}
          <YesNoDialog
            isOpen={openDialog}
            title={'SUPPRESSION DU RJ'}
            question={`Êtes vous sûr de vouloir supprimer le RJ «N°${rjValue !== undefined ? rjValue.numero : ""}» du dossier «${this.props.dossierValue !== undefined ? this.props.dossierValue : ""}» ?`}
            handleYesClick={() => this.suppRJ(idRJ)}
            handleClose={this.handleClose}
          />
        </div>
        <GenPdf
          isOpen={isOpen}
          file={file}
          closeDoc={() => this.setOpen(false)}
          scale={1}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  rj: state.rj.items,
  rjValue: state.rj.rjValue,
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
});
export default connect(mapStateToProps)(RJValidation);
