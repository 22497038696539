export const getDataFromPgc = async (dossierValue) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  };
  try {
    const response = await fetch(`/api/prediuo/getDataFromPgc/${dossierValue}`, requestOptions);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Resource not found");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching MoMoe infos:", err);
    throw err;
  }
}

export const getCoverPageData = async (id) => {
  const requestOptions = { 
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  };
  try {
    const response = await fetch(`/api/prediuo/getCoverPageData/${id}`, requestOptions);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Resource not found");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching MoMoe infos:", err);
    throw err;
  }
}

export const getCspsInformationsData = async (dossierValue, preDiuoValue) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  };
  try {
    const response = await fetch(`/api/prediuo/getCspsInformationsData/${dossierValue}/${preDiuoValue}`, requestOptions);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Resource not found");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching MoMoe infos:", err);
    throw err;
  }
}

export const getConstructionElementsData = async (preDiuoValue) => {
  if (!preDiuoValue) {
    throw new Error('preDiuoValue is required');
  }

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  };

  try {
    const response = await fetch(
      `/api/prediuo/getConstructionElementsData/${encodeURIComponent(preDiuoValue)}`,
      requestOptions
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `Request failed with status ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching construction elements data:', error);
    throw error;
  }
};

export const genPreDiuoPDF = async (preDiuoValue, dossierValue, index) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({ 
      preDiuoValue,
      ndossier: dossierValue,
      index
     }),
  };
  return await fetch("/api/prediuo/genPreDiuoPDF", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const getPreDiuoFile = async (dossierValue, index, type) => {
  try {
    const res = await fetch("/api/prediuo/getPreDiuoFile", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({
        dossierValue,
        index,
        type
      }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};



export const deletePreDiuo = async (dossierValue, index, preDiuoValue) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      dossierValue,
      index,
      preDiuoValue
    }),
  };

  try {
    const response = await fetch("/api/prediuo/deletePreDiuo", requestOptions);
    if (!response.ok) {
      throw new Error("Erreur lors de la suppression");
    }
    return response.json();
  } catch (err) {
    console.error(err);
    throw err;
  }
};
