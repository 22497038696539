import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "../../../Styles/Heures/Htp.scss";

class HtpCategory1 extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { conHourCat1, reaHourCat1, totalConHourCat1, totalReaHourCat1 } =
      this.props;
    return (
      <div className="htp-table">
        <div className="htp-subtable">
          <h2>Conception</h2>
          <TableContainer style={{ width: "500px" }} component={Paper}>
            <Table aria-label="simple table">
              <TableHead className="table-head">
                <TableRow className="head-row">
                  <TableCell className="MuiTableCell-head">Tâches</TableCell>
                  <TableCell className="MuiTableCell-head">HTP /h</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="subtitle" colSpan={2}>
                    1. Stade Avant-Projet/Permis de construire
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    1.1. Ouverture du Registre-journal de la coordination
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat1[0] !== 0 ? conHourCat1[0] : ""}
                      onChange={(e) => this.props.handleChange(e, 0)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    1.2. Visite du site
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat1[1] !== 0 ? conHourCat1[1] : ""}
                      onChange={(e) => this.props.handleChange(e, 1)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    1.3. Assistance Déclaration Préalable
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat1[2] !== 0 ? conHourCat1[2] : ""}
                      onChange={(e) => this.props.handleChange(e, 2)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    1.4. Examen dossier conception/Analyse de risques
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat1[3] !== 0 ? conHourCat1[3] : ""}
                      onChange={(e) => this.props.handleChange(e, 3)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    1.5. Réunions Maîtrise de l'ouvrage/Maîtrise d'Œuvre
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat1[4] !== 0 ? conHourCat1[4] : ""}
                      onChange={(e) => this.props.handleChange(e, 4)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="subtitle" colSpan={2}>
                    2. Projet/Consultation
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    2.1. Réunions Maîtrise de l'ouvrage/Maîtrise d'Œuvre
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat1[5] !== 0 ? conHourCat1[5] : ""}
                      onChange={(e) => this.props.handleChange(e, 5)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    2.2. Elaboration du P.G.C
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat1[6] !== 0 ? conHourCat1[6] : ""}
                      onChange={(e) => this.props.handleChange(e, 6)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    2.3. Elaboration pré D.I.U.O & du D.I.U.O
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat1[7] !== 0 ? conHourCat1[7] : ""}
                      onChange={(e) => this.props.handleChange(e, 7)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    2.4. Elaboration du règlement de C.I.S.S.C.T
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="conHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={conHourCat1[8] !== 0 ? conHourCat1[8] : ""}
                      onChange={(e) => this.props.handleChange(e, 8)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className="head-row">
                  <TableCell className="MuiTableCell-head">TOTAL</TableCell>
                  <TableCell className="MuiTableCell-head">
                    {totalConHourCat1} /h
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="htp-subtable">
          <h2>Réalisation</h2>
          <TableContainer style={{ width: "500px" }} component={Paper}>
            <Table aria-label="simple table">
              <TableHead className="table-head">
                <TableRow className="head-row">
                  <TableCell className="MuiTableCell-head">Tâches</TableCell>
                  <TableCell className="MuiTableCell-head">HTP /h</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="subtitle" colSpan={2}>
                    1. Préparation du chantier
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    1.1. Inspections Communes avec les entreprises
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat1[0] !== 0 ? reaHourCat1[0] : ""}
                      onChange={(e) => this.props.handleChange(e, 0)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    1.2. Harmonisation des P.P.S.P.S./Mise à jour du P.G.C
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat1[1] !== 0 ? reaHourCat1[1] : ""}
                      onChange={(e) => this.props.handleChange(e, 1)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    1.3. Tenue Registre Journal
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat1[2] !== 0 ? reaHourCat1[2] : ""}
                      onChange={(e) => this.props.handleChange(e, 2)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="subtitle" colSpan={2}>
                    2. Travaux
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    2.1. Visites de chantier
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat1[3] !== 0 ? reaHourCat1[3] : ""}
                      onChange={(e) => this.props.handleChange(e, 3)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    2.2. Réunions de C.I.S.S.C.T
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat1[4] !== 0 ? reaHourCat1[4] : ""}
                      onChange={(e) => this.props.handleChange(e, 4)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    2.3. Participation au rendez-vous de chantier
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat1[5] !== 0 ? reaHourCat1[5] : ""}
                      onChange={(e) => this.props.handleChange(e, 5)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="subtitle" colSpan={2}>
                    3. Diffusion des documents
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    3.1. Récolement des pièces liées au D.I.U.O
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat1[6] !== 0 ? reaHourCat1[6] : ""}
                      onChange={(e) => this.props.handleChange(e, 6)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    3.2. Mise à jour du D.I.U.O.final
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat1[7] !== 0 ? reaHourCat1[7] : ""}
                      onChange={(e) => this.props.handleChange(e, 7)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    3.3. Diffusion du P.G.C. et du D.I.U.O
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      name="reaHourCat1"
                      inputProps={{ min: "0", step: "0.5" }}
                      value={reaHourCat1[8] !== 0 ? reaHourCat1[8] : ""}
                      onChange={(e) => this.props.handleChange(e, 8)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className="head-row">
                  <TableCell className="MuiTableCell-head">TOTAL</TableCell>
                  <TableCell className="MuiTableCell-head">
                    {totalReaHourCat1} /h
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className="subtable-btn">
            <Button
              variant="contained"
              className="btn-valid"
              onClick={() => this.props.handleClick()}
            >
              Valider
            </Button>
            <Button
              variant="contained"
              className="btn-reset"
              onClick={this.props.handleResetArray}
            >
              Annuler
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default HtpCategory1;
