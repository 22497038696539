import React from 'react';
import history from '../Routes/history';
import * as EmailValidator from "email-validator"
import { faUser, faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import '../../Styles/LoginPage/Login.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from "react-router-dom"
import {Image} from 'cloudinary-react';

class LoginForm extends React.Component{
   constructor(props) {
      super(props);
  
      this.state = {
        errorMessage:"" ,      
        email: "",
        password: "",
        showPass:false,
        formErrors: { 
          email: "",
          password: ""
        }
      };
      this.handleChange=this.handleChange.bind(this)
    }
 /**
  * Envoie les données de l'utilisateur afin qu'il se connecte à l'ERP
  */ 
    handleSubmit(){
      if(EmailValidator.validate(this.state.email)){
        var login={}
        login.email=this.state.email
        login.password=this.state.password
        const requestOptions={
          method:"POST",
          headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
          body: JSON.stringify(login)
        }
        fetch("/api/login/auth",requestOptions)
        .then(async(res) =>{
          if(res.status==403){
            this.setState({errorMessage:await res.text()})
          }
          if(res.status==401){
            this.setState({errorMessage:await res.text()})
          }
          if(res.status==200){
            return res.json()
          }
        })
        .then(async(res) =>{
          // console.log(res)
          if(res.access_token){
            localStorage.setItem("user",JSON.stringify(res))
            history.push("/dashboard")
          }
        })
        .catch(err => console.log(err))
      }
      else {
        this.setState({errorMessage:"Votre adresse mail est invalide"})
      }
    }
  
    handleChange(event){
      this.setState({[event.target.name]:event.target.value})
    }

    hideOrShow(){
      this.setState({showPass:!this.state.showPass})
    }

    logout(){
      localStorage.removeItem("user")
    }

    showPicture(user){
      if(user.image==""){
        return <span style={{fontSize:"30px"}}><FontAwesomeIcon icon={faUser}/></span>
      }
      else {
        return <span><Image className="styleIcon" cloudName="ericguillet" publicId={user.image} crop="scale" /></span>
      }
    }
   render(){
     const user = JSON.parse(localStorage.getItem("user"))
        return(
            <div className="form"> 
                  <h1>SEC</h1>    
                  {user ? this.showPicture(user)
                   : <span style={{fontSize:"30px"}}><FontAwesomeIcon icon={faUser}/></span>}   
                  <br />
                  <br />
                  {user!==null ? <div><span>Vous êtes connecté en tant que {user.lname+" "+user.fname}</span>
                    <p><Link to="/dashboard">Aller vers le Dashboard</Link></p>
                    <p><Link to="/login" onClick={this.logout.bind(this)}>Se déconnecter</Link></p>
                    </div>
                    : <React.Fragment><div className="mail">
                        <label>Email</label> <br /> <br />
                        <input type="text" className="form-control" id="email" name="email" onChange={this.handleChange}  />
                      </div>
                      <br/>
                      <div className="pwd">
                        <label>Password</label> <br /> <br />
                        <input type={!this.state.showPass ? "password" : "text"} className="form-control" id="password" name="password" onChange={this.handleChange}/>
                        <span onClick={this.hideOrShow.bind(this)}><FontAwesomeIcon icon={!this.state.showPass ? faEye : faEyeSlash}/></span>
                      </div>
                      <br />  
                      <p><Link to="/forgotpass">Mot de Passe Oublié</Link></p>
                      <p><Link to="/register">S'inscrire</Link> </p>           
                      <button className="btn btn-primary" onClick={this.handleSubmit.bind(this)}>Login</button></React.Fragment>}
                      {this.state.errorMessage!=="" ? <div style={{color:"red"}}>{this.state.errorMessage}</div> : ""}         
            </div>
        );
    }
}
export default LoginForm;