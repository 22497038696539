import React from "react";
import { connect } from "react-redux";
import Header from '../Header/Header';
import DocMenu from '../DocMenu';
import NumDoss from '../NumDoss';
import ListTable from './FactureRecap/ListTable';
import GenPdf from '../GenPdf';
import { getDevisRea, getContact, genRecapPDF, getFactureRecapPDF } from './Utils';
import { fetchAllReminder } from "../../redux/actions/reminderActions";
import { fetchAllFac, fetchFac } from '../../redux/actions/facActions';
import { changeTitleName, changePdfFileName } from "../../redux/actions/emailAction";
import "../../Styles/Factures/FactureRecap.scss";

class FactureRecap extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      facturesData: [],
      openFile: false,
      file: null,
      disableButton: false,
      totalCumulativeAmount: 0,
      totalAmount: 0,
      refFacRecap: "",
      bdcFacRecap: "",
    }
  }

  componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    this.props.dispatch(fetchAllReminder())
    if (this.props.factures.length > 0) {
      this.props.dispatch(fetchFac("conception"));
      this.loadData()
    }
  }

  componentDidUpdate(prevProps) {
    const { echeance, factures, dossierValue, factureIndex } = this.props;
    const { echeance: prevEcheance, factures: prevFactures, dossierValue: prevDossierValue, factureIndex: prevFactureIndex } = prevProps;
    const isPaymentMethod26 = echeance.payment_method === 26;

    if (factures.length === 0) {
      this.props.dispatch(fetchAllFac());
    }

    if (factureIndex !== prevFactureIndex) {
      const fetchType = isPaymentMethod26
        ? factureIndex < 3
          ? "conception"
          : "realisation"
        : factureIndex === 1
          ? "conception"
          : "realisation";
      this.props.dispatch(fetchFac(fetchType));
    }

    if (
      echeance !== prevEcheance ||
      factures !== prevFactures ||
      dossierValue !== prevDossierValue ||
      factureIndex !== prevFactureIndex
    ) {
      this.loadData();
    }
  }

  initializeFefBdcValues = () => {
    this.setState({
      refFacRecap: "",
      bdcFacRecap: "",
    });
  }

  handleChangeDateToMonth(dateString) {
    const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    const year = date.getFullYear()
    const month = months[monthIndex];

    return `${month} ${year}`;
  }

  handleGetDateReminder = (refFacture, firstReminder) => {
    const { dossierValue, reminders } = this.props;
    const formatRefFacture = refFacture !== "C1" && refFacture !== "C2" ? `${refFacture} ` : refFacture;
    const currentReminders = reminders
      .filter(item => item.ndossier === dossierValue && item.first_reminder === firstReminder && item.numclient.includes(formatRefFacture))
    const formatedDate = currentReminders.length > 0 ? new Date(currentReminders[0].date_reminder).toLocaleDateString('en-CA') : ""
    return formatedDate
  }

  handleGetCumulativeAmount = (facturesData, amount, index) => {
    const { echeance, factureIndex } = this.props;

    const isPaymentMethod26 = echeance.payment_method === 26;
    const paymentMethodOffset = isPaymentMethod26 ? 2 : 1;

    if (factureIndex - paymentMethodOffset <= index) return null

    let result = facturesData.slice(0, isPaymentMethod26 ? 2 : 1)
      .reduce((total, facture) => total + facture.total_ht, 0);

    return result + amount * (index + 1);
  }

  handleGetNfacture = () => {
    const { factures, dossierValue, factureIndex } = this.props;
    let nfactures = ""

    const facturesFiltred = factures.filter(item => item.ndossier === dossierValue);

    for (let i = 0; i < factureIndex; i++) {
      const newNfacture = facturesFiltred[i].nfacture.split(" ").join("/")
      nfactures += facturesFiltred[i].validation !== 1 ? nfactures.length > 0 ? " & " + newNfacture : newNfacture : "";
    }

    return nfactures;
  }

  formatDate = (date) => date ? new Date(date).toLocaleDateString('en-CA') : null;

  getValidationFacture = (refFacture) => {
    const { factures, dossierValue } = this.props;
    const formatRefFacture = refFacture !== "C1" && refFacture !== "C2" ? `${refFacture} ` : refFacture;
    const result = factures.filter(item => item.ndossier === dossierValue && item.numclient.includes(formatRefFacture))
    const payment_date = result.length > 0 ? result[0].payment_date === null ? null : this.formatDate(result[0].payment_date) : null
    const validation = result.length > 0 ? result[0].validation : null

    return { payment_date, validation }
  }

  getNumFacture = (refFacture, paymentMethod) => {
    const { factures, dossierValue, factureIndex } = this.props;
    const formatRefFacture = refFacture !== "C1" && refFacture !== "C2" ? `${refFacture} ` : refFacture;
    const result = factures.filter(item => item.ndossier === dossierValue && item.numclient.includes(formatRefFacture))
    if (factureIndex < 2) {
      return ""
    }
    if (refFacture === 'C2') {
      return result.length > 0 && factureIndex > 1 ? `${result[0].nfacture.split(" ")[1]} 74% ` : ""
    }
    return result.length > 0 && (parseInt(refFacture.substring(1)) + (paymentMethod == 26 ? 2 : 1) <= factureIndex) ? `${result[0].nfacture.split(" ")[1]} ` : ""
  }

  handleChangeRefFacRecap = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value.toUpperCase() })
  }

  loadData() {
    this.props.dispatch(changePdfFileName(""))
    const { factures, dossierValue, echeance, devisrea, factureIndex } = this.props;
    let facturesData = [...this.state.facturesData]
    let facturesReaDates = echeance.dates ? JSON.parse(echeance.dates) : []
    const amount_ht = getDevisRea(devisrea, dossierValue).totalht / facturesReaDates.length
    const paymentMethod = echeance.payment_method
    let totalCumulativeAmount = 0
    let totalAmount = 0

    facturesData = factures
      .filter(item => item.ndossier === dossierValue && item.numclient.includes('C1'))
      .map(item => ({
        month_ech: `${this.handleChangeDateToMonth(item.date_ech)} FAC: ${item.nfacture.split(" ")[1]}${paymentMethod === 26 ? " 26%" : " 34%"} C1`,
        date_ech: this.formatDate(item.date_ech),
        first_reminder: this.handleGetDateReminder("C1", 1),
        second_reminder: this.handleGetDateReminder("C1", 0),
        total_ht: item.total_ht,
        cumulative_amount: item.total_ht,
        payment_date: this.formatDate(item.payment_date),
        validation: item.payment_date === null ? false : true
      }));
    totalCumulativeAmount = facturesData[0]?.total_ht
    totalAmount = facturesData[0]?.payment_date === null ? facturesData[0]?.total_ht : 0

    if (facturesData.length > 0) {
      const validationData = this.getValidationFacture("C2");

      if (paymentMethod == 26) {
        const total_ht = parseFloat(((facturesData[0].total_ht / 26) * 74).toFixed(2));
        facturesData.push({
          month_ech: `${this.handleChangeDateToMonth(echeance.datepgce)} FAC: ${this.getNumFacture('C2')}C2`,
          date_ech: this.formatDate(echeance.datepgce),
          first_reminder: this.handleGetDateReminder("C2", 1),
          second_reminder: this.handleGetDateReminder("C2", 0),
          total_ht,
          cumulative_amount: factureIndex > 1 ? facturesData[0].total_ht + total_ht : null,
          payment_date: validationData.payment_date === null ? null : validationData.payment_date,
          validation: validationData.validation === 1 ? true : false
        })
        totalCumulativeAmount = factureIndex > 1 ? totalCumulativeAmount + (totalCumulativeAmount / 26) * 74 : totalCumulativeAmount
        totalAmount = factureIndex > 1 && validationData.payment_date === null ? totalAmount + ((facturesData[0]?.total_ht / 26) * 74) : totalAmount
      }

      facturesReaDates.forEach((item, index) => {
        const validationData = this.getValidationFacture(`R${index + 1}`);
        facturesData.push({
          month_ech: `${this.handleChangeDateToMonth(item.dateech)} FAC: ${this.getNumFacture(`R${index + 1}`, paymentMethod)}R${index + 1}`,
          date_ech: this.formatDate(item.dateech),
          first_reminder: this.handleGetDateReminder(`R${index + 1}`, 1),
          second_reminder: this.handleGetDateReminder(`R${index + 1}`, 0),
          total_ht: amount_ht,
          cumulative_amount: this.handleGetCumulativeAmount(facturesData, amount_ht, index),
          payment_date: validationData.payment_date === null ? null : validationData.payment_date,
          validation: validationData.validation === 1 ? true : false
        })
      })
      totalCumulativeAmount += paymentMethod === 26 && factureIndex <= 2
        ? 0
        : paymentMethod === 26
          ? amount_ht * (factureIndex - 2)
          : amount_ht * (factureIndex - 1);

      if (factureIndex > (paymentMethod === 26 ? 2 : 1)) {
        const loopCount = paymentMethod === 26 ? (factureIndex - 2) : (factureIndex - 1);

        for (let i = 0; i < loopCount; i++) {
          totalAmount += this.getValidationFacture(`R${i + 1}`).payment_date === null ? amount_ht : 0
        }
      }
    }
    this.setState({ facturesData, totalCumulativeAmount, totalAmount, refFacRecap: "", bdcFacRecap: "", file: null });
  }

  getSignatureDate(numdossier) {
    const { factures } = this.props
    const firstFacture = factures.find(f => f.ndossier === numdossier && f.numclient.includes('C1'))
    return new Date(firstFacture.date_ech).toLocaleDateString('en-CA')
  }

  getDevisCon() {
    var arr = [];
    for (let i = 0; i < this.props.deviscon.length; i++) {
      if (
        this.props.deviscon[i].ndossier == this.props.dossierValue
      ) {
        arr.push(this.props.deviscon[i]);
      }
    }
    var max = Math.max.apply(
      Math,
      arr.map((item) => item.iddeviscon)
    );
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].iddeviscon == max) {
        return arr[i];
      }
    }
    return "";
  }

  getDevisRea() {
    var arr = [];
    for (let i = 0; i < this.props.devisrea.length; i++) {
      if (
        this.props.devisrea[i].ndossier == this.props.dossierValue
      ) {
        arr.push(this.props.devisrea[i]);
      }
    }
    var max = Math.max.apply(
      Math,
      arr.map((item) => item.iddevisrea)
    );
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].iddevisrea == max) {
        return arr[i];
      }
    }
    return "";
  }

  getEchPrice(paymentMethod, duree) {
    if (paymentMethod == 26) {
      return this.getDevisRea().totalht / Math.ceil(duree / 2)
    } else return ((this.getDevisRea().totalht + this.getDevisCon().totalht) * 0.66) / duree
  }

  getEchNum(paymentMethod, duree) {
    if (paymentMethod == 26) {
      return Math.ceil(duree / 2) + " échéances bimestrielles";
    } else return duree + " échéances mensuelles";
  }

  formatNumCLient = (inputString) => {
    const formattedString = inputString.replace(/(FR )(\d+)(\/C)/g, (match, p1, p2, p3) => {
      const formattedNumbers = p2.split('').join('-');
      return `${p1}${formattedNumbers}${p3}`;
    });
    return formattedString;
  };

  handleSaveRecap = async () => {
    this.setState({ disableButton: true })
    const { dossierValue, echeance, facturations, factureIndex } = this.props
    const { facturesData, totalCumulativeAmount, totalAmount, refFacRecap, bdcFacRecap } = this.state
    const paymentMethod = echeance.payment_method
    let refFacture = "";

    if (paymentMethod === 26) {
      refFacture = factureIndex < 3 ? `C${factureIndex}` : `R${factureIndex - 2}`;
    } else {
      refFacture = factureIndex === 1 ? `C${factureIndex}` : `R${factureIndex - 1}`;
    }

    refFacture = refFacture !== "C1" && refFacture !== "C2" ? `${refFacture} ` : refFacture;

    const id_facturation = facturations.filter(item => item.ndossier === dossierValue && item.numclient.includes(refFacture))[0].id

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
      body: JSON.stringify({
        ndossier: dossierValue,
        id_facturation,
        data: facturesData,
        cumulative_amount: totalCumulativeAmount.toFixed(2),
        total_amount: totalAmount.toFixed(2),
        ref_facture: refFacRecap,
        bdc_facture: bdcFacRecap,
      })
    }

    await fetch("/api/facture/saveRecap", requestOptions)
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
    await this.setState({ disableButton: false })
  }

  handleGenRecap = async () => {
    await this.handleSaveRecap();
    this.setState({ openFile: true, disableButton: true, file: null });

    const { dossierValue, echeance, factures, factureIndex, dossiers, contact } = this.props
    const { facturesData, totalCumulativeAmount, totalAmount, refFacRecap, bdcFacRecap } = this.state
    const maitreOuvrage = getContact(dossiers, contact, dossierValue)

    const paymentMethod = echeance.payment_method
    let refFacture = "";
    let json = {};
    const devis_rea = this.getDevisRea();
    const duree = devis_rea.duree;
    const devisReaTotalht = devis_rea.totalht;
    const devisConTotalht = this.getDevisCon().totalht;

    if (paymentMethod === 26) {
      refFacture = factureIndex < 3 ? `C${factureIndex}` : `R${factureIndex - 2}`;
    } else {
      refFacture = factureIndex === 1 ? `C${factureIndex}` : `R${factureIndex - 1}`;
    }

    refFacture = refFacture !== "C1" && refFacture !== "C2" ? `${refFacture} ` : refFacture;

    const currentFacture = factures.filter(item => item.ndossier === dossierValue && item.numclient.includes(refFacture))[0]

    json.payment_method = paymentMethod;
    json.ndossier = dossierValue;
    json.maitre_ouvrage = JSON.stringify(maitreOuvrage);
    json.dossier_op = currentFacture.dossier;
    json.ref_facture = refFacture.trim();
    json.data = facturesData.map(item => {
      return {
        ...item,
        total_ht: item.total_ht.toFixed(2),
        validation: item.validation ? "100%" : "",
        cumulative_amount: item.cumulative_amount ? `${item.cumulative_amount.toFixed(2)} €` : "",
      }
    });
    json.cumulative_total = totalCumulativeAmount.toFixed(2);
    json.amount_to_pay = totalAmount.toFixed(2);
    json.amount_to_pay_tva = (totalAmount * 0.2).toFixed(2);
    json.amount_to_pay_ttc = (totalAmount * 1.2).toFixed(2);
    json.date_signature = this.getSignatureDate(dossierValue);
    json.numclient = this.formatNumCLient(currentFacture.numclient)
    json.nfacture = this.handleGetNfacture();
    json.duree = duree
    json.ref_fac_recap = refFacRecap.trim()
    json.bdc_fac_recap = bdcFacRecap.trim()

    json.datepgce = new Date(echeance.datepgce).toLocaleDateString('en-CA');
    json.echnum = this.getEchNum(paymentMethod, duree);
    json.totalht_con26 = (devisConTotalht * 0.26).toFixed(2);
    json.totalttc_con26 = (devisConTotalht * 0.26 * 1.2).toFixed(2);
    json.totalht_con74 = (devisConTotalht * 0.74).toFixed(2);
    json.totalttc_con74 = (devisConTotalht * 0.74 * 1.2).toFixed(2);
    json.totalht_rea = devisReaTotalht.toFixed(2);
    json.totalttc_rea = (devisReaTotalht * 1.2).toFixed(2);
    json.echprice_ht = this.getEchPrice(paymentMethod, duree).toFixed(2);
    json.echprice_ttc = (this.getEchPrice(paymentMethod, duree) * 1.2).toFixed(2);
    json.totalht = (Number(devisConTotalht) + Number(devisReaTotalht)).toFixed(2);
    json.totalttc = (Number(devisConTotalht * 1.2) + Number(devisReaTotalht * 1.2)).toFixed(2);

    json.totalht_con = devisConTotalht.toFixed(2);
    json.totalttc_con = (devisConTotalht * 1.2).toFixed(2);

    const requestOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(json)
    }

    let blob = await genRecapPDF(requestOptions);
    if (blob) {
      this.setState({ file: blob.size > 100 ? blob : null, openFile: blob.size > 100 ? true : false });
    }
    await this.setState({ disableButton: false });
  }


  handleShowPdf = async () => {
    const { dossierValue, echeance, factureIndex } = this.props
    this.setState({ file: null, openFile: true, disableButton: true });
    const paymentMethod = echeance.payment_method
    let refFacture = "";

    if (paymentMethod === 26) {
      refFacture = factureIndex < 3 ? `C${factureIndex}` : `R${factureIndex - 2}`;
    } else {
      refFacture = factureIndex === 1 ? `C${factureIndex}` : `R${factureIndex - 1}`;
    }
    let response = await getFactureRecapPDF(dossierValue, refFacture, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob, openFile: true });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    } else {
      this.setState({ openFile: false });
    }
    await this.setState({ disableButton: false });
  };


  handleGetPdfFile = async () => {
    const { dossierValue, echeance, factureIndex } = this.props
    this.setState({ file: null });
    const paymentMethod = echeance.payment_method
    let refFacture = "";

    if (paymentMethod === 26) {
      refFacture = factureIndex < 3 ? `C${factureIndex}` : `R${factureIndex - 2}`;
    } else {
      refFacture = factureIndex === 1 ? `C${factureIndex}` : `R${factureIndex - 1}`;
    }

    let response = await getFactureRecapPDF(dossierValue, refFacture, 'pdf');
    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    const { dossierValue, echeance, factureIndex } = this.props
    this.setState({ file: null });
    const paymentMethod = echeance.payment_method
    let refFacture = "";

    if (paymentMethod === 26) {
      refFacture = factureIndex < 3 ? `C${factureIndex}` : `R${factureIndex - 2}`;
    } else {
      refFacture = factureIndex === 1 ? `C${factureIndex}` : `R${factureIndex - 1}`;
    }

    let response = await getFactureRecapPDF(dossierValue, refFacture, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  closeDoc = () => {
    this.setState({ openFile: false, file: null });
  }

  handleChangeTitleName() {
    const { echeance, factureIndex } = this.props
    const paymentMethod = echeance.payment_method
    let refFacture = "";

    if (paymentMethod === 26) {
      refFacture = factureIndex < 3 ? `C${factureIndex}` : `R${factureIndex - 2}`;
    } else {
      refFacture = factureIndex === 1 ? `C${factureIndex}` : `R${factureIndex - 1}`;
    }
    this.props.dispatch(changeTitleName(`Facture Récapitulative ${refFacture}`))
  }

  render() {
    const { factures } = this.props
    const { facturesData, totalCumulativeAmount, totalAmount, refFacRecap, bdcFacRecap, disableButton, file } = this.state

    return (
      <div className="facture-recap">
        <Header />
        <DocMenu
          file={file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Facture Récapitulative</h1>
        <NumDoss type="reminder" />
        <ListTable
          factures={factures}
          facturesData={facturesData}
          totalCumulativeAmount={totalCumulativeAmount}
          totalAmount={totalAmount}
          refFacRecap={refFacRecap}
          bdcFacRecap={bdcFacRecap}
          disableButton={disableButton}
          handleChangeRefFacRecap={this.handleChangeRefFacRecap.bind(this)}
          initializeFefBdcValues={this.initializeFefBdcValues.bind(this)}
          handleSaveRecap={this.handleSaveRecap.bind(this)}
          handleGenRecap={this.handleGenRecap.bind(this)}
          handleShowPdf={this.handleShowPdf}
        />
        <GenPdf file={this.state.file} isOpen={this.state.openFile} closeDoc={this.closeDoc} scale={this.state.file !== null ? 1 : 0.5} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  facturations: state.facture.items,
  factures: state.facture.allfacture,
  reminders: state.reminder.items,
  deviscon: state.devis.deviscon,
  devisrea: state.devis.devisrea,
  dossierValue: state.devis.dossierValue,
  factureIndex: state.facture.facindex,
  dossiers: state.dossier.items,
  contact: state.contact.items,
  echeance: state.echeances.itemNdossier,
});
export default connect(mapStateToProps)(FactureRecap);