import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';

class SuppDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      index: this.props.index,
    }
  }

  componentDidUpdate(prevProps) { 
    if (this.props.index !== prevProps.index) {
      this.setState({ index: this.props.index })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Dialog open={this.props.open} onClose={() => this.props.setOpen(false, this.state.index)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">SUPPRESSION DOSSIER</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Voulez-vous vraiment supprimer le dossier N°{this.props.dossierTab.length !== 0 ? this.props.dossierTab[this.state.index].ndossier : ""} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" style={{ backgroundColor: "#ff7f50", color: "white", boxShadow: '0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F' }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#ff9b61"
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#ff7f50"
              }}
              onClick={() => {
                this.props.onConfirm()
                this.props.setOpen(false, 0)
              }}
            >
              Oui
            </Button>
            <Button color="primary" style={{ backgroundColor: "#F5B039", color: "white", boxShadow: '0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F' }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "rgb(247, 194, 104)"
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#F5B039"
              }}
            onClick={() => this.props.setOpen(false, this.state.index)}>
              Non
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => ({
  dossierTab: state.devis.dossierTab,
});
export default connect(mapStateToProps)(SuppDialog)