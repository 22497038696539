import React from "react";
import ReactTable from "react-table-6";
import Button from "@material-ui/core/Button";
import moment from "moment";

class UsersHist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.quotaData,
      dateStart: null,
      dateEnd: null,
    };
    this.handleResetData = this.handleResetData.bind(this);
    this.dateStartInputRef = React.createRef();
    this.dateEndInputRef = React.createRef();
  }
  componentDidUpdate(prevProps) { 
    if (this.props.quotaData !== prevProps.quotaData) {
      this.setState({
        data: this.props.quotaData,
      });
    }
  }

  
  handleResetDate = () => {
    this.dateStartInputRef.current.value = "";
    this.dateEndInputRef.current.value = "";
    this.setState({
      dateStart: null,
      dateEnd: null
    })
  };

  handleChangeDate(date, value) {
    this.setState({
      [date === "start" ? "dateStart" : "dateEnd"] : value,
    });
  }

  handleResetData() {
    this.setState({
      data: this.props.quotaData,
    });
    this.handleResetDate();
  }

  handleFilter = () => {
    const { dateStart, dateEnd } = this.state;
    const filteredData = this.props.quotaData.filter((row) => {
      const rowDate = moment(row.dateaction, "DD/MM/YYYY").toDate();
      if (dateStart && rowDate < moment(dateStart).toDate()) return false;
      if (dateEnd && rowDate > moment(dateEnd).toDate()) return false;
      return true;
    });
    this.setState({ data: filteredData });
    this.handleResetDate();
  };
  

  render() {
    const columnsCon = [
      {
        Header: "Utilisateur",
        accessor: "username",
        width: 150,
      },
      {
        Header: "Opération",
        accessor: "action",
        width: 90,
        Cell: ({ value }) => (value === "add" ? "Ajouter" : "Supprimer"),
      },
      {
        Header: "Type",
        accessor: "type",
        width: 90,
        Cell: ({ value }) => (value === "Realisation" ? "Réalisation" : "Conception"),
      },
      {
        Header: "Tâche",
        accessor: "tache",
      },
      {
        Header: "Heure",
        accessor: "timeaction",
        width: 70,
        style: {
          textAlign: "center",
        },
      },
      {
        Header: "Date",
        accessor: "dateaction",
        width: 100,
        style: {
          textAlign: "center",
        },
      },
    ];
    const parentColumn = [
      {
        Header: "Historique",
        columns: columnsCon,
      },
    ];
    return (
      <div className="usersHist">
        <div className="usersHist__filter">
          <span>De</span>
          <input ref={this.dateStartInputRef} type="date" onChange={(e) => this.handleChangeDate("start", e.target.value)}/>
          <span>à</span>
          <input ref={this.dateEndInputRef} type="date" onChange={(e) => this.handleChangeDate("end", e.target.value)}/>
          <Button variant="contained" onClick={this.handleFilter} disabled={!this.state.dateStart || !this.state.dateEnd}>Filtrer</Button>
          <Button variant="contained" className="reset-btn" onClick={this.handleResetData}>Annuler</Button>
        </div>
        <ReactTable
          data={this.state.data}
          columns={parentColumn}
          defaultPageSize={10}
          pageSizeOptions={[10, 20, 50, 100]}
          previousText={"Précédent"}
          nextText={"Suivant"}
          loadingText={"Chargement en cours..."}
          noDataText={"Aucune donnée trouvée."}
          rowsText={"lignes"}
          ofText={"de"}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              onClick: () => {
                this.props.handleOpen(rowInfo.original);
              },
              style: {
                cursor: "pointer",
                background:
                  rowInfo && rowInfo.row.action === "add"
                    ? "green"
                    : rowInfo && rowInfo.row.action === "remove"
                    ? "red"
                    : "transparent",
                color: "white",
              },
            };
          }}
        />
        <Button
          variant="contained"
          className="generate-pdf-btn"
          onClick={() => this.props.genPDF(this.state.data)}
        >
          Générer PDF
        </Button>
        <Button
          variant="contained"
          className="display-pdf-btn"
          onClick={this.props.showPDF}
        >
          Afficher PDF
        </Button>
      </div>
    );
  }
}
export default UsersHist;
