import React from 'react';
import * as EmailValidator from "email-validator"
import '../../Styles/LoginPage/Login.scss';
import {Link} from "react-router-dom"

class SendMailPass extends React.Component{
   constructor(props) {
      super(props);
  
      this.state = {
        errorMessage:"" ,
        confirmMessage:"", 
        email:"",     
      };
      this.handleChange=this.handleChange.bind(this)
    }
 /**
  * Envoie le mail rentré à la partie Back afin d'envoyer un mail pour le changement de mot de passe
  */ 
    handleSubmit(){ 
      if(EmailValidator.validate(this.state.email)){
        const requestOptions={
          method:"POST",
          headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
          body: JSON.stringify({email: this.state.email})
        }
        fetch("/api/sendMailPassword",requestOptions)
        .then(async(res) =>{
          if(res.status==401){
            this.setState({confirmMessage:""})
            this.setState({errorMessage:await res.text()})
          }
          if(res.status==200){
            this.setState({errorMessage:""})
            this.setState({confirmMessage:await res.text()})
          }
        })
        .catch(err => console.log(err))
      }
      else {
        this.setState({confirmMessage:""})
        this.setState({errorMessage:"Votre adresse mail est invalide"})
      }
    }
  
    handleChange(event){
      this.setState({[event.target.name]:event.target.value})
    }


   render(){
        return(
            <div className="form"> 
                  <h1 style={{fontSize:"20px"}}>Mot de Passe Oublié</h1>
                  <div className="mail">
                    <label>Adresse Mail</label> <br /> <br />
                    <input type="text" name="email" onChange={this.handleChange}/>
                  </div>   
                  <br/> 
                  <p><Link to="/login">Se Connecter</Link></p>         
                    <button className="btn btn-primary" onClick={this.handleSubmit.bind(this)} style={{fontSize:"15px"}}>
                    Envoyer
                    </button>
                    {this.state.errorMessage!=="" ? <div style={{color:"red"}}>{this.state.errorMessage}</div> : ""} 
                    {this.state.confirmMessage!=="" ? <div style={{color:"green"}}>{this.state.confirmMessage}</div> : ""} 

            </div>
        );
    }
}
export default SendMailPass;