import React, { useEffect } from "react";
import BaseTable, { Column } from "react-base-table";
import "react-base-table/styles.css";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from "react-redux";
import { fetchAllLots } from "../../redux/actions/vicActions";

const LotsVICTable = ({ allLots, fetchAllLots, loadLotQuestions, handleDeleteLot }) => {
  useEffect(() => {
    fetchAllLots();
  }, []);

  const columns = [ 
    {
      key: "num-cat",
      dataKey: "num-cat",
      width: 60,
      align: Column.Alignment.CENTER,
      cellRenderer: ({ rowData, rowIndex }) => (
        <p key={rowIndex}>{rowIndex + 1}</p>
      ),
    },
    {
      title: "Nom du lot",
      key: "name",
      dataKey: "name",
      width: 450,
      align: Column.Alignment.CENTER,
      cellRenderer: ({ rowData, rowIndex }) => (
        <div key={rowIndex}>{rowData.name}</div>
      ),
    },
    {
      key: "edit",
      width: 60,
      align: Column.Alignment.CENTER,
      cellRenderer: ({ rowData, rowIndex }) => (
        <IconButton
          key={rowIndex}
          className="edit-employment-law"
          aria-label="edit-employment-law"
          size="small"
          style={{ backgroundColor: "transparent" }}
          onClick={() => loadLotQuestions(rowData)}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      key: "del",
      width: 60,
      align: Column.Alignment.CENTER,
      cellRenderer: ({ rowData, rowIndex }) => (
        <IconButton
          key={rowIndex}
          className="del-employment-law"
          aria-label="del-employment-law"
          size="small"
          style={{ backgroundColor: "transparent" }}
          onClick={() => handleDeleteLot(rowData)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div className="display-categories">
      <BaseTable
        columns={columns}
        width={630}
        headerHeight={50}
        rowHeight={50}
        height={allLots.length * 50 + 50}
        data={allLots.map((row, index) => ({ ...row, _uniqueId: index }))}
        rowStyle={{
          height: "50px",
        }}
        rowKeyField="_uniqueId"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  allLots: state.vic.allLots,
});

const mapDispatchToProps = {
  fetchAllLots,
};

export default connect(mapStateToProps, mapDispatchToProps)(LotsVICTable);
