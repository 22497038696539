import { Button } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import Header from "../Header/Header";
import GenPdf from "../GenPdf";
import DocMenu from "../DocMenu";
import VICMenu from "./VICMenu";
import "../../Styles/VIC/VICValidation.scss";
import { 
  genPDF,
  getClient,
  getLot,
  getLotNbrPages,
  getPDF,
  getVIC,
} from "./Utils";
import { fetchVIC } from "../../redux/actions/vicActions";
import { fetchEntreprise } from "../../redux/actions/entrepriseActions";
import { changeTitleName, changePdfFileName } from "../../redux/actions/emailAction";

class VICValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      isOpen: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entrepriseValue !== this.props.entrepriseValue || prevProps.dossierValue !== this.props.dossierValue || prevProps.lotValue !== this.props.lotValue) {
      this.props.dispatch(changePdfFileName(""))
      this.setState({ file: null });
    }
  }
  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }
  async handleGenClick(entreprise) {
    this.setOpen(true);
    this.setState({ file: null });
    let blob = await genPDF(
      this.props.dossierValue,
      entreprise.id,
      this.props.lotValue,
      getLotNbrPages(this.props.lots, this.props.lotValue),
      getClient(this.props)
    );
    if (blob) {
      this.setState({ file: blob });
    }
  }

  async handleShowPdf() {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'pdf');

      if (response.blob.size > 100) {
        this.setState({ file: response.blob, isOpen: true });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  }

  handleGetPdfFile = async () => {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'pdf');

      if (response.blob.size > 100) {
        this.setState({ file: response.blob });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  };

  async handleGetWordFile() {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'word');

      if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this.setState({ file: response.blob });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  }

  async suppVIC(entreprise) {
    const { vic, dossierValue, lots, lotValue } = this.props;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        idvic: getVIC(vic, dossierValue, lotValue, entreprise.id).id,
        ndossier: dossierValue,
        lot: getLot(lots, lotValue),
        entreprise: entreprise,
      }),
    };
    fetch("/api/vic/suppVIC", requestOptions).catch((err) => {
      console.log(err);
    });
    await this.props.dispatch(fetchVIC(dossierValue));
    await this.props.dispatch(fetchEntreprise());
  }

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`VIC`))
  }

  render() {
    var entreprise = "";
    if (
      this.props.entrepriseValue !== undefined &&
      this.props.entrepriseValue !== ""
    ) {
      entreprise = JSON.parse(this.props.entrepriseValue);
    }
    return (
      <div className="vicValid">
        <Header />
        <DocMenu
          file={this.state.file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Validation de la VIC</h1>
        <VICMenu />
        <div className="data">
          <div className="validation">
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.handleGenClick(entreprise)}
              disabled={entreprise.id === undefined ? true : false}
            >
              Générer le PDF
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.handleShowPdf()}
              disabled={entreprise.id === undefined ? true : false}
            >
              Afficher le PDF
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={entreprise.id === undefined ? true : false}
            >
              Valider la VIC
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.suppVIC(entreprise)}
              disabled={entreprise.id === undefined ? true : false}
            >
              Supprimer la VIC
            </Button>
          </div>
        </div>
        <GenPdf
          isOpen={this.state.isOpen}
          file={this.state.file}
          closeDoc={() => this.setOpen(false)}
          scale={1}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
  lotValue: state.vic.lotValue,
  lots: state.vic.lots,
  vic: state.vic.items,
  contact: state.contact.items,
  entreprise: state.entreprise.items,
  entrepriseValue: state.entreprise.entrepriseValue,
});
export default connect(mapStateToProps)(VICValidation);
