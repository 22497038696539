import React from "react"
import { connect } from "react-redux"
import { newEntrepriseValue } from "../../redux/actions/entrepriseActions"
import { getLotNbrPages } from "./Utils"

class EntrepriseVICList extends React.Component{
    constructor(props){
        super(props)
        this.state={
        }
    }
    async componentDidMount(){ 
        if(this.props.entrepriseValue!==undefined && this.props.entreprise.length !== 0){
            await this.props.dispatch(newEntrepriseValue(this.props.entrepriseValue))
        }
        else{
            await this.props.dispatch(newEntrepriseValue(JSON.stringify(this.filterEntreprise()[0])))
        }
    }
    async componentDidUpdate(prevProps){
        if(this.props.lotValue !== prevProps.lotValue || this.props.dossierValue !== prevProps.dossierValue){
            await this.props.dispatch(newEntrepriseValue(JSON.stringify(this.filterEntreprise()[0])))
        }
        if(this.props.vic.length!==prevProps.vic.length){
            const length = this.filterEntreprise().length
            await this.props.dispatch(newEntrepriseValue(JSON.stringify(this.filterEntreprise()[length-1])))
        }
        if(this.props.entrepriseValue && (JSON.stringify(this.props.entreprise) != JSON.stringify(prevProps.entreprise))){
            this.props.entreprise.map(async(entreprise) =>{
                if(entreprise.id==JSON.parse(this.props.entrepriseValue).id){
                    await this.props.dispatch(newEntrepriseValue(JSON.stringify(entreprise)))
                }
            })
        }
    }
    async handleEntrepriseChange(event){
        await this.props.dispatch(newEntrepriseValue(event.target.value))
    }

    // filterEntreprise(){
    //     return this.props.entreprise.filter(item =>{
    //         var numPage = Number(window.location.pathname.slice(-1))
    //          if(numPage==7 || numPage==8){
    //             for(const vic of this.props.vic){
    //                 if(vic.identreprise==item.id && vic.ndossier==this.props.dossierValue && vic.lots==this.props.lotValue
    //                     && vic.typeConv!==""){
    //                     return true
    //                 }
    //                 if(vic.identreprise==item.id && vic.ndossier==this.props.dossierValue && vic.lots==this.props.lotValue
    //                     && getLotNbrPages(this.props.lots,this.props.lotValue)>6){
    //                     return true
    //                 }
    //             }
    //         }
    //         else{
    //             for(const vic of this.props.vic){
    //                 if(vic.identreprise==item.id && vic.ndossier==this.props.dossierValue && vic.lots==this.props.lotValue){
    //                     return true
    //                 }
    //             }
    //         }
    //         return false
    //     })
    // }
    filterEntreprise(){
        let companyList = []
        for(const vic of this.props.vic){
            for(const company of this.props.entreprise ){
                if(vic.identreprise==company.id && vic.ndossier==this.props.dossierValue && vic.lots==this.props.lotValue){
                    companyList.push(company)
                }
            }
        }
        return companyList
    }
    render(){
        return(
            <div className="entrepriselist">
                <label>Entreprise : </label>
                <select onChange={this.handleEntrepriseChange.bind(this)} value={this.props.entrepriseValue}>
                    {this.filterEntreprise().map((item, i) =>{
                        return <option value={JSON.stringify(item)} key={i}>{item.nom}</option>
                    })}
                </select>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    vic : state.vic.items,
    lots : state.vic.lots,
    entreprise: state.entreprise.items,
    entrepriseValue: state.entreprise.entrepriseValue,
    lotValue : state.vic.lotValue,
    dossierValue: state.devis.dossierValue
  });
export default connect(mapStateToProps)(EntrepriseVICList);