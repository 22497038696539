import React, { useState, useCallback, useMemo } from 'react';
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";

const SecondYear = ({
  startYear,
  appointments,
  lotList,
  scheduleItemsSecondYear,
  itemConst,
  deleteItemSecondYear,
  setScheduleItemsSecondYear
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);

  const handleClick = useCallback((event, rowIndex, cellKey) => {
    setAnchorEl(event.currentTarget);
    setSelectedCell({ rowIndex, cellKey });
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null); 
    setSelectedCell(null);
  }, []);

  const handleSelectLot = useCallback((lot) => {
    if (selectedCell) {
      const { rowIndex, cellKey } = selectedCell;
      setScheduleItemsSecondYear(prevItems => {
        const updatedItems = [...prevItems];
        const matchingAppointments = appointments.find(item => item.lot === lot);

        if (matchingAppointments && lot) {
          const lotWithCompany = `${lot} (${matchingAppointments.company})`;
          updatedItems[rowIndex] = { ...updatedItems[rowIndex], [cellKey]: lotWithCompany };
        } else {
          updatedItems[rowIndex] = { ...updatedItems[rowIndex], [cellKey]: lot };
        }

        return updatedItems;
      });
    }
    handleClose();
  }, [selectedCell, appointments, setScheduleItemsSecondYear, handleClose]);

  const addNewItem = useCallback(() => {
    setScheduleItemsSecondYear(prevItems => [...prevItems, itemConst]);
  }, [setScheduleItemsSecondYear, itemConst]);

  const renderHeaderRows = useMemo(() => (
    <>
      <tr className="custom-cell-thead">
        <td className="custom-cell-label" colSpan={12}><strong>{startYear + 1}</strong></td>
      </tr>
      <tr className="custom-cell-thead">
        <td className="custom-cell-label" colSpan={6}><strong>PREMIER SEMESTRE</strong></td>
        <td className="custom-cell-label" colSpan={6}><strong>DEUXIEME SEMESTRE</strong></td>
      </tr>
    </>
  ), [startYear]);

  const renderFooterRows = useMemo(() => (
    <>
      <tr className="custom-cell-item">
        <td className="custom-cell-label" colSpan={6} style={{ backgroundColor: "#ffcc9f", padding: "10px" }}>SEQUENCE SEMESTRIELLE DE JANVIER A JUIN</td>
        <td className="custom-cell-label" colSpan={6} style={{ backgroundColor: "#ffcc9f", padding: "10px" }}>SEQUENCE SEMESTRIELLE DE JUILLET A DECEMBRE</td>
      </tr>
      <tr className="custom-cell-item">
        {[...Array(12)].map((_, i) => (
          <td className="custom-cell-label" key={`month-${i}`} style={{ backgroundColor: "#E8E5E2", padding: "10px" }}>M{i + 1}</td>
        ))}
      </tr>
      <tr className="custom-cell-item">
        <td colSpan={12} style={{ textAlign: 'left', backgroundColor: 'transparent' }}>
          <IconButton
            aria-label="add"
            style={{ backgroundColor: "transparent" }}
            onClick={addNewItem}
          >
            <Add fontSize="small" />
          </IconButton>
        </td>
      </tr>
    </>
  ), [addNewItem]);

  return (
    <table className="schedule-table" style={{ marginTop: "10px" }}>
      <thead>
        <tr></tr>
        {renderHeaderRows}
      </thead>
      <tbody>
        {scheduleItemsSecondYear.map((item, i) => (
          <tr className="custom-cell-item" key={`item-${i}`}>
            {Object.entries(item).map(([key, value]) => (
              key !== 'id' && (
                <td
                  className="custom-cell-label"
                  key={key}
                  onClick={(e) => handleClick(e, i, key)}
                >
                  {value}
                </td>
              )
            ))}
            <td>
              <IconButton
                aria-label="delete"
                size="small"
                className="delete"
                onClick={() => deleteItemSecondYear(i)}
                title="Supprimer"
              >
                <Delete />
              </IconButton>
            </td>
          </tr>
        ))}
        {renderFooterRows}
      </tbody>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSelectLot('')} style={{ color: 'red' }}>
          Supprimer
        </MenuItem>
        {lotList.map((lot, index) => (
          <MenuItem key={`lot-${index}`} onClick={() => handleSelectLot(lot)}>
            {lot}
          </MenuItem>
        ))}
      </Menu>
    </table>
  );
};

export default React.memo(SecondYear);