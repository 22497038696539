import React from 'react';
import LogoChantier from '../../Styles/Pictures/logo_chantier.png';
import Fleche from '../../Styles/Pictures/down-arrow.svg';
class ChantierImage extends React.Component{
    render(){
        return(
            <div className="chantier">
                <img className="logochantier"src={LogoChantier} alt=""></img>
                <img className="flechebas1"src={Fleche} alt=""></img>
                <label>Suivi Chantier</label>
            </div>
        );
    } 
}
export default ChantierImage;