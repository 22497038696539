export const FETCH_ENTREPRISE_BEGIN   = 'FETCH_ENTREPRISE_BEGIN';
export const FETCH_ENTREPRISE_SUCCESS = 'FETCH_ENTREPRISE_SUCCESS';
export const FETCH_ENTREPRISE_FAILURE = 'FETCH_ENTREPRISE_FAILURE';
export const CHANGE_ENTREPRISE_VALUE = 'CHANGE_ENTREPRISE_VALUE';


export function fetchEntreprise() { 
    return dispatch => {
      dispatch(fetchEntrepriseBegin());
      return fetch("/api/entreprise/getAll")
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
          dispatch(fetchEntrepriseSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchEntrepriseFailure(error)));
    };
  }

  // Handle HTTP errors since fetch won't.
  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

export function newEntrepriseValue(newValue){
  return dispatch => dispatch(changeEntrepriseValue(newValue))
}

export const fetchEntrepriseBegin = () => ({
  type: FETCH_ENTREPRISE_BEGIN
});

export const fetchEntrepriseSuccess = entreprise => ({
  type: FETCH_ENTREPRISE_SUCCESS,
  payload: { items:entreprise }
});

export const fetchEntrepriseFailure = error => ({
  type: FETCH_ENTREPRISE_FAILURE,
  payload: { error }
});

export const changeEntrepriseValue = entrepriseValue => ({
  type: CHANGE_ENTREPRISE_VALUE,
  payload: { entrepriseValue }
});