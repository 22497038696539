import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import Header from "../../../Header/Header";
import VICSMenu from "../../VICSMenu";
import DocMenu from "../../../DocMenu";
import GenPdf from "../../../GenPdf";
import history from "../../../Routes/history";
import SignatureCanvas from "react-signature-canvas";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import CircularProgress from "@material-ui/core/CircularProgress";
import { newEntrepriseValue } from "../../../../redux/actions/entrepriseActions";
import { changeVicValue, fetchCompaniesByDossierLot } from "../../../../redux/actions/vicActions";
import { changeTitleName, changePdfFileName } from "../../../../redux/actions/emailAction";
import { getQuestionsPage, getQuestionsPage5, updateQuestionsPage5, getVicValues, genVicPDF, getVicPDF } from "../../Utils"
import "../../../../Styles/VIC/Page-5.scss";

const titles = [
  "Dossier Marché Maître d’ouvrage", 
  "Agrément du Maître d’ouvrage",
  "PPSPS du Lot Gros Œuvre",
  "Plan d’installation de chantier",
  "Accueil du personnel sur le site"
];
const initialQuestions = Array(5).fill({ answer: 0, comment: '' });

const Page5 = ({ vicValue, dossierValue, lotsValue, dispatch, entrepriseValue, companies }) => {
  const sigCanvas = useRef({});
  const prevDossierValue = useRef(dossierValue);
  const prevLotsValue = useRef(lotsValue);
  const prevCompanies = useRef(companies);
  const fileInputRef = useRef(null);
  const prevEntrepriseValue = useRef(entrepriseValue);
  const [disableButton, setDisableButton] = useState(false);
  const [page4data, setPage4data] = useState([]);
  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [tampon, setTampon] = useState(null);
  const [sigLot, setSigLot] = useState(null);
  const [organism, setOrganism] = useState("");
  const [specificTasks, setSpecificTasks] = useState([]);
  const [questionsLot, setQuestionsLot] = useState(initialQuestions);

  // useEffect(() => {
  //   if (!vicValue) {
  //     history.push("/vic/page-1");
  //   }
  // }, []);

  useEffect(() => {
    setDisableButton(true);
    let isMounted = true;

    const fetchQuestionsData = async () => {
      await getQuestionsPage(4, vicValue).then((data) => {
        dispatch(changePdfFileName(""))
        setPage4data(data !== null ? data : []);
      })
      try {
        const data = await getQuestionsPage5(5, vicValue);
        if (isMounted) {
          if (data.length === 0) {
            setQuestionsLot(initialQuestions);
            setOrganism("");
            setSigLot(null);
            setTampon(null);
            setSpecificTasks([]);
          } else {
            if (data.tampon === null) {
              setTampon(null);
            } else {
              let base64Flag = 'data:image/jpeg;base64,';
              let imageStr = arrayBufferToBase64(data.tampon.data);
              let imgSrc = base64Flag + imageStr;
              setTampon(imgSrc);
            }
            setOrganism(data.organism);
            setSigLot(data.sig_lot);
            setQuestionsLot(JSON.parse(data.answers))
            data.specific_tasks ? setSpecificTasks(JSON.parse(data.specific_tasks)) : setSpecificTasks([]);
          }
        }
      } catch (error) {
        console.error('Erreur lors du chargement des données:', error);
      }
    };

    function arrayBufferToBase64(buffer) {
      let binary = '';
      let bytes = [].slice.call(new Uint8Array(buffer));
      bytes.forEach((b) => binary += String.fromCharCode(b));
      return window.btoa(binary);
    };

    fetchQuestionsData();
    setDisableButton(false);

    return () => {
      isMounted = false;
    };
  }, [vicValue]);



  useEffect(() => {
    setDisableButton(true);
    const fetchData = async () => {
      if (dossierValue !== prevDossierValue.current || lotsValue !== prevLotsValue.current) {
        await dispatch(fetchCompaniesByDossierLot(dossierValue, lotsValue));
        prevDossierValue.current = dossierValue;
        prevLotsValue.current = lotsValue;
      }
    };

    fetchData();
    setDisableButton(false);
  }, [dossierValue, lotsValue, dispatch]);

  useEffect(() => {
    setDisableButton(true);
    const fetchData = async () => {
      if (companies !== prevCompanies.current) {
        if (companies.length !== 0) {
          await dispatch(newEntrepriseValue(companies[0]));
          const result = await getVicValues(dossierValue, lotsValue, companies[0]);
          await dispatch(changeVicValue(result.id));
        } else {
          await dispatch(changeVicValue(""));
        }
        prevCompanies.current = companies;
      }

      if (entrepriseValue !== prevEntrepriseValue.current) {
        const result = await getVicValues(dossierValue, lotsValue, entrepriseValue);
        await dispatch(changeVicValue(result.id));
        prevEntrepriseValue.current = entrepriseValue;
      }
    };

    fetchData();
    setDisableButton(false);
  }, [companies, entrepriseValue, dispatch]);

  const handleChangeTampon = (event) => {
    const file = event.target.files[0];

    let reader = new FileReader();
    reader.onloadend = function () {
      setTampon(reader.result);
    }
    reader.readAsDataURL(file);
  };

  const handleRemoveTampon = () => {
    setTampon(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleChangeData = (event, index) => {
    const { name, value, checked } = event.target;
    let temp_state = [...questionsLot];
    temp_state[index][name] = name === "answer" ? checked ? 1 : 0 : value;
    setQuestionsLot(temp_state);
  }

  const handleChangeSpecificTasksData = (event, index) => {
    const { name, value, checked } = event.target;
    let temp_state = [...specificTasks];
    temp_state[index][name] = name === "answer" ? checked ? 1 : 0 : value;
    setSpecificTasks(temp_state);
  }

  const handleValidClick = async () => {
    setDisableButton(true);
    let formData = new FormData();

    formData.append("vicValue", vicValue);
    formData.append("answers", JSON.stringify(questionsLot));
    formData.append("specificTasks", JSON.stringify(specificTasks));
    formData.append("organism", organism);
    if (!sigCanvas.current?.isEmpty()) {
      formData.append("sigLot", sigCanvas.current.getTrimmedCanvas().toDataURL());
    } else formData.append("sigLot", sigLot);
    if (!tampon) {
      formData.append("tampon", null);
    } else {
      let data = atob(tampon.split(',')[1]);
      let dataArray = [];
      for (let i = 0; i < data.length; i++) {
        dataArray.push(data.charCodeAt(i));
      }
      let blob = new Blob([new Uint8Array(dataArray)], { type: 'image/jpeg' });
      let file = new File([blob], 'image.jpg', { type: 'image/jpeg', lastModified: Date.now() });
      formData.append("tampon", file);
    }
    await updateQuestionsPage5(formData);
    setDisableButton(false);
  }

  const handleGenClick = async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genVicPDF(vicValue);
    if (blob) {
      setFile(blob);
      setIsOpen(true);
    }
    setDisableButton(false);
  }

  const handleShowPdf = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'pdf');
    
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);

      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }

  const handleGetPdfFile = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }

  const handleGetWordFile = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
    }
    setDisableButton(false);
  }

  const prevPage = () => {
    history.push("/vic/page-4");
  };

  const nextPage = () => {
    history.push("/vic/page-6");
  };

  const handleChangeTitleName = () => {
    dispatch(changeTitleName(`VIC`));
  };

  return (
    <div className="page5">
      <Header />
      <DocMenu
      file={file}
      getSourceFile={handleGetWordFile}
      getPDF={handleGetPdfFile}
      handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Visite d'Inspection Commune (Page 5)</h1>
      <VICSMenu />

      <div className="data-page5">
        {specificTasks.length > 0 &&
          <div className="specific-tasks">
            <h3>TRAVAUX SPÉCIFIQUES AMIANTE ET PLOMB</h3>
            {specificTasks.map(({ answer, title }, index) => {
              return index === 9 ?
                (
                  <div className="specific-task" key={index}>
                    <input name='answer' type="checkbox" checked={answer == 1 ? true : false} onChange={(event) => handleChangeSpecificTasksData(event, index)} />
                    <label>Décliner les modes opératoires du CREP dans votre PPSPS suivant le document remis par l’organisme </label>
                    <input className='organism' name='organism' value={organism} onChange={(event) => setOrganism(event.target.value)} placeholder=" &quot;Nom de l’organisme&quot;" />
                    <label> agrée par la Préfecture</label>
                  </div>
                ) : (
                  <div className="specific-task" key={index}>
                    <input name='answer' type="checkbox" checked={answer == 1 ? true : false} onChange={(event) => handleChangeSpecificTasksData(event, index)} />
                    <label>{title}</label>
                  </div>
                );
            })}
          </div>}
        <table className="custom-table">
          <tbody>
            <tr className="custom-cell" key={0}>
              <td rowSpan={5}>Documents en possession de l’entreprise</td>
              <td className="custom-cell-check"><input name='answer' type="checkbox" checked={questionsLot[0].answer == 1 ? true : false} onChange={(event) => handleChangeData(event, 0)} /></td>
              <td className="custom-cell-label"><label>{titles[0]}</label><input name='comment' value={questionsLot[0].comment} onChange={(event) => handleChangeData(event, 0)} /></td>
            </tr>
            {questionsLot.slice(1).map((item, index) => {
              return (
                <tr className="custom-cell" key={index + 1}>
                  <td className="custom-cell-check"><input name='answer' type="checkbox" checked={questionsLot[index + 1].answer == 1 ? true : false} onChange={(event) => handleChangeData(event, index + 1)} /></td>
                  <td className="custom-cell-label"><label>{titles[index + 1]}</label><input name='comment' value={item.comment} onChange={(event) => handleChangeData(event, index + 1)} /></td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className='signature-container'>
          <div className="signature">
            <div className="signature-items">
              <label>Signature : </label>
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
              />
              <Button color="primary" variant="contained" onClick={() => sigCanvas.current.clear()}>Effacer</Button>
            </div>
            {tampon !== null &&
              <div className="tampon">
                <div style={{ position: 'relative' }}>
                  <img
                    src={tampon}
                    alt="tampon"
                  />
                  <button
                    type="button"
                    onClick={handleRemoveTampon}
                  >
                    &times;
                  </button>
                </div>
              </div>
            }
          </div>
          <div className="tampon">
            <label>Tampon de l'entreprise : </label>
            <input type="file" onChange={handleChangeTampon} />
          </div>
        </div>
        <div className="buttons-container">
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleValidClick()}
            disabled={vicValue === "" || disableButton}
          >
            Enregistrer
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleGenClick()}
            disabled={vicValue === "" || disableButton}
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleShowPdf()}
            disabled={vicValue === "" || disableButton}
          >
            Afficher le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowLeft />}
            onClick={prevPage}
            disabled={disableButton}
          >
            Page précedente
          </Button>
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowRight />}
            onClick={nextPage}
            disabled={disableButton}
          >
            Page suivante
          </Button>
          {disableButton && <CircularProgress className="spinner" color="success" />}
        </div>
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
        pageNumber={page4data.length > 0 ? 5 : 4}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  vicValue: state.vic.vicValue,
  lotsValue: state.vic.lotsValue,
  companies: state.vic.companies,
  entrepriseValue: state.entreprise.entrepriseValue,
});

export default connect(mapStateToProps)(Page5);
