export const CHANGE_URL_FILE = 'CHANGE_URL_FILE';
export const CHANGE_TITLE_NAME = 'CHANGE_TITLE_NAME';
export const CHANGE_FILE_NAME = 'CHANGE_FILE_NAME';
export const CHANGE_TYPE_FILE = 'CHANGE_TYPE_FILE';

export function newUrlFile(newValue) {
    return dispatch => dispatch(changeUrlFile(newValue))
}
export const changeUrlFile = url => ({ 
    type: CHANGE_URL_FILE,
    payload: { url }
})
export const changeTitleName = titleName => ({
    type: CHANGE_TITLE_NAME,
    payload: { titleName }
})
export const changePdfFileName = fileName => ({
    type: CHANGE_FILE_NAME,
    payload: { fileName }
})
export const changeTypeFileName = typeFile => ({
    type: CHANGE_TYPE_FILE,
    payload: { typeFile }
})