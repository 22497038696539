import { Button } from '@material-ui/core'
import React from 'react'
import Header from '../../Header/Header'
import NumDoss from '../../NumDoss'
import Dialogs from './Dialogs'
import GenPdf from '../../GenPdf'
import { connect } from 'react-redux'
class ContratValid extends React.Component{ 
    constructor(props){
        super(props)
        this.state={
            isOpen:false,
            file:null,
            pageNumber:1,
            numPages:13,
            openDialog:true
        }
    }
    /**
     * Ferme la boîte de dialogue
     */
    closeDialog(){
        this.setState({openDialog:false})
      }
    /**
     * Affiche le fichier PDF 
     */
    openDoc(){
        this.setState({isOpen:true})
      }
    /**
     * Ferme le fichier PDF
     */
    closeDoc(){
        this.setState({isOpen: false})
      }
    /**
     * Affiche le PDF du contrat précédemment généré
     */
  async showPDF(){
    this.openDoc()
    const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
        body: JSON.stringify({ndossier: this.props.dossierValue})
      }
    let blob = await fetch('/api/contrat/showPDF',requestOptions).then(res => res.blob())
    if(blob){
      this.setState({file:blob})
    }
}
    render(){
        return(
            <div className="contratValid">
                <Header/>
                <NumDoss type="devis"/>
                <h1 className="title">
                    Validation du Contrat
                </h1>
                <div className="data">
                    <Button variant="contained" color="primary" onClick={this.showPDF.bind(this)}>Afficher Contrat Enregistré</Button>
                    <Button variant="contained" color="primary">Valider</Button>
                    <Button variant="contained" color="primary">Renvoyer une autre proposition</Button>
                </div>
                <GenPdf isOpen={this.state.isOpen} file={this.state.file} closeDoc={this.closeDoc.bind(this)}
                 scale={true} type={"contrat"}/>
                {this.state.openDialog ? <Dialogs closeDialog={this.closeDialog.bind(this)}/> : ""}
            </div>
        )
    }
}
const mapStateToProps = state => ({
    dossierValue : state.devis.dossierValue,
  });
export default connect(mapStateToProps)(ContratValid);