import React from "react";
import Button from "@material-ui/core/Button";
import ReactTable from 'react-table-6';
import CircularProgress from "@material-ui/core/CircularProgress";
import { numberToFormat } from "../../Utils";

class ListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilters: false,
    }
  }

  formatDate(inputDate) {
    const [year, month, day] = inputDate.split('-');
    return year && month && day ? `${day} / ${month} / ${year}` : "";
  }

  render() {
    const { facturesData, totalAmount, disableButton, startDate, endDate, filtersStatut, handleDateChange, handleStatutChange, totalBredAmount, totalCaAmount } = this.props
    const { showFilters } = this.state
    const data = facturesData;

    const columns = [
      { 
        Header:
          <div>
            Numéro<br />
            Facture
          </div>,
        accessor: 'nfacture',
        width: 119,
        Cell: props => {
          return (
            <div className="centered-cell">
              {(props.value).replace(/ /g, ' / A-')}
            </div>
          )
        }
      }, {
        Header: "Dossier",
        accessor: 'ndossier',
        width: 85,
        Cell: props => {
          return (
            <div className="centered-cell">
              {props.value}
            </div>
          )
        }
      }, {
        Header: 'Affectation',
        accessor: 'dossier',
        width: 200,
        Cell: props => {
          return <div>{props.value.sci_sccv}<br />{props.value.ville_op}</div>
        }
      }, {
        Header: <div>
          Mois de Facture<br />
          Concept-Réal
        </div>,
        accessor: 'month_facture',
        width: 150,
        Cell: props => {
          return <div>{props.value.month}<br />{props.value.ref}</div>
        }
      }, {
        Header: 'Code Client',
        accessor: 'numclient',
        width: 180,
        Cell: props => {
          return (
            <div className="centered-cell">
              {props.value}
            </div>
          );
        }
      }, {
        Header:
          <div>
            Date<br />
            Echéance
          </div>,
        accessor: 'date_ech',
        width: 120,
        Cell: props => {
          return (
            <div className="centered-cell">
              {props.value ? this.formatDate(props.value) : ""}
            </div>
          )
        }
      }, {
        Header:
          <div>
            1<sup>ère</sup> relance
          </div>,
        accessor: 'first_reminder',
        width: 130,
        Cell: props => {
          return (
            <div className="centered-cell" style={{ backgroundColor: props.value === "" ? "" : "orange", color: props.value === "À ENVOYER" ? "black" : "white", fontWeight: "bold" }}>
              {props.value !== "À ENVOYER" ? this.formatDate(props.value) : props.value}
            </div>
          );
        }
      }, {
        Header:
          <div>
            2<sup>ème</sup> relance
          </div>,
        accessor: 'second_reminder',
        width: 130,
        Cell: props => {
          return (
            <div className="centered-cell" style={{ backgroundColor: props.value === "" ? "" : "red", color: props.value === "À ENVOYER" ? "black" : "white", fontWeight: "bold" }}>
              {props.value !== "À ENVOYER" ? this.formatDate(props.value) : props.value}
            </div>
          );
        }
      }, {
        Header:
          <div>
            Montant<br />
            HT
          </div>,
        accessor: 'amount_ht',
        width: 110,
        Cell: props => {
          return (
            <div className="centered-cell">
              {props.value !== "" ? numberToFormat(props.value) : ""}
            </div>
          );
        }
      }, {
        Header:
          <div>
            Montant<br />
            TVA
          </div>,
        accessor: 'amount_tva',
        width: 120,
        Cell: props => {
          return (
            <div className="centered-cell">
              {props.value !== "" ? numberToFormat(props.value) : ""}
            </div>
          );
        }
      }, {
        Header:
          <div>
            Bred<br />
            Virement
          </div>,
        accessor: 'amount_bred',
        width: 82,
        Cell: props => {
          return (
            <div className="centered-cell">
              {props.value !== "" ? numberToFormat(props.value) : ""}
            </div>
          );
        }
      }, {
        Header:
          <div>
            CA<br />
            Virement
          </div>,
        accessor: 'amount_ca',
        width: 82,
        Cell: props => {
          return (
            <div className="centered-cell">
              {props.value !== "" ? numberToFormat(props.value) : ""}
            </div>
          );
        }
      }, {
        Header:
          <div>
            Date de<br />
            Paiement
          </div>,
        accessor: 'payment_date',
        width: 130,
        Cell: props => {
          return (
            <div style={{ backgroundColor: props.value === null ? "" : "green", height: "100%", color: "white", fontWeight: "bold" }}>
              <div className="centered-cell">
                {this.formatDate(props.value || "")}
              </div>
            </div>
          );
        }
      }, {
        Header:
          <div>
            Payé<br />
            <span style={{ color: "#02f902" }}>Oui</span> - <span style={{ color: "red" }}>Non</span>
          </div>,
        accessor: 'validation',
        width: 90,
        Cell: props => {
          return (
            <div className="centered-cell" style={{ backgroundColor: props.value ? "green" : "red" }}>
              <input
                type="checkbox"
                name="validation"
                disabled={true}
                checked={props.value || ""}
              />
            </div>
          );
        }
      }, {
        Header:
          <div>
            Contrat<br />
            <span style={{ color: "#02f902" }}>Oui</span> - <span style={{ color: "red" }}>Non</span>
          </div>,
        accessor: 'contrat_valide',
        width: 90,
        Cell: props => {
          return (
            <div className="centered-cell" style={{ backgroundColor: props.value ? "green" : "red" }}>
              <input
                type="checkbox"
                name="contrat_valide"
                disabled={true}
                checked={props.value || ""}
              />
            </div>
          );
        }
      },
    ];

    return (
      <div className="global-table">
        {(showFilters && <div className="filter-container">
          <div className="filter-row">
            <div className="date-filter">
              <h4>Période:</h4>
              <div>
                <label className="label">Date de début:</label>
                <input
                  type="date"
                  name="startDate"
                  value={startDate}
                  onChange={(event) => { handleDateChange(event) }}
                  className="date-input"
                />
              </div>
              <div>
                <label className="label">Date de fin:</label>
                <input
                  type="date"
                  name="endDate"
                  value={endDate}
                  onChange={(event) => { handleDateChange(event) }}
                  className="date-input"
                />
              </div>
            </div>
            <div className="statut-filter">
              <h4>Statut:</h4>
              <label>
                <input
                  type="checkbox"
                  name="firstReminder"
                  checked={filtersStatut.firstReminder}
                  onChange={handleStatutChange}
                  className="checkbox"
                />
                <span style={{ color: "#FF7800", fontWeight: "bold" }}>1<sup>ère</sup> relance</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="secondReminder"
                  checked={filtersStatut.secondReminder}
                  onChange={handleStatutChange}
                  className="checkbox"
                />
                <span style={{ color: "red", fontWeight: "bold" }}>2<sup>ème</sup> relance</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="toSend"
                  checked={filtersStatut.toSend}
                  onChange={handleStatutChange}
                  className="checkbox"
                />
                <span style={{ fontWeight: "bold" }}>À envoyer</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="paye"
                  checked={filtersStatut.paye}
                  onChange={handleStatutChange}
                  className="checkbox"
                />
                <span style={{ color: "green", fontWeight: "bold" }}>Payé</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name="nonPaye"
                  checked={filtersStatut.nonPaye}
                  onChange={handleStatutChange}
                  className="checkbox"
                />
                <span style={{ color: "red", fontWeight: "bold" }}>Non payé</span>
              </label>
            </div>
          </div>
          <div className="filter-row">
            <Button onClick={this.props.loadData} variant="contained" color="primary">Filtrer</Button>
            <Button onClick={this.props.resetData} variant="contained" color="primary">Annuler</Button>
          </div>
        </div>)}
        <ReactTable
          data={data}
          columns={columns}
          defaultPageSize={10}
          pageSizeOptions={[10, 20, 50]}
          previousText={"Précédent"}
          nextText={"Suivant"}
          loadingText={"Chargement en cours..."}
          noDataText={"Aucune donnée trouvée."}
          rowsText={"lignes"}
          ofText={"de"}
          style={{
            background: 'white',
            textAlign: 'center',
          }}
          getTheadThProps={() => {
            return {
              style: {
                backgroundColor: '#154bc0',
                color: 'white',
                fontSize: '19px',
              }
            }
          }}
        />
        <div className="total-table">
          <div className="table-container">
            <table className="table-bank">
              <tbody>
                <tr>
                  <th>Total Bred TTC</th>
                  <td>{totalBredAmount ? numberToFormat(totalBredAmount) : "0.00"}</td>
                </tr>
                <tr>
                  <th>Total Crédit Agricole TTC</th>
                  <td>{totalCaAmount ? numberToFormat(totalCaAmount) : "0.00"}</td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <th>Total HT</th>
                  <td>{totalAmount ? numberToFormat(totalAmount) : "0.00"}</td>
                </tr>
                <tr>
                  <th>Total TVA</th>
                  <td>{totalAmount ? numberToFormat(totalAmount * 0.2) : "0.00"}</td>
                </tr>
                <tr>
                  <th>Total TTC</th>
                  <td>{totalAmount ? numberToFormat(totalAmount * 1.2) : "0.00"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="btn-group">
          {disableButton && (
            <CircularProgress className="spinner" color="inherit" />
          )}
          <Button className="btn-filter" onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
            this.setState({ showFilters: !showFilters })
          }} disabled={disableButton} variant="contained">Filtres</Button>
          <Button onClick={this.props.handleGenClick} disabled={disableButton} variant="contained" color="primary">Générer</Button>
          <Button onClick={() => {
            this.props.handleShowPdf()
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }} disabled={disableButton} variant="contained" color="primary">Afficher</Button>
          <Button onClick={this.props.resetData} disabled={disableButton} variant="contained" color="primary">Annuler</Button>
        </div>
      </div>
    )
  }
}
export default ListTable;