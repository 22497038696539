import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import Options from "../Chatbot/Options";
import Quiz from "../Chatbot/Quiz";


const config = {
    
    //botName: "Bot d'apprentissage",
    lang: "fr",
    initialMessages: [
        createChatBotMessage(`Hello! Comment je peux vous aider`, {
            widget: "options",
        }),
    ],
    customComponents: { 
        
        header: () => <div className="headerChatbot"  /*onClick={this.clickToGererNotif}*/style={{
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            color: "white",
            padding: "12.5px",
            fontWeight: "bold",
            backgroundColor: "#ff7f00",
            fontFamily: "Arial",
            display: "flex",
            alignItems: "center",
            fontSize: "0.85rem",
    }
} >
    Conversation avec notre Bot </div>,
        },
widgets: [
    {
        widgetName: "options",
        widgetFunc: (props) => <Options {...props} />,
    },
    {
        widgetName: "javascriptQuiz",
        widgetFunc: (props) => <Quiz {...props} />,
        props: {
            questions: [
                {
                    question: "Je n'arrive pas à telecharger ma facture ",
                    answer: "votre facture se trouve dans la rubrique facture en bas de page. vous pouvez aussi consulter votre facture avant de la télecharger ",
                    id: 1,
                },
                {
                    question: "Je veux déclarer un avoir concernant la facture N°FC1569455 ",
                    answer: "vous pouvez déclarer votre avoir dans la rubrique avoir dans le menu Facture  ",
                    id: 2,

                },

            ],

        },

    },
],
};

export default config