import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { newDossierValue } from "../redux/actions/devisActions";
import { fetchPreDiuoByDoss, fetchPreDiuoByDossVerif, newPreDiuoIndexValue } from "../redux/actions/preDiuoActions";
import "../Styles/PreDIUO/NumDossPreDiuo.scss";

const useDossier = () => useSelector((state) => state.dossier.items, shallowEqual);
const usePreDiuo = () => useSelector((state) => state.preDiuo.preDiuoByDoss, shallowEqual);

const NumDossPreDiuo = () => { 
  const dispatch = useDispatch();

  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const dossier = useDossier();
  const preDiuo = usePreDiuo();
  const index = useSelector((state) => state.preDiuo.index);
  const preDiuoValue = useSelector((state) => state.preDiuo.preDiuoValue);

  const handleDossierChange = useCallback((event) => {
    const value = event.target.value;
    dispatch(newPreDiuoIndexValue(''));
    dispatch(newDossierValue(value));
    dispatch(fetchPreDiuoByDoss(value));
  }, [dispatch]);

  const handleIndexChange = useCallback((event) => {
    const value = +event.target.value;

    dispatch(newPreDiuoIndexValue(value));
  }, [dispatch]);

  useEffect(() => {
    if (!dossierValue || preDiuoValue) return
    dispatch(fetchPreDiuoByDossVerif(dossierValue));
  }, []);

  useEffect(() => {
    if (dossierValue && !preDiuoValue) {
      dispatch(fetchPreDiuoByDoss(dossierValue));
    }
  }, [dossierValue, preDiuoValue, dispatch]);

  const dossierOptions = useMemo(() => (
    dossier.map(({ ndossier }) => (
      <option key={ndossier} value={ndossier}>
        {ndossier}
      </option>
    ))
  ), [dossier]);

  const preDiuoOptions = useMemo(() => (
    preDiuo.map(({ pre_diuo_index }) => (
      <option key={pre_diuo_index} value={pre_diuo_index}>
        {pre_diuo_index}
      </option>
    ))
  ), [preDiuo]);

  return (
    <div className="pre-diuo-menu">
      <div className="ndossier">
        <label htmlFor="dossier">N° Dossier : </label>
        <select
          id="dossier"
          onChange={handleDossierChange}
          value={dossierValue}
        >
          {dossierOptions}
        </select>
      </div>
      <div className="indice">
        <label htmlFor="index">Indice : </label>
        <select
          id="index"
          onChange={handleIndexChange}
          value={index}
        >
          {preDiuoOptions}
        </select>
      </div>
    </div>
  );
};

export default React.memo(NumDossPreDiuo);
