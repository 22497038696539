import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import {
  fetchDevis,
  newDossierValue,
  newNpropValue,
  fetchNewPropo,
} from "../../../../redux/actions/devisActions";
import { fetchContact } from "../../../../redux/actions/contactActions";
import { fetchDossier } from "../../../../redux/actions/dossierActions";

class DialogModifComp extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      comp: "",
      idcontact: "",
      prenom: "",
      nom: "",
      sexe: "",
      tel_port: "",
      mail: "",
      adress: "",
      cp: "",
      city: "",
      loading: false,
    };
    this.handleCloseProcess = this.handleCloseProcess.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getContactInfos = this.getContactInfos.bind(this);
  }
  componentDidMount() {
    this.getContactInfos();
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleCloseProcess() {
    this.setState({ isOpen: false });
    this.props.closeDialog();
  }
  handleOpen() {
    this.setState({ isOpen: true });
  }
  async handleCompModif() {
    this.setState({ loading: true });
    const { dossierValue, nprop } = this.props;
    const requestOptions1 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ ndossier: dossierValue, comp: this.state.comp }),
    };
    const requestOptions2 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        idcontact: this.state.idcontact,
        prenom: this.state.prenom,
        nom: this.state.nom,
        sexe: this.state.sexe,
        tel_port: this.state.tel_port,
        mail: this.state.mail,
        adress: this.state.adress,
        cp: this.state.cp,
        city: this.state.city,
      }),
    };

    try {
      await fetch("/api/devis/modifContact", requestOptions2);
      await fetch("/api/devis/modifComp", requestOptions1);
      await this.props.dispatch(fetchContact());
      await this.props.dispatch(fetchDossier());
      await this.props.dispatch(fetchDevis());
      await this.props.dispatch(fetchNewPropo(dossierValue));
      await this.props.dispatch(newDossierValue(dossierValue));
      await this.props.dispatch(newNpropValue(nprop));
      await this.setState({ loading: false });
      await this.handleCloseProcess();
    } catch (err) {
      console.log(err);
    }
  }

  getComp() {
    for (let i = 0; i < this.props.dossier.length; i++) {
      if (this.props.dossier[i].ndossier == this.props.dossierValue) {
        return this.props.dossier[i];
      }
    }
    return "";
  }

  getContactInfos() {
    const { dossier, dossierValue, contact } = this.props;
    let idcontact = null;
    for (let i = 0; i < dossier.length; i++) {
      if (dossier[i].ndossier == dossierValue) {
        idcontact = dossier[i].idcontact;
        this.setState({
          comp: dossier[i].sci_sccv,
          idcontact,
        });
      }
    }
    if (idcontact !== null) {
      for (let i = 0; i < contact.length; i++) {
        if (contact[i].idcontact == idcontact) {
          const item = contact[i];
          this.setState({
            prenom: item.prenom,
            nom: item.nom,
            sexe: item.sexe,
            tel_port: item.tel_port,
            mail: item.mail,
            adress: item.adress,
            cp: item.cp,
            city: item.city,
          });
        }
      }
    }
  }
  render() {
    return (
      <div>
        <Dialog
          open={this.state.isOpen}
          onClose={this.handleCloseProcess}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            MODIFICATION DÉTAILS MAITRE D'OUVRAGE
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="comp"
              defaultValue={this.state.comp}
              label="Entreprise représentante"
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              name="prenom"
              defaultValue={this.state.prenom}
              label="Prénom"
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              name="nom"
              defaultValue={this.state.nom}
              label="Nom"
              onChange={this.handleChange}
              fullWidth
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="sexe-label">Sexe</InputLabel>
              <Select
                labelId="sexe-label"
                name="sexe"
                value={this.state.sexe}
                onChange={this.handleChange}
              >
                <MenuItem value="homme">Homme</MenuItem>
                <MenuItem value="femme">Femme</MenuItem>
              </Select>
            </FormControl>
            <TextField
              autoFocus
              margin="dense"
              name="tel_port"
              defaultValue={this.state.tel_port}
              label="Téléphone"
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              name="mail"
              defaultValue={this.state.mail}
              label="Mail"
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              name="adress"
              defaultValue={this.state.adress}
              label="Adresse"
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              name="cp"
              defaultValue={this.state.cp}
              label="Code postal"
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              name="city"
              defaultValue={this.state.city}
              label="Ville"
              onChange={this.handleChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseProcess} color="primary">
              Annuler la Modification
            </Button>
            <Button onClick={this.handleCloseProcess} color="primary">
              Ne pas enregistrer
            </Button>
            <Button onClick={this.handleCompModif.bind(this)} color="primary">
              Enregistrer
            </Button>
            {this.state.loading && (
              <CircularProgress className="spinner" color="inherit" />
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  contact: state.contact.items,
  nprop: state.devis.nprop,
  dossier: state.dossier.items,
});
export default connect(mapStateToProps)(DialogModifComp);