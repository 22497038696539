import React from 'react';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";


class YesNoDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() { 
    const { isOpen, title, question, handleYesClick, handleClose } = this.props

    return (
      <div className="yesno-dialog">
        <Dialog open={isOpen} onClose={handleClose}>
          <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {question}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleYesClick} style={{ color: "white", backgroundColor: "#FF7F50" }}>
              Oui
            </Button>
            <Button variant="contained" onClick={handleClose} style={{ color: "white", backgroundColor: "#F5B039" }}>
              Non
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
export default YesNoDialog;