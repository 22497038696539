import React from 'react'
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';

const getSuggestionValue = suggestion => suggestion.nom;

const renderSuggestion = suggestion => (
  <div style={{ padding: "auto" }}>
    {suggestion.nom}
  </div>
);


class AutoSuggestComp extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      suggestions: [],
    }
  }

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.props.entreprise.filter(item =>
      item.nom.toLowerCase().slice(0, inputLength) === inputValue
    )
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };


  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.props.setAllState(suggestion)
  };

  render() {
    const inputProps = {
      name: "company",
      value: this.props.company,
      onChange: this.props.handleChange,
      style: {
        border: "none", height: "100%", width: "300%", pointerEvents: "visible", backgroundColor: "transparent"
        , textAlign: "left", fontSize: "14px"
      }
    };
    return (
      <React.Fragment>
        <Autosuggest
          onSuggestionSelected={this.onSuggestionSelected}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => ({
  entreprise: state.entreprise.items
});
export default connect(mapStateToProps)(AutoSuggestComp)