import React from "react";
import BaseTable, { Column } from "react-base-table";
import "react-base-table/styles.css";
import { IconButton } from "@material-ui/core";
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';

class PhotoTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoChangeCount: 0
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.observationsByFloor !== this.props.observationsByFloor) {
      this.setState(prevState => ({ photoChangeCount: prevState.photoChangeCount + 1 }));
    }
  }
  
  render() { 
    // console.log("props", this.props);
    const data = this.props.facteursArray;
    const column = [
      {
        title: "Facteur de Risque",
        key: "facteur-risque",
        dataKey: "facteur-risque",
        width: 148,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <select
            name="facteur-risque"
            key={`facteur_${this.state.photoChangeCount}`}
            disabled= {this.props.nbImg > 3 || this.props.observationsByFloor.length === 0}
            onChange={(event) => this.props.handleChangeFacteurRisque(event.target.value)}
            style={{ width: "130px" }}
          >
            {rowData.title.map((item, index) => {
              return (
                <option value={item} key={index}>
                  {item}
                </option>
              );
            })}
          </select>
        ),
      },
      {
        title: "Reportage Photo",
        key: "photo",
        dataKey: "photo",
        width: 994,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <div
            className="imgCell"
          >
            <input
              type="file"
              key={`photo_${rowIndex}_${this.state.photoChangeCount}`}
              multiple
              disabled= {this.props.nbImg > 3 || this.props.observationsByFloor.length === 0}
              style={{
                width: "100%",
                pointerEvents: "visible",
                textAlign: "center",
                fontSize: "14px",
              }}
              accept=".jpg, .jpeg, .png"
              onChange={(event) => this.props.handleChangePhoto(event, rowIndex)}
              name="photo"
            />
          </div>
        ),
      },
      {
        width: 47,
        key: "add-photo",
        dataKey: "add-photo",
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <IconButton
            aria-label="add-photo"
            size="small"
            disabled= {this.props.nbImg > 3 || this.props.observationsByFloor.length === 0}
            onClick={() => this.props.handleChangePhotosArray()}
            style={{ backgroundColor: "transparent" }}
            className="add-del-button"
          >
            <SaveTwoToneIcon />
          </IconButton>
        ),
      },
    ];
    return (
      <div className="photoTable">
        <BaseTable
          fixed
          columns={column}
          width={1190}
          data={data}
          height={100}
          rowKey={"facteur-risque"}
        ></BaseTable>
      </div>
    );
  }
}
export default PhotoTable;
