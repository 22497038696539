import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../Header/Header";
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Card, CardContent, Grid } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import EmploymentLawTable from "./EmploymentLawTable";
import LotsVICTable from "./LotsVICTable";
import EmploymentLawQuestionsTable from "./EmploymentLawQuestionsTable";
import YesNoDialog from '../../Components/YesNoDialog';
import { fetchAllLots, fetchAllLotItems } from "../../redux/actions/vicActions";
import "../../Styles/VIC/EmploymentLaw.scss";
 
const page5Questions = [{ "answer": false, "title": "Analyse d’empoussièrement préliminaire en META ou point 0" }, { "answer": false, "title": "Fournir votre Plan de Retrait, certificats d’Acceptation Préalable (CAP), B.S.D.A, Procès-verbaux de consignation des réseaux, Résultats des tests de fumés et délibératoires" }, { "answer": false, "title": "E.P.I spécifiques (masque à ventilation assistée, combinaison, gants, etc.) aux travaux en milieu amiante" }, { "answer": false, "title": "Aptitude médicale à jour" }, { "answer": false, "title": "Décrire le mode opératoire de l’intervention dans votre PPSPS" }, { "answer": false, "title": "Confinement de la zone préciser le nombre de SAS" }, { "answer": false, "title": "Pas de coactivité" }, { "answer": false, "title": "Déchets (conditionnements des déchets dans des emballages réglementés, aspiration des emballages avant évacuation, etc.)" }, { "answer": false, "title": "Décret n° 2006-474 du 25 avril 2006 relatif à la lutte contre le saturnisme" }, { "answer": false, "title": "Décliner les modes opératoires du CREP dans votre PPSPS suivant le document remis par l’organisme agrée par la Préfecture." }, { "answer": false, "title": "Sous-section 3 et 4 R. 4412-139 et 140 ; R. 4412-142 à 147 l’absence d’un plan retrait n’exclut pas l’envoi du mode opératoire à la CRAMIF etc." }]

class EmploymentLaw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allLots: [],
      categories: [],
      newCategories: [],
      allEmploymentLaw: [],
      allEmploymentLaws: [],
      allQuestions: [],
      questions: [],
      questionsLot: { page2: [], page3: [], page4: [], page5: "" },
      currentPage: 2,
      currentLotId: null,
      nameLot: "",
      name: "",
      title: "",
      detail: "",
      category_id: "",
      employmentLawName: "",
      employmentLawDetail: "",
      employmentLawTitle: "",
      currentEmploymentLawId: "",
      questionDel: "",
      questionDelId: null,
      employmentLawNew: false,
      ctCategories: true,
      ctQuestions: false,
      idCatQuestion: null,
      openDialogRead: false,
      openDialogDel: false,
      openDialogDelLot: false,
      openDialogDelQuestion: false,
      createState: true,
      createCat: true,
      _isMounted: false,
      loading: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.addNewEmploymentLaw = this.addNewEmploymentLaw.bind(this);
    this.loadVICCategories = this.loadVICCategories.bind(this);
    this.loadVICEmploymentLaw = this.loadVICEmploymentLaw.bind(this);
    this.getEmploymentLawInfos = this.getEmploymentLawInfos.bind(this);
    this.updateEmploymentLaw = this.updateEmploymentLaw.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadVICCategories();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleHideThirdComponent = (e) => {
    if (e.target === e.currentTarget) {
      this.setState({ ctQuestions: false });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  loadVICCategories = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const [res, resNew] = await Promise.all([
        fetch("/api/vic/categoriesEmploymentLaw", requestOptions),
        fetch("/api/vic/categoriesEmploymentLaws", requestOptions)
      ]);

      const [data, dataNew] = await Promise.all([res.json(), resNew.json()]);

      if (this._isMounted) {
        this.setState({
          categories: data,
          newCategories: dataNew
        });
        this.loadVICEmploymentLaw();
      }
    } catch (err) {
      console.error("Error loading VIC categories:", err);
    }
  };

  loadVICEmploymentLaw = async () => {
    this.setState({ loading: true });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const res = await fetch("/api/vic/allVICEmploymentLaw", requestOptions);
      const data = await res.json();
      if (this._isMounted) {
        this.setState({
          allEmploymentLaw: data,
          loading: false
        });
        this.loadAllQuestions();
      }
    } catch (err) {
      console.log(err);
    }
  };

  loadVICEmploymentLaws = async () => {
    this.setState({ loading: true });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const res = await fetch("/api/vic/allVICEmploymentLaws", requestOptions);
      const data = await res.json();

      if (this._isMounted) {
        this.setState({
          allEmploymentLaws: data,
          loading: false
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  loadAllQuestions = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const res = await fetch("/api/vic/getAllQuestions", requestOptions);
      let data = await res.json();
      data = data.map(item => ({ ...item, questions: JSON.parse(item.questions) }));
      this.setState({ allQuestions: data });
    } catch (err) {
      console.log(err);
    }
  };

  async addNewEmploymentLaw() {
    const { name, title, detail, category_id } = this.state;

    if (name && title && detail && category_id) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: name.trim(),
          title: title.trim(),
          detail,
          category_id,
        }),
      };
      try {
        const response = await fetch(
          `/api/vic/newEmploymentLaw${this.state.employmentLawNew ? "s" : ""}`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error("Failed to add new employment law");
        }

        this.setState({
          name: "",
          title: "",
          detail: "",
          category_id: ""
        });
      } catch (error) {
        console.log(error);
      }

      if (this.state.employmentLawNew) {
        await this.loadVICEmploymentLaws();
      } else await this.loadVICEmploymentLaw();
    } else {
      this.setState({
        openDialogRead: true,
        employmentLawName: "INFORMATIONS MANQUANTES",
        employmentLawDetail: "Veuillez remplir tous les champs obligatoires",
      });
    }
  }

  getEmploymentLawInfos({ name, title, detail, category_id, id }) {
    this.setState({
      name: name,
      title: title,
      detail: detail,
      category_id: category_id,
      currentEmploymentLawId: id,
      createState: false,
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  async updateEmploymentLaw() {
    const { name, title, detail, category_id, currentEmploymentLawId } = this.state;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: name,
        title: title,
        detail: detail,
        category_id: category_id,
        id: currentEmploymentLawId,
      }),
    };
    try {
      const response = await fetch(
        `/api/vic/updateEmploymentLaw${this.state.employmentLawNew ? "s" : ""}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to add new employment law");
      }
    } catch (error) {
      console.log(error);
    }
    await this.resetState();
    if (this.state.employmentLawNew) {
      await this.loadVICEmploymentLaws();
    } else await this.loadVICEmploymentLaw();
  }

  loadLotQuestions(data) {
    const { id, name, questions } = data
    this.setState({
      currentLotId: id,
      nameLot: name,
      questionsLot: JSON.parse(questions),
      createState: false
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  async deleteEmploymentLaw(id) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ id }),
    };
    try {
      const response = await fetch(
        `/api/vic/suppEmploymentLaw${this.state.employmentLawNew ? "s" : ""}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to add new employment law");
      }
    } catch (error) {
      console.log(error);
    }
    await this.setState({ currentEmploymentLawId: "" });
    if (this.state.employmentLawNew) {
      await this.loadVICEmploymentLaws();
    } else await this.loadVICEmploymentLaw();
    await this.handleCloseDel();
  }


  resetState() {
    this.setState({
      name: "",
      title: "",
      detail: "",
      category_id: "",
      currentEmploymentLawId: "",
      createState: true,
      currentLotId: null,
      nameLot: "",
      questionsLot: { page2: [], page3: [], page4: [], page5: "" },
    });
  }

  handleClickOpenRead = ({ name, title, detail }) => {
    this.setState({
      openDialogRead: true,
      employmentLawName: name,
      employmentLawTitle: title,
      employmentLawDetail: detail,
    });
  };

  handleCloseRead = () => {
    this.setState({
      openDialogRead: false,
      employmentLawName: "",
      employmentLawTitle: "",
      employmentLawDetail: "",
    });
  };

  handleClickOpenDel = ({ name, title, detail, id }) => {
    this.setState({
      openDialogDel: true,
      employmentLawName: name,
      employmentLawTitle: title,
      employmentLawDetail: detail,
      currentEmploymentLawId: id,
    });
  };

  handleCloseDel = () => {
    this.setState({
      openDialogDel: false,
      employmentLawName: "",
      employmentLawTitle: "",
      employmentLawDetail: "",
      currentEmploymentLawId: "",
    });
  };

  handleChangeQuestions = (page, event) => {
    const { name, checked } = event.target;
    let questionsLot = { ...this.state.questionsLot };
    const { allQuestions } = this.state;
    const allQuestionsFiltred = allQuestions.filter(item => item.page == page);

    if (page === 5) {
      questionsLot[`page${page}`] = checked ? page5Questions : "";
      this.setState({ questionsLot });
      return
    }

    if (checked) {
      const index = allQuestionsFiltred.findIndex(item => item.cat_question === name);
      questionsLot[`page${page}`].push(allQuestionsFiltred[index]);
    } else {
      const index = questionsLot[`page${page}`].findIndex(item => item.cat_question === name);
      questionsLot[`page${page}`].splice(index, 1);
    }

    this.setState({ questionsLot });
  };

  loadAllLots = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const res = await fetch("/api/vic/allLots", requestOptions);
      const data = await res.json();

      this.setState({ allLots: data });
    } catch (err) {
      console.log(err);
    }
  };

  async addNewLot() {
    const { nameLot, questionsLot } = this.state;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: nameLot.trim(),
        questions: questionsLot,
      }),
    };
    try {
      const response = await fetch(
        "/api/vic/addLot",
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to add new employment law");
      }
      await this.props.dispatch(fetchAllLots());
      await this.props.dispatch(fetchAllLotItems());
      await this.setState({
        nameLot: "",
        questionsLot: { page2: [], page3: [], page4: [], page5: "" }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async updateLot() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        id: this.state.currentLotId,
        name: this.state.nameLot.trim(),
        questions: this.state.questionsLot,
      }),
    };
    try {
      const response = await fetch("/api/vic/updateLot", requestOptions);
      if (!response.ok) {
        throw new Error("Failed to update log");
      }
      await this.props.dispatch(fetchAllLots());
      await this.props.dispatch(fetchAllLotItems());
      await this.setState({
        createState: true,
        currentLotId: null,
        nameLot: "",
        questionsLot: { page2: [], page3: [], page4: [], page5: "" }
      });
    } catch (error) {
      console.log(error);
    }
  };

  async deleteLot() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        id: this.state.currentLotId,
      }),
    };
    try {
      const response = await fetch("/api/vic/deleteLot", requestOptions);
      if (!response.ok) {
        throw new Error("Failed to delete log");
      }
      await this.props.dispatch(fetchAllLots());
      await this.props.dispatch(fetchAllLotItems());
      await this.setState({
        openDialogDelLot: false,
        createState: true,
        currentLotId: null,
        nameLot: "",
        questionsLot: { page2: [], page3: [], page4: [], page5: "" }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async deleteQuestion() {
    this.setState((prevState) => {
      const currentPage = `page${this.state.currentPage}`;
      const updatedQuestionsPage = this.state.questionsLot[currentPage].filter(question => question.id !== this.state.questionDelId);

      return {
        questionsLot: {
          ...prevState.questionsLot,
          [currentPage]: updatedQuestionsPage
        }
      };
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        id: this.state.questionDelId,
      }),
    };

    try {
      const response = await fetch("/api/vic/deleteQuestion", requestOptions);
      if (!response.ok) {
        throw new Error("Failed to delete log");
      }
      await this.loadAllQuestions();
      await this.setState({
        openDialogDelQuestion: false,
        questionDel: "",
        questionDelId: null
      });
    } catch (error) {
      console.log(error);
    }
  }

  handleDeleteLot(data) {
    const { id, name } = data;
    this.setState({
      currentLotId: id,
      nameLot: name,
      openDialogDelLot: true
    });
  }

  handleCloseDelLot = () => {
    this.setState({
      openDialogDelLot: false,
      currentLotId: null,
      nameLot: ""
    });
  };

  updateQuestionsLot = (page, idCatQuestion, catQuestion, questionsArray) => {
    let questionsLot = { ...this.state.questionsLot };
    questionsLot[`page${page}`].map((item, index) => {
      if (item.id === idCatQuestion) {
        questionsLot[`page${page}`][index].cat_question = catQuestion;
        questionsLot[`page${page}`][index].questions = questionsArray;
      }
    })
    this.setState({ questionsLot });
  }

  handleCloseDelQuestion = () => {
    this.setState({ openDialogDelQuestion: false });
  };

  async handleAddQuestion(items) {
    const { page, catQuestion, questionsArray } = items;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        cat_question: catQuestion.trim(),
        questions: questionsArray,
        page
      }),
    };
    try {
      const response = await fetch("/api/vic/addNewQuestion", requestOptions);

      if (!response.ok) {
        throw new Error("Failed to add new question");
      }
      await this.loadAllQuestions();
      this.setState({ ctQuestions: false });
    } catch (error) {
      console.log(error);
    }
  }

  async handleUpdateQuestion(items) {
    const { catQuestion, questionsArray } = items;
    const { idCatQuestion } = this.state;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        id: idCatQuestion,
        cat_question: catQuestion.trim(),
        questions: questionsArray
      }),
    };

    try {
      const response = await fetch("/api/vic/updateQuestion", requestOptions);

      if (!response.ok) {
        throw new Error("Failed to add new question");
      }
      await this.loadAllQuestions();
      this.setState({ ctQuestions: false });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const {
      questionsLot,
      allQuestions,
      questions,
      currentPage,
      nameLot,
      name,
      title,
      detail,
      category_id,
      categories,
      newCategories,
      allEmploymentLaw,
      allEmploymentLaws,
      createState,
      currentEmploymentLawId,
      questionDel,
      openDialogDelLot,
      openDialogDelQuestion,
      openDialogDel,
      openDialogRead,
      employmentLawName,
      employmentLawTitle,
      employmentLawDetail,
      employmentLawNew,
      ctCategories,
      ctQuestions,
      idCatQuestion,
      createCat,
      loading
    } = this.state;

    return (
      <div className="vicEmploymentLaw">
        <Header />
        <h1 className="title">Gérer lots et codes du travail (VIC)</h1>
        <div className="ct-categories">
          <Button
            className="cat-button"
            variant="contained"
            style={{
              color: (ctCategories && !employmentLawNew) ? "black" : "white",
              fontWeight: (ctCategories && !employmentLawNew) ? "bold" : "",
              backgroundColor: (ctCategories && !employmentLawNew)
                ? "rgb(253, 181, 142)"
                : "rgba(255, 127, 80, 0.73)",
            }}
            onClick={() => {
              this.resetState();
              this.setState({
                employmentLawNew: false,
                ctCategories: true,
                ctQuestions: false
              })
            }}
          >
            Code du travail
          </Button>
          <Button
            className="cat-button"
            variant="contained"
            style={{
              width: "160px",
              color: (!ctCategories && !employmentLawNew) ? "black" : "white",
              fontWeight: (!ctCategories && !employmentLawNew) ? "bold" : "",
              backgroundColor: (!ctCategories && !employmentLawNew)
                ? "rgb(253, 181, 142)"
                : "rgba(255, 127, 80, 0.73)",
            }}
            onClick={() => {
              this.resetState();
              this.setState({
                employmentLawNew: false,
                ctCategories: false,
                ctQuestions: false
              })
            }}
          >
            Lot
          </Button>
          <Button
            className="cat-button"
            variant="contained"
            style={{
              color: employmentLawNew ? "black" : "white",
              fontWeight: employmentLawNew ? "bold" : "",
              backgroundColor: employmentLawNew
                ? "rgb(253, 181, 142)"
                : "rgba(255, 127, 80, 0.73)",
            }}
            onClick={() => {
              this.loadVICEmploymentLaws();
              this.resetState();
              this.setState({
                employmentLawNew: true
              })
            }}
          >
            Code du travail - Nouveaux lots
          </Button>
        </div>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={5}>
            <Card>
              {(ctCategories && !employmentLawNew) && (
                <CardContent>
                  {createState ? (
                    <h2>Ajouter un code du travail</h2>
                  ) : (
                    <h2>Modifier le code du travail</h2>
                  )}
                  <form>
                    <FormControl fullWidth>
                      <InputLabel id="category-label">Catégorie :</InputLabel>
                      <Select
                        labelId="category-label"
                        id="category"
                        name="category_id"
                        onChange={this.handleChange}
                        value={category_id || ""}
                      >
                        <MenuItem value="" />
                        {categories.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      label="Code du travail"
                      id="name"
                      name="name"
                      value={name}
                      onChange={this.handleChange}
                    />
                    <TextField
                      fullWidth
                      label="Titre"
                      id="title"
                      name="title"
                      value={title}
                      onChange={this.handleChange}
                    />
                    <TextField
                      fullWidth
                      label="Détail"
                      id="detail"
                      name="detail"
                      multiline
                      rows={8}
                      value={detail}
                      onChange={this.handleChange}
                    />
                    <div className="buttons">
                      {createState ? (
                        <Button
                          className="btn-button"
                          variant="contained"
                          color="primary"
                          onClick={this.addNewEmploymentLaw}
                        >
                          Ajouter
                        </Button>
                      ) : (
                        <Button
                          className="btn-button"
                          variant="contained"
                          color="primary"
                          onClick={this.updateEmploymentLaw}
                        >
                          Modifier
                        </Button>
                      )}
                      <Button
                        className="btn-button"
                        variant="contained"
                        color="primary"
                        onClick={this.resetState}
                      >
                        Annuler
                      </Button>
                    </div>
                  </form>
                </CardContent>
              )}
              {employmentLawNew && (
                <CardContent>
                  {createState ? (
                    <h2>Ajouter un code du travail</h2>
                  ) : (
                    <h2>Modifier le code du travail</h2>
                  )}
                  <form>
                    <FormControl fullWidth>
                      <InputLabel id="category-label">Catégorie :</InputLabel>
                      <Select
                        labelId="category-label"
                        id="category"
                        name="category_id"
                        onChange={this.handleChange}
                        value={category_id || ""}
                      >
                        <MenuItem value="" />
                        {newCategories.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      label="Code du travail"
                      id="name"
                      name="name"
                      value={name}
                      onChange={this.handleChange}
                    />
                    <TextField
                      fullWidth
                      label="Titre"
                      id="title"
                      name="title"
                      value={title}
                      onChange={this.handleChange}
                    />
                    <TextField
                      fullWidth
                      label="Détail"
                      id="detail"
                      name="detail"
                      multiline
                      rows={8}
                      value={detail}
                      onChange={this.handleChange}
                    />
                    <div className="buttons">
                      {createState ? (
                        <Button
                          className="btn-button"
                          variant="contained"
                          color="primary"
                          onClick={this.addNewEmploymentLaw}
                        >
                          Ajouter
                        </Button>
                      ) : (
                        <Button
                          className="btn-button"
                          variant="contained"
                          color="primary"
                          onClick={this.updateEmploymentLaw}
                        >
                          Modifier
                        </Button>
                      )}
                      <Button
                        className="btn-button"
                        variant="contained"
                        color="primary"
                        onClick={this.resetState}
                      >
                        Annuler
                      </Button>
                    </div>
                  </form>
                </CardContent>
              )}
              {(!ctCategories && !employmentLawNew) && (
                <CardContent>
                  <form className="lot-form">
                    {createState ? (
                      <h2>Ajouter un lot</h2>
                    ) : (
                      <h2>Modifier le lot</h2>
                    )}
                    <TextField
                      fullWidth
                      label="Nom du lot"
                      name="nameLot"
                      value={nameLot}
                      onChange={this.handleChange}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h3>Page 2</h3>
                      <IconButton
                        aria-label="add"
                        size="small"
                        style={{ backgroundColor: 'transparent' }}
                        onClick={() => this.setState({ currentPage: 2, ctQuestions: true })}
                      >
                        <Add />
                      </IconButton>
                    </div>
                    {allQuestions.filter(item => item.page === 2).map(({ id, cat_question, id_cat_question }, index) => {
                      const indexQuestion = questionsLot['page2'].findIndex(item => item.id_cat_question == id_cat_question) + 1

                      return (
                        <div className="questions-by-lot" key={`p2${index}`}>
                          <div>
                            <input type="checkbox" name={cat_question} onChange={(event) => this.handleChangeQuestions(2, event)} checked={questionsLot['page2'].some(item => item.id_cat_question == id_cat_question)} />
                            <sup style={{ color: 'red' }}>{indexQuestion === 0 ? "" : indexQuestion}</sup>
                            <label>{cat_question}</label>
                          </div>
                          <div>
                            <IconButton
                              key={`upP2${index}`}
                              className="small-icon-button"
                              aria-label="edit-question-lot"
                              size="small"
                              onClick={() => this.setState({ idCatQuestion: id, currentPage: 2, ctQuestions: true })}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              key={`delP2${index}`}
                              className="del-question-lot"
                              aria-label="del-question-lot"
                              size="small"
                              onClick={() => this.setState({ questionDel: cat_question, openDialogDelQuestion: true, currentPage: 2, questionDelId: id })}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </div>
                      );
                    })}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h3>Page 3</h3>
                      <IconButton
                        aria-label="add"
                        size="small"
                        style={{ backgroundColor: 'transparent' }}
                        onClick={() => this.setState({ currentPage: 3, ctQuestions: true })}
                      >
                        <Add />
                      </IconButton>
                    </div>
                    {allQuestions.filter(item => item.page === 3).map(({ id, cat_question, id_cat_question }, index) => {
                      const indexQuestion = questionsLot['page3'].findIndex(item => item.id_cat_question == id_cat_question) + 1
                      return (
                        <div className="questions-by-lot" key={`p3${index}`}>
                          <div>
                            <input type="checkbox" name={cat_question} onChange={(event) => this.handleChangeQuestions(3, event)} checked={questionsLot['page3'].some(item => item.id_cat_question == id_cat_question)} />
                            <sup style={{ color: 'red' }}>{indexQuestion === 0 ? "" : indexQuestion}</sup>
                            <label>{cat_question}</label>
                          </div>
                          <div>
                            <IconButton
                              key={`upP3${index}`}
                              className="small-icon-button"
                              aria-label="edit-question-lot"
                              size="small"
                              onClick={() => this.setState({ idCatQuestion: id, currentPage: 3, ctQuestions: true })}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              key={`delP3${index}`}
                              className="del-question-lot"
                              aria-label="del-question-lot"
                              size="small"
                              onClick={() => this.setState({ questionDel: cat_question, openDialogDelQuestion: true, currentPage: 3, questionDelId: id })}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </div>
                      );
                    })}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h3>Page 4</h3>
                      <IconButton
                        aria-label="add"
                        size="small"
                        style={{ backgroundColor: 'transparent' }}
                        onClick={() => this.setState({ currentPage: 4, ctQuestions: true })}
                      >
                        <Add />
                      </IconButton>
                    </div>
                    {allQuestions.filter(item => item.page === 4).map(({ id, cat_question, id_cat_question }, index) => {
                      const indexQuestion = questionsLot['page4'].findIndex(item => item.id_cat_question == id_cat_question) + 1
                      return (
                        <div className="questions-by-lot" key={`p4${index}`}>
                          <div>
                            <input type="checkbox" name={cat_question} onChange={(event) => this.handleChangeQuestions(4, event)} checked={questionsLot['page4'].some(item => item.id_cat_question == id_cat_question)} />
                            <sup style={{ color: 'red' }}>{indexQuestion === 0 ? "" : indexQuestion}</sup>
                            <label>{cat_question}</label>
                          </div>
                          <div>
                            <IconButton
                              key={`upP4${index}`}
                              className="small-icon-button"
                              aria-label="edit-question-lot"
                              size="small"
                              onClick={() => this.setState({ idCatQuestion: id, currentPage: 4, ctQuestions: true })}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              key={`delP4${index}`}
                              className="del-question-lot"
                              aria-label="del-question-lot"
                              size="small"
                              onClick={() => this.setState({ questionDel: cat_question, openDialogDelQuestion: true, currentPage: 4, questionDelId: id })}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </div>
                      );
                    })}
                    <h3>Page 5</h3>
                    <input type="checkbox" name="page5" onChange={(event) => this.handleChangeQuestions(5, event)} checked={questionsLot['page5'].length > 0} /><label>Travaux spécifiques amiante et plomb</label>
                    <div className="buttons">
                      {createState ? (
                        <Button
                          className="ct-button"
                          variant="contained"
                          color="primary"
                          disabled={nameLot.length < 1 || questionsLot['page2'].length < 1 || questionsLot['page3'].length < 1}
                          onClick={this.addNewLot.bind(this)}
                        >
                          Ajouter
                        </Button>
                      ) : (
                        <Button
                          className="ct-button"
                          variant="contained"
                          color="primary"
                          disabled={nameLot.length < 1 || questionsLot['page2'].length < 1 || questionsLot['page3'].length < 1}
                          onClick={this.updateLot.bind(this)}
                        >
                          Modifier
                        </Button>
                      )}
                      <Button
                        className="ct-button"
                        variant="contained"
                        color="primary"
                        onClick={this.resetState.bind(this)}
                      >
                        Annuler
                      </Button>
                    </div>
                  </form>
                </CardContent>
              )}
            </Card>
          </Grid >
        </Grid >
        {(ctCategories || employmentLawNew) &&
          <EmploymentLawTable
            allEmploymentLaw={employmentLawNew ? allEmploymentLaws : allEmploymentLaw}
            handleClickOpenRead={this.handleClickOpenRead}
            handleClickOpenDel={this.handleClickOpenDel}
            getEmploymentLawInfos={this.getEmploymentLawInfos}
          />
        }
        {(!ctCategories && !employmentLawNew) && <LotsVICTable loadLotQuestions={this.loadLotQuestions.bind(this)} handleDeleteLot={this.handleDeleteLot.bind(this)} />}
        {(ctQuestions && !employmentLawNew) &&
          <div className="display-questions-table">
            <div className="overlay">
              <div className="overlay-wrapper" onClick={this.handleHideThirdComponent}>
                <EmploymentLawQuestionsTable
                  idCatQuestion={idCatQuestion}
                  currentPage={currentPage}
                  allQuestions={allQuestions}
                  resetIdCarQuestion={() => this.setState({ idCatQuestion: null })}
                  updateQuestionsLot={this.updateQuestionsLot.bind(this)}
                  handleAddQuestion={this.handleAddQuestion.bind(this)}
                  handleUpdateQuestion={this.handleUpdateQuestion.bind(this)}
                  handleCloseComponent={() => this.setState({ ctQuestions: false })}
                />
              </div>
            </div>
          </div>
        }

        {loading && (<CircularProgress className="spinner" color="inherit" />)}

        <div>
          <Dialog open={openDialogRead} onClose={this.handleCloseRead}>
            <DialogTitle style={{ textAlign: "center" }}>
              {employmentLawName}
            </DialogTitle>
            <DialogContent>
              <h4>{employmentLawTitle}</h4>
              <div>
                <pre style={{ whiteSpace: "pre-wrap" }}>
                  {employmentLawDetail}
                </pre>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={this.handleCloseRead}
                style={{ color: "white", backgroundColor: "#FF7F50" }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog open={openDialogDel} onClose={this.handleCloseDel}>
            <DialogTitle style={{ textAlign: "center" }}>
              SUPPRESSION - CODE DU TRAVAIL
            </DialogTitle>
            <DialogContent>
              <h5>{employmentLawName}</h5>
              <h5>{employmentLawTitle}</h5>
              <div>
                <pre style={{ whiteSpace: "pre-wrap" }}>
                  {employmentLawDetail}
                </pre>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => this.deleteEmploymentLaw(currentEmploymentLawId)}
                style={{ color: "white", backgroundColor: "#FF7F50" }}
              >
                oui
              </Button>
              <Button
                variant="contained"
                onClick={this.handleCloseDel}
                style={{ color: "white", backgroundColor: "#F5B039" }}
              >
                non
              </Button>
            </DialogActions>
          </Dialog>
          <YesNoDialog
            isOpen={openDialogDelLot}
            title={'SUPPRESSION - LOT'}
            question={`Êtes-vous sûr de vouloir supprimer le lot «${nameLot}» ?`}
            handleYesClick={this.deleteLot.bind(this)}
            handleClose={this.handleCloseDelLot}
          />
          <YesNoDialog
            isOpen={openDialogDelQuestion}
            title={'SUPPRESSION - QUESTION'}
            question={`Êtes-vous sûr de vouloir supprimer les questions de «${questionDel}» ?`}
            handleYesClick={this.deleteQuestion.bind(this)}
            handleClose={this.handleCloseDelQuestion}
          />
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  allLots: state.vic.allLots,
});

export default connect(mapStateToProps)(EmploymentLaw);
