import React from 'react';
import ReactTable from 'react-table-6';
class ActTable extends React.Component{
    render(){
      /**
       * Initialisation des données du tableau avec les données récupérées en props
       */
        const data = [{
            nature: 'Activités du jour',
            type: this.props.data.map(act => act.typedujour),
            nbr: this.props.data.map(act => act.dujour)
          },{
            nature: 'Activités futur',
            type: this.props.data.map(act => act.typefutur),
            nbr: this.props.data.map(act => act.futur)
          },{
            nature: 'Activités en cours',
            type: this.props.data.map(act => act.typeencours),
            nbr: this.props.data.map(act => act.encours)
          },{
            nature: 'Activités en retard',
            type: this.props.data.map(act => act.typeenretard),
            nbr: this.props.data.map(act => act.enretard)
          }
        ]     
          const columns = [{ 
            Header: 'Nature',
            accessor: 'nature'
          },{
            Header: 'Type',
            accessor: 'type'
          },{
            Header: 'Nombre',
            accessor: 'nbr'
          }]
      
          return (
                
                    <ReactTable
                      data={data}
                      columns={columns}
                      defaultPageSize = {4}
                      //pageSizeOptions = {[3, 6]}
                    />
                      
          );
    }
}
export default ActTable;