import React from "react";
import { connect } from "react-redux";
import { getCurrentDevis } from "../Utils";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import tasksArray from "./tasksArray.json";
import "../../../Styles/Heures/QuotaH.scss";
import { v4 as uuidv4 } from "uuid";

class TaskList extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      isDesignPhase: true,
      conPhasesIndex: 0,
      reaPhasesIndex: 0,
      selectedPhase: "",
      selectedType: "",
      selectedTask: "",
      selectedDate: null,
      selectedTaskId: null,
      currentTaskHtp: 0,
      currentTaskHtr: 0,
      currentTaskHr: 0,
      currentTaskHd: 0,
      startH: "",
      endH: "",
      duration: 0,
      hoursCon: [],
      hoursRea: [],
    };
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleResetState = this.handleResetState.bind(this);
    this.dateInputRef = React.createRef();
  }

  componentDidMount() {
    this.initHours();
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      duration,
      currentTaskHtp,
      currentTaskHtr,
      selectedTaskId,
      isDesignPhase,
      selectedTask,
    } = this.state;
    const { dossierValue, dataCon, dataReal } = this.props;

    if (dossierValue !== prevProps.dossierValue) {
      this.initHours();
    }

    if (prevState.duration !== duration ||
        prevState.currentTaskHtp !== currentTaskHtp
    ) {
      let prevCurrentTaskHtr = 0;
      const data = isDesignPhase ? dataCon : dataReal;
      if (data.length > 0) {
        for (let i = data.length - 1; i >= 0; i--) {
          if (data[i].order_task === selectedTaskId) {
            prevCurrentTaskHtr = data[i].hree;
            break;
          }
        }
      }
      const newCurrentTaskHtr = Number(prevCurrentTaskHtr) + Number(duration);
      this.setState({ currentTaskHtr: newCurrentTaskHtr });
    }

    if (prevState.currentTaskHtr !== currentTaskHtr || 
        prevState.selectedTask !== selectedTask ||
        prevState.currentTaskHtp !== currentTaskHtp
    ) {
      const newCurrentTaskHrDis =
        Number(currentTaskHtp) - Number(currentTaskHtr);
      const newCurrentTaskHdDis =
        Number(currentTaskHtr) - Number(currentTaskHtp);
      this.setState({
        currentTaskHr: newCurrentTaskHrDis,
        currentTaskHd: newCurrentTaskHdDis,
      });
    }
  }

  initHours() {
    const { deviscon, devisrea, dossierValue } = this.props;
    const currentDeviscon = getCurrentDevis(deviscon, dossierValue);
    const currentDevisrea = getCurrentDevis(devisrea, dossierValue);
    if (currentDeviscon && currentDevisrea) {
      this.setState({
        hoursCon: JSON.parse(currentDeviscon.devisTab).nbrHeures,
        hoursRea: JSON.parse(currentDevisrea.devisTab).nbrHeures,
      });
    }
  }

  handleChangePhase(phase) {
    this.setState({
      isDesignPhase: phase === "Conception" ? true : false,
      selectedPhase: phase,
      selectedType: "",
      selectedTask: "",
      selectedTaskId: null,
      currentTaskHtp: 0,
      currentTaskHr: 0,
      currentTaskHd: 0,
    });
  }

  handleChangeType(item, index) {
    const indexToUpdate = this.state.isDesignPhase
      ? "conPhasesIndex"
      : "reaPhasesIndex";
    this.setState({
      [indexToUpdate]: index,
      selectedType: item,
      selectedTask: "",
      selectedTaskId: null,
      currentTaskHtp: 0,
      currentTaskHr: 0,
    });
  }

  handleChangeTask(item) {
    this.setState({
      selectedTask: item.name,
      selectedTaskId: item.index,
    });
    this.handleGetHtp(item.index);
  }

  handleGetHtp(selectedTaskId) {
    const updateCurrentTaskHtp = this.state.isDesignPhase
      ? this.state.hoursCon[selectedTaskId]
      : this.state.hoursRea[selectedTaskId];
    this.setState({
      currentTaskHtp: updateCurrentTaskHtp,
    });
  }

  handleTimeChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "startH") {
      this.setState({
        startH: value,
        duration: isNaN(this.props.getDuration(value, this.state.endH))
          ? 0
          : this.props.getDuration(value, this.state.endH),
      });
    } else if (name === "endH") {
      this.setState({
        endH: value,
        duration: isNaN(this.props.getDuration(this.state.startH, value))
          ? 0
          : this.props.getDuration(this.state.startH, value),
      });
    }
  }

  handleResetState() {
    this.setState({
      selectedPhase: "",
      selectedType: "",
      selectedTask: "",
      selectedDate: null,
      selectedTaskId: null,
      currentTaskHtp: 0,
      currentTaskHtr: 0,
      currentTaskHr: 0,
      currentTaskHd: 0,
      startH: "",
      endH: "",
      duration: 0,
    });
    this.handleResetDate();
  }

  handleResetDate = () => {
    this.dateInputRef.current.value = "";
  };

  handleChangeDate(date) {
    this.setState({
      selectedDate: new Date(date).toISOString(),
    });
  }

  render() {
    const currentTask = {
      ndossier: this.props.dossierValue,
      type: this.state.isDesignPhase ? "Conception" : "Realisation",
      tache: this.state.selectedTask,
      order_task: this.state.selectedTaskId,
      hdebut: this.state.startH,
      hfin: this.state.endH,
      hprev: this.state.currentTaskHtp,
      hree: this.state.currentTaskHtr,
      hres: this.state.currentTaskHr,
      hdep: this.state.currentTaskHd,
      date: this.state.selectedDate,
      isLastTask: 0,
    };
    const { category1 } = this.props;
    return (
      <div className="task-table">
        <TableContainer style={{ width: "767px" }} component={Paper}>
          <Table aria-label="simple table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell
                  className="head-row"
                  colSpan={4}
                  style={{ fontSize: "16px" }}
                >
                  Ajouter une tâche
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table-row">
              <TableRow>
                <TableCell component="th" scope="row" colSpan={2}>
                  Phase:
                </TableCell>
                <TableCell align="right" colSpan={2}>
                  <Select
                    labelId="phase-select-label"
                    id="phase-select"
                    value={this.state.selectedPhase}
                    onChange={(event) =>
                      this.handleChangePhase(event.target.value)
                    }
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    {tasksArray.phases.map((item) => {
                      return (
                        <MenuItem value={item} key={uuidv4()}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
              </TableRow>
              {this.state.selectedPhase !== "" && (
                <>
                  {category1 && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Type:
                      </TableCell>
                      <TableCell align="right" colSpan={3}>
                        <Select
                          labelId="actes-type-select-label"
                          id="actes-type-select"
                          value={this.state.selectedType}
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          {tasksArray.cat1[
                            this.state.isDesignPhase ? "conPhases" : "reaPhases"
                          ].map((item, index) => {
                            return (
                              <MenuItem
                                value={item}
                                key={uuidv4()}
                                onClick={() =>
                                  this.handleChangeType(item, index)
                                }
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </TableCell>
                    </TableRow>
                  )}
                  {((category1 && this.state.selectedType !== "") ||
                    !category1) && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Tâche:
                      </TableCell>
                      <TableCell align="right" colSpan={3}>
                        <Select
                          labelId="task-select-label"
                          id="task-select"
                          value={this.state.selectedTask}
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          {category1
                            ? tasksArray.cat1[
                                this.state.isDesignPhase
                                  ? "conTasks"
                                  : "reaTasks"
                              ][
                                this.state.isDesignPhase
                                  ? this.state.conPhasesIndex
                                  : this.state.reaPhasesIndex
                              ].map((item) => {
                                return (
                                  <MenuItem
                                    value={item.name}
                                    key={uuidv4()}
                                    onClick={() => this.handleChangeTask(item)}
                                  >
                                    {item.name}
                                  </MenuItem>
                                );
                              })
                            : tasksArray.cat2[
                                this.state.isDesignPhase
                                  ? "conTasks"
                                  : "reaTasks"
                              ].map((item) => {
                                return (
                                  <MenuItem
                                    value={item.name}
                                    key={uuidv4()}
                                    onClick={() => this.handleChangeTask(item)}
                                  >
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                        </Select>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
              <TableRow>
                <TableCell component="th" scope="row">
                  Heure Début:
                </TableCell>
                <TableCell component="th" scope="row">
                  <input
                    type="time"
                    name="startH"
                    onChange={this.handleTimeChange}
                    value={this.state.startH}
                    style={{ fontSize: "17px" }}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  Heure Fin:
                </TableCell>
                <TableCell component="th" scope="row">
                  <input
                    type="time"
                    name="endH"
                    onChange={this.handleTimeChange}
                    value={this.state.endH}
                    style={{ fontSize: "17px" }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Quota du:
                </TableCell>
                <TableCell component="th" scope="row">
                  <input
                    ref={this.dateInputRef}
                    style={{ fontSize: "17px" }}
                    type="date"
                    onChange={(e) => this.handleChangeDate(e.target.value)}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  Durée:
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography>
                    {this.state.duration !== 0
                      ? Number(this.state.duration.toFixed(2))
                      : ""}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Heures Totales Prévues:
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography>
                    {this.state.currentTaskHtp !== 0
                      ? this.state.currentTaskHtp
                      : ""}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  Heures Totales Réelles:
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography>
                    {this.state.currentTaskHtr !== 0
                      ? Number(this.state.currentTaskHtr.toFixed(2))
                      : ""}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Heures Restantes:
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography style={{ color: "blue" }}>
                    {this.state.currentTaskHr > 0
                      ? Number(this.state.currentTaskHr.toFixed(2))
                      : ""}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  Heures Dépassées:
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography style={{ color: "red" }}>
                    {this.state.currentTaskHd > 0
                      ? Number(this.state.currentTaskHd.toFixed(2))
                      : ""}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className="task-buttons">
          <div>
            <Button
              className="save-task-button"
              variant="contained"
              onClick={this.props.saveData}
            >
              Enregistrer
            </Button>
            <Button
              className="gen-task-button"
              variant="contained"
              onClick={this.props.genPDF}
            >
              Générer PDF
            </Button>
            <Button
              className="gen-task-button"
              variant="contained"
              onClick={this.props.showPDF}
            >
              Afficher PDF
            </Button>
          </div>
          <div>
            <Button
              className="add-task-button"
              color="primary"
              variant="contained"
              onClick={() => this.props.handleUpdateTasks(currentTask)}
              disabled={
                ![
                  this.state.selectedTask,
                  this.state.startH,
                  this.state.endH,
                  this.state.selectedDate,
                ].every(Boolean)
              }
            >
              Ajouter
            </Button>
            {/* <Button className="validate-task-button" variant="contained">
              Terminer
            </Button> */}
            <Button
              className="cancel-task-button"
              variant="contained"
              onClick={this.handleResetState}
            >
              Annuler
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  dossiers: state.dossier.items,
  deviscon: state.devis.deviscon,
  devisrea: state.devis.devisrea,
});
export default connect(mapStateToProps)(TaskList);
