import React from 'react';
import { connect } from 'react-redux';
import Button from "@material-ui/core/Button";
import GenPdf from '../GenPdf';
import Header from '../Header/Header';
import NumDoss from '../NumDoss';
import DocMenu from "../DocMenu";
import YesNoDialog from '../YesNoDialog';
import InformationDialog from '../InformationDialog';
import FactureStatus from '../Factures/FactureStatus';
import FacAmendmentInfos from "./FacAmendment/FacAmendmentInfos";
import FacAmendmentTable from "./FacAmendment/FacAmendmentTable";
import { fetchAllFacAmendment, fetchFacAmendmentByDoss, changeFacAmendmentNumber } from "../../redux/actions/facAmendmentActions";
import { fetchContrat } from "../../redux/actions/contratActions";
import { changeTitleName, changePdfFileName } from "../../redux/actions/emailAction";
import { getDossier, getContactInfos, getLastContractAmendmentInfos, getNbAmendments, getTauxhRea, titlesRealisation, ca, bred, genFacAmendment, getFacAmendment } from "./Utils";
import "../../Styles/FactureAmendment/FacAmendment.scss";


class FacAmendment extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      dossier: {},
      company: "",
      operation: "",
      adressOp: "",
      cpOp: "",
      cityOp: "",
      nameMo: "",
      sexeMo: "homme",
      adressMo: "",
      cityMo: "",
      cpMo: "",
      dates: [],
      numclient: "",
      bdcValue: "",
      dateEch: "",
      datecomptable: "",
      nfacture: "",
      bank: "",
      quantity: Array(9).fill(0),
      amountHt: Array(9).fill(0),
      nbHours: 0,
      totalht: 0,
      tauxh: 0,
      paymentMethod: 0,
      nbAmendments: 0,
      file: null,
      isFileOpen: false,
      tva: 20,
      disableButton: false,
      dataDetail: true,
      openDialog: false,
      openCreateFacDialog: false,
    }
    this.loadData = this.loadData.bind(this)
    this.resetValues = this.resetValues.bind(this)
  }

  async componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    const { dossierValue } = this.props;
    await this.props.dispatch(fetchAllFacAmendment())

    if (!dossierValue) {
      return
    }

    await this.props.dispatch(fetchContrat(dossierValue))
    await this.props.dispatch(fetchFacAmendmentByDoss(dossierValue))
    await this.handleCheckFacAmendmentIndexExist()
    await this.loadData()
    await this.setNbFactures()
  }

  async componentDidUpdate(prevProps) {
    const { dossierValue, facAmendmentIndex } = this.props;

    if (prevProps.dossierValue !== dossierValue) {
      await this.props.dispatch(fetchContrat(dossierValue))
      await this.props.dispatch(fetchFacAmendmentByDoss(dossierValue))
      await this.loadData();
      await this.setNbFactures();
    }
    if (prevProps.facAmendmentIndex !== facAmendmentIndex) {
      await this.loadData();
      await this.setNbFactures();
    }
  }

  handleCheckFacAmendmentIndexExist() {
    const { dossierValue, facAmendments } = this.props;
    const sortedFacAmendment = [...facAmendments].filter((item) => item.ndossier == dossierValue)
    if (sortedFacAmendment.length === 0) {
      this.props.dispatch(changeFacAmendmentNumber(0))
    } else this.props.dispatch(changeFacAmendmentNumber(1))
  }

  checkIndex(index) {
    if (index < 4) {
      return index - 1;
    } else if (index < 8) {
      return index - 2;
    } else return index - 3
  }

  handleChange(event, index) {
    const value = event.target.value;
    if (!/^[0-9.]*$/.test(value)) return
    const elementHt = this.state.tauxh * value
    let quantity = [...this.state.quantity]
    let amountHt = [...this.state.amountHt]
    quantity[this.checkIndex(index)] = value
    amountHt[this.checkIndex(index)] = parseFloat(elementHt.toFixed(4))
    const totalht = parseFloat(amountHt.reduce((total, element) => total + element, 0).toFixed(2))
    this.setState({ quantity, amountHt, totalht });
  }

  handleChangeValues(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({ [name]: value });
  }

  handleChangeTva(value) {
    this.setState({ tva: value });
  }

  handleButtonClick = (isDataDetail) => {
    this.setState({ dataDetail: isDataDetail });
  };

  setNbFactures() {
    const { dossierValue, facAmendments } = this.props;
    this.setState({
      nbAmendments: getNbAmendments(facAmendments, dossierValue)
    })
  }

  loadData() {
    this.props.dispatch(changePdfFileName(""))
    const { contact, dossiers, dossierValue, devisrea, contractFacAmendments, infosContract, facAmendmentsByDoss, facAmendmentIndex } = this.props;
    const currentFolder = getDossier(dossiers, dossierValue)
    const idcontact = currentFolder?.idcontact;
    const currentContact = idcontact !== null ? getContactInfos(contact, idcontact) : null;

    if (currentContact === null || !infosContract) {
      this.resetValues()
      return
    }

    let currentContractAmendmentInfos = getLastContractAmendmentInfos(contractFacAmendments, dossierValue)
    if (currentContractAmendmentInfos !== null) {
      currentContractAmendmentInfos.dates = currentContractAmendmentInfos.dates !== undefined ? JSON.parse(currentContractAmendmentInfos.dates) : []
    }

    if (currentContact) {
      this.setState({
        dossier: currentFolder,
        numclient: facAmendmentsByDoss[facAmendmentIndex - 1]?.numclient,
        nfacture: facAmendmentsByDoss[facAmendmentIndex - 1]?.nfacture,
        datecomptable: facAmendmentsByDoss[facAmendmentIndex - 1] ? facAmendmentsByDoss[facAmendmentIndex - 1].date_fac !== null ? new Date(facAmendmentsByDoss[facAmendmentIndex - 1].date_fac).toLocaleDateString("en-CA") : "" : "",
        bdcValue: facAmendmentsByDoss[facAmendmentIndex - 1]?.bdc_facture,
        quantity: facAmendmentsByDoss[facAmendmentIndex - 1] ? JSON.parse(facAmendmentsByDoss[facAmendmentIndex - 1].quantity) : Array(9).fill(0),
        amountHt: facAmendmentsByDoss[facAmendmentIndex - 1] ? JSON.parse(facAmendmentsByDoss[facAmendmentIndex - 1].amount_ht) : Array(9).fill(0),
        totalht: facAmendmentsByDoss[facAmendmentIndex - 1]?.total_ht,
        dateEch: currentContractAmendmentInfos.dates[facAmendmentIndex - 1]?.dateech,
        nbHours: currentContractAmendmentInfos?.hours,
        nameMo: `${currentContact.prenom} ${currentContact.nom}`,
        sexeMo: currentContact.sexe,
        adressMo: currentContact.adress,
        cityMo: currentContact.city,
        cpMo: +currentContact.cp,
        company: currentFolder.sci_sccv,
        operation: currentFolder.operation,
        adressOp: currentFolder.adresse_op,
        cpOp: +currentFolder.cp_op,
        cityOp: currentFolder.ville_op,
        bank: infosContract.bank,
        paymentMethod: infosContract ? infosContract.payment_method : 0,
        dates: currentContractAmendmentInfos.dates,
        tauxh: getTauxhRea(devisrea, dossierValue),
        dataDetail: !facAmendmentsByDoss[facAmendmentIndex - 1] && !this.state.dataDetail ? true : this.state.dataDetail,
        isHidden: facAmendmentsByDoss[facAmendmentIndex - 1] ? facAmendmentsByDoss[facAmendmentIndex - 1].validation == 1 ? false : true : true,
        file: null
      })
    }
  }

  resetValues() {
    this.setState({
      numclient: "",
      dateEch: "",
      nfacture: "",
      company: "",
      operation: "",
      adressOp: "",
      cpOp: "",
      cityOp: "",
      nameMo: "",
      sexeMo: "homme",
      adressMo: "",
      cityMo: "",
      cpMo: "",
      dates: [],
      tauxh: 0,
      nbHours: 0,
      paymentMethod: 0,
      bank: 0,
      bdcValue: "",
      dataDetail: true,
      isHidden: true,
    })
  }

  generateArrayData(quantity, amountHt) {
    const { tauxh } = this.state
    const result = [];
    for (let i = 0; i < quantity.length; i++) {
      result.push({ "title": titlesRealisation[i], "quantity": quantity[i] === "" ? 0 : quantity[i], "puht": tauxh.toFixed(2), "amountht": amountHt[i].toFixed(2), "tva": 1 });
    }
    return result;
  }

  transformText(inputText) {
    return inputText.replace(/(\d)(\d)(\d)/, '$1-$2-$3');
  }

  sumQuantity(array) {
    const arrayFormated = array.map(item => parseFloat(item === "" ? 0 : item));
    return arrayFormated.reduce((total, currentValue) => total + currentValue, 0);
  }

  async hundleCreateFacAmendment() {
    const { dossierValue, facAmendmentIndex } = this.props;
    const { bank, tva, dates, tauxh, paymentMethod, nbAmendments, dossier } = this.state;
    const initialValue = Array(9).fill(0)

    this.setState({ disableButton: true })

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Accept: "application/json" },
        body: JSON.stringify({
          ndossier: dossierValue,
          cp_op: dossier.cp_op,
          date_ech: dates[facAmendmentIndex].dateech,
          quantity: JSON.stringify(initialValue),
          amount_ht: JSON.stringify(initialValue),
          total_ht: 0,
          tva,
          pu_ht: tauxh,
          bank,
          payment_method: paymentMethod,
        })
      };

      const response = await fetch("/api/facamendment/createFacAmendment", requestOptions);
      if (!response.ok) {
        throw new Error("La requête a échoué.");
      }

      await this.props.dispatch(fetchAllFacAmendment())
      await this.props.dispatch(fetchFacAmendmentByDoss(dossierValue))
      await this.props.dispatch(changeFacAmendmentNumber(nbAmendments + 1))
    } catch (error) {
      console.error("Erreur lors de la création de la facture :", error);
    } finally {
      await this.setState({ disableButton: false, openCreateFacDialog: false })
    }
  }

  handleSaveClick = async () => {
    const { dossierValue } = this.props;
    const { numclient, bdcValue, dateEch, datecomptable, quantity, amountHt, totalht, bank, tva, tauxh, paymentMethod } = this.state;
    if (!datecomptable) {
      this.setState({ openDialog: true })
      return
    }
    const quantityParseFloat = quantity.map(item => parseFloat(item === "" ? 0 : item));
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Accept: "application/json" },
        body: JSON.stringify({
          ndossier: dossierValue,
          numclient,
          bdc_facture: bdcValue,
          date_ech: dateEch,
          date_fac: datecomptable,
          quantity: JSON.stringify(quantityParseFloat),
          amount_ht: JSON.stringify(amountHt),
          total_ht: totalht,
          tva,
          pu_ht: tauxh,
          bank,
          payment_method: paymentMethod,
        })
      };

      const response = await fetch("/api/facamendment/updateFacAmendment", requestOptions);
      if (!response.ok) {
        throw new Error("La requête a échoué.");
      } else {
        await this.props.dispatch(fetchAllFacAmendment())
      }

    } catch (error) {
      console.error("Erreur lors de la création de la facture :", error);
    }

  }

  handleGenClick = async () => {
    const { numclient, nfacture, nameMo, sexeMo, bdcValue, totalht, tva, quantity, amountHt, dossier, datecomptable } = this.state
    if (!datecomptable) {
      this.setState({ openDialog: true })
      return
    }

    this.setState({ file: null, isFileOpen: true, disableButton: true })
    const arraydata = this.generateArrayData(quantity, amountHt)
    const amouttva = totalht * tva / 100
    const totalttc = totalht + amouttva
    const totalhtFixed = totalht.toFixed(2)
    const nfacvalue = nfacture.replace(' ', '/A-')
    const customerCode = this.transformText(numclient)
    const sumquantity = this.sumQuantity(quantity)
    const bank_name = this.state.bank
    const bank = this.state.bank == 'ca' ? ca : bred

    const mo = {
      name: sexeMo == 'homme' ? `Monsieur ${nameMo}` : `Madame ${nameMo}`,
      adress: this.state.adressMo,
      cp: this.state.cpMo,
      city: this.state.cityMo
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
      body: JSON.stringify({
        ndossier: this.props.dossierValue,
        filetitle: customerCode.replace(/\//g, ' '),
        customercode: customerCode,
        dossier,
        arraydata,
        tva,
        amouttva: amouttva,
        totalht: totalhtFixed,
        totalttc: totalttc,
        sumquantity,
        bank_name,
        bank,
        nfacvalue,
        mo,
        datefac: this.state.dateEch,
        datecompta: datecomptable,
        facture_type: 'rea',
        bdc_facture: bdcValue
      })
    };

    let blob = await genFacAmendment(requestOptions);
    if (blob) {
      this.setState({ file: blob, disableButton: false });
      await this.handleSaveClick();
    }
  }

  handleShowPdf = async () => {
    const { dossierValue } = this.props
    const { numclient } = this.state
    const customerCode = numclient.replace(/\//g, ' ').split(' ')[2]

    this.setState({ file: null, disableButton: true });

    let response = await getFacAmendment(dossierValue, customerCode, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob, isFileOpen: true });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    } else {
      this.setState({ isFileOpen: false });
    }
    await this.setState({ disableButton: false });
  };

  handleGetPdfFile = async () => {
    const { dossierValue } = this.props
    const { numclient } = this.state
    const customerCode = numclient.replace(/\//g, ' ').split(' ')[2]

    this.setState({ file: null });
    let response = await getFacAmendment(dossierValue, customerCode, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    const { dossierValue } = this.props
    const { numclient } = this.state
    const customerCode = numclient.replace(/\//g, ' ').split(' ')[2]

    this.setState({ file: null });
    let response = await getFacAmendment(dossierValue, customerCode, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  closeDoc() {
    this.setState({ isFileOpen: false })
  }

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleOpenCreateFac = () => {
    this.setState({ openCreateFacDialog: true });
  };

  handleCloseCreateFac = () => {
    this.setState({ openCreateFacDialog: false });
  };

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`Facture Avenant AR${this.props.facAmendmentIndex}`))
  }

  render() {
    const { dataDetail, dates, openDialog, openCreateFacDialog, isHidden, dateEch, nbAmendments, file } = this.state
    const { facAmendmentIndex, dossierValue } = this.props

    return (
      <div className="fac-amendment">
        <Header />
        <DocMenu
          file={file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <NumDoss type="facAmendment" />
        <h1 className="title">
          Facture Avenant
        </h1>
        <FactureStatus
          isHidden={isHidden}
          dateFacture={dateEch}
          type="realisation"
        />
        <div className="data">
          <div className="btns">
            <Button
              className="btn"
              variant="contained"
              style={{
                color: dataDetail ? "black" : "white",
                fontWeight: dataDetail ? "bold" : "",
                backgroundColor: dataDetail
                  ? "rgb(253, 181, 142)"
                  : "rgba(255, 127, 80, 0.73)",
              }}
              onClick={() => this.handleButtonClick(true)}>
              Données
            </Button>
            <Button
              className="btn"
              variant="contained"
              style={{
                color: !dataDetail ? "black" : "white",
                fontWeight: !dataDetail ? "bold" : "",
                backgroundColor: !dataDetail
                  ? "rgb(253, 181, 142)"
                  : "rgba(255, 127, 80, 0.73)",
                ...(facAmendmentIndex === 0
                  ? { backgroundColor: "#ccc", cursor: "not-allowed" }
                  : {}),
              }}
              onClick={() => this.handleButtonClick(false)}
              disabled={facAmendmentIndex === 0}
            >
              Détails
            </Button>
          </div>
          {dataDetail ?
            <FacAmendmentInfos
              numclient={this.state.numclient}
              company={this.state.company}
              nameMo={this.state.nameMo}
              operation={this.state.operation}
              adressOp={this.state.adressOp}
              cpOp={this.state.cpOp}
              cityOp={this.state.cityOp}
              tauxh={this.state.tauxh}
              nbHours={this.state.nbHours}
              paymentMethod={this.state.paymentMethod}
              nbAmendments={this.state.nbAmendments}
              dates={dates}
              dateEch={this.state.dateEch}
              datecomptable={this.state.datecomptable}
              bdcValue={this.state.bdcValue}
              handleChangeValues={this.handleChangeValues.bind(this)}
              hundleCreateFacAmendment={this.handleOpenCreateFac.bind(this)}
            /> :
            <FacAmendmentTable
              quantity={this.state.quantity}
              amountHt={this.state.amountHt}
              totalht={this.state.totalht}
              tva={this.state.tva}
              puHt={this.state.tauxh}
              disableButton={this.state.disableButton}
              checkIndex={this.checkIndex}
              handleChangeTva={this.handleChangeTva.bind(this)}
              handleChange={this.handleChange.bind(this)}
              handleSaveClick={this.handleSaveClick.bind(this)}
              handleGenClick={this.handleGenClick.bind(this)}
              handleShowPdf={this.handleShowPdf}
              loadData={this.loadData}
            />}
          <InformationDialog
            isOpen={openDialog}
            title={'INFORMATION'}
            information={'La date de comptabilité (facturation) n\'est pas communiquée'}
            handleClose={this.handleClose}
          />
          <YesNoDialog
            isOpen={openCreateFacDialog}
            title={'CRÉATION FACTURE AVENANT'}
            question={`Voulez-vous créer la facture avenant «AR${nbAmendments + 1}» du dossier «${dossierValue}» ?`}
            handleYesClick={this.hundleCreateFacAmendment.bind(this)}
            handleClose={this.handleCloseCreateFac}
          />

          <GenPdf isOpen={this.state.isFileOpen} file={this.state.file} closeDoc={this.closeDoc.bind(this)} scale={this.state.file !== null ? 1 : 0.5} />
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  devisrea: state.devis.devisrea,
  dossierValue: state.devis.dossierValue,
  dossiers: state.dossier.items,
  contact: state.contact.items,
  contractFacAmendments: state.facAmendment.contractFacAmendments,
  facAmendments: state.facAmendment.facAmendments,
  facAmendmentsByDoss: state.facAmendment.facAmendmentsByDoss,
  facAmendmentIndex: state.facAmendment.facAmendmentIndex,
  infosContract: state.contrat.items,
});
export default connect(mapStateToProps)(FacAmendment)