import React from "react";
import BaseTable, { Column } from "react-base-table"
import 'react-base-table/styles.css'

class DocEntreprise extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            iterator: 0
        }
    }
    render() {
        const column = [ 
            {
                key: 'box1',
                dataKey: 'box1',
                width: 300,
                flexGrow: 1,
                flexShrink: 0,
            },
            {
                key: 'box2',
                dataKey: 'box2',
                width: 300,
                align: Column.Alignment.CENTER,
                flexGrow: 2,
                cellRenderer: ({ rowIndex }) => (<input type="checkbox"
                    onChange={(event) => this.props.handleChange(event, rowIndex)}
                    checked={this.props.valueArray[rowIndex]?.answer || false} />)
            }, {
                key: 'box3',
                dataKey: 'box3',
                width: 400,
                flexGrow: 3,
                cellRenderer: ({ cellData, rowIndex }) => (
                    <div style={{ display: "flex" }}>
                        <label style={{ marginRight: "20px" }}>{cellData}</label>
                        <input type="text" name="comment"
                            onChange={(event) => this.props.handleChange(event, rowIndex)}
                            value={this.props.valueArray[rowIndex]?.comment || ""}
                        />
                    </div>
                )
            }
        ]
        const data = [
            {
                id: 1,
                box1: "Documents en possession de l’entreprise",
                box3: 'Dossier Marché Maître d’ouvrage',
            },
            {
                id: 2,
                box3: 'Agrément du Maître d’ouvrage',
            },
            {
                id: 3,
                box3: 'PPSPS du Lot Gros Œuvre',
            },
            {
                id: 4,
                box3: 'Plan d’installation de chantier',
            },
            {
                id: 5,
                box3: 'Accueil du personnel sur le site ',
            },
        ];

        column[0].rowSpan = ({ rowData, rowIndex }) => 5
        column[0].align = Column.Alignment.CENTER

        const rowRenderer = ({ rowData, rowIndex, cells, columns }) => {
            if (rowIndex === 0) {
                const rowSpan = columns[0].rowSpan({ rowData, rowIndex })
                const cell = cells[0]
                const style = {
                    ...cell.props.style,
                    backgroundColor: "white",
                    height: rowSpan * 50 - 1,
                    alignSelf: 'flex-start',
                    zIndex: 1,
                }
                cells[0] = React.cloneElement(cell, { style })
            }
            return cells
        }
        return (
            <div className="testTable">
                <BaseTable rowKey="id" columns={column} width={1000} height={250} data={data} headerHeight={0} rowRenderer={rowRenderer} />
            </div>
        )
    }
}
export default DocEntreprise