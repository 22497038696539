export const titlesRealisation = [
  "Inspections Communes avec les entreprises",
  "Harmonisation des P.P.S.P.S./Mise à jour du P.G.C",
  "Tenue Registre Journal",
  "Visites de chantier (Nombre de visite)",
  "Réunions de C.I.S.S.C.T",
  "Participation au rendez-vous de chantier",
  "Récolement des pièces liées au D.I.U.O",
  "Mise à jour du D.I.U.O final",
  "Diffusion du P.G.C et du D.I.U.O"
]
 
export const titlesConception = [
  "Ouverture du Registre-Journal de la coordination",
  "Visite du site",
  "Assistance Déclaration Préalable",
  "Examen dossier conception/Analyse de risques",
  "Réunions Maîtrise de l'ouvrage/Maîtrise d'OEuvre (avant projet)",
  "Réunions Maîtrise de l'ouvrage/Maîtrise d'OEuvre (projet/consultation)",
  "Elaboration du P.G.C",
  "Elaboration pré D.I.U.O & du D.I.U.O",
  "Elaboration du règlement de C.I.S.S.C.T"
]

export const bred = {
  name: "BRED BANQUE POPULAIRE",
  code_bank: "10107",
  code_guichet: "00237",
  code_bic: "BREDFRPPXXX",
  cle: "42",
  num: "00529016611",
};

export const ca = {
  name: "CREDIT AGRICOLE",
  code_bank: "18706",
  code_guichet: "00000",
  code_bic: "AGRIFRPP887",
  cle: "32",
  num: "97521129119",
};

export const getFacture = (facture, is26Percent, first_slice) => {
  for (let i = 0; i < facture.length; i++) {
    if (is26Percent) {
      if (first_slice) {
        if (facture[i].numclient.includes('C1')) {
          return facture[i]
        }
      } else {
        if (facture[i].numclient.includes('C2')) {
          return facture[i]
        }
      }
    } else {
      if (facture[i].numclient.includes('C1')) {
        return facture[i]
      }
    }
  }
  return ""
}
export const getFactureRea = (facture, facIndex) => {
  for (let i = 0; i < facture.length; i++) {
    if (facture[i].numclient.includes(facIndex)) {
      return facture[i]
    }
  }
  return []
}

export const getEch = (echeances, dossierValue) => {
  let latestEcheance = null;

  for (const echeance of echeances) {
    if (echeance.ndossier === dossierValue) {
      if (!latestEcheance || echeance.amendment > latestEcheance.amendment) {
        latestEcheance = echeance;
      }
    }
  }

  return latestEcheance;
}

/**
 * Renvoie les données de la facture correspondant à nfac
 * @param {[]} facture 
 * @param {string} nfac 
 * @returns 
 */
export const getFac = (facture, nfac) => {
  for (let i = 0; i < facture.length; i++) {
    if (facture[i].old_nfac == nfac || facture[i].new_nfac == nfac) {
      return facture[i]
    }
  }
  return ""
}

/**
 * Renvoie les données de la facture correspondant à nfac
 * @param {[]} facture 
 * @param {number} dossierValue 
 * @returns 
 */
export const getAllConFacs = (facture, dossierValue) => {
  let data = [...facture]
  return data.filter(item => item.ndossier == dossierValue && item.numclient.includes('RX'))
}
export const getAllReaFacs = (facture, dossierValue) => {
  let data = [...facture]
  return data.filter(item => item.ndossier == dossierValue && item.numclient.includes('CX'))
}

/**
 * Renvoie l'échéance de la facture correspondante
 * @param {[]} facture 
 * @param {string} nfac 
 * @param {number} dossierValue 
 * @returns 
 */
export const getEcheance = (facture, nfac, dossierValue) => {
  for (let i = 0; i < facture.length; i++) {
    if (facture[i].old_nfac == nfac && facture[i].ndossier == dossierValue || facture[i].new_nfac == nfac && facture[i].ndossier == dossierValue) {
      return facture[i].date_ech
    }
  }
  return ""
}

/**
 * Récupère les données du contact choisi
 * @param {[]} dossier 
 * @param {[]} contact 
 * @param {number} dossierValue 
 * @returns 
 */
export function getContact(dossier, contact, dossierValue) {
  for (let i = 0; i < dossier.length; i++) {
    if (dossierValue == dossier[i].ndossier) {
      for (let j = 0; j < contact.length; j++) {
        if (dossier[i].idcontact == contact[j].idcontact) {
          return contact[j]
        }
      }
    }
  }
  return []
}

/**
 * Renvoie le dossier choisi
 * @param {[]} dossier 
 * @param {number} dossierValue 
 * @returns 
 */
export const getDossier = (dossier, dossierValue) => {
  for (let i = 0; i < dossier.length; i++) {
    if (dossier[i].ndossier == dossierValue) {
      return dossier[i]
    }
  }
  return ""
}

/**
  * Renvoie la durée de l'opération, le nombre de mois entre les échéances ainsi que le nombre de jours entre les échéances
  * @param {[]} devisrea 
  * @param {[]} dossier 
  * @param {number} dossierValue 
  * @returns 
  */
export const getEchNum2 = (devisrea, dossier, dossierValue) => {
  if (getDevisRea(devisrea, dossierValue).duree < 6 && getDossier(dossier, dossierValue).domaine == "Privé") {
    return [getDevisRea(devisrea, dossierValue).duree, 1, 30]
  }
  else if (getDevisRea(devisrea, dossierValue).duree >= 6 && getDossier(dossier, dossierValue).domaine == "Privé") {
    return [getDevisRea(devisrea, dossierValue).duree, 2, 60]
  }
  else {
    return [getDevisRea(devisrea, dossierValue).duree, 1.5, 45]
  }
}

/**
 * Renvoie les données du dernier devis réalisation validé
 * @param {[]} deviscon 
 * @param {number} dossierValue 
 * @returns 
 */
export const getDevisRea = (devisrea, dossierValue) => {
  var arr = []
  for (let i = 0; i < devisrea.length; i++) {
    if (devisrea[i].ndossier == dossierValue && devisrea[i].validation >= 0) {
      arr.push(devisrea[i])
    }
  }
  var max = Math.max.apply(Math, arr.map(item => item.validation))
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].validation == max) {
      return arr[i]
    }
  }
  return ""
}

/**
 * Renvoie les données du dernier devis conception validé
 * @param {[]} deviscon 
 * @param {number} dossierValue 
 * @returns 
 */
export const getDevisCon = (deviscon, dossierValue) => {
  var arr = []
  for (let i = 0; i < deviscon.length; i++) {
    if (deviscon[i].ndossier == dossierValue && deviscon[i].validation >= 0) {
      arr.push(deviscon[i])
    }
  }
  var max = Math.max.apply(Math, arr.map(item => item.validation))
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].validation == max) {
      return arr[i]
    }
  }
  return ""
}

/**
 * Renvoie le nombre d'échéances et leur fréquences pour la partie réalisation du dossier choisi
 * @param {[]} devisrea 
 * @param {[]} dossier 
 * @param {number} dossierValue 
 * @returns 
 */
export const getEchNum = (devisrea, dossier, dossierValue) => {
  if (getDevisRea(devisrea, dossierValue).duree < 6 && getDossier(dossier, dossierValue).domaine == "Privé") {
    return getDevisRea(devisrea, dossierValue).duree + " échéances Mensuelles"
  }
  else if (getDevisRea(devisrea, dossierValue).duree >= 6 && getDossier(dossier, dossierValue).domaine == "Privé") {
    return Math.ceil(getDevisRea(devisrea, dossierValue).duree / 2) + " échéances Bimensuelles"
  }
  else {
    return Math.ceil(getDevisRea(devisrea, dossierValue).duree / 3) + " échéances Trimensuelles"
  }
}

export const genPDF = async (requestOptions) => {
  return await fetch("/api/facture/genFacture", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const genFacTablePDF = async (requestOptions) => {
  return await fetch("/api/facture/genFactureTable", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const genSyntheseTablePDF = async (requestOptions) => {
  return await fetch("/api/facture/genSyntheseTablePDF", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};


export const getPDF = async (dossierValue, title, type) => {
  try {
    const res = await fetch("/api/facture/getFacture", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, filetitle: title, type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const genReminderPDF = async (requestOptions) => {
  return await fetch("/api/facture/genReminder", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const genReminderTablePDF = async (requestOptions) => {
  return await fetch("/api/facture/genReminderTable", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const genRecapPDF = async (requestOptions) => {
  return await fetch("/api/facture/genRecap", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const getReminderPDF = async (dossierValue, first_reminder, ref_reminder, type) => {
  try {
    const res = await fetch("/api/facture/getReminderPdf", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, first_reminder, ref_reminder, type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getReminderTablePDF = async (dossierValue) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({ ndossier: dossierValue }),
  };
  return await fetch("/api/facture/getReminderTablePdf", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const getFacTablePDF = async (dossierValue, type) => {
  try {
    const res = await fetch("/api/facture/getTableFacture", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getFactureRecapPDF = async (dossierValue, refFacture, type) => {
  try {
    const res = await fetch("/api/facture/getFacRecapPdf", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, refFacture, type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getSyntheseTablePDF = async (type) => {
  try {
    const res = await fetch("/api/facture/getSyntheseTablePdf", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getCodeFacture = (code) => {
  const pattern1 = /(\d{3}\/C[12]\/RX \d{4})/;
  const pattern2 = /(\d{3}\/CX\/R\d+) \d{4}/;

  const match1 = code.match(pattern1);
  if (match1) {
    return match1[0].split('/')[1];
  }

  const match2 = code.match(pattern2);
  if (match2) {
    return match2[1].split('/')[2];
  }

  return null;
}

export const handleFormatCustomerCode = (ndossier, numclient, date) => {
  const ndoss = ndossier.toString().split('').join('-')
  const code = getCodeFacture(numclient)
  const year = new Date(date).getFullYear()
  return `${ndoss} ${code}/${year}`
}
