import React from "react";
import Button from "@material-ui/core/Button";
import ReactTable from 'react-table-6';
import CircularProgress from "@material-ui/core/CircularProgress";
import { numberToFormat } from "../../Utils";

class ListTable extends React.Component {
  constructor(props) {
    super(props);
  }

  formatDate(inputDate) {
    const [year, month, day] = inputDate.split('-');

    return year && month && day ? `${day} / ${month} / ${year}` : "";
  }

  getColorBackgroundReminder = (validation, dateString, firstReminder = false) => {
    const today = new Date();
    const dateFormated = new Date(dateString);
    const reminderInterval = firstReminder ? 15 : 30;
    dateFormated.setDate(dateFormated.getDate() + reminderInterval);

    return today >= dateFormated ? validation ? "" : (firstReminder ? "orange" : "red") : "";
  }
 
  checkReminderDate = (propsValue, validation, dateString, firstReminder = false) => {
    if (propsValue !== "") return this.formatDate(propsValue)
    const response = this.getColorBackgroundReminder(validation, dateString, firstReminder) !== "" ? validation ? "" : "À ENVOYER" : ""
    return response
  }

  render() {
    const { facturesData, totalCumulativeAmount, totalAmount, refFacRecap, bdcFacRecap, disableButton } = this.props
    const data = facturesData;
    const columns = [
      {
        Header:
          <div>
            Eléments de mission
          </div>,
        accessor: 'month_ech',
        minWidth: 330,
        Cell: props => {
          return (
            <div className="centered-cell first-div" style={{ textAlign: "left" }}>
              {props.value}
            </div>
          )
        }
      }, {
        Header:
          <div>
            1<sup>ère</sup> relance
          </div>,
        accessor: 'first_reminder',
        minWidth: 130,
        Cell: props => {
          return (
            <div style={{ backgroundColor: props.value === "" ? this.getColorBackgroundReminder(facturesData[props.index].validation, facturesData[props.index].date_ech, true) : "orange", height: "100%", color: props.value === "" ? "black" : "white", fontWeight: "bold" }}>
              <div className="centered-cell">
                {this.checkReminderDate(props.value, facturesData[props.index].validation, facturesData[props.index].date_ech, true)}
              </div>
            </div>
          );
        }
      }, {
        Header:
          <div>
            2<sup>ème</sup> relance
          </div>,
        accessor: 'second_reminder',
        minWidth: 130,
        Cell: props => {
          return (
            <div style={{ backgroundColor: props.value === "" ? this.getColorBackgroundReminder(facturesData[props.index].validation, facturesData[props.index].date_ech) : "red", height: "100%", color: props.value === "" ? "black" : "white", fontWeight: "bold" }}>
              <div className="centered-cell">
                {this.checkReminderDate(props.value, facturesData[props.index].validation, facturesData[props.index].date_ech)}
              </div>
            </div>
          );
        }
      },
      {
        Header: 'Montant HT',
        accessor: 'total_ht',
        minWidth: 110,
        Cell: props => {
          return (
            <div className="centered-cell">
              {numberToFormat(props.value)}
            </div>
          );
        }
      },
      {
        Header:
          <div>
            Montant<br />
            Cumulé HT
          </div>,
        accessor: 'cumulative_amount',
        minWidth: 118,
        Cell: props => {
          return (
            <div className="centered-cell">
              {props.value ? numberToFormat(props.value) : ""}
            </div>
          );
        }
      },
      {
        Header:
          <div>
            Date de<br />
            Paiement
          </div>,
        accessor: 'payment_date',
        minWidth: 135,
        Cell: props => {
          return (
            <div style={{ backgroundColor: props.value === null ? "" : "green", height: "100%", color: "white", fontWeight: "bold" }}>
              <div className="centered-cell">
                {this.formatDate(props.value || "")}
              </div>
            </div>
          );
        }
      }, {
        Header:
          <div>
            Payé<br />
            <span style={{ color: "green" }}>Oui</span> - <span style={{ color: "red" }}>Non</span>
          </div>,
        accessor: 'validation',
        minWidth: 100,
        Cell: props => {
          return (
            <div style={{ backgroundColor: props.value ? "green" : "red" }} className="centered-cell">
              <input
                type="checkbox"
                name="validation"
                disabled={true}
                checked={props.value || ""}
              />
            </div>
          );
        }
      },
    ];

    return (
      <div className="table-list">
        <ReactTable
          data={data}
          columns={columns}
          defaultPageSize={10}
          pageSizeOptions={[10, 20, 50]}
          previousText={"Précédent"}
          nextText={"Suivant"}
          loadingText={"Chargement en cours..."}
          noDataText={"Aucune donnée trouvée."}
          rowsText={"lignes"}
          ofText={"de"}
          style={{
            background: 'white',
            textAlign: 'center',
          }}
          getTheadThProps={() => {
            return {
              style: {
                backgroundColor: '#a0a0a0',
                fontSize: '19px',
              }
            }
          }}
        />
        <div className="recap-table">
          <div className="table-container">
            <table>
              <tbody>
                <tr>
                  <th>Montant cumulé</th>
                  <td>{totalCumulativeAmount ? numberToFormat(totalCumulativeAmount) : "0.00"}</td>
                </tr>
                <tr>
                  <th>Montant à payer HT</th>
                  <td>{totalAmount ? numberToFormat(totalAmount) : "0.00"}</td>
                </tr>
                <tr>
                  <th>TVA 20%</th>
                  <td>{totalAmount ? numberToFormat(totalAmount * 0.2) : "0.00"}</td>
                </tr>
                <tr>
                  <th>Montant TTC</th>
                  <td>{totalAmount ? numberToFormat(totalAmount * 1.2) : "0.00"}</td>
                </tr>
              </tbody>
            </table>
            <table className="table-ref">
              <tbody>
                <tr>
                  <th>Référence de la facture</th>
                  <td><input type="text" name="refFacRecap" placeholder="A1, A2..." value={refFacRecap} onChange={(event) => this.props.handleChangeRefFacRecap(event)} /></td>
                </tr>
                <tr>
                  <th>N° BDC</th>
                  <td><input type="text" name="bdcFacRecap" placeholder="&quot;Bon de commande&quot;" value={bdcFacRecap} onChange={(event) => this.props.handleChangeRefFacRecap(event)} /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="btn-group">
          {disableButton && (
            <CircularProgress className="spinner" color="inherit" />
          )}
          <Button onClick={this.props.handleSaveRecap} disabled={disableButton || refFacRecap === ""} variant="contained" color="primary">Enregistrer</Button>
          <Button onClick={this.props.handleGenRecap} disabled={disableButton || refFacRecap === ""} variant="contained" color="primary">Générer</Button>
          <Button onClick={this.props.handleShowPdf} disabled={disableButton} variant="contained" color="primary">Afficher</Button>
          <Button onClick={this.props.initializeFefBdcValues} disabled={disableButton} variant="contained" color="primary">Annuler</Button>
        </div>
      </div>
    )
  }
}
export default ListTable;