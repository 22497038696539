import React from "react";
import BaseTable, { Column } from "react-base-table";
import "react-base-table/styles.css";
import { IconButton } from "@material-ui/core";
import ZoomInSharpIcon from "@material-ui/icons/ZoomInSharp";
import EditIcon from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

class EmploymentLawTable extends React.Component {
  render() {
    const { allEmploymentLaw } = this.props;
    const data = allEmploymentLaw;
    const column = [ 
      {
        title: "Catégorie",
        key: "category",
        dataKey: "category",
        width: 180,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <p key={`category-${rowIndex}`}>{rowData.category}</p>
        ),
      },
      {
        title: "Code du travail",
        key: "name",
        dataKey: "name",
        width: 120,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <p key={`name-${rowIndex}`}>{rowData.name}</p>
        ),
      },
      {
        title: "Titre",
        key: "title",
        dataKey: "title",
        width: 280,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <p key={`title-${rowIndex}`}>{rowData.title}</p>
        ),
      },
      {
        title: "Détail",
        key: "detail",
        dataKey: "detail",
        width: 400,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <div key={`detail-${rowIndex}`}>{rowData.detail.slice(0, 50)}...</div>
        ),
      },
      {
        key: "read",
        width: 50,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <IconButton
            key={`read-${rowIndex}`}
            className="read-employment-law"
            aria-label="read-employment-law"
            size="small"
            style={{ backgroundColor: "transparent" }}
            onClick={() => this.props.handleClickOpenRead(rowData)}
          >
            <ZoomInSharpIcon />
          </IconButton>
        ),
      },
      {
        key: "edit",
        width: 50,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <IconButton
            key={`edit-${rowIndex}`}
            className="edit-employment-law"
            aria-label="edit-employment-law"
            size="small"
            style={{ backgroundColor: "transparent" }}
            onClick={() => this.props.getEmploymentLawInfos(rowData)}
          >
            <EditIcon />
          </IconButton>
        ),
      },
      {
        key: "del",
        width: 50,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <IconButton
            key={`del-${rowIndex}`}
            className="del-employment-law"
            aria-label="del-employment-law"
            size="small"
            style={{ backgroundColor: "transparent" }}
            onClick={() => this.props.handleClickOpenDel(rowData)}
          >
            <Delete />
          </IconButton>
        ),
      },
    ];
    return (
      <div className="display-employment-law">
        <BaseTable
          columns={column}
          width={1100}
          headerHeight={50}
          rowHeight={60}
          height={data.length * 60 + 50}
          data={data}
          rowStyle={{
            height: "60px",
          }}
        ></BaseTable>
      </div>
    );
  }
}
export default EmploymentLawTable;
