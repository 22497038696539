export const getLastNum = (rj, dossierValue) => {
  let rjNum = -1;
  rj.map((rjValue) => {
    if (rjValue.ndossier == dossierValue) {
      if (rjValue.numero > rjNum) {
        rjNum = rjValue.numero;
      }
    }
  });
  return rjNum;
};

export const genPDF = async (dossierValue, numero, lastYearAverage) => { 
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({ ndossier: dossierValue, numero: numero, lastYearAverage }),
  };
  return await fetch("/api/rj/genPDF", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const getPDF = async (dossierValue, numero, type) => {
  try {
    const res = await fetch("/api/rj/getPDF", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, numero: numero, type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};
