import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Switch from '@material-ui/core/Switch';
import Header from '../Header/Header';
import DocMenu from '../DocMenu';
import NumDoss from '../NumDoss';
import GenPdf from '../GenPdf';
import FactureConData from './FactureCon/FactureConData';
import FactureConDetail from './FactureCon/FactureConDetail';
import FactureStatus from './FactureStatus';
import "../../Styles/Factures/FactureCon.scss";
import { getAllConFacs, getContact, getDossier, getFacture, genPDF, getPDF, getEch, titlesConception } from './Utils';
import { fetchFac, fetchFacByDoss } from '../../redux/actions/facActions';
import { fetchContrat } from '../../redux/actions/contratActions';
import { changeTitleName, changePdfFileName } from "../../redux/actions/emailAction";

class FactureCon extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      is26Percent: true,
      first_slice: true,
      sortedFac: [],
      currentDeviscon: {},
      dossier: {},
      mo: {},
      file: null,
      dataDetail: true,
      puHt: 0,
      totalht: 0,
      quantity: Array(9).fill(0),
      amountHt: Array(9).fill(0),
      datepgcp: null,
      datepgce: null,
      datecomptable: null,
      openDialog: false,
      pdf: false,
      disableButton: false,
      bank: 'bred',
      isHidden: true,
      tva: 20,
      bdcValue: ""
    };

    this.loadData = this.loadData.bind(this);
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
    this.generateArrayData = this.generateArrayData.bind(this);
    this.handleDateComptableChange = this.handleDateComptableChange.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    if (this.props.dossierValue) {
      this.props.dispatch(fetchFacByDoss(this.props.dossierValue))
      this.loadData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dossierValue, contratValues, facture, echeances, facturesbydoss } = this.props;
    const { first_slice } = this.state;

    if (facture !== prevProps.facture) {
      this.props.dispatch(fetchFacByDoss(dossierValue))
    }
    if (dossierValue !== prevProps.dossierValue) {
      this.props.dispatch(fetchFac('conception'))
      this.setState({ first_slice: true }, this.loadData);
    } else if (first_slice !== prevState.first_slice) {
      this.loadData();
    }

    if (contratValues && (contratValues !== prevProps.contratValues || echeances !== prevProps.echeances)) {
      let echeance = getEch(echeances, dossierValue)

      const { payment_method, bank } = contratValues;
      this.setState({
        is26Percent: payment_method === 26,
        bank,
        datepgcp: echeance !== null ? echeance.datepgcp.substring(0, 10) : contratValues?.datepgcp.substring(0, 10),
        datepgce: echeance !== null ? echeance.datepgce.substring(0, 10) : contratValues?.datepgce.substring(0, 10)
      });
    }
    if (facturesbydoss !== prevProps.facturesbydoss) {
      this.loadData()
    }
  }

  loadData() {
    this.props.dispatch(changePdfFileName(""))
    const { facture, dossierValue, dossier, contact, facturesbydoss } = this.props

    this.props.dispatch(fetchContrat(dossierValue))
    const currentDeviscon = this.recuperDeviscon(dossierValue)
    const sortedFac = getFacture(getAllConFacs(facture, dossierValue), this.state.is26Percent, this.state.first_slice)
    const data = facturesbydoss.filter(item => item.numclient == sortedFac.numclient)
    this.setState({
      sortedFac,
      dossier: getDossier(dossier, dossierValue),
      mo: getContact(dossier, contact, dossierValue),
      puHt: data.length ? data[0].pu_ht : currentDeviscon?.tauxh,
      currentDeviscon,
      totalht: data.length ? data[0].total_ht : 0,
      quantity: data.length ? JSON.parse(data[0].quantity) : Array(9).fill(0),
      amountHt: data.length ? JSON.parse(data[0].amount_ht) : Array(9).fill(0),
      datecomptable: data.length ? data[0].date_fac.substring(0, 10) : null,
      tva: data.length ? JSON.parse(data[0].tva) : 20,
      isHidden: data.length ? data[0].validation == 1 ? false : true : true,
      bdcValue: data.length ? data[0].bdc_facture : '',
      file: null
    })
  }

  recuperDeviscon(ndossier) {
    const devisconNdossier = this.props.deviscon.filter(item => item.ndossier == ndossier && item.validation == 1);
    devisconNdossier.sort((a, b) => b.nprop - a.nprop);
    const elementRecherche = devisconNdossier[0];
    return elementRecherche;
  }

  async createFact() {
    const { dossierValue } = this.props
    const { is26Percent, first_slice } = this.state
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: dossierValue,
        paymentMethod26: is26Percent,
        firstSlice: first_slice
      })
    };

    try {
      const response = await fetch("/api/facture/createConfacture", requestOptions);
      const result = await response.json();
      if (result) {
        await this.props.dispatch(fetchFac('conception'))
      }
    } catch (error) {
      console.log(error);
    }

  }

  handleChange(value, index) {
    if (!/^[0-9.]*$/.test(value)) return
    const elementHt = this.state.puHt * value
    let quantity = [...this.state.quantity]
    let amountHt = [...this.state.amountHt]
    quantity[this.checkIndex(index)] = value
    amountHt[this.checkIndex(index)] = parseFloat(elementHt.toFixed(2))
    const totalht = parseFloat(amountHt.reduce((total, element) => total + element, 0).toFixed(2))
    this.setState({ quantity, amountHt, totalht });
  }

  handleChangeTva(value) {
    this.setState({ tva: value });
  }

  handleDateComptableChange(event) {
    const { name, value } = event.target
    if (name == 'datefacture') {
      const nameDate = this.state.first_slice ? 'datepgcp' : 'datepgce'
      this.setState({ [nameDate]: value })
    } else this.setState({ [name]: value });
  }

  checkIndex(index) {
    if (index < 6) {
      return index - 1;
    } else return index - 2
  }

  generateArrayData(quantity, amountHt) {
    const { puHt } = this.state
    const result = [];
    for (let i = 0; i < quantity.length; i++) {
      result.push({ "title": titlesConception[i], "quantity": quantity[i] === "" ? 0 : quantity[i], "puht": puHt.toFixed(2), "amountht": amountHt[i], "tva": 1 });
    }
    return result;
  }

  transformText(inputText) {
    const transformedText = inputText.replace(/(\d)(\d)(\d)/, '$1-$2-$3');
    const percentage = this.state.is26Percent ? this.state.first_slice ? '26%' : '74%' : '34%';
    const finalText = transformedText.replace(/(RX) (\d{4})/, `$1 ${percentage} $2`);
    return finalText;
  }

  sumQuantity(array) {
    const arrayFormated = array.map(item => item = (item === null || item === '') ? 0 : parseFloat(item))
    return arrayFormated.reduce((total, currentValue) => total + currentValue, 0);
  }

  handleChangeSwitch() {
    this.setState({ first_slice: !this.state.first_slice });
  }

  handleSaveFactureClick = async () => {
    const { datepgcp, datepgce, bdcValue, is26Percent, first_slice, sortedFac, totalht, tva, quantity, amountHt, mo, datecomptable, puHt } = this.state
    if (!datecomptable) {
      this.setState({ openDialog: true })
      return
    }
    this.setState({ disableButton: true })
    const jsonQantity = JSON.stringify(quantity.map(item => item = (item === null || item === '') ? 0 : parseFloat(item)))
    const jsonAmoutHt = JSON.stringify(amountHt)
    const bank = this.state.bank

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
      body: JSON.stringify({
        ndossier: this.props.dossierValue,
        numclient: sortedFac.numclient,
        nfacture: sortedFac.new_nfac,
        bdc_facture: bdcValue,
        date_ech: first_slice ? datepgcp : datepgce,
        date_fac: datecomptable,
        mo,
        quantity: jsonQantity,
        amount_ht: jsonAmoutHt,
        total_ht: totalht,
        tva,
        pu_ht: puHt,
        payment_method: is26Percent ? 26 : 34,
        first_slice,
        bank,
        facture_type: 'con'
      })
    }

    await fetch("/api/facture/savefacture", requestOptions)
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
    await this.props.dispatch(fetchFacByDoss(this.props.dossierValue))
    await this.setState({ disableButton: false })
  }

  handleGenClick = async () => {
    const { datepgcp, datepgce, bdcValue, first_slice, sortedFac, totalht, tva, quantity, amountHt, dossier, datecomptable } = this.state
    if (!datecomptable) {
      this.setState({ openDialog: true })
      return
    }
    this.setState({ file: null, pdf: true, disableButton: true })
    const arraydata = this.generateArrayData(quantity, amountHt)
    const amouttva = totalht * tva / 100
    const totalttc = totalht + amouttva
    const totalhtFixed = totalht.toFixed(2)
    const nfacvalue = sortedFac.new_nfac.replace(' ', '/')
    const customerCode = this.transformText(sortedFac.numclient)
    const sumquantity = this.sumQuantity(quantity)
    const bank_name = this.state.bank
    let mo = { ...this.state.mo }
    mo.name = mo.sexe == 'homme' ? `Monsieur ${mo.prenom} ${mo.nom}` : `Madame ${mo.prenom} ${mo.nom}`
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
      body: JSON.stringify({
        ndossier: this.props.dossierValue,
        filetitle: customerCode.replace(/\//g, ' '),
        customercode: customerCode,
        dossier,
        arraydata,
        tva,
        amouttva: amouttva.toFixed(2),
        totalht: totalhtFixed,
        totalttc: totalttc.toFixed(2),
        sumquantity,
        bank_name,
        nfacvalue,
        mo,
        first_slice,
        datepgcp,
        datepgce,
        datecompta: datecomptable,
        facture_type: 'con',
        bdc_facture: bdcValue
      })
    };

    let blob = await genPDF(requestOptions);
    if (blob) {
      await this.handleSaveFactureClick();
      await this.setState({ file: blob, disableButton: false });
    }
  }

  handleShowPdf = async () => {
    const { sortedFac } = this.state
    let customerCode = this.transformText(sortedFac.numclient)
    customerCode = customerCode.replace(/\//g, ' ')
    await this.setState({ file: null, pdf: true, disableButton: true });
    let response = await getPDF(this.props.dossierValue, customerCode, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob, pdf: true });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    } else {
      this.setState({ pdf: false });
    }
    await this.setState({ disableButton: false });
  };

  handleGetPdfFile = async () => {
    const { sortedFac } = this.state
    let customerCode = this.transformText(sortedFac.numclient)
    customerCode = customerCode.replace(/\//g, ' ')
    await this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, customerCode, 'pdf');
    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    const { sortedFac } = this.state
    let customerCode = this.transformText(sortedFac.numclient)
    customerCode = customerCode.replace(/\//g, ' ')
    await this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, customerCode, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  handleClose = () => {
    this.setState({ openDialog: false, dataDetail: true });
  };

  closeDoc = () => {
    this.setState({ pdf: false });
  }

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`Facture Conception ${this.state.is26Percent ? this.state.first_slice ? 'C1' : 'C2' : 'C1'}`))
  }
  render() {
    const {
      dataDetail,
      is26Percent,
      first_slice,
      sortedFac,
      puHt,
      totalht,
      quantity,
      amountHt,
      tva,
      dossier,
      mo,
      currentDeviscon,
      datepgcp,
      datepgce,
      datecomptable,
      openDialog,
      file,
      isHidden,
      pdf,
      disableButton,
      bdcValue
    } = this.state

    return (
      <div className="facture-con">
        <Header />
        <DocMenu
          file={file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Facture Conception</h1>
        <NumDoss />
        <div className="data">
          <FactureStatus
            isHidden={isHidden}
            datepgcp={datepgcp}
            datepgce={datepgce}
            first_slice={first_slice}
            type="conception"
          />
          <div className="btns">
            <Button
              className="btn"
              variant="contained"
              style={{
                color: dataDetail ? "black" : "white",
                fontWeight: dataDetail ? "bold" : "",
                backgroundColor: dataDetail
                  ? "rgb(253, 181, 142)"
                  : "rgba(255, 127, 80, 0.73)",
              }}
              onClick={() => this.setState({ dataDetail: true })}
            >
              Données
            </Button>
            <Button
              className="btn"
              variant="contained"
              style={{
                color: !dataDetail ? "black" : "white",
                fontWeight: !dataDetail ? "bold" : "",
                backgroundColor: !dataDetail
                  ? "rgb(253, 181, 142)"
                  : "rgba(255, 127, 80, 0.73)",
              }}
              disabled={!sortedFac.numclient}
              onClick={() => this.setState({ dataDetail: false })}
            >
              Détails
            </Button>
          </div>
          {is26Percent && <div className="switch-div26">
            <span className={first_slice ? "active" : "no-active"}>1<sup>ère </sup>tranche de 26%</span>
            <Switch checked={!first_slice} color="primary" onChange={this.handleChangeSwitch} />
            <span className={first_slice ? "no-active" : "active"}>2<sup>ème </sup>tranche de 74%</span>
          </div>}
          {!is26Percent && <div className="switch-div34">
            <span>La conception représente 34% de la totalité</span>
          </div>}
          {dataDetail ?
            <FactureConData
              sortedFac={sortedFac}
              dossier={dossier}
              mo={mo}
              deviscon={currentDeviscon}
              datepgcp={datepgcp}
              datepgce={datepgce}
              bdcValue={bdcValue}
              is26Percent={is26Percent}
              first_slice={first_slice}
              datecomptable={datecomptable}
              createFact={this.createFact.bind(this)}
              handleChange={this.handleDateComptableChange}
            />
            : <FactureConDetail
              puHt={puHt}
              totalht={totalht}
              quantity={quantity}
              amountHt={amountHt}
              tva={tva}
              disableButton={disableButton}
              handleChange={this.handleChange.bind(this)}
              handleChangeTva={this.handleChangeTva.bind(this)}
              checkIndex={this.checkIndex}
              handleSaveFactureClick={this.handleSaveFactureClick.bind(this)}
              handleGenClick={this.handleGenClick.bind(this)}
              handleShowPdf={this.handleShowPdf.bind(this)}
              handleResetQantity={this.loadData}
            />}
        </div>
        <Dialog open={openDialog} onClose={this.handleClose}>
          <DialogTitle>INFORMATION</DialogTitle>
          <DialogContent>
            <DialogContentText>
              La date de comptabilité (facturation) n'est pas communiquée
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.handleClose} style={{ color: "white", backgroundColor: "#FF7F50" }}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <GenPdf file={file} isOpen={pdf} closeDoc={this.closeDoc} scale={this.state.file !== null ? 1 : 0.5} />
      </div>

    )
  }
}
const mapStateToProps = state => ({
  facture: state.facture.items,
  facturesbydoss: state.facture.facturesbydoss,
  nfacvalue: state.facture.nfacvalue,
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
  deviscon: state.devis.deviscon,
  contact: state.contact.items,
  echeances: state.echeances.items,
  contratValues: state.contrat.items
});
export default connect(mapStateToProps)(FactureCon);