import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';

class DialogModifDem extends React.Component { 
  constructor(props){
    super(props)
    this.state={
      isOpen:true,
      datedem:""
    }
    this.handleCloseProcess=this.handleCloseProcess.bind(this)
    this.handleOpen=this.handleOpen.bind(this)
    this.handleChange=this.handleChange.bind(this)
  }
  handleChange(event){
    this.setState({[event.target.name]:event.target.value})
  }
  handleCloseProcess(){
    this.setState({isOpen:false})
    this.props.closeDialog()
}
  handleOpen(){
    this.setState({isOpen:true})
}
handleModif(){
  // const requestOptions = {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
  //   body: JSON.stringify({ndossier:this.props.dossierValue, datedem:this.state.datedem})
  //   };   
  //   fetch("/api/devis/modifComp",requestOptions)
  //   .then(this.props.showNextDialog(3))
  //  .catch((err) => console.log(err))
   this.props.showNextDialog(3)
}
    render() {
      return (
        <div>
        <Dialog open={this.state.isOpen} onClose={this.handleCloseProcess} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Modification Entreprise Représentante</DialogTitle>
          <DialogContent>
            <DialogContentText>
                Veuillez indiquer la date de démarrage de chantier pour le dossier N°{this.props.dossierValue}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="datedem"
              label="Date de démarrage du chantier"
              onChange={this.handleChange}
              fullWidth
          />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.showNextDialog(3)} color="primary">
              Ne pas enregistrer
            </Button>
            <Button onClick={this.handleModif.bind(this)} color="primary">
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>  
        </div>       
      )
    }
  }
  const mapStateToProps = state => ({
    dossierValue : state.devis.dossierValue,
    dossier: state.dossier.items
  });
export default connect(mapStateToProps)(DialogModifDem)