export const documentToProvideConst = [
  {
    yes: false,
    no: true,
    type: "Dossier Technique Amiante"
  },
  {
    yes: false,
    no: true,
    type: "Rapport de mission de Repérage Amiante avant Travaux"
  },
  {
    yes: false,
    no: true,
    type: "Dossier de Diagnostic Technique Plomb"
  },
  {
    yes: false,
    no: true,
    type: "Règlement de la Consultation"
  },
  {
    yes: false,
    no: true,
    type: "CCTP" 
  },
  {
    yes: false,
    no: true,
    type: "CCAP"
  },
  {
    yes: false,
    no: true,
    type: "Étude de Sol"
  },
  {
    yes: false,
    no: true,
    type: "Plans"
  },
  {
    yes: false,
    no: true,
    type: "Plan des réseaux sous-Terrain, Aériens, Etc."
  },
  {
    yes: false,
    no: true,
    type: "Fiches de Données de Sécurité"
  }
]