import React from "react";
import Header from "../../Header/Header";
import NumDoss from "../../NumDoss";
import DocMenu from "../../DocMenu";
import { connect } from "react-redux";
import "../../../Styles/Heures/Hist.scss";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getHistPDF, getInfosDossier } from "../Utils";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import moment from "moment";
import GenPdf from "../../GenPdf";
import UsersHist from "./UsersHist";
class Hist extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      isOpen: false,
      data: [],
      action: {},
      openDialog: false
    };
    this.genPDF = this.genPDF.bind(this);
    this.showPDF = this.showPDF.bind(this);
  }
  async componentDidMount() {
    this.loadHistData();
  }

  async componentDidUpdate(prevProps) {
    this.props.dispatch(changePdfFileName(""))
    if (this.props.dossierValue !== prevProps.dossierValue) {
      this.loadHistData();
    }
  }

  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  async loadHistData() {
    this.props.dispatch(changeTitleName(``))
    const queryParams = new URLSearchParams({
      ndossier: this.props.dossierValue,
    });
    const url = "/api/quota/getHistByNdossier?" + queryParams.toString();

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const data = await response.json();
      this.setState({
        data,
        file: null,
      });
    } catch (err) {
      console.log(err);
    }
  }

  displayTask(data) {
    this.setState({
      action: data,
    })
  }

  async genPDF(actions) {
    this.setOpen(true);
    this.setState({ file: null });
    let dossier = getInfosDossier(this.props.dossiers, this.props.dossierValue);
    let data = actions.map(item => {
      if (item.action === "add") {
        return { ...item, action: "Ajouter" };
      } else {
        return { ...item, action: "Supprimer" };
      }
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: dossier.ndossier,
        operation: dossier.operation,
        adresse_op: dossier.adresse_op,
        cp_op: dossier.cp_op,
        ville_op: dossier.ville_op,
        currentdate: new Date().toLocaleDateString("fr-FR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        data,
      }),
    };
    let blob = await fetch("/api/quota/histGenPDF", requestOptions).then(
      (res) => res.blob()
    );
    if (blob) {
      this.setState({ file: blob });
    }
  }

  handleClose = () => {
    this.setState({
      openDialog: false,
      action: {},
    })
  }

  handleOpen = (data) => {
    this.setState({
      openDialog: true,
      action: data,
    })
  }

  async showPDF() {
    this.setState({ file: null });
    let response = await getHistPDF(this.props.dossierValue, 'pdf');

    if (response.blob.size > 100) {
      this.setOpen(true);
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  handleGetPdfFile = async () => {
    this.setState({ file: null });
    let response = await getHistPDF(this.props.dossierValue, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    this.setState({ file: null });
    let response = await getHistPDF(this.props.dossierValue, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`Historique des quotas d'heures`))
  }

  render() {
    return (
      <div className="hist">
        <Header />
        <DocMenu
          file={this.state.file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Historique des quotas d'heures</h1>
        <NumDoss />
        <UsersHist
          quotaData={this.state.data}
          handleOpen={this.handleOpen}
          genPDF={this.genPDF}
          showPDF={this.showPDF}
        />
        <Dialog open={this.state.openDialog} onClose={this.handleClose}>
          <DialogTitle style={{ textAlign: "center" }} >ACTION</DialogTitle>
          <DialogContent>
            <DialogContentText> Utilisateur: {this.state.action.username} </DialogContentText>
            <DialogContentText> Opération: {this.state.action.action === "add" ? "Addition" : "Suppression"} </DialogContentText>
            <DialogContentText> Type: {this.state.action.type === "Realisation" ? "Réalisation" : "Conception"} </DialogContentText>
            <DialogContentText> Tâche: {this.state.action.tache} </DialogContentText>
            <DialogContentText> Heure début: {this.state.action.hdebut?.slice(0, 5)} </DialogContentText>
            <DialogContentText> Heure fin: {this.state.action.hfin?.slice(0, 5)} </DialogContentText>
            <DialogContentText> Date: {moment(this.state.action.date).format("DD/MM/YYYY")} </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{ color: "white", backgroundColor: "#FF7F50" }} onClick={this.handleClose}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <GenPdf
          file={this.state.file}
          isOpen={this.state.isOpen}
          closeDoc={() => this.setOpen(false)}
          scale={1}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossiers: state.dossier.items,
  dossierValue: state.devis.dossierValue,
});
export default connect(mapStateToProps)(Hist);
