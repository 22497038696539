import {
  FETCH_FACTURE_BEGIN,
  FETCH_FACTURE_SUCCESS,
  FETCH_FACTURE_FAILURE,
  FETCH_ALL_FACTURE_BEGIN,
  FETCH_ALL_FACTURE_SUCCESS,
  FETCH_ALL_FACTURE_FAILURE,
  CHANGE_NFAC_VALUE,
  CHANGE_FACINDEX_VALUE,
  FETCH_FACBYDOSS_BEGIN,
  FETCH_FACBYDOSS_SUCCESS,
  FETCH_FACBYDOSS_FAILURE
} from '../actions/facActions';

const initialState = { 
  items: [],
  allfacture: [],
  facturesbydoss: [],
  loading: false,
  error: null,
  nfacvalue: "",
  facindex: 0
}

// Use the initialState as a default value
export default function facReducer(state = initialState,
  action) {
  switch (action.type) {
    case FETCH_FACTURE_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_FACTURE_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: action.payload.facture,
        nfacvalue: action.payload.nfacvalue
      }
    }
    case FETCH_FACTURE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };
    }
    case FETCH_ALL_FACTURE_BEGIN: {
      return {
        ...state,
        error: null
      }
    }
    case FETCH_ALL_FACTURE_SUCCESS: {
      return {
        ...state,
        allfacture: action.payload.allfacture
      }
    }
    case FETCH_ALL_FACTURE_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        items: []
      };
    }
    case CHANGE_NFAC_VALUE: {
      return {
        ...state,
        nfacvalue: action.payload.nfacvalue
      };
    }
    case CHANGE_FACINDEX_VALUE: {
      return {
        ...state,
        facindex: action.payload.index
      };
    }
    case FETCH_FACBYDOSS_BEGIN: {
      return {
        ...state,
        error: null
      };
    }
    case FETCH_FACBYDOSS_SUCCESS: {
      return {
        ...state,
        facturesbydoss: action.payload.facturesbydoss
      };
    }
    case FETCH_FACBYDOSS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        facturesbydoss: []
      };
    }
    default:
      return state
  }
}