import { FETCH_NOTIF_BEGIN, FETCH_NOTIF_SUCCESS, FETCH_NOTIF_FAILURE} from '../actions/notifActions';
const initialState = {
    items: [],
    loading:false,
    error:null
  }
  
  // Use the initialState as a default value
  export default function notifReducer(state = initialState, action) {  
    switch (action.type) {
      case FETCH_NOTIF_BEGIN: {
        return {
          ...state,
          loading:true,
          error:null  
        }
      }
      case FETCH_NOTIF_SUCCESS:{
          return {
              ...state,
              loading:false,
              items: action.payload.notif,
          }
      }
      case FETCH_NOTIF_FAILURE:{
        return {
            ...state,
            loading: false,
            error: action.payload.error,
            items: []
          }; 
      }
      default:
        return state
    }
  }