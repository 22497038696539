import React from "react"
import NumDoss from "../NumDoss"
import { connect } from "react-redux"
import { fetchLots, fetchVIC, newLotValue } from "../../redux/actions/vicActions"
// import { fetchPhotos, fetchRJ, newRjValue } from "../../redux/actions/rjActions"
import EntrepriseVICList from "./EntrepriseVICList"
class VICMenu extends React.Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    async componentDidMount(){ 
        if(this.props.dossierValue){
            await this.props.dispatch(fetchVIC(this.props.dossierValue))
        }
    }

    async componentDidUpdate(prevProps){
        if(!this.props.lots.length){
            await this.props.dispatch(fetchLots())
        }

        if(this.props.dossierValue !== prevProps.dossierValue){
            await this.props.dispatch(fetchVIC(this.props.dossierValue))
        }
        
    }
    // async componentDidUpdate(prevProps){
    //     if(this.props.dossierValue !== prevProps.dossierValue){
    //         await this.props.dispatch(newRjValue(this.filterRJ()[this.filterRJ().length - 1]))
    //     }
    //     if(JSON.stringify(this.props.rjValue)!==JSON.stringify(prevProps.rjValue)){
    //         this.filterRJ().map(async(rj) =>{
    //             if(rj.numero==this.props.rjValue.numero){
    //                 await this.props.dispatch(newRjValue(rj))
    //                 // await this.props.dispatch(fetchPhotos(this.props.dossierValue))
    //             }
    //         })
    //     }
    // }

    // filterRJ = () => {
    //     return this.props.rj.filter(rj =>{
    //         if(rj.ndossier==this.props.dossierValue) return true
    //         else return false
    //     })
    // }

    filterLots(){
        const {vic} = this.props
        var numPage = Number(window.location.pathname.slice(-1))
        return this.props.lots.filter(lot => {  
            if(numPage<=lot.nbr_pages) return true
            if(numPage==6 && lot.nbr_pages==5) return true
            if(window.location.pathname.includes("validation")) return true  
            if(numPage==7 || numPage==8){
                for(const item of vic){
                    if(item.ndossier==this.props.dossierValue && item.typeConv!=="" && lot.nbr_pages<=6 && item.lots==lot.code){
                        return true
                    }
                    if(item.ndossier==this.props.dossierValue && lot.nbr_pages>6 && item.lots==lot.code){
                        return true
                    }
                }
            }       
            return false
        })
    }

    async handleLotChange(event){
        await this.props.dispatch(newLotValue(event.target.value))
    }

    render(){
        return(
            <div className="vicmenu">
                <NumDoss/>
                <div className="lot">
                    <label>Lot : </label>
                    <select onChange={this.handleLotChange.bind(this)} value={this.props.lotValue}>
                        {this.filterLots().map(({code, nom},i) =>{
                            return <option value={code} key={i}>{nom}</option>
                        })}
                    </select>
                </div>
                <EntrepriseVICList/>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    vic : state.vic.items,
    lots : state.vic.lots,
    lotValue : state.vic.lotValue,
    dossierValue: state.devis.dossierValue,
    rj : state.rj.items,
    rjValue : state.rj.rjValue,
  });
export default connect(mapStateToProps)(VICMenu);