import React from 'react';
import LogoContact from "../../Styles/Pictures/logo_contact.png";
import Fleche from '../../Styles/Pictures/down-arrow.svg';
class ContactImage extends React.Component{
    render(){
        return(
            <div className="contact">
                <img className="logocontact"src={LogoContact} alt=""></img>
                <img className="flechebas2"src={Fleche} alt=""></img>
                <label>Contact</label>
            </div>
        );
    }
}
export default ContactImage; 