export const FETCH_DEVIS_BEGIN   = 'FETCH_DEVIS_BEGIN';
export const FETCH_DEVIS_SUCCESS = 'FETCH_DEVIS_SUCCESS';
export const FETCH_PROPO_SUCCESS = 'FETCH_PROPO_SUCCESS';
export const FETCH_DEVIS_FAILURE = 'FETCH_DEVIS_FAILURE';
export const CHANGE_DOSSIER_VALUE = 'CHANGE_DOSSIER_VALUE';
export const CHANGE_DEVISC_VALUE = 'CHANGE_DEVISC_VALUE';
export const CHANGE_DEVISR_VALUE = 'CHANGE_DEVISR_VALUE';
export const CHANGE_DOSSIER_TAB_VALUE = 'CHANGE_DOSSIER_TAB_VALUE';
export const CHANGE_PROPO_VALUE = 'CHANGE_PROPO_VALUE';

export function fetchDevis() { 
    return dispatch => {
      dispatch(fetchDevisBegin());
      return fetch("/api/devis/getAll")
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
          dispatch(fetchDevisSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchDevisFailure(error)));
    };
  }

  export function fetchNewPropo(dossier) {
    return dispatch => {
      dispatch(fetchDevisBegin());
      return fetch("/api/devis/getAll")
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
          dispatch(fetchNewPropoSuccess(json,dossier));
          return json;
        })
        .catch(error => dispatch(fetchDevisFailure(error)));
    };
  }

  // Handle HTTP errors since fetch won't.
  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  // function filterDevisCon(devis){
  //   var devisCon = []
  //   for(let i=0;i<devis.length;i++){
  //     if(devis[i].type==="conception"){
  //       devisCon.push(devis[i])
  //     }
  //   }
  //   return devisCon
  // }

  // function filterDevisRea(devis){
  //   var devisRea = []
  //   for(let i=0;i<devis.length;i++){
  //     if(devis[i].type==="realisation"){
  //       devisRea.push(devis[i])
  //     }
  //   }
  //   return devisRea
  // }

export function newDossierValue(newValue){
  return dispatch => dispatch(changeDossierValue(Number(newValue)))
}

export function newDevisConValue(newValue){
  return dispatch => dispatch(changeDevisConValue(newValue))
}

export function newDevisReaValue(newValue){
  return dispatch => dispatch(changeDevisReaValue(newValue))
}

export function newNpropValue(newValue){
  return dispatch => dispatch(changeNpropValue(Number(newValue)))
}

export const fetchDevisBegin = () => ({
  type: FETCH_DEVIS_BEGIN
});

export const fetchDevisSuccess = devis => ({
  type: FETCH_DEVIS_SUCCESS,
  payload: { deviscon:devis.deviscon, devisrea:devis.devisrea, dossierValue:devis.deviscon[0].ndossier, dossierTab:devis.dossierTab,
  nprop:devis.deviscon[0].nprop,validation:devis.validation }
});

export const fetchNewPropoSuccess = (devis,dossier) => ({
  type: FETCH_DEVIS_SUCCESS,
  payload: { deviscon:devis.deviscon, devisrea:devis.devisrea, dossierValue:dossier, dossierTab:devis.dossierTab,
  nprop:devis.deviscon[devis.deviscon.length - 1].nprop,validation:devis.validation }
});

export const fetchDevisFailure = error => ({
  type: FETCH_DEVIS_FAILURE,
  payload: { error }
});

export const changeDossierTabValue = dossierTab => ({
  type: CHANGE_DOSSIER_TAB_VALUE,
  payload: { dossierTab }
});

export const changeDossierValue = dossierValue => ({
  type: CHANGE_DOSSIER_VALUE,
  payload: { dossierValue }
});

export const changeDevisConValue = deviscon => ({
  type: CHANGE_DEVISC_VALUE,
  payload: { deviscon }
});

export const changeDevisReaValue = devisrea => ({
  type: CHANGE_DEVISR_VALUE,
  payload: { devisrea }
});

export const changeNpropValue = nprop => ({
  type: CHANGE_PROPO_VALUE,
  payload: { nprop }
});


