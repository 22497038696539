import React, { useEffect, useState, useCallback } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getDossierInfos, getCoverPageData, getProjectPresentationData, genPgcPDF, getPgcFile } from "../Utils";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossPGC from "../../NumDossPGC";
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import { initialDiagnosisDescription, preventionOrganizationsCont } from "./Const";
import "../../../Styles/PGC/Page2.scss";

const PgcPage2 = () => { 
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.pgc.index);
  const pgcValue = useSelector((state) => state.pgc.pgcValue);

  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [siteParticipants, setSiteParticipants] = useState([
    { title: "Maître d'ouvrage", adress: '', representative: '', contact: '' },
    { title: 'Architecte', adress: '', representative: '', contact: '' },
    { title: 'Bureau', adress: '', representative: '', contact: '' },
    { title: 'Contrôle technique de la construction', adress: '', representative: '', contact: '' },
    {
      title: 'Coordonnateur SPS', adress: 'COORDOGEC\n4, rue des Artisans\n93160 - NOISY LE GRAND',
      representative: 'Jean-Marie SAINT-GERMAIN',
      contact: '0143036317 - 0680681482\ncoordogec@coordogec.fr'
    },
  ]);

  const [preventionOrganizations, setPreventionOrganizations] = useState(preventionOrganizationsCont)

  const [operationDescription, setOperationDescription] = useState({
    description: {
      adress: '',
      operation: '',
      buildingPurpose: '',
      nbBuilding: '',
      nbLevels: '',
      surface: ''
    },
    schedule: {
      estimatedTimeline: '',
      preparationDate: '',
      startDate: '',
      endDate: ''
    },
    estimatedWorkforce: {
      category: '',
      estimatedAverageWorkforce: '',
      estimatedPeakWorkforce: '',
      nbCompany: ''
    }
  });

  const [diagnosisDescription, setDiagnosisDescription] = useState([initialDiagnosisDescription]);
  const [diagnosis, setDiagnosis] = useState([
    {
      title: 'Étude géotechnique des sols',
      reference: '',
      vigilance: ''
    },
    {
      title: 'Environnemental',
      reference: '',
      vigilance: ''
    },
    {
      title: 'Amiante',
      reference: '',
      vigilance: ''
    }
  ])

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (!dossierValue) return
    loadData()

  }, [pgcValue]);

  const loadData = async () => {
    await resetData();
    if (!pgcValue) return
    setIsLoading(true);

    const responseProjectPresentationData = await getProjectPresentationData(pgcValue, dossierValue, index);

    if (responseProjectPresentationData.site_participants) {
      const siteParticipantsLoaded = JSON.parse(responseProjectPresentationData.site_participants);
      const preventionOrganizationsLoaded = JSON.parse(responseProjectPresentationData.prevention_organizations);
      const operationDescriptionLoaded = JSON.parse(responseProjectPresentationData.operation_description);
      const diagnosisDescriptionLoaded = responseProjectPresentationData.diagnosis_description ? JSON.parse(responseProjectPresentationData.diagnosis_description) : [initialDiagnosisDescription];
      const diagnosisLoaded = JSON.parse(responseProjectPresentationData.diagnosis);

      unstable_batchedUpdates(() => {
        setSiteParticipants(siteParticipantsLoaded);
        setPreventionOrganizations(preventionOrganizationsLoaded);
        setOperationDescription(operationDescriptionLoaded);
        setDiagnosisDescription(diagnosisDescriptionLoaded);
        setDiagnosis(diagnosisLoaded);
        setIsLoading(false);
      })
    } else {
      const responseCoverPageData = await getCoverPageData(pgcValue, dossierValue);
      const responseDossierInfos = await getDossierInfos(dossierValue);

      if (Object.keys(responseCoverPageData).length > 2) {
        const construction_site = JSON.parse(responseCoverPageData.construction_site);
        const project_owner = JSON.parse(responseCoverPageData.project_owner);
        const project_manager = JSON.parse(responseCoverPageData.project_manager);
        const office = JSON.parse(responseCoverPageData.office);
        const timeline = responseCoverPageData.timeline;
        
        unstable_batchedUpdates(() => {
          setSiteParticipants(prevParticipants => {
            const updatedParticipants = office.type === 'Contrôle'
              ? prevParticipants.filter(participant => participant.title !== 'Contrôle technique de la construction')
              : prevParticipants;

            return updatedParticipants.map(participant =>
              participant.title === "Maître d'ouvrage"
                ? {
                  ...participant,
                  adress: `${project_owner.name ? `${project_owner.name}\n${project_owner.complement ? `${project_owner.complement}\n` : ''}${project_owner.adress}\n${project_owner.cp} - ${project_owner.city}` : ''}`,
                  contact: `${project_owner?.tel || ''}`
                }
                : participant.title === "Architecte"
                  ? {
                    ...participant,
                    adress: `${project_manager.name ? `${project_manager.name}\n${project_manager.complement ? `${project_manager.complement}\n` : ''}${project_manager.adress}\n${project_manager.cp} - ${project_manager.city}` : ''}`,
                    contact: `${project_manager?.tel || ''}`
                  }
                  : participant.title.includes("Bureau")
                    ? {
                      ...participant,
                      title: office.type === 'Contrôle' ? 'Bureau de contrôle' : office.type === 'Etude thermique' ? 'Bureau d\'étude thermique' : `Bureau ${office.type}`,
                      adress: `${office.name ? `${office.name}\n${office.complement ? `${office.complement}\n` : ''}${office.adress}\n${office.cp} - ${office.city}` : ''}`,
                      contact: `${office?.tel || ''}`
                    }
                    : participant
            );
          });

          setOperationDescription(prevState => ({
            ...prevState,
            description: {
              ...prevState.description,
              adress: `${construction_site.adress}, ${construction_site.cp} - ${construction_site.city}`,
              operation: construction_site.operation
            },
            schedule: {
              ...prevState.schedule,
              estimatedTimeline: `${timeline.rea} mois`,
              preparationDate: `${timeline.con} mois`
            },
            estimatedWorkforce: {
              ...prevState.estimatedWorkforce,
              category: construction_site.category
            }
          }))

          if (responseDossierInfos) {
            setSiteParticipants(prevParticipants =>
              prevParticipants.map(participant =>
                participant.title === "Maître d'ouvrage"
                  ? {
                    ...participant,
                    representative: `${responseDossierInfos.prenomMo} ${responseDossierInfos.nomMo}`,
                    contact: `${project_owner?.tel ? project_owner.tel : responseDossierInfos.telMo}\n${responseDossierInfos.mailMo}`
                  }
                  : participant
              )
            );
          }
          setIsLoading(false);
        })
      }
    }
  };

  const handleUpdateData = (setter) => (event, partToUpdate) => {
    const { name, value } = event.target;

    setter(prevState => ({
      ...prevState,
      [partToUpdate]: {
        ...prevState[partToUpdate],
        [name]: value
      }
    }));
  };

  const handleUpdatePreventionOrganizations = handleUpdateData(setPreventionOrganizations);
  const handleUpdateOperationDescription = handleUpdateData(setOperationDescription);

  const handleUpdateDiagnosisDescription = (event, index) => {
    const { name, value } = event.target;
    setDiagnosisDescription(prevState =>
      prevState.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      )
    );
  }

  const handleAddDiagnosisDescription = () => {
    setDiagnosisDescription(prevState => [...prevState, {
      number: prevState.length + 1,
      mission: '',
      bet: '',
      affair: '',
      date: ''
    }]);
  }

  const handleDeleteDiagnosisDescription = (i) => {
    const tempDiagnosisDescription = diagnosisDescription.filter((item, index) => index !== i);

    if (tempDiagnosisDescription.length === 0) {
      setDiagnosisDescription([initialDiagnosisDescription]);
    } else {
      tempDiagnosisDescription.forEach((item, index) => {
        item.number = index + 1;
      });

      setDiagnosisDescription(tempDiagnosisDescription);
    }
  }

  const handleAddSiteParticipants = () => {
    setSiteParticipants(prevState => [...prevState, {
      title: "",
      adress: '',
      representative: '',
      contact: ''
    }])
  }

  const handleDeleteSiteParticipants = (i) => {
    const tempSiteParticipants = siteParticipants.filter((item, index) => index !== i);
    setSiteParticipants(tempSiteParticipants);
  }

  const handleAddDiagnosisElement = () => {
    setDiagnosis(prevState => [...prevState, {
      title: '',
      reference: '',
      vigilance: ''
    }]);
  }

  const handleDeleteDiagnosisElement = (i) => {
    const tempDiagnosis = diagnosis.filter((item, index) => index !== i);
    setDiagnosis(tempDiagnosis);
  }

  const handleSaveData = async (e) => {
    e.preventDefault();
    setDisableButton(true);

    // Préparer les données du formulaire
    const formData = new FormData();
    formData.append('ndossier', dossierValue);
    formData.append('index', index);
    formData.append('pgcValue', pgcValue);
    formData.append('siteParticipants', JSON.stringify(siteParticipants));
    formData.append('preventionOrganizations', JSON.stringify(preventionOrganizations));
    formData.append('operationDescription', JSON.stringify(operationDescription));
    formData.append('diagnosisDescription', JSON.stringify(diagnosisDescription));
    formData.append('diagnosis', JSON.stringify(diagnosis));

    try {
      // Envoyer la requête au serveur
      const response = await fetch('/api/pgc/updateProjectPresentationData', {
        enctype: "multipart/form-data",
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        console.error('Failed to upload images');
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    } finally {
      setDisableButton(false);
    }
  }

  const resetData = () => {
    setSiteParticipants([
      { title: "Maître d'ouvrage", adress: '', representative: '', contact: '' },
      { title: 'Architecte', adress: '', representative: '', contact: '' },
      { title: 'Bureau', adress: '', representative: '', contact: '' },
      { title: 'Contrôle technique de la construction', adress: '', representative: '', contact: '' },
      {
        title: 'Coordonnateur SPS', adress: 'COORDOGEC\n4, rue des Artisans\n93160 - NOISY LE GRAND',
        representative: 'Jean-Marie SAINT-GERMAIN',
        contact: '0143036317 - 0680681482\ncoordogec@coordogec.fr'
      },
    ]);

    setPreventionOrganizations(preventionOrganizationsCont)

    setOperationDescription({
      description: {
        adress: '',
        operation: '',
        buildingPurpose: '',
        nbBuilding: '',
        nbLevels: '',
        surface: ''
      },
      schedule: {
        estimatedTimeline: '',
        preparationDate: '',
        startDate: '',
        endDate: ''
      },
      estimatedWorkforce: {
        category: '',
        estimatedAverageWorkforce: '',
        estimatedPeakWorkforce: '',
        nbCompany: ''
      }
    });

    setDiagnosisDescription([initialDiagnosisDescription]);

    setDiagnosis([
      {
        title: 'Étude géotechnique des sols',
        reference: '',
        vigilance: ''
      },
      {
        title: 'Environnemental',
        reference: '',
        vigilance: ''
      },
      {
        title: 'Amiante',
        reference: '',
        vigilance: ''
      }
    ])
  }

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genPgcPDF(pgcValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      dispatch(changePdfFileName(response.fileName))
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`PGC`));
  }, [pgcValue]);

  const prevPage = () => {
    history.push("/pgc/page1");
  }

  const nextPage = () => {
    history.push("/pgc/page3");
  }

  return (
    <div className="pgc-page2">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Plan Général de Coordination (Présentation du projet)</h1>
      <NumDossPGC />
      <div className="container">
        <h2>Coordonnées des intervenants</h2>
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-thead">
              <td className="custom-cell-label" colSpan={5}><strong>Intervenants sur le chantier</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell-title">
              <td className="custom-cell-label"><strong>Intervenants</strong></td>
              <td className="custom-cell-label"><strong>Adresse</strong></td>
              <td className="custom-cell-label"><strong>Représentant</strong></td>
              <td className="custom-cell-label"><strong>Contact</strong></td>
              <td></td>
            </tr>
            {siteParticipants.map((item, i) => (
              <tr className="custom-cell" key={i}>
                <td>{i > (siteParticipants[2].title === 'Bureau de contrôle' ? 3 : 4) ? <textarea name="title" onChange={e => setSiteParticipants(prevState => [...prevState.slice(0, i), { ...prevState[i], title: e.target.value }, ...prevState.slice(i + 1)])} value={item.title} placeholder="&quot;Intervenant&quot;" /> : item.title}</td>
                <td><textarea name="adress" onChange={e => setSiteParticipants(prevState => [...prevState.slice(0, i), { ...prevState[i], adress: e.target.value }, ...prevState.slice(i + 1)])} value={item.adress} placeholder="&quot;123 Rue de la République&quot;&#10;&quot;75001 - Paris&quot;" /></td>
                <td><textarea name="representative" onChange={e => setSiteParticipants(prevState => [...prevState.slice(0, i), { ...prevState[i], representative: e.target.value }, ...prevState.slice(i + 1)])} value={item.representative} placeholder="&quot;Jean Dupont&quot;" /></td>
                <td><textarea name="contact" onChange={e => setSiteParticipants(prevState => [...prevState.slice(0, i), { ...prevState[i], contact: e.target.value }, ...prevState.slice(i + 1)])} value={item.contact} placeholder="&quot;01 23 45 67 89&quot;&#10;&quot;jean.dupont@exemple.fr&quot;" /></td>
                {i > 4 ? <td style={{ width: '50px' }}>
                  <IconButton
                    style={{ marginLeft: '5px' }}
                    aria-label="delete"
                    size="small"
                    className="delete"
                    onClick={() => handleDeleteSiteParticipants(i)}
                  >
                    <Delete />
                  </IconButton>
                </td> : <td style={{ width: '50px' }}></td>}
              </tr>
            ))}
            <tr className="add-technical">
              <td>
                <IconButton
                  aria-label="add"
                  style={{ backgroundColor: "transparent" }}
                  onClick={handleAddSiteParticipants}
                >
                  <Add />
                </IconButton>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-thead">
              <td className="custom-cell-label" colSpan={4}><strong>Organisme de prévention institutionnels</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell-title">
              <td className="custom-cell-label"><strong>Intervenants</strong></td>
              <td className="custom-cell-label"><strong>Adresse</strong></td>
              <td className="custom-cell-label"><strong>Représentant</strong></td>
              <td className="custom-cell-label"><strong>Contact</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Inspection du travail</td>
              <td><textarea name="adress" value={preventionOrganizations.laborInspection.adress} onChange={e => handleUpdatePreventionOrganizations(e, 'laborInspection')} placeholder="&quot;Organisme&quot;&#10;&quot;123 Rue de la République&quot;&#10;&quot;75001 - Paris&quot;" /></td>
              <td><textarea name="representative" value={preventionOrganizations.laborInspection.representative} onChange={e => handleUpdatePreventionOrganizations(e, 'laborInspection')} placeholder="&quot;Jean Dupont&quot;" /></td>
              <td><textarea name="contact" value={preventionOrganizations.laborInspection.contact} onChange={e => handleUpdatePreventionOrganizations(e, 'laborInspection')} placeholder="&quot;01 23 45 67 89&quot;&#10;&quot;jean.dupont@exemple.fr&quot;" /></td>
            </tr>
            <tr className="custom-cell">
              <td>Caisse Régionale d'Assurance Maladie</td>
              <td><textarea name="adress" value={preventionOrganizations.CRAM.adress} onChange={e => handleUpdatePreventionOrganizations(e, 'CRAM')} placeholder="&quot;Organisme&quot;&#10;&quot;123 Rue de la République&quot;&#10;&quot;75001 - Paris&quot;" /></td>
              <td><textarea name="representative" value={preventionOrganizations.CRAM.representative} onChange={e => handleUpdatePreventionOrganizations(e, 'CRAM')} placeholder="&quot;Jean Dupont&quot;" /></td>
              <td><textarea name="contact" value={preventionOrganizations.CRAM.contact} onChange={e => handleUpdatePreventionOrganizations(e, 'CRAM')} placeholder="&quot;01 23 45 67 89&quot;&#10;&quot;jean.dupont@exemple.fr&quot;" /></td>
            </tr>
            <tr className="custom-cell">
              <td>O P P B T P</td>
              <td><textarea name="adress" value={preventionOrganizations.OPPBTP.adress} onChange={e => handleUpdatePreventionOrganizations(e, 'OPPBTP')} placeholder="&quot;Organisme&quot;&#10;&quot;123 Rue de la République&quot;&#10;&quot;75001 - Paris&quot;" /></td>
              <td><textarea name="representative" value={preventionOrganizations.OPPBTP.representative} onChange={e => handleUpdatePreventionOrganizations(e, 'OPPBTP')} placeholder="&quot;Jean Dupont&quot;" /></td>
              <td><textarea name="contact" value={preventionOrganizations.OPPBTP.contact} onChange={e => handleUpdatePreventionOrganizations(e, 'OPPBTP')} placeholder="&quot;01 23 45 67 89&quot;&#10;&quot;jean.dupont@exemple.fr&quot;" /></td>
            </tr>
          </tbody>
        </table>
        <h2>Description de l’opération</h2>
        <table className="custom-description-table">
          <thead>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={7}><strong>Description</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell">
              <td colSpan={3}>Adresse/localisation chantier</td>
              <td colSpan={4}><input type="text" name="adress" value={operationDescription.description.adress} onChange={e => handleUpdateOperationDescription(e, 'description')} /></td>
            </tr>
            <tr className="custom-cell">
              <td colSpan={3}>Opération</td>
              <td colSpan={4}><input type="text" name="operation" value={operationDescription.description.operation} onChange={e => handleUpdateOperationDescription(e, 'description')} /></td>
            </tr>
            <tr className="custom-cell">
              <td colSpan={3}>Destination des bâtiments</td>
              <td colSpan={4}><input type="text" name="buildingPurpose" value={operationDescription.description.buildingPurpose} onChange={e => handleUpdateOperationDescription(e, 'description')} /></td>
            </tr>
            <tr className="custom-cell">
              <td colSpan={3}>Nombre de bâtiments</td>
              <td colSpan={4}><input type="text" name="nbBuilding" value={operationDescription.description.nbBuilding} onChange={e => handleUpdateOperationDescription(e, 'description')} /></td>
            </tr>
            <tr className="custom-cell">
              <td colSpan={3}>Nombre de niveaux</td>
              <td colSpan={4}><input type="text" name="nbLevels" value={operationDescription.description.nbLevels} onChange={e => handleUpdateOperationDescription(e, 'description')} /></td>
            </tr>
            <tr className="custom-cell">
              <td colSpan={3}>Surface (m²)</td>
              <td colSpan={4}><input type="text" name="surface" value={operationDescription.description.surface} onChange={e => handleUpdateOperationDescription(e, 'description')} /></td>
            </tr>
          </tbody>
          <thead>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={7}><strong>Calendrier</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell">
              <td colSpan={3}>Délai prévisionnel</td>
              <td colSpan={4}><input type="text" name="estimatedTimeline" value={operationDescription.schedule.estimatedTimeline} onChange={e => handleUpdateOperationDescription(e, 'schedule')} /></td>
            </tr>
            <tr className="custom-cell">
              <td colSpan={3}>Délai de préparation</td>
              <td colSpan={4}><input type="text" name="preparationDate" value={operationDescription.schedule.preparationDate} onChange={e => handleUpdateOperationDescription(e, 'schedule')} /></td>
            </tr>
            <tr className="custom-cell">
              <td colSpan={3}>Début des travaux</td>
              <td colSpan={4}><input type="date" name="startDate" value={operationDescription.schedule.startDate} onChange={e => handleUpdateOperationDescription(e, 'schedule')} /></td>
            </tr>
            <tr className="custom-cell">
              <td colSpan={3}>Fin des travaux</td>
              <td colSpan={4}><input type="date" name="endDate" value={operationDescription.schedule.endDate} onChange={e => handleUpdateOperationDescription(e, 'schedule')} /></td>
            </tr>
          </tbody>
          <thead>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={7}><strong>Effectif prévisionnel</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell">
              <td colSpan={3}>Catégorie</td>
              <td colSpan={4}><span>{operationDescription.estimatedWorkforce.category}</span></td>
            </tr>
            <tr className="custom-cell">
              <td colSpan={3}>Effectif moyen prévisionnel</td>
              <td colSpan={4}><input type="text" name="estimatedAverageWorkforce" value={operationDescription.estimatedWorkforce.estimatedAverageWorkforce} onChange={e => handleUpdateOperationDescription(e, 'estimatedWorkforce')} /></td>
            </tr>
            <tr className="custom-cell">
              <td colSpan={3}>Effectif en pointe prévisionnel</td>
              <td colSpan={4}><input type="text" name="estimatedPeakWorkforce" value={operationDescription.estimatedWorkforce.estimatedPeakWorkforce} onChange={e => handleUpdateOperationDescription(e, 'estimatedWorkforce')} /></td>
            </tr>
            <tr className="custom-cell">
              <td colSpan={3}>Nombre d’entreprise prévisible dont sous-traitantes</td>
              <td colSpan={4}><input type="text" name="nbCompany" value={operationDescription.estimatedWorkforce.nbCompany} onChange={e => handleUpdateOperationDescription(e, 'estimatedWorkforce')} /></td>
            </tr>
          </tbody>
          <thead>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={7} style={{ borderBlockEnd: '1px solid #C2C1C1' }}><strong>Diagnostics établis préalablement aux travaux</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" style={{ borderRight: '1px solid #C2C1C1' }} colSpan={2}>Pièce n°</td>
              <td className="custom-cell-label" style={{ width: '20%', borderRight: '1px solid #C2C1C1' }}>Mission Géotechnique</td>
              <td className="custom-cell-label" style={{ width: '20%', borderRight: '1px solid #C2C1C1' }}>BET Géotechnique</td>
              <td className="custom-cell-label" style={{ width: '20%', borderRight: '1px solid #C2C1C1' }}>Affaire</td>
              <td className="custom-cell-label" style={{ width: '20%', }}>Date</td>
              <td className="custom-cell-label" style={{ width: '5%' }}></td>
            </tr>
            {diagnosisDescription.map((item, i) => (
              <tr className="custom-cell" key={`diagnosis-${i}`}>
                <td className="custom-cell-label" style={{ textAlign: 'center' }} colSpan={2}>{item.number}</td>
                <td className="custom-cell-label"><input type="text" name="mission" value={item.mission} onChange={e => handleUpdateDiagnosisDescription(e, i)} /></td>
                <td className="custom-cell-label"><input type="text" name="bet" value={item.bet} onChange={e => handleUpdateDiagnosisDescription(e, i)} /></td>
                <td className="custom-cell-label"><input type="text" name="affair" value={item.affair} onChange={e => handleUpdateDiagnosisDescription(e, i)} /></td>
                <td className="custom-cell-label"><input type="date" name="date" value={item.date} style={{ width: '90%' }} onChange={e => handleUpdateDiagnosisDescription(e, i)} /></td>
                <td className="custom-cell-label">
                  <IconButton
                    style={{ margin: '0 5px' }}
                    aria-label="delete"
                    size="small"
                    className="delete"
                    disabled={diagnosisDescription.length === 1 && diagnosisDescription.every((item) => JSON.stringify(item) === JSON.stringify(initialDiagnosisDescription))}
                    onClick={() => handleDeleteDiagnosisDescription(i)}
                  >
                    <Delete />
                  </IconButton>
                </td>
              </tr>
            ))}
            <tr className="add-technical">
              <td>
                <IconButton
                  aria-label="add"
                  size="small"
                  style={{ backgroundColor: "transparent" }}
                  onClick={handleAddDiagnosisDescription}
                >
                  <Add />
                </IconButton>
              </td>
            </tr>
          </tbody>
          <thead>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={4}></td>
              <td className="custom-cell-label"><strong>Référence du rapport</strong></td>
              <td className="custom-cell-label"><strong>Point de vigilance</strong></td>
              <td className="custom-cell-label"></td>
            </tr>
          </thead>
          <tbody>
            {diagnosis.map((item, i) => (
              <tr className="custom-cell" key={`diagnosis-${i}`}>
                <td colSpan={4}>{i > 2 ? <input type="text" name="title" value={item.title} style={{ width: '90%' }} onChange={e => setDiagnosis(prevState => [...prevState.slice(0, i), { ...prevState[i], title: e.target.value }, ...prevState.slice(i + 1)])} /> : item.title}</td>
                <td colSpan={1}><input type="text" name="reference" value={item.reference} onChange={e => setDiagnosis(prevState => [...prevState.slice(0, i), { ...prevState[i], reference: e.target.value }, ...prevState.slice(i + 1)])} /></td>
                <td colSpan={1}><input type="text" name="vigilance" value={item.vigilance} onChange={e => setDiagnosis(prevState => [...prevState.slice(0, i), { ...prevState[i], vigilance: e.target.value }, ...prevState.slice(i + 1)])} /></td>
                <td className="custom-cell-label">
                  {i > 2 && <IconButton
                    style={{ margin: '0 5px' }}
                    aria-label="delete"
                    size="small"
                    className="delete"
                    onClick={() => handleDeleteDiagnosisElement(i)}
                  >
                    <Delete />
                  </IconButton>}
                </td>
              </tr>
            ))}
            <tr className="add-technical">
              <td>
                <IconButton
                  aria-label="add"
                  size="small"
                  style={{ backgroundColor: "transparent" }}
                  onClick={handleAddDiagnosisElement}
                >
                  <Add />
                </IconButton>
              </td>
            </tr>
            {/* <tr className="custom-cell">
              <td colSpan={2}>Étude géotechnique des sols</td>
              <td colSpan={2}><input type="text" name="reference" value={diagnosis.geotechnicalSoilStudy.reference} onChange={e => handleUpdateDiagnosis(e, 'geotechnicalSoilStudy')} /></td>
              <td colSpan={2}><input type="text" name="vigilance" value={diagnosis.geotechnicalSoilStudy.vigilance} onChange={e => handleUpdateDiagnosis(e, 'geotechnicalSoilStudy')} /></td>
            </tr>
            <tr className="custom-cell">
              <td colSpan={2}>Environnemental</td>
              <td colSpan={2}><input type="text" name="reference" value={diagnosis.environmental.reference} onChange={e => handleUpdateDiagnosis(e, 'environmental')} /></td>
              <td colSpan={2}><input type="text" name="vigilance" value={diagnosis.environmental.vigilance} onChange={e => handleUpdateDiagnosis(e, 'environmental')} /></td>
            </tr>
            <tr className="custom-cell">
              <td colSpan={2}>Amiante</td>
              <td colSpan={2}><input type="text" name="reference" value={diagnosis.asbestos.reference} onChange={e => handleUpdateDiagnosis(e, 'asbestos')} /></td>
              <td colSpan={2}><input type="text" name="vigilance" value={diagnosis.asbestos.vigilance} onChange={e => handleUpdateDiagnosis(e, 'asbestos')} /></td>
            </tr> */}
          </tbody>
        </table>
      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveData}
          disabled={disableButton || !pgcValue}
        >
          Enregistrer
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !pgcValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleShowPdf()}
          disabled={disableButton || !pgcValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={prevPage}
          disabled={disableButton || !pgcValue}
        >
          Page précedente
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={disableButton || !pgcValue}
        >
          Page suivante
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};

export default PgcPage2;