import React from "react";
import Header from "../../Header/Header";
import { newDevisConValue } from "../../../redux/actions/devisActions";
import { fetchDossier } from "../../../redux/actions/dossierActions";
import { connect } from "react-redux";
import NumDoss from "../../NumDoss";
import DevisConTable2 from "./DevisConTable2";
import { newNpropValue } from "../../../redux/actions/devisActions";
import { getDureeCon, getTauxHCon, getValidation } from "../Utils";
import { Slider } from "@material-ui/core";
class DevisCon extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      tauxh: Number(
        getTauxHCon(
          this.props.deviscon,
          this.props.nprop,
          this.props.dossierValue
        )
      ),
      duree: Number(
        getDureeCon(
          this.props.deviscon,
          this.props.nprop,
          this.props.dossierValue
        )
      ),
      validation: getValidation(
        this.props.deviscon,
        this.props.dossierValue,
        this.props.nprop
      ),
    };
    this.registerView = this.registerView.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(fetchDossier());
  }
  /**
   * Mise à jour des valeurs du devis lors du changement de dossier
   * @param {{}} prevProps
   */
  async componentDidUpdate(prevProps) {
    if (
      this.props.dossierValue !== prevProps.dossierValue
    ) {
      await this.props.dispatch(newNpropValue(1))
    }
    if (
      this.props.dossierValue !== prevProps.dossierValue ||
      this.props.nprop !== prevProps.nprop
    ) {
      await this.setState({
        tauxh: getTauxHCon(
          this.props.deviscon,
          this.props.nprop,
          this.props.dossierValue
        ),
        duree: getDureeCon(
          this.props.deviscon,
          this.props.nprop,
          this.props.dossierValue
        ),
        validation: getValidation(
          this.props.deviscon,
          this.props.dossierValue,
          this.props.nprop
        ),
      });
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSliderTauxChange(event) {
    const newValue = event.target.value;
    const regex = /^[0-9,.\b]+$/;

    if (newValue === "" || !regex.test(newValue)) {
      this.setState({ tauxh: "" });
    } else {
      const formattedValue = newValue.replace(/,/g, ".");
      this.setState({ tauxh: formattedValue });
    }
  }
  handleSliderDureeChange(event) {
    const newValue = event.target.value;
    const regex = /^[0-9,.\b]+$/;

    if (newValue === "" || !regex.test(newValue)) {
      this.setState({ duree: "" });
    } else {
      const formattedValue = newValue.replace(/,/g, ".");
      this.setState({ duree: formattedValue });
    }
  }
  /**
   * Sauvegarde la vue du tableau
   * @param {[]} devis
   */
  registerView(devis) {
    for (let i = 0; i < this.props.deviscon.length; i++) {
      if (
        this.props.deviscon[i].ndossier == devis.ndossier &&
        this.props.deviscon[i].nprop == devis.nprop
      ) {
        let newLine = {
          iddeviscon: this.props.deviscon[i].iddeviscon,
          devisTab: JSON.stringify(devis.devisTab),
          totalh: devis.totalh,
          totalht: devis.totalht,
          ndossier: devis.ndossier,
          nprop: devis.nprop,
          tauxh: devis.tauxh,
          duree: this.state.duree,
        };
        let newDevis = this.props.deviscon;
        newDevis[i] = newLine;
        this.props.dispatch(newDevisConValue(newDevis));
      }
    }
  }
  replaceValues() {
    var result = null;
    for (let i = 0; i < this.props.deviscon.length; i++) {
      if (
        this.props.dossierValue == this.props.deviscon[i].ndossier &&
        this.props.nprop == this.props.deviscon[i].nprop
      ) {
        result = this.props.deviscon[i].devisTab;
      }
    }
    if (result === null) {
      if (
        //   getCategorieOp(this.props.dossier,this.props.dossierValue) !== 1
        // &&  getCategorieOp(this.props.dossier,this.props.dossierValue) !== 2
        true
      ) {
        const object = {
          nbrHeures: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          ht: [0, 0],
        };
        return object;
      }
    }
    return JSON.parse(result);
  }

  render() {
    const tauxh = isNaN(this.state.tauxh) ? 0 : parseFloat(this.state.tauxh);
    const duree = isNaN(this.state.duree) ? 0 : parseFloat(this.state.duree);
    return (
      <div className="devisCon">
        <Header />
        <h1 className="title">
          Décomposition du temps prévisionnel d'intervention et de prix <br />
          Conception
        </h1>
        <NumDoss type="devis" />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="tauxh">
            <label>Taux Horaire : </label>
            <Slider
              value={this.state.tauxh || 0}
              step={5}
              min={0}
              max={200}
              onChange={(_, value) => this.setState({ tauxh: value })}
            />

            <input
              name="tauxh"
              value={isNaN(this.state.tauxh) ? "" : this.state.tauxh}
              onChange={this.handleSliderTauxChange.bind(this)}
            />{" "}
            €
          </div>
          <div className="duree">
            <label>Durée de la phase conception : </label>
            <Slider
              value={this.state.duree ? this.state.duree : 0}
              step={0.5}
              min={0}
              max={48}
              // disabled={this.state.validation}
              onChange={(_, value) => this.setState({ duree: value })}
            />
            <input
              name="duree"
              value={isNaN(this.state.duree) ? "" : this.state.duree}
              onChange={this.handleSliderDureeChange.bind(this)}
            />{" "}
            Mois
          </div>
        </div>
        <DevisConTable2
          registerView={this.registerView}
          devisTab={this.replaceValues()}
          tauxh={tauxh}
          duree={duree}
          validation={this.state.validation}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  deviscon: state.devis.deviscon,
  dossierValue: state.devis.dossierValue,
  nprop: state.devis.nprop,
  dossier: state.dossier.items,
});
export default connect(mapStateToProps)(DevisCon);
