import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
class DashboardCal extends React.Component{
    constructor(props){
        super(props)
        this.state={
            date: new Date()
        }
    }
    /**
     * Change la valeur de la date lors du clique sur une case du calendrier
     * @param {Date} date 
     */
    onChange = date =>{
        this.setState({date})
        this.props.date(date)
    }
    render(){
        return(
            <div>
            <Calendar className="react-calendar"
            onChange={this.onChange}
            value={this.state.date}/>
            </div>
        );
    }
}  
export default DashboardCal; 