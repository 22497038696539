import React from 'react';

const Sheet3Table = ({ index, sheet }) => {
  return (
    <div className="sheet-3-table">
      <div className='sheet-3-table-1'>
        <table>
          <thead>
            <tr>
              <th colSpan="2" ><strong>Fiche n°{index + 1} {sheet.title}</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="2" style={{ padding: '15px 0', backgroundColor: '#9b9b9b', fontWeight: 'bold' }}>Description</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left', paddingTop: '10px' }} colSpan="2">
                Elles sont symbolisées par une lettre et un chiffre.<br /><br /> 
                <strong>• Domaines de tension</strong><br />
                <strong>B</strong> : ouvrages des domaines BT ou TBT<br />
                <strong>H</strong> : ouvrages des domaines HT<br /><br />
                <strong>• Nature des opérations autorisées</strong><br />
                <strong>R</strong> : dépannages, Raccordements, essais, mesurages, vérifications<br />
                <strong>C</strong> : Consignations<br />
                <strong>T</strong> : travail sous Tension<br />
                <strong>N</strong> : travaux de Nettoyage sous tension<br />
                <strong>V</strong> : travaux au Voisinage de pièces nues sous tension non protégées<br /><br />
                <strong>• Indice numérique</strong><br />
                <strong>0</strong> : personnel réalisant exclusivement des travaux d'ordre non électrique<br />
                <strong>1</strong> : exécutant de travaux d'ordre électrique<br />
                <strong>2</strong> : chargé de travaux d'ordre électrique, quel que soit le nombre d'exécutants placés sous ses ordres.<br /><br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='sheet-3-table-2'>
        <table>
          <thead>
            <tr>
              <th rowSpan="2">Habilitation du personnel</th>
              <th colSpan="2">Travaux</th>
              <th rowSpan="2">Intervention du domaine BT</th>
            </tr>
            <tr>
              <th>Hors tensions</th>
              <th>Sous tension</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Non électricien</td>
              <td>BO ou HO</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Exécutant électricien</td>
              <td>B1 ou H1</td>
              <td>B1T ou H1T</td>
              <td>BR</td>
            </tr>
            <tr>
              <td>Chargé d’intervention</td>
              <td>-</td>
              <td>-</td>
              <td>BR</td>
            </tr>
            <tr>
              <td>Chargé des travaux</td>
              <td>B2 ou H2</td>
              <td>B2T ou H2T</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Chargé de consignation</td>
              <td>BC ou HC</td>
              <td>-</td>
              <td>BC</td>
            </tr>
            <tr>
              <td>Agent de nettoyage sous tension</td>
              <td>-</td>
              <td>BN ou HN</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='sheet-3-table-3'>
        <table>
          <tbody>
            <tr>
              <td colSpan="2" style={{ padding: '15px 0', backgroundColor: '#9b9b9b', fontWeight: 'bold' }}>Description</td>
            </tr>
            <tr>
              <td colSpan="2" style={{ padding: '15px 0' }}>TITRE D’HABILITATION N°</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left', paddingTop: '10px' }} colSpan="2">
                Nom :<br /><br />
                Prénom :<br /><br />
                Fonction :<br /><br />
                Employeur :<br /><br />
                Affectation :<br /><br />
                Validité :<br /><br />
              </td>
            </tr>
          </tbody>
        </table>
        <div className='sheet-3-table-3-2'>
          <table>
            <thead>
              <tr>
                <th rowSpan="2">Personnel</th>
                <th colSpan="3">Champs d’application</th>
              </tr>
              <tr>
                <th>Hors tensions</th>
                <th>Sous tension</th>
                <th>Indications supplémentaires</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Non électricien</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Exécutant électricien</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Chargé d’intervention ou de travaux</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Chargé de consignation</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Habilités spéciaux</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='sheet-3-table-3-3'>
          <table style={{ width: '90%', textAlign: 'left' }}>
            <tbody>
              <tr>
                <td>Le titulaire:</td>
                <td>L’employeur:</td>
              </tr>
              <tr>
                <td>Date et signature:</td>
                <td>Date et signature:</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Sheet3Table;