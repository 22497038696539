import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';

class DialogComp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: true,
    }
    this.handleCloseProcess = this.handleCloseProcess.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }
  handleCloseProcess() {
    this.setState({ isOpen: false })
    this.props.closeDialog()
  }
  handleOpen() { 
    this.setState({ isOpen: true })
  }
  render() {
    return (
      <div>
        <Dialog open={this.state.isOpen} onClose={this.handleCloseProcess} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">MODIFICATION DÉTAILS MAITRE D'OUVRAGE</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Voulez-vous modifier les détails du maître d'ouvrage du dossier N°{this.props.dossierValue} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseProcess} color="primary">
              Annuler la Modification
            </Button>
            <Button onClick={this.handleCloseProcess} color="primary">
              Non
            </Button>
            <Button onClick={() => this.props.showNextDialog(4)} color="primary">
              Oui
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  devisrea: state.devis.devisrea,
  deviscon: state.devis.deviscon,
  dossierValue: state.devis.dossierValue,
  nprop: state.devis.nprop,
  attachment: state.email.file
});
export default connect(mapStateToProps)(DialogComp)