import React from "react";
import { connect } from "react-redux";
import { getCategoryNum } from "../Utils";
import { fetchNewPropo } from "../../../redux/actions/devisActions";
import Header from "../../Header/Header";
import NumDoss from "../../NumDoss";
import HtpCategory1 from "./HtpCategory1";
import HtpCategory2 from "./HtpCategory2";
import "../../../Styles/Heures/Htp.scss";

class Htp extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      category1: true,
      deviscon: {},
      devisrea: {},
      conHourCat1: Array(9).fill(0),
      reaHourCat1: Array(9).fill(0),
      conHourCat2: Array(6).fill(0),
      reaHourCat2: Array(10).fill(0),
      totalConHourCat1: 0,
      totalReaHourCat1: 0,
      totalConHourCat2: 0,
      totalReaHourCat2: 0,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { dossierValue, dossiers, deviscon, devisrea } = this.props;
    // const category = getCategoryNum(dossiers, dossierValue) === 1;
    const category = true;
    this.setState({ category1: category });
    this.getNbrHours(deviscon, dossierValue, category, "conception");
    this.getNbrHours(devisrea, dossierValue, category, "realisation");
  }

  componentDidUpdate(prevProps) {
    const { dossierValue, dossiers, deviscon, devisrea } = this.props;
    if (prevProps.dossierValue !== dossierValue) {
      // const category = getCategoryNum(dossiers, dossierValue) === 1;
      const category = true;
      this.setState({ category1: category });
      this.getNbrHours(deviscon, dossierValue, category, "conception");
      this.getNbrHours(devisrea, dossierValue, category, "realisation");
    }
  }

  getNbrHours = (devis, dossierValue, category, conception) => {
    const devisData = devis.find((element) => element.ndossier == dossierValue);
    if (!devisData) {
      return;
    }

    const { devisTab } = devisData;

    const nbrHours = JSON.parse(devisTab).nbrHeures.map((el) => +el);
    const sumHours = nbrHours.reduce((total, heure) => total + heure);

    if (conception === "conception") {
      if (category) {
        this.setState({ conHourCat1: nbrHours, totalConHourCat1: sumHours });
      } else {
        this.setState({ conHourCat2: nbrHours, totalConHourCat2: sumHours });
      }
      this.setState({ deviscon: devisData });
    } else {
      if (category) {
        this.setState({ reaHourCat1: nbrHours, totalReaHourCat1: sumHours });
      } else {
        this.setState({ reaHourCat2: nbrHours, totalReaHourCat2: sumHours });
      }
      this.setState({ devisrea: devisData });
    }
  };

  handleChange = (event, index) => {
    const { name, value } = event.target;

    const updateCategory = (categoryName, totalName) => {
      const values = [...this.state[categoryName]];
      values[index] = +value;
      const total = values.reduce((acc, cur) => acc + cur, 0);
      return { [categoryName]: values, [totalName]: total };
    };

    const nameToMethod = {
      conHourCat1: () =>
        this.setState(updateCategory("conHourCat1", "totalConHourCat1")),
      reaHourCat1: () =>
        this.setState(updateCategory("reaHourCat1", "totalReaHourCat1")),
      conHourCat2: () =>
        this.setState(updateCategory("conHourCat2", "totalConHourCat2")),
      reaHourCat2: () =>
        this.setState(updateCategory("reaHourCat2", "totalReaHourCat2")),
    };

    nameToMethod[name]();
  };

  feesCon(array, tauxh) {
    const item1 = array.slice(0, 5).reduce((acc, curr) => acc + curr, 0) * tauxh;
    const item2 = array.slice(5, 9).reduce((acc, curr) => acc + curr, 0) * tauxh;
    return [item1, item2];
}


  feesRea(array, tauxh){
    const item1 = array.slice(0, 3).reduce((acc, curr) => acc + curr, 0) * tauxh;
    const item2 = array.slice(3, 6).reduce((acc, curr) => acc + curr, 0) * tauxh;
    const item3 = array.slice(6, 9).reduce((acc, curr) => acc + curr, 0) * tauxh;
    return [item1, item2, item3];
  }

  async handleClick() {
    const {
      category1,
      conHourCat1,
      conHourCat2,
      reaHourCat1,
      reaHourCat2,
      totalConHourCat1,
      totalConHourCat2,
      totalReaHourCat1,
      totalReaHourCat2,
      deviscon,
      devisrea,
    } = this.state;

    let conCategory = category1 ? [...conHourCat1] : [...conHourCat2];
    let reaCategory = category1 ? [...reaHourCat1] : [...reaHourCat2];

    let totalConCategory = category1 ? totalConHourCat1 : totalConHourCat2;
    let totalReaCategory = category1 ? totalReaHourCat1 : totalReaHourCat2;

    let updateDeviscon = { ...deviscon };
    const tauxhCon = updateDeviscon.tauxh;
    let updateDevisrea = { ...devisrea };
    const tauxhRea = updateDevisrea.tauxh;

    updateDeviscon.devisTab = JSON.parse(updateDeviscon.devisTab);
    updateDevisrea.devisTab = JSON.parse(updateDevisrea.devisTab);

    updateDeviscon.devisTab.nbrHeures = conCategory;
    updateDeviscon.devisTab.ht = this.feesCon(conCategory, tauxhCon);

    updateDevisrea.devisTab.nbrHeures = reaCategory;
    updateDevisrea.devisTab.ht = this.feesRea(reaCategory, tauxhRea);

    updateDeviscon.totalh = totalConCategory;
    updateDeviscon.totalht = totalConCategory * tauxhCon;
    
    updateDevisrea.totalh = totalReaCategory;
    updateDevisrea.totalht = totalReaCategory * tauxhRea;
    
    updateDeviscon.dureeCon = updateDeviscon.duree;

    const requestOptionsCon = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(updateDeviscon),
    };
    const requestOptionsRea = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(updateDevisrea),
    };
    await fetch("/api/devis/registerNewDataCon", requestOptionsCon).catch(
      (err) => console.log(err)
    );
    await fetch("/api/devis/registerNewDataRea", requestOptionsRea).catch(
      (err) => console.log(err)
    );
    await this.props.dispatch(fetchNewPropo(this.props.dossierValue));
  }

  handleResetArray = () => {
    const conHourCat1 = Array(9).fill(0);
    const conHourCat2 = Array(6).fill(0);
    const reaHourCat1 = Array(9).fill(0);
    const reaHourCat2 = Array(10).fill(0);
    const totalConHourCat1 = 0;
    const totalConHourCat2 = 0;
    const totalReaHourCat1 = 0;
    const totalReaHourCat2 = 0;
    this.setState({
      conHourCat1,
      conHourCat2,
      reaHourCat1,
      reaHourCat2,
      totalConHourCat1,
      totalConHourCat2,
      totalReaHourCat1,
      totalReaHourCat2,
    });
  };

  render() {
    const {
      category1,
      conHourCat1,
      reaHourCat1,
      conHourCat2,
      reaHourCat2,
      totalConHourCat1,
      totalReaHourCat1,
      totalConHourCat2,
      totalReaHourCat2,
    } = this.state;
    return (
      <div className="htp">
        <Header />
        <h1 className="title">Heures Totales Prévues</h1>
        <NumDoss />
        {category1 ? (
          <HtpCategory1
            conHourCat1={conHourCat1}
            reaHourCat1={reaHourCat1}
            totalConHourCat1={totalConHourCat1}
            totalReaHourCat1={totalReaHourCat1}
            handleChange={this.handleChange}
            handleResetArray={this.handleResetArray}
            handleClick={this.handleClick}
          />
        ) : (
          <HtpCategory2
            conHourCat2={conHourCat2}
            reaHourCat2={reaHourCat2}
            totalConHourCat2={totalConHourCat2}
            totalReaHourCat2={totalReaHourCat2}
            handleChange={this.handleChange}
            handleResetArray={this.handleResetArray}
            handleClick={this.handleClick}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  dossiers: state.dossier.items,
  deviscon: state.devis.deviscon,
  devisrea: state.devis.devisrea,
});
export default connect(mapStateToProps)(Htp);
