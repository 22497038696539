export const FETCH_RJ_BEGIN   = 'FETCH_RJ_BEGIN';
export const FETCH_RJ_SUCCESS = 'FETCH_RJ_SUCCESS';
export const FETCH_RJ_FAILURE = 'FETCH_RJ_FAILURE';
export const FETCH_RJ_FR_CATEGORIES_BEGIN   = 'FETCH_RJ_FR_CATEGORIES_BEGIN';
export const FETCH_RJ_FR_CATEGORIES_SUCCESS = 'FETCH_RJ_FR_CATEGORIES_SUCCESS';
export const FETCH_RJ_FR_CATEGORIES_FAILURE = 'FETCH_RJ_FR_CATEGORIES_FAILURE';
export const FETCH_PHOTOS_BEGIN   = 'FETCH_PHOTOS_BEGIN';
export const FETCH_PHOTOS_SUCCESS = 'FETCH_PHOTOS_SUCCESS';
export const FETCH_PHOTOS_FAILURE = 'FETCH_PHOTOS_FAILURE';
export const CHANGE_RJ_VALUE = 'CHANGE_RJ_VALUE';
export function fetchRJ(dossierValue) { 
    return async(dispatch) => {
      dispatch(fetchRjBegin());
      return fetch(`/api/rj/getAll?ndossier=${dossierValue}`)
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            dispatch(fetchRjSuccess(json));
            return json;
        })
        .catch(error => dispatch(fetchRjFailure(error)));
    };
}

export function fetchRjFrCategories() {
    return async(dispatch) => {
      dispatch(fetchRjFrCategoriesBegin());
      return fetch("/api/rj/categoriesEmploymentLaw")
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            json.unshift({name: ""}) 
            dispatch(fetchRjFrCategoriesSuccess(json));
            return json;
        })
        .catch(error => dispatch(fetchRjFrCategoriesFailure(error)));
    };
  }

  export function fetchPhotos(ndossier, numero ) {
    return async(dispatch) => {
      dispatch(fetchPhotosBegin());
      return fetch("/api/rj/getAllPhoto",{method:"POST",
      headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
      body:JSON.stringify({ndossier:ndossier, numero:numero})})
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
            dispatch(fetchPhotosSuccess(json));
            return json;
        })
        .catch(error => dispatch(fetchPhotosFailure(error)));
    };
  }

  // Handle HTTP errors since fetch won't.
  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

export function newRjValue(newValue){
    return dispatch => dispatch(changeRjValue(newValue))
}

export const fetchRjBegin = () => ({
  type: FETCH_RJ_BEGIN
});

export const fetchRjSuccess = (rj) => ({
  type: FETCH_RJ_SUCCESS,
  payload: { items:rj}
});

export const fetchRjFailure = error => ({
  type: FETCH_RJ_FAILURE,
  payload: { error }
});

export const fetchRjFrCategoriesBegin = () => ({
  type: FETCH_RJ_FR_CATEGORIES_BEGIN
});

export const fetchRjFrCategoriesSuccess = (rjCategories) => ({
  type: FETCH_RJ_FR_CATEGORIES_SUCCESS,
  payload: { listFactors:rjCategories}
});

export const fetchRjFrCategoriesFailure = error => ({
  type: FETCH_RJ_FR_CATEGORIES_FAILURE,
  payload: { error }
});

export const fetchPhotosBegin = () => ({
  type: FETCH_PHOTOS_BEGIN
});

export const fetchPhotosSuccess = (photos) => ({
  type: FETCH_PHOTOS_SUCCESS,
  payload: { photos }
});

export const fetchPhotosFailure = error => ({
  type: FETCH_PHOTOS_FAILURE,
  payload: { error }
});

export const changeRjValue = (rjValue) => ({
    type: CHANGE_RJ_VALUE,
    payload: { rjValue }
  });
