import React from 'react';
import * as EmailValidator from "email-validator"
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import '../../Styles/LoginPage/Login.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from "react-router-dom"

class ForgotPassForm extends React.Component{
   constructor(props) {
      super(props);
  
      this.state = {
        confirmMessage:"",
        errorMessage:"" , 
        email:"",     
        password: "",
        confPass: "",
        showPass:false,
      };
      this.handleChange=this.handleChange.bind(this) 
    }
 /**
  * Vérifie la correspondance des mots de passe et envoie le mot de passe à la partie Back avec le mail encrypté dans l'URL
  */ 
    handleSubmit(){
      if(this.state.password==this.state.confPass && /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{6,}$/gm.test(this.state.password)){
        const requestOptions={
          method:"POST",
          headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
          body: JSON.stringify({password:this.state.password, email:this.props.crypted})
        }
        fetch("/api/changepassword",requestOptions)
        .then(async(res) =>{
          if(res.status==401){
            this.setState({confirmMessage:""})
            this.setState({errorMessage:await res.text()})
          }
          if(res.status==200){
            this.setState({errorMessage:""})
            this.setState({confirmMessage:await res.text()})
          }
        })
        .catch(err => console.log(err))
      }
      else {
        this.setState({confirmMessage:""})
        this.setState({errorMessage:"Les informations ne sont pas rentrées correctement"})
      }
    }
  
    handleChange(event){
      this.setState({[event.target.name]:event.target.value})
    }

    /**
     * Permet d'afficher ou non le mot de passe en texte
     */
    hideOrShow(){
      this.setState({showPass:!this.state.showPass})
    }
   render(){
        return(
            <div className="form"> 
                  <h1 style={{fontSize:"20px"}}>Mot de Passe Oublié</h1>
                  <div className="pwd">
                    <label>Nouveau Mot de Passe</label> <br /> <br />
                    <input type={!this.state.showPass ? "password" : "text"} name="password" onChange={this.handleChange}/>
                    <span onClick={this.hideOrShow.bind(this)}><FontAwesomeIcon icon={!this.state.showPass ? faEye : faEyeSlash}/></span>
                  </div>
                      <br/>
                      <div className="confPwd">
                        <label>Confirmation Mot de Passe</label> <br /> <br />
                        <input type="password" name="confPass" onChange={this.handleChange}/>
                      </div>
                      <br />  
                      <p><Link to="/login">Se Connecter</Link></p>          
                      <button className="btn btn-primary" onClick={this.handleSubmit.bind(this)}>Changer votre Mot de Passe</button>
                      {this.state.errorMessage!=="" ? <div style={{color:"red"}}>{this.state.errorMessage}</div> : ""}    
                      {this.state.confirmMessage!=="" ? <div style={{color:"green"}}>{this.state.confirmMessage}</div> : ""}        
            </div>
        );
    }
}
export default ForgotPassForm;