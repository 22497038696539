import React from 'react';
import Notif from './Notif';
import Profile from './Profile';
import LogoCoordo from '../../../Styles/Pictures/logo_coordogec.jpg';
export default class RightHeader extends React.Component{
    render(){
        return( 
            <div className="rightheader">
                <Notif location={this.props.location}/>
                <Profile/>
                 <img src={LogoCoordo} alt=""/>
            </div>
        )
    }
}