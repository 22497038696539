import React from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { formatDate } from "../Utils";

class ContractAmendmentInfos extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { company, nameMo, operation, adressOp, cpOp, cityOp, lastEch, tauxh, paymentMethod, duration, nbHours, handleChange, nbAmendments } = this.props

    return (
      <div className="contract-amendment-infos">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead className="table-head">
              <TableRow className="head-row">
                <TableCell className="MuiTableCell-head" colSpan={2}>
                  Détails
                </TableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Maître d'ouvrage</TableCell>
                <TableCell>
                  {nameMo}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Client</TableCell>
                <TableCell>
                  {company}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Opération</TableCell>
                <TableCell>
                  {operation}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Adresse</TableCell>
                <TableCell>
                  {adressOp && `${adressOp}, ${cpOp} ${cityOp}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Dernière échéance réalisation</TableCell>
                <TableCell>
                  {formatDate(lastEch)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Taux horaire</TableCell>
                <TableCell>
                  {tauxh !== 0 && `${tauxh} €`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mode de paiement</TableCell>
                <TableCell>
                  {paymentMethod !== 0 && `${paymentMethod} %`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Durée (mois)</TableCell>
                <TableCell>
                  <input
                    type="number"
                    name="duration"
                    value={duration === 0 ? "" : duration}
                    onChange={(e) => handleChange(e)}
                    disabled={nbAmendments === 0}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nombre d'heures</TableCell>
                <TableCell>
                  <input
                    type="number"
                    name="nbHours"
                    value={nbHours === 0 ? "" : nbHours}
                    onChange={(e) => handleChange(e)}
                    disabled={nbAmendments === 0}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {paymentMethod !== 0 && <p className="delai">Délai de <span>{paymentMethod == 26 ? 60 : 30} jours</span> entre les échéances</p>}
      </div>
    )
  }
}
export default ContractAmendmentInfos;