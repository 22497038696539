import React from "react";
import { connect } from "react-redux";
import Header from '../Header/Header';
import DocMenu from '../DocMenu';
import NumDoss from '../NumDoss';
import ListTable from './FactureTable/ListTable';
import GenPdf from '../GenPdf';
import { genFacTablePDF, getFacTablePDF, getCodeFacture, handleFormatCustomerCode } from './Utils';
import { numberToFormat, handleChangeDateToMonth } from "../Utils";
import { fetchAllFac } from '../../redux/actions/facActions';
import { fetchAllReminderByDoss } from "../../redux/actions/reminderActions";
import { changeTitleName, changePdfFileName } from "../../redux/actions/emailAction";
import "../../Styles/Factures/FactureTable.scss";

class FactureTable extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      facturesData: [],
      totalHt: 0,
      totalTva: 0,
      totalTtc: 0,
      openFile: false,
      disableButton: false,
      file: null,
    }
    this.handleUpdateClick = this.handleUpdateClick.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    const { dossierValue } = this.props
    if (dossierValue) {
      this.props.dispatch(fetchAllReminderByDoss(dossierValue))
    }
    this.props.factures.length > 0 ? this.loadData() : this.props.dispatch(fetchAllFac())
  }

  async componentDidUpdate(prevProps) {
    const { factures, dossierValue } = this.props
    if (factures !== prevProps.factures || dossierValue !== prevProps.dossierValue) {
      await this.props.dispatch(fetchAllReminderByDoss(dossierValue))
      await this.loadData()
    }
  }

  handleChange(event, index) {
    let newFacturesData = [...this.state.facturesData]
    const name = event.target.name
    if (name === "contrat_valide") {
      const contratStatus = newFacturesData[index][name] == 0 ? 1 : 0
      newFacturesData = newFacturesData.map(item => { item.contrat_valide = contratStatus })
    } else if (name === "validation") {
      newFacturesData[index].payment_date = newFacturesData[index][name] == 0 ? newFacturesData[index].payment_date : null
      newFacturesData[index][name] = newFacturesData[index][name] == 0 ? 1 : 0
    } else if (name === "payment_date") {
      newFacturesData[index][name] = event.target.value
      newFacturesData[index].validation = 1
    }
    this.setState({ facturesData: newFacturesData })
    this.loadData(true)
  }

  handleGetReminder(validation, date, days, numclient) {
    const { reminders } = this.props;

    const reminderSent = reminders.filter(r => r.numclient === numclient && (days === 15 ? r.first_reminder == 1 : r.first_reminder == 0))
    const reminderDate = reminderSent.length > 0 ? new Date(reminderSent[0].date_reminder).toLocaleDateString('en-CA') : ''

    const today = new Date();
    const dateFormated = new Date(date);

    dateFormated.setDate(dateFormated.getDate() + days);

    return reminderSent && today >= dateFormated && validation != 1 ? reminderDate !== '' ? reminderDate : 'À ENVOYER' : ''
  }

  loadData(updateData = false) {
    this.props.dispatch(changePdfFileName(""))
    const { factures, dossierValue } = this.props;
    let facturesData = []
    if (!updateData) {
      if (dossierValue) {
        let totalHt = 0
        let totalTva = 0
        let totalTtc = 0
        facturesData = factures
          .filter(item => item.ndossier === dossierValue)
          .map(item => ({
            ...item,
            dossier: JSON.parse(item.dossier),
            montant_tva: parseFloat((item.total_ht * (item.tva / 100)).toFixed(2)),
            amount_ca: item.bank === 'ca' ? numberToFormat(item.total_ht + (item.total_ht * (item.tva / 100))) : "",
            amount_bred: item.bank !== 'ca' ? numberToFormat(item.total_ht + (item.total_ht * (item.tva / 100))) : "",
            month_facture: { month: handleChangeDateToMonth(item.date_ech), ref: getCodeFacture(item.numclient) },
            date_ech: new Date(item.date_ech).toLocaleDateString('en-CA'),
            payment_date: item.payment_date !== null ? new Date(item.payment_date).toLocaleDateString('en-CA') : null,
            contrat_valide: item.contrat_valide,
            first_reminder: this.handleGetReminder(item.validation, item.date_ech, 15, item.numclient),
            second_reminder: this.handleGetReminder(item.validation, item.date_ech, 30, item.numclient),
          }));

        totalHt = facturesData.reduce((total, currentValue) => total + currentValue.total_ht, 0);
        totalTva = facturesData.reduce((total, currentValue) => total + currentValue.montant_tva, 0);
        totalTtc = numberToFormat(totalHt + totalTva)
        totalHt = numberToFormat(totalHt)
        totalTva = numberToFormat(totalTva)
        this.setState({ totalHt, totalTva, totalTtc })
      }
    } else {
      facturesData = [...this.state.facturesData]
        .filter(item => item.ndossier === dossierValue)
        .map(item => ({
          ...item,
          montant_tva: parseFloat((item.total_ht * (item.tva / 100)).toFixed(2)),
          amount_ca: item.bank === 'ca' ? numberToFormat(item.total_ht + (item.total_ht * (item.tva / 100))) : "",
          amount_bred: item.bank !== 'ca' ? numberToFormat(item.total_ht + (item.total_ht * (item.tva / 100))) : "",
          month_facture: { month: handleChangeDateToMonth(item.date_ech), ref: getCodeFacture(item.numclient) },
          date_ech: new Date(item.date_ech).toLocaleDateString('en-CA'),
          contrat_valide: item.contrat_valide,
          first_reminder: this.handleGetReminder(item.validation, item.date_ech, 15, item.numclient),
          second_reminder: this.handleGetReminder(item.validation, item.date_ech, 30, item.numclient),
        }));
    }
    this.setState({ facturesData, file: null });
  }

  handleUpdateClick = async () => {
    const { dossierValue } = this.props
    const { facturesData } = this.state

    const json = {
      data: facturesData,
      ndossier: dossierValue,
    };

    const requestOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(json)
    };


    await fetch("/api/facture/updatefacture", requestOptions)
      .catch((err) => {
        console.log(err);
      });

    await this.props.dispatch(fetchAllFac())
  }

  handleGenClick = async () => {
    this.handleUpdateClick()
    this.setState({ file: null, openFile: true, disableButton: true });
    const { dossierValue } = this.props
    const { facturesData, totalHt, totalTva, totalTtc } = this.state

    const json = {
      factures: facturesData,
      ndossier: dossierValue,
      total_ht: totalHt,
      total_tva: totalTva,
      total_ttc: totalTtc,
      date: new Date().toLocaleDateString('en-CA'),
    };

    const requestOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(json)
    };

    let blob = await genFacTablePDF(requestOptions);
    if (blob) {
      this.setState({ file: blob, disableButton: false });
    }
  }

  handleShowPdf = async () => {
    this.setState({ file: null, disableButton: true });
    let response = await getFacTablePDF(this.props.dossierValue, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob, openFile: true });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    } else {
      this.setState({ openFile: false });
    }
    await this.setState({ disableButton: false });
  };

  handleGetPdfFile = async () => {
    this.setState({ file: null });
    let response = await getFacTablePDF(this.props.dossierValue, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    this.setState({ file: null });
    let response = await getFacTablePDF(this.props.dossierValue, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  closeDoc = () => {
    this.setState({ openFile: false, file: null });
  }


  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`Tableau de Facturation ${this.props.dossierValue}`))
  }

  render() {
    const { factures } = this.props
    const { facturesData, totalHt, totalTva, totalTtc, disableButton, file } = this.state

    return (
      <div className="facture-table">
        <Header />
        <DocMenu
          file={file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Tableau de Facturation</h1>
        <NumDoss />
        <ListTable
          factures={factures}
          facturesData={facturesData}
          totalHt={totalHt}
          totalTva={totalTva}
          totalTtc={totalTtc}
          disableButton={disableButton}
          loadData={this.loadData.bind(this)}
          handleChange={this.handleChange.bind(this)}
          handleUpdateClick={this.handleUpdateClick}
          handleGenClick={this.handleGenClick.bind(this)}
          handleShowPdf={this.handleShowPdf.bind(this)}
        />
        <GenPdf file={this.state.file} isOpen={this.state.openFile} closeDoc={this.closeDoc} scale={this.state.file !== null ? 1 : 0.5} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  factures: state.facture.allfacture,
  dossierValue: state.devis.dossierValue,
  reminders: state.reminder.items,
});
export default connect(mapStateToProps)(FactureTable);