export const collectiveProtectionsConst = [
  {
    nature: "Équipements en toiture",
    protection: "Garde-corps périphériques sur réservation, cf R4323-59 du Code du Travail",
    completed: "",
    maintained: "",
    deadline: ""
  },
  {
    nature: "Plomberie",
    protection: "Échafaudage et neutralisation de la zone d'intervention",
    completed: "",
    maintained: "",
    deadline: ""
  },
  {
    nature: "Peinture", 
    protection: "Échafaudage et neutralisation de la zone d'intervention",
    completed: "",
    maintained: "",
    deadline: ""
  },
  {
    nature: "Menuiseries extérieures",
    protection: "Garde-corps provisoires dans les réservations des planchers béton sur les baies avec allèges supérieures à 1m",
    completed: "",
    maintained: "",
    deadline: ""
  },
  {
    nature: "Travaux en façades (enduits, bardage, vêture, équipements)",
    protection: "Sol stabilisé en périphérie",
    completed: "",
    maintained: "",
    deadline: ""
  },
  {
    nature: "Travaux en façades (enduits, bardage, vêture,  équipements)",
    protection: "PEMP Échafaudage conforme Balisage de la zone d'évolution",
    completed: "",
    maintained: "",
    deadline: ""
  },
  {
    nature: "Gros Œuvre",
    protection: "Protection au niveau des ouvertures d’escaliers en étage intérieur",
    completed: "",
    maintained: "",
    deadline: ""
  }
]