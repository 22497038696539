import {
  FETCH_PGCBYDOSS_BEGIN,
  FETCH_PGCBYDOSS_SUCCESS,
  FETCH_PGCBYDOSS_FAILURE,
  FETCH_PGCBYDOSSVERIF_BEGIN,
  FETCH_PGCBYDOSSVERIF_SUCCESS,
  FETCH_PGCBYDOSSVERIF_FAILURE,
  CHANGE_INDEX_VALUE
} from '../actions/pgcActions';

const initialState = {
  pgcByDoss: [],
  pgcValue: '',
  index: '',
  loading: false,
  error: null
}

export default function pgcReducer(state = initialState, action) { 
  switch (action.type) {
    case FETCH_PGCBYDOSS_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_PGCBYDOSS_SUCCESS: {
      return {
        ...state,
        loading: false,
        pgcByDoss: action.payload.pgcByDoss,
        index: action.payload.pgcByDoss.length > 0 ? action.payload.pgcByDoss[action.payload.pgcByDoss.length - 1].pgc_index : '',
        pgcValue: action.payload.pgcByDoss.length > 0 ? action.payload.pgcByDoss[action.payload.pgcByDoss.length - 1].id : ''
      }
    }
    case FETCH_PGCBYDOSS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        pgcByDoss: []
      };
    }
    case FETCH_PGCBYDOSSVERIF_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_PGCBYDOSSVERIF_SUCCESS: {
      if (state.pgcByDoss !== action.payload.pgcByDossVerif) {
        return {
          ...state,
          loading: false,
          pgcByDoss: action.payload.pgcByDossVerif,
          index: action.payload.pgcByDossVerif.length > 0 ? action.payload.pgcByDossVerif[action.payload.pgcByDossVerif.length - 1].pgc_index : '',
          pgcValue: action.payload.pgcByDossVerif.length > 0 ? action.payload.pgcByDossVerif[action.payload.pgcByDossVerif.length - 1].id : ''
        }
      }
    }
    case FETCH_PGCBYDOSSVERIF_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        pgcByDoss: []
      };
    }
    case CHANGE_INDEX_VALUE: {
      let newPgcValue = '';
      state.pgcByDoss.forEach(pgc => {
        if (pgc.pgc_index === action.payload.index) {
          newPgcValue = pgc.id;
        }
      });
      return {
        ...state,
        index: action.payload.index,
        pgcValue: newPgcValue
      };
    }
    default:
      return state
  }
}