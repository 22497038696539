import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { newDossierValue } from "../redux/actions/devisActions";
import { fetchDiuoByDoss, fetchDiuoByDossVerif, newDiuoIndexValue } from "../redux/actions/diuoActions";
import "../Styles/DIUO/NumDossDIUO.scss";

const useDossier = () => useSelector((state) => state.dossier.items, shallowEqual);
const useDiuo = () => useSelector((state) => state.diuo.diuoByDoss, shallowEqual);

const NumDossDIUO = () => { 
  const dispatch = useDispatch();

  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const dossier = useDossier();
  const diuo = useDiuo();
  const index = useSelector((state) => state.diuo.index);
  const diuoValue = useSelector((state) => state.diuo.diuoValue);

  const handleDossierChange = useCallback((event) => {
    const value = event.target.value;
    dispatch(newDiuoIndexValue(''));
    dispatch(newDossierValue(value));
    dispatch(fetchDiuoByDoss(value));
  }, [dispatch]);

  const handleIndexChange = useCallback((event) => {
    const value = event.target.value;
    dispatch(newDiuoIndexValue(value));
  }, [dispatch]);

  useEffect(() => {
    if (!dossierValue || diuoValue) return
    dispatch(fetchDiuoByDossVerif(dossierValue));
  }, []);

  useEffect(() => {
    if (dossierValue && !diuoValue) {
      dispatch(fetchDiuoByDoss(dossierValue));
    }
  }, [dossierValue, diuoValue, dispatch]);

  const dossierOptions = useMemo(() => (
    dossier.map(({ ndossier }) => (
      <option key={ndossier} value={ndossier}>
        {ndossier}
      </option>
    ))
  ), [dossier]);

  const diuoOptions = useMemo(() => (
    diuo.map(({ diuo_index }) => (
      <option key={diuo_index} value={diuo_index}>
        {diuo_index}
      </option>
    ))
  ), [diuo, dossierValue, diuoValue]);

  return (
    <div className="diuo-menu">
      <div className="ndossier">
        <label htmlFor="dossier">N° Dossier : </label>
        <select
          id="dossier"
          onChange={handleDossierChange}
          value={dossierValue}
        >
          {dossierOptions}
        </select>
      </div>
      <div className="indice">
        <label htmlFor="index">Indice : </label>
        <select
          id="index"
          onChange={handleIndexChange}
          value={index}
        >
          {diuoOptions}
        </select>
      </div>
    </div>
  );
};

export default React.memo(NumDossDIUO);
