import {
  FETCH_PREDIUOBYDOSS_BEGIN,
  FETCH_PREDIUOBYDOSS_SUCCESS,
  FETCH_PREDIUOBYDOSS_FAILURE,
  FETCH_PREDIUOBYDOSSVERIF_BEGIN,
  FETCH_PREDIUOBYDOSSVERIF_SUCCESS,
  FETCH_PREDIUOBYDOSSVERIF_FAILURE,
  CHANGE_INDEX_VALUE
} from '../actions/preDiuoActions';

const initialState = {
  preDiuoByDoss: [],
  preDiuoValue: '',
  index: '',
  loading: false,
  error: null
}

export default function preDiuoReducer(state = initialState, action) { 
  switch (action.type) {
    case FETCH_PREDIUOBYDOSS_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_PREDIUOBYDOSS_SUCCESS: {
      return {
        ...state,
        loading: false,
        preDiuoByDoss: action.payload.preDiuoByDoss,
        index: action.payload.preDiuoByDoss.length > 0 ? action.payload.preDiuoByDoss[action.payload.preDiuoByDoss.length - 1].pre_diuo_index : '',
        preDiuoValue: action.payload.preDiuoByDoss.length > 0 ? action.payload.preDiuoByDoss[action.payload.preDiuoByDoss.length - 1].id : ''
      }
    }
    case FETCH_PREDIUOBYDOSS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        preDiuoByDoss: []
      };
    }
    case FETCH_PREDIUOBYDOSSVERIF_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_PREDIUOBYDOSSVERIF_SUCCESS: {
      if (state.preDiuoByDoss !== action.payload.preDiuoByDossVerif) {
        return {
          ...state,
          loading: false,
          preDiuoByDoss: action.payload.preDiuoByDossVerif,
          index: action.payload.preDiuoByDossVerif.length > 0 ? action.payload.preDiuoByDossVerif[action.payload.preDiuoByDossVerif.length - 1].pre_diuo_index : '',
          preDiuoValue: action.payload.preDiuoByDossVerif.length > 0 ? action.payload.preDiuoByDossVerif[action.payload.preDiuoByDossVerif.length - 1].id : ''
        }
      }
    }
    case FETCH_PREDIUOBYDOSSVERIF_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        preDiuoByDoss: []
      };
    }
    case CHANGE_INDEX_VALUE: {
      let newPreDiuoValue = '';
      state.preDiuoByDoss.forEach(preDiuo => {
        if (preDiuo.pre_diuo_index === action.payload.index) {
          newPreDiuoValue = preDiuo.id;
        }
      });
      return {
        ...state,
        index: action.payload.index,
        preDiuoValue: newPreDiuoValue
      };
    }
    default:
      return state
  }
}