import {
  FETCH_QUOTAS_BEGIN,
  FETCH_QUOTAS_SUCCESS,
  FETCH_QUOTAS_FAILURE,
} from "../actions/quotaActions";
const initialState = {
  quotas: [],
  loading: false,
  error: null,
};

export default function quotaReducer(state = initialState, action) { 
  switch (action.type) {
    case FETCH_QUOTAS_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case FETCH_QUOTAS_SUCCESS: {
      return {
        ...state,
        loading: false,
        quotas: action.payload.quotas,
      };
    }
    case FETCH_QUOTAS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        quotas: [],
      };
    }
    default:
      return state;
  }
}
