import React from "react";

class FactureStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  
  twoWeeksRemainCon() { 
    const { datepgcp, datepgce, first_slice, isHidden } = this.props
    if (!datepgcp || !datepgce) return true
    const today = new Date();
    const dateFac = new Date(first_slice ? datepgcp : datepgce);
    dateFac.setDate(dateFac.getDate() + 14)

    if (!this.fourWeeksRemainCon()) {
      return true
    } else return !isHidden ? true : dateFac > today
  }

  fourWeeksRemainCon() {
    const { datepgcp, datepgce, first_slice, isHidden } = this.props
    if (!datepgcp || !datepgce) return true
    const today = new Date();
    const dateFac = new Date(first_slice ? datepgcp : datepgce);
    dateFac.setDate(dateFac.getDate() + 30)
    return !isHidden ? true : dateFac > today
  }
  
  twoWeeksRemainRea() {
    const { dateFacture, isHidden } = this.props
    if (!dateFacture) return true
    const today = new Date();
    const dateFac = new Date(dateFacture);
    dateFac.setDate(dateFac.getDate() + 14)

    if (!this.fourWeeksRemainRea()) {
      return true
    } else return !isHidden ? true : dateFac > today
  }

  fourWeeksRemainRea() {
    const { dateFacture, isHidden } = this.props
    if (!dateFacture) return true
    const today = new Date();
    const dateFac = new Date(dateFacture);
    dateFac.setDate(dateFac.getDate() + 30)
    return !isHidden ? true : dateFac > today
  }

  render() {
    const { isHidden, type } = this.props
    return (
      <div className="status">
        <div className="green-light"></div><span className={isHidden ? 'hidden' : ''}>Validée</span>
        <div className="yellow-light"></div><span className={type === "conception" ? this.twoWeeksRemainCon() ? 'hidden' : '' :  this.twoWeeksRemainRea() ? 'hidden' : ''}>1<sup>ère</sup> relance</span>
        <div className="red-light"></div><span className={type === "conception" ? this.fourWeeksRemainCon() ? 'hidden' : '' : this.fourWeeksRemainRea() ? 'hidden' : ''}>2<sup>ème</sup> relance</span>
      </div>
    )
  }
}
export default FactureStatus;