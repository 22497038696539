import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import LoadingOverlay from "../../LoadingOverlay";
import 'moment/locale/fr';
import Header from "../../Header/Header";
import ContactList from "./ContactList";
import ConfirmationAppointment from '../ConfirmationAppointment';
import ReminderEmail from './ReminderEmail';
import { getAllAppointments, getDossierInfos } from '../Utils';
import authHeader from '../../Utils';
import history from '../../Routes/history';
import "../../../Styles/Calendar/MailsMonitoring.scss";

const MailsMonitoring = () => {
  const dossierValue = useSelector((state) => state.devis.dossierValue);

  const [appointments, setAppointments] = useState([]);
  const [dossierInfos, setDossierInfos] = useState({});
  const [intervenant, setIntervenant] = useState(null);
  const [isConfirmationAppointment, setIsConfirmationAppointment] = useState(false);
  const [sendReminderEmail, setSendReminderEmail] = useState(false);
 
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const user = authHeader();
    if (!user['x-access-token']) {
      history.push('/login');
      return;
    }

    if (!dossierValue) {
      setIsLoading(false);
      return;
    }

    try {
      const result = await getAllAppointments(user.id);
      setAppointments(result);
    } catch (error) {
      console.error('Erreur lors de la récupération des rendez-vous:', error);
    } finally {
      setIsLoading(false);
    }
  }, [dossierValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchDossierInfos = async () => {
      if (!dossierValue) return;
      setIsLoading(true);

      try {
        const result = await getDossierInfos(dossierValue);
        setDossierInfos(result);
      } catch (error) {
        console.error("Erreur dans useEffect:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDossierInfos();
  }, [dossierValue]);

  return (
    <div className="mails-monitoring">
      {isLoading && <LoadingOverlay />}
      <Header />
      <h1 className="title">{sendReminderEmail ? isConfirmationAppointment ? 'Envoi de la confirmation du rendez-vous' : 'Envoi de rappel' : 'Suivi des mails'}</h1>
      <div className="container">
        {!sendReminderEmail ?
          <ContactList
            appointments={appointments}
            setIntervenant={setIntervenant}
            setSendEmail={setSendReminderEmail}
            setIsConfirmationAppointment={setIsConfirmationAppointment}
          /> :
          isConfirmationAppointment ?
            <ConfirmationAppointment
              fetchData={fetchData}
              setIsLoading={setIsLoading}
              intervenant={intervenant}
              dossierInfos={dossierInfos}
              setIsConfirmationAppointment={setIsConfirmationAppointment}
              isConfirmationAppointment={isConfirmationAppointment}
              setSendEmail={setSendReminderEmail}
            /> :
            <ReminderEmail
              fetchData={fetchData}
              setIsLoading={setIsLoading}
              setSendEmail={setSendReminderEmail}
              intervenant={intervenant}
              dossierInfos={dossierInfos}
            />}
      </div>
    </div>
  );
};

export default MailsMonitoring;