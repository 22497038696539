import { FETCH_DEVIS_BEGIN,
   FETCH_DEVIS_SUCCESS,
  //  FETCH_PROPO_SUCCESS,
    FETCH_DEVIS_FAILURE,
     CHANGE_DOSSIER_VALUE,
      CHANGE_DEVISC_VALUE,
      CHANGE_DEVISR_VALUE,
      CHANGE_DOSSIER_TAB_VALUE,
      CHANGE_PROPO_VALUE
    } from '../actions/devisActions';
const initialState = {
    deviscon: [],
    devisrea:[],
    dossierTab:[],
    dossierValue:"",
    nprop:"",
    loading:false,
    error:null,
  }
  
  // Use the initialState as a default value
  export default function devisReducer(state = initialState, action) { 
    switch (action.type) {
      case FETCH_DEVIS_BEGIN: {
        return {
          ...state,
          loading:true,
          error:null  
        }
      }
      case FETCH_DEVIS_SUCCESS:{
          return {
              ...state,
              loading:false,
              deviscon: action.payload.deviscon,
              devisrea: action.payload.devisrea,
              dossierValue: action.payload.dossierValue,
              dossierTab: action.payload.dossierTab,
              nprop: action.payload.nprop,
  
          }
      }
      case FETCH_DEVIS_FAILURE:{
        return {
            ...state,
            loading: false,
            error: action.payload.error,
            items: []
          }; 
      }
      case CHANGE_DOSSIER_TAB_VALUE:{
        return {
            ...state,
            dossierTab: action.payload.dossierTab
          }; 
      }
      case CHANGE_DOSSIER_VALUE:{
        return {
            ...state,
            dossierValue: action.payload.dossierValue
          }; 
      }
      case CHANGE_DEVISC_VALUE:{
        return {
            ...state,
            deviscon: action.payload.deviscon
          }; 
      }
      case CHANGE_DEVISR_VALUE:{
        return {
            ...state,
            devisrea: action.payload.devisrea
          }; 
      }
      case CHANGE_PROPO_VALUE:{
        return {
            ...state,
            nprop: action.payload.nprop
          }; 
      }

      default:
        return state
    }
  }