import React from "react";
import BaseTable, { Column } from "react-base-table";
import "react-base-table/styles.css";
import { IconButton } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import PopperLevels from "./PopperLevels";
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';

class ContactsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iterator: 0,
    };
  }

  checkType(type) {
    if (type === "no") return "red";
    if (type === "yes") return "green";
    else return "gray";
  }

  contactEmpty(contact) { 
    if (contact.lot === "" ||
      contact.travaux === "" ||
      contact.name_company === "" ||
      contact.adress === "" ||
      contact.city === "" ||
      contact.cp === "" ||
      contact.name_contact === "" ||
      contact.mail === "" ||
      contact.vic === "" ||
      contact.ppsps === "") {
      return true
    } else return false
  }

  render() {
    const data = this.props.contact
    const column = [
      {
        title: "Lot",
        key: "lot",
        dataKey: "lot",
        width: 50,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <input
            type="text"
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
            }}
            onChange={(event) => this.props.handleChangeContact(event)}
            name="lot"
            value={rowData.lot}
          />
        ),
      },
      {
        title: "Travaux",
        key: "travaux",
        dataKey: "travaux",
        width: 120,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <select
            name="travaux"
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
            }}
            value={rowData.travaux}
            onChange={(event) => this.props.handleChangeContact(event, rowIndex)}
          >
            <option value={""}></option>
            {this.props.travauxList.map((item, index) => {
              return (
                <option value={item} key={index}>
                  {item}
                </option>
              );
            })}
          </select>
        ),
      },
      {
        title: "Nom de l'entreprise",
        key: "name_company",
        dataKey: "name_company",
        width: 110,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <textarea
            type="text"
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
            }}
            onChange={(event) => this.props.handleChangeContact(event, rowIndex)}
            name="name_company"
            value={rowData.name_company}
          ></textarea>
        ),
      },
      {
        title: "Adresse",
        key: "adress",
        dataKey: "adress",
        width: 180,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <textarea
            type="text"
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
            }}
            onChange={(event) => this.props.handleChangeContact(event, rowIndex)}
            name="adress"
            value={rowData.adress}
          ></textarea>
        ),
      },
      {
        title: "Ville",
        key: "city",
        dataKey: "city",
        width: 100,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <textarea
            type="text"
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
            }}
            onChange={(event) => this.props.handleChangeContact(event, rowIndex)}
            name="city"
            value={rowData.city}
          ></textarea>
        ),
      },
      {
        title: "Code postal",
        key: "cp",
        dataKey: "cp",
        width: 80,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <input
            type="text"
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
            }}
            onChange={(event) => this.props.handleChangeContact(event, rowIndex)}
            name="cp"
            value={rowData.cp}
          />
        ),
      },
      {
        title: "Nom du contact",
        key: "name_contact",
        dataKey: "name_contact",
        width: 180,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <textarea
            type="text"
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
            }}
            onChange={(event) => this.props.handleChangeContact(event, rowIndex)}
            name="name_contact"
            value={rowData.name_contact}
          ></textarea>
        ),
      },
      {
        title: "Mobile",
        key: "mobile",
        dataKey: "mobile",
        width: 120,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <textarea
            type="text"
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
            }}
            onChange={(event) => this.props.handleChangeContact(event, rowIndex)}
            name="mobile"
            value={rowData.mobile}
          ></textarea>
        ),
      },
      {
        title: "Téléphone",
        key: "tel",
        dataKey: "tel",
        width: 120,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <textarea
            type="text"
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
            }}
            onChange={(event) => this.props.handleChangeContact(event, rowIndex)}
            name="tel"
            value={rowData.tel}
          ></textarea>
        ),
      },
      {
        title: "Email",
        key: "mail",
        dataKey: "mail",
        width: 120,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <textarea
            type="text"
            style={{
              width: "100%",
              pointerEvents: "visible",
              textAlign: "center",
              fontSize: "14px",
            }}
            onChange={(event) => this.props.handleChangeContact(event, rowIndex)}
            name="mail"
            value={rowData.mail}
          ></textarea>
        ),
      },
      {
        title: "VIC",
        key: "vic",
        dataKey: "vic",
        width: 63,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => {
          return (
            <div className="table-cell">
              <Checkbox
                name="vic"
                onChange={
                  rowData.vic !== ""
                    ? (event) => {
                      this.props.delLevel(event);
                    }
                    : (event) => {
                      this.props.showLevels(event, rowIndex);
                    }
                }
                style={{
                  color: this.checkType(rowData.vic),
                }}
                checked={rowData.vic !== ""}
              />
            </div>
          );
        },
      },
      {
        title: "PPSPS",
        key: "ppsps",
        dataKey: "ppsps",
        width: 63,
        name: "ppsps",
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => {
          return (
            <div className="table-cell">
              <Checkbox
                name="ppsps"
                onChange={
                  rowData.ppsps !== ""
                    ? (event) => {
                      this.props.delLevel(event);
                    }
                    : (event) => {
                      this.props.showLevels(event, rowIndex);
                    }
                }
                style={{
                  color: this.checkType(rowData.ppsps),
                }}
                checked={rowData.ppsps !== ""}
              />
            </div>
          );
        },
      },
      {
        width: 50,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <IconButton
            aria-label="add-contact"
            size="small"
            disabled={this.contactEmpty(this.props.contact[0])}
            onClick={() => this.props.addContact()}
            style={{ backgroundColor: "transparent" }}
            className="add-contact"
          >
            <SaveTwoToneIcon />
          </IconButton>
        ),
      },
    ];
    return (
      <div className="contacts-table">
        <BaseTable
          columns={column}
          width={1300}
          height={100}
          data={data}
        ></BaseTable>
        <PopperLevels
          open={this.props.open}
          anchorEl={this.props.anchorEl}
          hidLevels={this.props.hidLevels}
          levelsWrapper={this.props.levelsWrapper}
          handleLevelClick={this.props.handleLevelClick}
          index={this.props.index}
        />
      </div>
    );
  }
}
export default ContactsTable;
