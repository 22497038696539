import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getMaintenanceRecommendationsData, genDiuoPDF, getDiuoFile } from "../Utils";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossDIUO from "../../NumDossDIUO";
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import { maintenanceRecommendationsConst } from "./Const";
import "../../../Styles/DIUO/Page2.scss";

const initialMaintenanceRecommendations = {
  trade: "",
  workDescription: [""],
  frequency: [""], 
  interventionSheetNo: [""]
};

const DiuoPage2 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.diuo.index);
  const diuoValue = useSelector((state) => state.diuo.diuoValue);

  const [maintenanceRecommendations, setMaintenanceRecommendations] = useState([initialMaintenanceRecommendations]);

  const [customInputs, setCustomInputs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  const isCustomTrade = (trade) => {
    return trade !== "" && !maintenanceRecommendationsConst.some(item => item.trade === trade);
  };

  const loadData = async () => {
    if (!diuoValue) {
      setMaintenanceRecommendations([initialMaintenanceRecommendations]);
      setCustomInputs([false]);
      return;
    }

    setIsLoading(true);
    const response = await getMaintenanceRecommendationsData(diuoValue);
    const loadedMaintenanceRecommendations = response.maintenanceRecommendations ?
      JSON.parse(response.maintenanceRecommendations) :
      [initialMaintenanceRecommendations];

    setMaintenanceRecommendations(loadedMaintenanceRecommendations);
    setCustomInputs(loadedMaintenanceRecommendations.map(item => isCustomTrade(item.trade)));
    setIsLoading(false);
  };

  useEffect(() => {
    if (!dossierValue) return
    loadData()

  }, [diuoValue]);


  const handleUpdateMaintenanceRecommendations = (event, index) => {
    const { value } = event.target;
    const list = [...maintenanceRecommendations];

    if (value === "") {
      list[index] = {
        trade: "",
        workDescription: [""],
        frequency: [""],
        interventionSheetNo: [""]
      };
      setCustomInputs(prevCustomInputs => {
        const newCustomInputs = [...prevCustomInputs];
        newCustomInputs[index] = false;
        return newCustomInputs;
      });
    } else if (value === "Autre...") {
      list[index] = {
        trade: "",
        workDescription: [""],
        frequency: [""],
        interventionSheetNo: [""]
      };
      setCustomInputs(prevCustomInputs => {
        const newCustomInputs = [...prevCustomInputs];
        newCustomInputs[index] = true;
        return newCustomInputs;
      });
    } else {
      const selectedItem = maintenanceRecommendationsConst.find(item => item.trade === value);
      list[index] = {
        trade: selectedItem.trade,
        workDescription: [...selectedItem.workDescription],
        frequency: [...selectedItem.frequency],
        interventionSheetNo: Array(selectedItem.workDescription.length).fill("")
      };
      setCustomInputs(prevCustomInputs => {
        const newCustomInputs = [...prevCustomInputs];
        newCustomInputs[index] = false;
        return newCustomInputs;
      });
    }

    setMaintenanceRecommendations(list);
  };

  const handleDeleteMaintenanceRecommendation = (index) => {
    if (maintenanceRecommendations.length === 1) {
      setMaintenanceRecommendations([initialMaintenanceRecommendations]);
      setCustomInputs([false]);
    } else {
      setMaintenanceRecommendations(prevState => {
        const newState = prevState.filter((_, i) => i !== index);
        return newState;
      });

      setCustomInputs(prevCustomInputs => {
        const newCustomInputs = [...prevCustomInputs];
        newCustomInputs.splice(index, 1);
        return newCustomInputs;
      });
    }
  };

  const handleAddMaintenanceRecommendation = () => {
    setMaintenanceRecommendations(prevState => [...prevState, initialMaintenanceRecommendations]);
    setCustomInputs(prevCustomInputs => [...prevCustomInputs, false]);
  };

  const handleCustomInputChange = (index, field, value, subIndex = 0) => {
    const list = [...maintenanceRecommendations];
    if (field === 'trade') {
      list[index].trade = value;
    } else if (field === 'workDescription') {
      list[index].workDescription[subIndex] = value;
    } else if (field === 'frequency') {
      list[index].frequency[subIndex] = value;
    }
    setMaintenanceRecommendations(list);
  };

  const handleAddWorkDescription = (index) => {
    const list = [...maintenanceRecommendations];
    list[index].workDescription.push("");
    list[index].frequency.push("");
    list[index].interventionSheetNo.push("");
    setMaintenanceRecommendations(list);
  };

  const handleRemoveWorkDescription = (groupIndex, rowIndex) => {
    const list = [...maintenanceRecommendations];
    list[groupIndex].workDescription.splice(rowIndex, 1);
    list[groupIndex].frequency.splice(rowIndex, 1);
    list[groupIndex].interventionSheetNo.splice(rowIndex, 1);
    setMaintenanceRecommendations(list);
  };

  const handleUpdateInterventionSheetNo = (event, index, indexDes) => {
    const { value } = event.target;
    const list = [...maintenanceRecommendations];
    list[index].interventionSheetNo[indexDes] = value;
    setMaintenanceRecommendations(list);
  }

  const convertNewLines = (text) => {
    if (!text) return null;
    return text.split('\n').map((line, i) => (
      <React.Fragment key={i}>
        {line}
        {i !== text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const handleSaveData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        diuoValue,
        maintenanceRecommendations: JSON.stringify(maintenanceRecommendations)
      }),
    };
    try {
      const response = await fetch("/api/diuo/saveMaintenanceRecommendations", requestOptions);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genDiuoPDF(diuoValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      dispatch(changePdfFileName(response.fileName))
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`DIUO`));
  }, [diuoValue]);

  const prevPage = () => {
    history.push("/diuo/page1");
  }

  const nextPage = () => {
    history.push("/diuo/page3");
  }

  return (
    <div className="diuo-page2">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">DIUO (Recommandations d’entretien d'un batiment)</h1>
      <NumDossDIUO />
      <div className="container">
        <table className="custom-table">
          <tbody>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" style={{ width: '150px' }}><strong>CORPS D'ETAT</strong></td>
              <td className="custom-cell-label"><strong>DESIGNATION DES TRAVAUX</strong></td>
              <td className="custom-cell-label" style={{ width: '100px' }}><strong>PERIODICITE</strong></td>
              <td className="custom-cell-label" style={{ width: '150px' }}><strong>FICHE<br />D'INTERVENTION<br />N°</strong></td>
              <td className="custom-cell-label" style={{ width: '30px' }}></td>
            </tr>
            {maintenanceRecommendations.map((item, groupIndex) => {
              const filteredMaintenanceRecommendationsConst = maintenanceRecommendationsConst.filter(element =>
                !maintenanceRecommendations.some(el => el.trade === element.trade && el !== item)
              );

              return item.workDescription.map((itemDes, rowIndex) => (
                <tr
                  key={`${groupIndex}-${rowIndex}`}
                  data-group-id={groupIndex}
                >
                  {rowIndex === 0 && (
                    <td rowSpan={item.workDescription.length}>
                      {customInputs[groupIndex] ? (
                        <input
                          value={item.trade === "Autre..." ? "" : item.trade}
                          onChange={(e) => handleCustomInputChange(groupIndex, 'trade', e.target.value)}
                          placeholder="&quot;Corps d'état&quot;"
                          rows={3}
                          style={{ width: '96%' }}
                        />
                      ) : (
                        <select
                          value={item.trade}
                          onChange={(e) => handleUpdateMaintenanceRecommendations(e, groupIndex)}
                        >
                          <option value={''}></option>
                          {filteredMaintenanceRecommendationsConst.map((itemFil, indexFil) => (
                            <option value={itemFil.trade} key={indexFil}>{itemFil.trade}</option>
                          ))}
                          <option value="Autre...">Autre...</option>
                        </select>
                      )}
                    </td>
                  )}
                  <td style={{ padding: customInputs[groupIndex] ? '7px' : '15px' }}>
                    {customInputs[groupIndex] ? (
                      <textarea
                        value={itemDes}
                        onChange={(e) => handleCustomInputChange(groupIndex, 'workDescription', e.target.value, rowIndex)}
                        placeholder="&quot;Description des travaux&quot;"
                        rows={3}
                        style={{ width: '99%', height: '40px' }}
                      />
                    ) : (
                      convertNewLines(itemDes)
                    )}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {customInputs[groupIndex] ? (
                      <input
                        value={item.frequency[rowIndex]}
                        onChange={(e) => handleCustomInputChange(groupIndex, 'frequency', e.target.value, rowIndex)}
                        placeholder="&quot;Périodicité&quot;"
                        rows={3}
                        style={{ textAlign: 'center', width: '95%' }}
                      />
                    ) : (
                      convertNewLines(item.frequency[rowIndex])
                    )}
                  </td>
                  <td>
                    <input
                      type="text"
                      value={item.interventionSheetNo[rowIndex]}
                      onChange={(e) => handleUpdateInterventionSheetNo(e, groupIndex, rowIndex)}
                    />
                  </td>
                  {rowIndex === 0 && (
                    <td rowSpan={item.workDescription.length}>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        className="delete"
                        disabled={maintenanceRecommendations.length === 1 &&
                          maintenanceRecommendations.every((item) =>
                            JSON.stringify(item) === JSON.stringify(initialMaintenanceRecommendations))}
                        onClick={() => handleDeleteMaintenanceRecommendation(groupIndex)}
                      >
                        <Delete />
                      </IconButton>
                      {customInputs[groupIndex] && (
                        <>
                          <IconButton
                            aria-label="add-row"
                            size="small"
                            onClick={() => handleAddWorkDescription(groupIndex)}
                          >
                            <Add />
                          </IconButton>
                          {item.workDescription.length > 1 && rowIndex > 0 && (
                            <IconButton
                              aria-label="remove-row"
                              size="small"
                              onClick={() => handleRemoveWorkDescription(groupIndex, rowIndex)}
                            >
                              <Delete />
                            </IconButton>
                          )}
                        </>
                      )}
                    </td>
                  )}
                </tr>
              ));
            })}
            <tr className="add-maintenance-recommendations">
              <td>
                <IconButton
                  aria-label="add"
                  style={{ backgroundColor: "transparent" }}
                  onClick={handleAddMaintenanceRecommendation}
                >
                  <Add />
                </IconButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveData}
          disabled={disableButton || !diuoValue}
        >
          Enregistrer
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !diuoValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleShowPdf()}
          disabled={disableButton || !diuoValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={prevPage}
          disabled={disableButton || !diuoValue}
        >
          Page précedente
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={disableButton || !diuoValue}
        >
          Page suivante
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div >
  );
};

export default DiuoPage2;