import React from "react";
import Header from "../../Header/Header";
import VICMenu from "../VICMenu";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { fetchVIC } from "../../../redux/actions/vicActions";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import "../../../Styles/VIC/Page6.scss";
import { genPDF, getClient, getLotNbrPages, getPDF } from "../Utils";
import GenPdf from "../../GenPdf";
import ObservationTable from "./ObservationTable";
import history from "../../Routes/history";
import ConvDialog from "./ConvDialog";
import DocMenu from "../../DocMenu";
import vicObservationTitle from "./vicObservationTitle.json";
import { isEqual } from "lodash";

class Page6 extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      defaultArray: [],
      valueArray: Array.apply(null, Array(1)).map(function (x, i) {
        return {
          id: 0,
          npage: "",
          date: "",
          title: "",
          observation: "",
          code_travail: "",
          prevu: "",
        };
      }),
      delObs: [],
      newObs: 0,
      file: null,
      isOpen: false,
      isDialogOpen: false,
      loading: false,
      disableButton: false,
      employmentLawArray: [],
      openDialog: false,
      nameEmploymentLaw: "",
      detailEmploymentLaw: "",
    };
  }

  componentDidMount() {
    this.loadObservation();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.lotValue !== prevProps.lotValue ||
      this.props.dossierValue !== prevProps.dossierValue ||
      this.props.entrepriseValue !== prevProps.entrepriseValue
    ) {
      this.loadObservation();
      this.setState({ defaultArray: [] });
    }
    if (!isEqual(this.state.valueArray, prevState.valueArray)) {
      var joined = this.state.defaultArray.concat(this.state.valueArray);
      this.setState({ defaultArray: joined });
    }
  }

  async handleLoadEmploymentLaw(name) {
    var categoryName = name;
    var url = new URL("/api/vic/employmentLawByCategory", window.location.href);
    url.searchParams.append("categoryName", categoryName);
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return await Promise.resolve(data);
  }

  async loadObservation() {
    this.props.dispatch(changePdfFileName(""))
    this.setState({ disableButton: true });
    var vic = this.props.vic.filter((vic) => {
      if (
        this.props.dossierValue == vic.ndossier &&
        this.props.lotValue == vic.lots
      ) {
        if (this.props.entrepriseValue !== undefined) {
          var entreprise = JSON.parse(this.props.entrepriseValue);
          if (entreprise.id == vic.identreprise) {
            return true;
          } else return false;
        } else return true;
      } else return false;
    })[0];
    if (vic !== undefined) {
      var observationTab = [];
      var employmentLaw;
      var newEmploymentLawArray = [];
      var travaux = JSON.parse(vic.travaux);
      var data = JSON.parse(vic.compte_rendu);
      var ppsps = JSON.parse(vic.ppsps);
      var id = 0;
      var newObs = 0;
      for (let numPage = 1; numPage <= 4; numPage++) {
        var numObs = 1;
        if (travaux !== undefined && numPage == 1) {
          if (ppsps.observation) {
            id++;
            observationTab.push({
              id: id,
              npage: numPage + "-" + numObs,
              date: "",
              title: vicObservationTitle["page" + numPage][0],
              observation: "",
              code_travail: "",
              prevu: "",
              type: "ppsps",
            });
            employmentLaw = await this.handleLoadEmploymentLaw(
              vicObservationTitle["category"]["page" + numPage][0]
            );
            await newEmploymentLawArray.push(employmentLaw);
            await newEmploymentLawArray[numPage - 1].splice(0, 0, {
              name: "",
              title: "",
              detail: "",
            });
            numObs++;
          }
          for (const key of Object.keys(travaux)) {
            if (travaux[key].observation) {
              id++;
              observationTab.push({
                id: id,
                npage: numPage + "-" + numObs,
                date: "",
                title:
                  key == "soustraitant"
                    ? vicObservationTitle["page" + numPage][1]
                    : key == "pgc"
                      ? vicObservationTitle["page" + numPage][2]
                      : "",
                observation: "",
                code_travail: "",
                prevu: "",
                type: key,
              });
              const employmentLaw =
                key == "soustraitant"
                  ? await this.handleLoadEmploymentLaw(
                    vicObservationTitle["category"]["page" + numPage][1]
                  )
                  : await this.handleLoadEmploymentLaw(
                    vicObservationTitle["category"]["page" + numPage][2]
                  );
              await newEmploymentLawArray.push(employmentLaw);
              await newEmploymentLawArray[
                newEmploymentLawArray.length - 1
              ].splice(0, 0, { name: "", title: "", detail: "" });
              numObs++;
            }
          }
        }
        if (data["page" + numPage] !== undefined) {
          for (let index = 0; index < data["page" + numPage].length; index++) {
            const item = data["page" + numPage][index];
            if (item.observation) {
              id++;
              observationTab.push({
                id: id,
                npage: numPage + "-" + numObs,
                date: "",
                title:
                  numPage == 2
                    ? vicObservationTitle["page" + numPage][index]
                    : numPage == 3 || numPage == 4
                      ? vicObservationTitle["page" + numPage][vic.lots - 1][index]
                      : "",
                observation: "",
                code_travail: "",
                prevu: "",
              });
              const observationTitle =
                numPage == 2
                  ? vicObservationTitle["category"]["page" + numPage][index]
                  : vicObservationTitle["category"]["page" + numPage][
                  vic.lots - 1
                  ][index];
              if (observationTitle !== "") {
                const employmentLaw =
                  numPage == 2
                    ? await this.handleLoadEmploymentLaw(
                      vicObservationTitle["category"]["page" + numPage][index]
                    )
                    : await this.handleLoadEmploymentLaw(
                      vicObservationTitle["category"]["page" + numPage][
                      vic.lots - 1
                      ][index]
                    );
                await newEmploymentLawArray.push(employmentLaw);
                await newEmploymentLawArray[
                  newEmploymentLawArray.length - 1
                ].splice(0, 0, { name: "", title: "", detail: "" });
              } else {
                await newEmploymentLawArray.push([]);
              }
              numObs++;
            }
          }
        }
      }
      if (this.getLotNumPages() == 5) {
        if (data.page5 !== undefined) {
          for (const item of data.page5) {
            if (item.npage > 0) {
              observationTab.push({
                id: item.id,
                npage: item.npage,
                date: item.date,
                title: item.title,
                observation: item.observation,
                code_travail: item.code_travail,
                prevu: item.prevu,
              });
              newObs++;
            }
            for (let i = 0; i < observationTab.length; i++) {
              if (
                observationTab[i] !== undefined &&
                observationTab[i].title == item.title
              ) {
                observationTab[i].id = item.id;
                observationTab[i].date = item.date;
                observationTab[i].observation = item.observation;
                observationTab[i].code_travail = item.code_travail;
                observationTab[i].prevu = item.prevu;
              }
            }
          }
        }
      } else {
        if (data.page6 !== undefined) {
          for (const item of data.page6) {
            if (item.npage > 0) {
              observationTab.push({
                id: item.id,
                npage: item.npage,
                date: item.date,
                title: item.title,
                observation: item.observation,
                code_travail: item.code_travail,
                prevu: item.prevu,
              });
              newObs++;
            }
            for (let i = 0; i < observationTab.length; i++) {
              if (
                observationTab[i] !== undefined &&
                observationTab[i].title == item.title
              ) {
                observationTab[i].id = item.id;
                observationTab[i].date = item.date;
                // observationTab[i].title = item.title;
                observationTab[i].observation = item.observation;
                observationTab[i].code_travail = item.code_travail;
                observationTab[i].prevu = item.prevu;
              }
            }
          }
        }
      }
      this.setState({ valueArray: observationTab, newObs: newObs });
    } else {
      this.setState({
        valueArray: [
          {
            id: 0,
            npage: "",
            date: "",
            title: "",
            observation: "",
            code_travail: "",
            prevu: "",
          },
        ],
      });
    }
    await this.setState({
      employmentLawArray: newEmploymentLawArray,
      disableButton: false,
      file: null
    });
  }

  handleChange(event, index, title) {
    let temp_state = [...this.state.valueArray];
    let temp_element = { ...temp_state[index] };
    if (event.target.name === "date") {
      temp_element.date = event.target.value;
    }
    if (event.target.name === "title") {
      temp_element.title = event.target.value;
    }
    if (event.target.name === "observation") {
      temp_element.observation = event.target.value;
    }
    if (event.target.name === "code_travail") {
      if (event.target.tagName === "INPUT") {
        temp_element.code_travail = event.target.value;
      } else {
        temp_element.code_travail = event.target.value;
        temp_element.observation = title;
      }
    }
    if (event.target.name === "prevu") {
      temp_element.prevu = event.target.value;
    }
    temp_state[index] = temp_element;
    this.setState({ valueArray: temp_state });
  }
  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }
  setDialogOpen = (isDialogOpen) => {
    this.setState({ isDialogOpen: isDialogOpen });
  };
  addObservation() {
    this.state.valueArray.push({
      id: this.state.valueArray.length
        ? this.state.valueArray[this.state.valueArray.length - 1].id + 1
        : 0,
      npage: (this.state.newObs + 1).toString(),
      date: "",
      title: "",
      observation: "",
      code_travail: "",
      prevu: "",
    });
    this.setState({ newObs: this.state.newObs + 1 });
    this.setState(this.state.valueArray);
  }

  deleteObservation(rowIndex) {
    let observationDeleted = {};
    let obsAddInDelObs = false;

    let updatedValueArray = this.state.valueArray.map((item) => {
      if (
        item.npage.includes("-") &&
        this.state.valueArray[rowIndex].npage.includes("-")
      ) {
        if (
          item.npage.charAt(0) ==
          this.state.valueArray[rowIndex].npage.charAt(0) &&
          Number(item.npage.slice(2)) >
          Number(this.state.valueArray[rowIndex].npage.slice(2))
        ) {
          item.npage =
            item.npage.charAt(0) + "-" + (Number(item.npage.slice(2)) - 1);
          item.id = item.id - 1;
        }
      }
      return item;
    });

    observationDeleted = updatedValueArray.splice(rowIndex, 1)[0];

    if (this.state.delObs.length) {
      for (var i = 0; i < this.state.delObs.length; i++) {
        if (
          observationDeleted.id < this.state.delObs[i].id &&
          observationDeleted.npage.charAt(0) ==
          this.state.delObs[i].npage.charAt(0)
        ) {
          let updatedDelObs = [...this.state.delObs];
          updatedDelObs.push(updatedDelObs[updatedDelObs.length - 1]);
          updatedDelObs[updatedDelObs.length - 1].id--;
          updatedDelObs[updatedDelObs.length - 1].npage =
            updatedDelObs[updatedDelObs.length - 1].npage.charAt(0) +
            "-" +
            (Number(updatedDelObs[updatedDelObs.length - 1].npage.slice(2)) -
              1);
          for (var j = updatedDelObs.length - 2; j > i; j--) {
            updatedDelObs[j - 1].id--;
            updatedDelObs[j - 1].npage =
              updatedDelObs[updatedDelObs.length - 1].npage.charAt(0) +
              "-" +
              (Number(updatedDelObs[updatedDelObs.length - 1].npage.slice(2)) -
                1);
            updatedDelObs[j] = updatedDelObs[j - 1];
          }
          updatedDelObs[i] = observationDeleted;
          obsAddInDelObs = true;
          this.setState({ delObs: updatedDelObs });
          break;
        }
      }
      if (!obsAddInDelObs) {
        let updatedDelObs = [...this.state.delObs];
        updatedDelObs.push(observationDeleted);
        this.setState({ delObs: updatedDelObs });
      }
    } else {
      let updatedDelObs = [...this.state.delObs];
      updatedDelObs.push(observationDeleted);
      this.setState({ delObs: updatedDelObs });
    }
    this.setState({
      valueArray: updatedValueArray,
      newObs: this.state.newObs - 1,
    });
  }

  async handleValidClick(entreprise) {
    this.setState({ disableButton: true });
    this.setState({ valueArray: this.state.valueArray });
    this.setState({ loading: true });
    if (this.getLotNumPages() == 5) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ndossier: this.props.dossierValue,
          lotValue: this.props.lotValue,
          identreprise: entreprise.id,
          page5: this.state.valueArray,
          numPage: this.getLotNumPages(),
          delObs: this.state.delObs,
          defaultArray: this.state.defaultArray,
        }),
      };
      fetch("/api/vic/page5", requestOptions)
        .then(async (res) => {
          if (res.ok) {
            await this.props.dispatch(fetchVIC(this.props.dossierValue));
            await this.setState({ loading: false });
            await this.setState({ disableButton: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ndossier: this.props.dossierValue,
          lotValue: this.props.lotValue,
          identreprise: entreprise.id,
          page6: this.state.valueArray,
          numPage: this.getLotNumPages(),
          delObs: this.state.delObs,
          defaultArray: this.state.defaultArray,
        }),
      };
      fetch("/api/vic/page6", requestOptions)
        .then(async (res) => {
          if (res.ok) {
            await this.props.dispatch(fetchVIC(this.props.dossierValue));
            await this.setState({ loading: false });
            await this.setState({ disableButton: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    this.setState({ delObs: [] });
  }
  async handleGenClick(entreprise) {
    this.setOpen(true);
    this.setState({ file: null });
    let blob = await genPDF(
      this.props.dossierValue,
      entreprise.id,
      this.props.lotValue,
      getLotNbrPages(this.props.lots, this.props.lotValue),
      getClient(this.props)
    );
    if (blob) {
      this.setState({ file: blob });
    }
  }
  async handleShowPdf() {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'pdf');

      if (response.blob.size > 100) {
        this.setState({ file: response.blob, isOpen: true });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  }

  handleGetPdfFile = async () => {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'pdf');

      if (response.blob.size > 100) {
        this.setState({ file: response.blob });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  };

  async handleGetWordFile() {
    if (JSON.parse(this.props.entrepriseValue) !== undefined) {
      const entreprise = JSON.parse(this.props.entrepriseValue);
      let response = await getPDF(this.props.dossierValue, entreprise, this.props.lotValue, 'word');

      if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        this.setState({ file: response.blob });

        const fileName = response.fileName;
        this.props.dispatch(changePdfFileName(fileName))
      }
    }
  }

  getLotNumPages() {
    if (
      getLotNbrPages(this.props.lots, this.props.lotValue) == 5 ||
      getLotNbrPages(this.props.lots, this.props.lotValue) == 7
    ) {
      return 5;
    } else return 6;
  }
  nextPage = () => {
    history.push("/vic/page7");
  };
  prevPage = () => {
    history.push("/vic/page5");
  };
  disableSaveButton(entreprise) {
    if (entreprise.id === undefined) return true;
    if (this.state.loading) return true;
    return false;
  }

  handleClickOpen = (name, detail) => {
    this.setState({
      openDialog: true,
      nameEmploymentLaw: name,
      detailEmploymentLaw: detail,
    });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`VIC`))
  }

  render() {
    var entreprise = "";
    if (
      this.props.entrepriseValue !== undefined &&
      this.props.entrepriseValue !== ""
    ) {
      entreprise = JSON.parse(this.props.entrepriseValue);
    }

    // this.state.valueArray = this.state.valueArray;

    return (
      <div className="page6">
        <Header />
        <DocMenu
          file={this.state.file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Visite d'Inspection Commune (Page 6)</h1>
        <VICMenu />
        <div className="data">
          <ObservationTable
            key={JSON.stringify(this.state.employmentLawArray)}
            valueArray={this.state.valueArray}
            employmentLawArray={this.state.employmentLawArray}
            handleChange={this.handleChange.bind(this)}
            addObservation={this.addObservation.bind(this)}
            deleteObservation={this.deleteObservation.bind(this)}
            handleLoadEmploymentLaw={this.handleLoadEmploymentLaw}
            handleClickOpen={this.handleClickOpen}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleValidClick(entreprise)}
            disabled={
              this.state.disableButton || this.disableSaveButton(entreprise)
            }
          >
            Enregistrer
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleGenClick(entreprise)}
            disabled={
              this.state.disableButton || entreprise.id === undefined
                ? true
                : false
            }
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleShowPdf()}
            disabled={
              this.state.disableButton || entreprise.id === undefined
                ? true
                : false
            }
          >
            Afficher le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.setDialogOpen(true)}
            disabled={
              this.state.disableButton ||
                entreprise.id === undefined ||
                getLotNbrPages(this.props.lots, this.props.lotValue) > 6
                ? true
                : false
            }
          >
            Convention
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowLeft />}
            onClick={this.prevPage}
            disabled={this.state.disableButton}
          >
            Page précedente
          </Button>
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowRight />}
            onClick={this.nextPage}
            disabled={this.state.disableButton}
          >
            Page suivante
          </Button>
          {this.state.disableButton && (
            <CircularProgress className="spinner" color="inherit" />
          )}
        </div>
        <div>
          <Dialog open={this.state.openDialog} onClose={this.handleClose}>
            <DialogTitle style={{ textAlign: "center" }}>
              {this.state.nameEmploymentLaw !== ""
                ? this.state.nameEmploymentLaw
                : "CODE DU TRAVAIL"}
            </DialogTitle>
            <DialogContent>
              <div>
                <pre style={{ whiteSpace: "pre-wrap" }}>
                  {this.state.detailEmploymentLaw !== ""
                    ? this.state.detailEmploymentLaw
                    : "Aucun code du travail n'a été sélectionné"}
                </pre>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={this.handleClose}
                style={{ color: "white", backgroundColor: "#FF7F50" }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <ConvDialog
          isOpen={this.state.isDialogOpen}
          setOpen={this.setDialogOpen}
          entreprise={entreprise}
        />
        <GenPdf
          file={this.state.file}
          isOpen={this.state.isOpen}
          closeDoc={() => this.setOpen(false)}
          scale={1}
          pageNumber={this.getLotNumPages()}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
  lotValue: state.vic.lotValue,
  lots: state.vic.lots,
  vic: state.vic.items,
  contact: state.contact.items,
  entreprise: state.entreprise.items,
  entrepriseValue: state.entreprise.entrepriseValue,
});
export default connect(mapStateToProps)(Page6);
