import React from "react";
import { connect } from "react-redux";
import Header from '../Header/Header';
import DocMenu from '../DocMenu';
import ListTable from './Global/ListTable';
import GenPdf from '../GenPdf';
import { genSyntheseTablePDF, getSyntheseTablePDF, getCodeFacture } from './Utils';
import { handleChangeDateToMonth } from "../Utils";
import { fetchAllReminder } from "../../redux/actions/reminderActions";
import { fetchAllFac } from '../../redux/actions/facActions';
import { changeTitleName, changePdfFileName } from "../../redux/actions/emailAction";
import "../../Styles/Factures/Global.scss";

class Global extends React.Component {
  _isMounted = false; 
  constructor(props) {
    super(props);
    this.state = {
      facturesData: [],
      openFile: false,
      file: null,
      disableButton: false,
      totalAmount: 0,
      totalBredAmount: 0,
      totalCaAmount: 0,
      startDate: '',
      endDate: '',
      filtersStatut: {
        firstReminder: false,
        secondReminder: false,
        toSend: false,
        paye: false,
        nonPaye: false,
      },
    }
  }

  async componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    const { factures } = this.props;
    this._isMounted = true;

    await this.props.dispatch(fetchAllReminder())
    await factures.length === 0 ? this.props.dispatch(fetchAllFac()) : this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const { reminders, factures } = this.props;
    if (factures !== prevProps.factures || reminders !== prevProps.reminders) {
      this.loadData()
    }
  }

  formatDate = (date) => date ? new Date(date).toLocaleDateString('en-CA') : null;

  getTotalAmount = (facturesData) => facturesData.reduce((total, facture) => total + facture.amount_ht, 0);

  getTotalAmountByBank = (facturesData) => {
    const bredAmount = facturesData.reduce((total, facture) => total + +facture.amount_bred, 0);
    const caAmount = facturesData.reduce((total, facture) => total + +facture.amount_ca, 0);
    return { bredAmount, caAmount };
  }

  handleDateChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleStatutChange = (event) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      filtersStatut: {
        ...prevState.filtersStatut,
        [name]: checked,
      },
    }));
  };


  handleGetDateReminder = (propsItem, refFacture, firstReminder) => {
    const { reminders } = this.props;
    const { validation, date_ech, ndossier } = propsItem;
    const today = new Date();
    const dateFormated = new Date(date_ech);
    const reminderInterval = firstReminder ? 15 : 30;
    const formatRefFacture = refFacture !== "C1" && refFacture !== "C2" ? `${refFacture} ` : refFacture;

    dateFormated.setDate(dateFormated.getDate() + reminderInterval);

    const currentReminders = reminders
      .filter(item => item.ndossier === ndossier && item.first_reminder === firstReminder && item.numclient.includes(formatRefFacture))

    const formatedDate = currentReminders.length > 0 ? new Date(currentReminders[0].date_reminder).toLocaleDateString('en-CA') : validation ? "" : (today >= dateFormated) ? "À ENVOYER" : "";
    return formatedDate
  }

  getRefFacture(numclient) {
    const regex = numclient.includes('RX') ? /C\d+/ : /R\d+/;
    return numclient.match(regex)[0];
  }

  isReminderNeeded = (date, validation, numclient) => {
    const { reminders } = this.props;

    if (validation === 1) {
      return false;
    }
    const today = new Date();
    const dateFormatedFirstReminder = new Date(date);
    const dateFormatedSecondReminder = new Date(date);

    dateFormatedFirstReminder.setDate(dateFormatedFirstReminder.getDate() + 15);
    dateFormatedSecondReminder.setDate(dateFormatedSecondReminder.getDate() + 30);

    const checkFirstReminder = reminders.some(reminder => reminder.numclient === numclient && reminder.first_reminder === 1);
    const checkSecondReminder = reminders.some(reminder => reminder.numclient === numclient && reminder.first_reminder === 0);

    return (((today >= dateFormatedFirstReminder) && !checkFirstReminder) || ((today >= dateFormatedSecondReminder) && !checkSecondReminder)) ? true : false;
  }

  loadData() {
    this.props.dispatch(changePdfFileName(""))
    const { factures } = this.props;
    const { startDate, endDate, filtersStatut } = this.state;
    const currentLoadDataId = Symbol();
    this._currentLoadDataId = currentLoadDataId;

    try {
      let facturesData = [...factures].sort((a, b) => a.nfacture < b.nfacture)
        .map((item) => ({
          nfacture: item.nfacture,
          ndossier: item.ndossier,
          dossier: JSON.parse(item.dossier),
          numclient: item.numclient,
          month_facture: { month: handleChangeDateToMonth(item.date_ech), ref: getCodeFacture(item.numclient) },
          date_ech: this.formatDate(item.date_ech),
          first_reminder: this.handleGetDateReminder(item, this.getRefFacture(item.numclient), 1),
          second_reminder: this.handleGetDateReminder(item, this.getRefFacture(item.numclient), 0),
          payment_date: this.formatDate(item.payment_date),
          validation: item.validation,
          contrat_valide: item.contrat_valide,
          amount_ht: item.total_ht,
          amount_tva: item.total_ht * (item.tva / 100),
          amount_ca: item.bank === 'ca' ? item.total_ht + (item.total_ht * (item.tva / 100)) : "",
          amount_bred: item.bank !== 'ca' ? item.total_ht + (item.total_ht * (item.tva / 100)) : "",
        }));

      if (filtersStatut.firstReminder || filtersStatut.secondReminder || filtersStatut.toSend || filtersStatut.paye || filtersStatut.nonPaye) {
        facturesData = facturesData.filter((item) => {
          if (filtersStatut.paye && item.validation === 1) {
            return true;
          }
          if (filtersStatut.nonPaye && (item.validation === 0 || item.validation === null)) {
            return true;
          }
          if (filtersStatut.toSend && this.isReminderNeeded(item.date_ech, item.validation, item.numclient)) {
            return true;
          }
          if (filtersStatut.firstReminder && item.first_reminder !== "" && item.first_reminder !== "À ENVOYER") {
            return true;
          }
          if (filtersStatut.secondReminder && item.second_reminder !== "" && item.second_reminder !== "À ENVOYER") {
            return true;
          }
          return false;
        });
      }

      if (startDate !== "" && endDate !== "") {
        const startDateValue = new Date(startDate);
        const endDateValue = new Date(endDate);
        facturesData = facturesData.filter((item) => {
          const itemDate = new Date(item.date_ech);
          return itemDate >= startDateValue && itemDate <= endDateValue;
        });
      }

      const totalAmount = this.getTotalAmount(facturesData)
      const totalBredAmount = this.getTotalAmountByBank(facturesData).bredAmount
      const totalCaAmount = this.getTotalAmountByBank(facturesData).caAmount
      if (this._isMounted && currentLoadDataId === this._currentLoadDataId) {
        this.setState({ facturesData, totalAmount, totalBredAmount, totalCaAmount, file: null });
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleGenClick = async () => {
    this.setState({ file: null, openFile: true, disableButton: true });
    const { facturesData, totalAmount, totalBredAmount, totalCaAmount } = this.state

    const json = {
      data: facturesData,
      total_amount: totalAmount,
      today: new Date().toLocaleDateString('fr-FR').replace(/\//g, '-'),
      total_ttc_bred: totalBredAmount,
      total_ttc_ca: totalCaAmount
    };

    const requestOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(json)
    };

    let blob = await genSyntheseTablePDF(requestOptions);
    if (blob) {
      this.setState({ file: blob, disableButton: false });
    }
  }

  handleShowPdf = async () => {
    this.setState({ file: null, disableButton: true });
    let response = await getSyntheseTablePDF('pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob, openFile: true });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    } else {
      this.setState({ openFile: false });
    }
    await this.setState({ disableButton: false });
  }

  handleGetPdfFile = async () => {
    this.setState({ file: null });
    let response = await getSyntheseTablePDF('pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    this.setState({ file: null });
    let response = await getSyntheseTablePDF('word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  resetData = async () => {
    await this.setState({
      startDate: '',
      endDate: '',
      filtersStatut: {
        firstReminder: false,
        secondReminder: false,
        toSend: false,
        paye: false,
        nonPaye: false,
      },
    })
    await this.loadData();
  }

  closeDoc = () => {
    this.setState({ openFile: false, file: null });
  }

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`Tableau Synthèse de Facturation`))
  }

  render() {
    const { facturesData, totalAmount, disableButton, startDate, endDate, filtersStatut, totalBredAmount, totalCaAmount, file } = this.state
    return (
      <div className="facture-global">
        <Header />
        <DocMenu
          file={file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Tableau Synthèse de Facturation</h1>
        <ListTable
          facturesData={facturesData}
          totalAmount={totalAmount}
          totalBredAmount={totalBredAmount}
          totalCaAmount={totalCaAmount}
          disableButton={disableButton}
          startDate={startDate}
          endDate={endDate}
          filtersStatut={filtersStatut}
          resetData={this.resetData.bind(this)}
          loadData={this.loadData.bind(this)}
          handleDateChange={this.handleDateChange.bind(this)}
          handleStatutChange={this.handleStatutChange.bind(this)}
          handleGenClick={this.handleGenClick.bind(this)}
          handleShowPdf={this.handleShowPdf.bind(this)}
        />
        <GenPdf file={this.state.file} isOpen={this.state.openFile} closeDoc={this.closeDoc} scale={this.state.file !== null ? 1 : 0.5} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  facturations: state.facture.items,
  factures: state.facture.allfacture,
  reminders: state.reminder.items,
  deviscon: state.devis.deviscon,
  devisrea: state.devis.devisrea,
  dossierValue: state.devis.dossierValue,
  factureIndex: state.facture.facindex,
  dossiers: state.dossier.items,
  contracts: state.contrat.items,
  echeance: state.echeances.itemNdossier,
});
export default connect(mapStateToProps)(Global);