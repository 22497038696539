import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import axios from "axios";

class Dialog3 extends React.Component { 
  constructor(props){
    super(props)
    this.state={
      isOpen:true,
      datedem:"",
      data: [],
    }
    this.handleCloseProcess=this.handleCloseProcess.bind(this)
    this.handleOpen=this.handleOpen.bind(this)
    this.handleChange=this.handleChange.bind(this)
  }
  componentDidMount() {
    //get request
    axios.get('http://localhost:3000/dem/').then(res => {
      this.setState({ data: res.data });
    });

  }
  handleChange(event){
    this.setState({[event.target.name]:event.target.value})
  }

  handleCloseProcess(){
    this.setState({isOpen:false})
    this.props.closeDialog()
}
  handleOpen(){
    this.setState({isOpen:true})
}

  //get la datedu date du dem
  handleDatedem(event) {

    fetch("http://localhost:3000/dem/")
      .then(response => {
        response.json();
      })

      .then(err => {
        console.log(err);
      });
  }

    render() {
      return (
        <div>
        <Dialog open={this.state.isOpen} onClose={this.handleCloseProcess} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Date Démarrage du chantier</DialogTitle>
          <DialogContent>
            <DialogContentText>
            <p variant="contained" color="primary" > La date du démarrage du chantier est-elle?
                <tbody>
                  {this.state.data.map((result) => {
                    return (
                      <tr>
                        <td>{result.datedem}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.showNextDialog(4)} color="primary">
              Non
            </Button>
            <Button onClick={this.handleCloseProcess} color="primary">
              Oui
            </Button>
          </DialogActions>
        </Dialog>  
        </div>       
      )
    }
  }
  const mapStateToProps = state => ({
    devisrea : state.devis.devisrea,
    deviscon : state.devis.deviscon,
    dossierValue : state.devis.dossierValue,
    nprop : state.devis.nprop,
    attachment: state.email.file
  });
export default connect(mapStateToProps)(Dialog3)