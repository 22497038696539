import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Button, Grid } from "@material-ui/core";
import LoadingOverlay from "../../LoadingOverlay";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from '@material-ui/icons/Remove';
import 'moment/locale/fr';
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumsDoss from "../../NumsDoss";
import GenPdf from "../../GenPdf";
import FirstYear from './FirstYear';
import SecondYear from './SecondYear';
import ThirdYear from './ThirdYear';
import FourthYear from './FourthYear';
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getAppointments, getSchedulesTable, genSchedulePDF, getScheduleFile } from '../Utils';
import authHeader from '../../Utils';
import history from '../../Routes/history';
import "../../../Styles/Calendar/Schedule.scss";
import { set } from 'date-fns';

const Schedule = () => { 
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const itemConst = Object.fromEntries(Array.from({ length: 12 }, (_, i) => [`m${i + 1}`, '']));
  const emptyItems = [itemConst];
  const years = ['first_year', 'second_year', 'third_year', 'fourth_year'];

  const dispatch = useDispatch();

  const [lotList, setLotList] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [userId, setUserId] = useState(null);
  const [scheduleItemsFirstYear, setScheduleItemsFirstYear] = useState(emptyItems);
  const [scheduleItemsSecondYear, setScheduleItemsSecondYear] = useState(emptyItems);
  const [scheduleItemsThirdYear, setScheduleItemsThirdYear] = useState(emptyItems);
  const [scheduleItemsFourthYear, setScheduleItemsFourthYear] = useState(emptyItems);

  const [isLoading, setIsLoading] = useState(false);
  const [displayedYears, setDisplayedYears] = useState(1);
  const [startYear, setStartYear] = useState(new Date().getFullYear());
  const [yearContainerWidth, setYearContainerWidth] = useState('100%');
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const yearContainerRef = useRef(null);

  useEffect(() => {
    const updateWidth = () => {
      if (yearContainerRef.current) {
        setYearContainerWidth(`${yearContainerRef.current.offsetWidth}px`);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const handleGetAllLots = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await fetch("/api/rj/getAllLots", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      setLotList(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des lots:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const user = authHeader();
    if (!user['x-access-token']) {
      history.push('/login');
      return;
    }
    setUserId(user.id);

    if (!dossierValue) {
      setIsLoading(false);
      return;
    }

    try {
      if (lotList.length === 0) await handleGetAllLots();
      const result = await getAppointments(dossierValue, user.id);
      setAppointments(result);
    } catch (error) {
      console.error('Erreur lors de la récupération des rendez-vous:', error);
    } finally {
      setIsLoading(false);
    }
  }, [dossierValue, lotList.length, handleGetAllLots]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getData = useCallback(async () => {
    if (!userId || !dossierValue) return;

    try {
      setIsLoading(true);
      const schedulesTable = await getSchedulesTable(userId, dossierValue);

      if (schedulesTable) {
        const parsedYears = years.map(year => JSON.parse(schedulesTable[year]));
        let nbYears = parsedYears.reduce((acc, year) =>
          JSON.stringify(year) !== JSON.stringify(emptyItems) ? acc + 1 : acc, 0
        );
        
        setStartYear(schedulesTable.start_year);
        setScheduleItemsFirstYear(parsedYears[0]);
        setScheduleItemsSecondYear(parsedYears[1]);
        setScheduleItemsThirdYear(parsedYears[2]);
        setScheduleItemsFourthYear(parsedYears[3]);
        setDisplayedYears(nbYears || 1);
      } else {
        setScheduleItemsFirstYear(emptyItems);
        setScheduleItemsSecondYear(emptyItems);
        setScheduleItemsThirdYear(emptyItems);
        setScheduleItemsFourthYear(emptyItems);
        setDisplayedYears(1);
        setStartYear(new Date().getFullYear());
      }
    } catch (error) {
      console.error('Erreur lors de la création du planning:', error);
    } finally {
      setIsLoading(false);
    }
  }, [userId, dossierValue]);

  useEffect(() => {
    getData();
  }, [userId, dossierValue]);


  const deleteItem = useCallback((setItems) => (index) => {
    setItems(prevItems =>
      prevItems.length === 1
        ? [itemConst]
        : prevItems.filter((_, i) => i !== index)
    );
  }, []);

  const deleteItemFirstYear = useMemo(() => deleteItem(setScheduleItemsFirstYear), [deleteItem]);
  const deleteItemSecondYear = useMemo(() => deleteItem(setScheduleItemsSecondYear), [deleteItem]);
  const deleteItemThirdYear = useMemo(() => deleteItem(setScheduleItemsThirdYear), [deleteItem]);
  const deleteItemFourthYear = useMemo(() => deleteItem(setScheduleItemsFourthYear), [deleteItem]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const scheduleData = {
        user_id: userId,
        ndossier: dossierValue,
        start_year: startYear,
        first_year: JSON.stringify(scheduleItemsFirstYear),
        second_year: JSON.stringify(scheduleItemsSecondYear),
        third_year: JSON.stringify(scheduleItemsThirdYear),
        fourth_year: JSON.stringify(scheduleItemsFourthYear)
      };

      const response = await axios.post('/api/agenda/saveSchedulesTable', scheduleData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status !== 200) {
        throw new Error("Erreur lors de l'enregistrement du planning");
      }
    } catch (error) {
      console.error("Erreur lors de l'enregistrement du planning:", error);
    } finally {
      setIsLoading(false);
      getData();
    }
  };

  const handleAddYear = useCallback(() => {
    setDisplayedYears(prev => Math.min(prev + 1, 4));
  }, []);

  const handleRemoveYear = useCallback(() => {
    setDisplayedYears(prev => {
      const newDisplayedYears = Math.max(prev - 1, 1);

      switch (newDisplayedYears) {
        case 1:
          setScheduleItemsSecondYear([itemConst]);
          break;
        case 2:
          setScheduleItemsThirdYear([itemConst]);
          break;
        case 3:
          setScheduleItemsFourthYear([itemConst]);
          break;
      }

      return newDisplayedYears;
    });
  }, []);

  const yearComponents = [
    <FirstYear
      key="first"
      displayedYears={displayedYears}
      startYear={startYear}
      setStartYear={setStartYear}
      appointments={appointments}
      lotList={lotList}
      scheduleItemsFirstYear={scheduleItemsFirstYear}
      itemConst={itemConst}
      deleteItemFirstYear={deleteItemFirstYear}
      setScheduleItemsFirstYear={setScheduleItemsFirstYear}
    />,
    <SecondYear
      key="second"
      startYear={startYear}
      appointments={appointments}
      lotList={lotList}
      scheduleItemsSecondYear={scheduleItemsSecondYear}
      itemConst={itemConst}
      deleteItemSecondYear={deleteItemSecondYear}
      setScheduleItemsSecondYear={setScheduleItemsSecondYear}
    />,
    <ThirdYear
      key="third"
      startYear={startYear}
      appointments={appointments}
      lotList={lotList}
      scheduleItemsThirdYear={scheduleItemsThirdYear}
      itemConst={itemConst}
      deleteItemThirdYear={deleteItemThirdYear}
      setScheduleItemsThirdYear={setScheduleItemsThirdYear}
    />,
    <FourthYear
      key="fourth"
      startYear={startYear}
      appointments={appointments}
      lotList={lotList}
      scheduleItemsFourthYear={scheduleItemsFourthYear}
      itemConst={itemConst}
      deleteItemFourthYear={deleteItemFourthYear}
      setScheduleItemsFourthYear={setScheduleItemsFourthYear}
    />
  ];

  const handleGenerate = useCallback(async () => {
    setIsLoading(true);
    setFile(null);
    const months = displayedYears * 12;
    let blob = await genSchedulePDF(dossierValue, months);
    if (blob) {
      setFile(blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

    }
    setIsLoading(false);
  }, [dossierValue, displayedYears]);

  const handleShowPdf = useCallback(async () => {
    setIsLoading(true);
    setFile(null);
    let response = await getScheduleFile(dossierValue, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
    setIsLoading(false);
  }, [dossierValue]);

  const handleGetPdfFile = useCallback(async () => {
    setIsLoading(true);
    setFile(null);
    let response = await getScheduleFile(dossierValue, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setIsLoading(false);
  }, [dossierValue]);

  const handleGetWordFile = useCallback(async () => {
    setIsLoading(true);
    setFile(null);
    let response = await getScheduleFile(dossierValue, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setIsLoading(false);
  }, [dossierValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`Relevé de rendez-vous pour effectuer une VIC`));
  }, [dossierValue]);

  return (
    <div className="schedule">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Relevé de rendez-vous pour effectuer une VIC hors intempéries</h1>
      <NumsDoss />
      <div className="container">
        <div ref={yearContainerRef}>
          {yearComponents.slice(0, displayedYears)}
        </div>
        <div style={{
          width: yearContainerWidth,
          display: 'flex',
          justifyContent: 'left',
          marginTop: '10px',
          height: '37px',
        }}>
          {displayedYears !== 4 && <IconButton
            aria-label="add"
            onClick={handleAddYear}
            style={{ padding: '7px' }}
          >
            <AddIcon />
          </IconButton>}
          {displayedYears !== 1 && <IconButton
            aria-label="remove"
            onClick={handleRemoveYear}
            style={{ padding: '7px' }}
          >
            <RemoveIcon />
          </IconButton>}
        </div>
      </div>
      <Grid container spacing={1} justifyContent="center" className='group-btn'>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Enregistrer
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleGenerate}>
            Générer PDF
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleShowPdf}>
            Afficher PDF
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={getData}>
            Annuler
          </Button>
        </Grid>
      </Grid>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};

export default Schedule;