import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

class ConfirmDoss extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return ( 
      <Dialog
        open={this.props.open}
        onClose={() => this.props.setOpen(false)}
        aria-labelledby="confirm-dialog">
        <DialogTitle id="confirm-dialog">CRÉATION DOSSIER</DialogTitle>
        <DialogContentText style={{ margin: "0 50px 30px 25px" }}>
          Voulez-vous vraiment créer ce dossier ?
        </DialogContentText>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              this.props.setOpen(false);
              this.props.onConfirm();
            }}
            style={{ backgroundColor: "#ff7f50", color: "white", boxShadow: '0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F' }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "#ff9b61"
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = "#ff7f50"
            }}
          >
            Confirmer
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              this.props.setOpen(false);
            }}
            style={{ backgroundColor: "#F5B039", color: "white", boxShadow: '0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F' }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "rgb(247, 194, 104)"
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = "#F5B039"
            }}
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
export default ConfirmDoss;