export const getDossierInfos = async (dossierValue) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      dossierValue
    }),
  };
  try {
    const response = await fetch("/api/devis/getDossierInfosPgc", requestOptions);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Resource not found");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching dossier info:", err);
    throw err;
  }
}

export const checkRjExists = async (dossierValue) => { 
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      dossierValue
    }),
  };
  try {
    const response = await fetch("/api/pgc/checkRj", requestOptions);
    return response.ok
  } catch (err) {
    console.error("Error fetching dossier info:", err);
    throw err;
  }
}

export const getCoverPageData = async (pgcValue, ndossier) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      pgcValue,
      ndossier
    })
  };

  try {
    const response = await fetch("/api/pgc/getCoverPageData", requestOptions);
    if (!response.ok) {
      throw new Error("Ressource non trouvée");
    }
    const data = await response.json();

    return data

  } catch (err) {
    console.log(err);
  }
}

export const getProjectPresentationData = async (pgcValue, dossierValue, index) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      pgcValue: pgcValue,
      ndossier: dossierValue,
      index
    })
  };

  try {
    const response = await fetch("/api/pgc/getProjectPresentationData", requestOptions);
    if (!response.ok) {
      throw new Error("Ressource non trouvée");
    }
    const data = await response.json();

    return data

  } catch (err) {
    console.log(err);
  }
}

export const getOrganizationalMeasuresData = async (pgcValue) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      pgcValue
    })
  };

  try {
    const response = await fetch("/api/pgc/getOrganizationalMeasuresData", requestOptions);
    if (!response.ok) {
      throw new Error("Ressource non trouvée");
    }
    const data = await response.json();

    return data

  } catch (err) {
    console.log(err);
  }
}

export const getCoordinationMeasuresData = async (pgcValue) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      pgcValue
    })
  };

  try {
    const response = await fetch("/api/pgc/getCoordinationMeasuresData", requestOptions);
    if (!response.ok) {
      throw new Error("Ressource non trouvée");
    }
    const data = await response.json();

    return data

  } catch (err) {
    console.log(err);
  }
}

export const getInterferenceOccupantsData = async (pgcValue) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      pgcValue
    })
  };

  try {
    const response = await fetch("/api/pgc/getInterferenceOccupantsData", requestOptions);
    if (!response.ok) {
      throw new Error("Ressource non trouvée");
    }
    const data = await response.json();

    return data

  } catch (err) {
    console.log(err);
  }
}

export const getCspsInformationsData = async (pgcValue) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      pgcValue
    })
  };

  try {
    const response = await fetch("/api/pgc/getCspsInformationsData", requestOptions);
    if (!response.ok) {
      throw new Error("Ressource non trouvée");
    }
    const data = await response.json();

    return data

  } catch (err) {
    console.log(err);
  }
}

export const deletePgc = async (dossierValue, index, pgcValue) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      dossierValue,
      index,
      pgcValue
    }),
  };

  try {
    const response = await fetch("/api/pgc/deletePgc", requestOptions);
    if (!response.ok) {
      throw new Error("Erreur lors de la suppression");
    }
    return response.json();
  } catch (err) {
    console.error(err);
    throw err;
  }
};


export const genPgcPDF = async (pgcValue, dossierValue, index) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({ 
      pgcValue,
      ndossier: dossierValue,
      index
     }),
  };
  return await fetch("/api/pgc/genPgcPDF", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const getPgcFile = async (dossierValue, index, type) => {
  try {
    const res = await fetch("/api/pgc/getPgcFile", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({
        dossierValue,
        index,
        type
      }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getMoCompanyTel = async (pgcValue) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      pgcValue
    }),
  };
  try {
    const response = await fetch("/api/pgc/getMoCompanyTel", requestOptions);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Resource not found");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching dossier info:", err);
    throw err;
  }
}