import React from 'react';
import ReactTable from 'react-table-6';
class TelTable extends React.Component{ 
  getTotal(){
    var total =0;
    var tab = Object.values(this.props.data[0])
    for(let i=0;i<tab.length;i++){
      total+=tab[i]
    }
    return total
  }
    render(){
        const data = [{ 
            type: "RJ",
            nbr: this.props.data[0]==undefined ? 0 : this.props.data[0].RJ
          },{
            type: "VIC",
            nbr: this.props.data[0]==undefined ? 0 : this.props.data[0].VIC
          },{
            type: "Facture",
            nbr: this.props.data[0]==undefined ? 0 : this.props.data[0].Facture
          },{
            type: "Devis",
            nbr: this.props.data[0]==undefined ? 0 : this.props.data[0].Devis
          },{
            type: "Total",
            nbr: this.props.data[0]==undefined ? 0 : this.getTotal()
          }
        ]     
          const columns = [{
            Header: 'Type',
            accessor: 'type'
          },{
            Header: 'Nombre',
            accessor: 'nbr'
          }]
      
          return (
                
                    <ReactTable
                      data={data}
                      columns={columns}
                      defaultPageSize = {5}
                      //pageSizeOptions = {[3, 6]}
                    />
                      
          );
    }
}
export default TelTable;