import React from 'react';
import {Pie} from 'react-chartjs-2';
import authHeader from '../Utils';
import 'chartjs-plugin-datalabels';
class PieChart extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            chartData : {
                labels : [],
                datasets : [{
                    label : '',
                    data : [],
                    backgroundColor : []
                    }
                ]
            }
        }
    }
    componentDidMount() {
        fetch('/api/dashboardData',{ 
            headers : authHeader(),
      })
      .then(res => res.json())
      .then(res => res.chartpourcentageeffectif)
      .then(res => this.setState({chartData:{
        labels:res.map(res => res.mois),
        datasets:[{
            data:res.map(res => res.pourcentage),
            backgroundColor : [
                'rgba(255, 128, 0, 0.5)',
                'rgba(255, 0, 0, 0.5)',
                'rgba(255, 255, 0, 0.5)',
                'rgba(90, 143, 1, 0.5)',
                'rgba(15, 15, 130, 0.5)',
                'rgba(50, 99, 132, 0.5)',
                'rgba(130, 75, 15, 0.5)',
                'rgba(255, 128, 64, 0.5)'

            ]
        }]
      }
      }));
    }
    render(){
        return(
            <div>
               
                <Pie 
                data={this.state.chartData}
                    width={18}
                    height={ window.innerWidth < 800 ? 19 :12}

                    options= {{
                        responsive:true,
                    legend: {
                        position: 'bottom'
                    },
                plugins: {
                    datalabels: {
                    color: '#fff',
                    anchor: 'end',
                    align: 'start',
                    offset: 0,
                    borderwidth: 2,
                    borderColor: "#fff",
                    borderRadius: 25,
                    backgroundColor: (context) => {
                        return context.dataset.backgroundColor;
                    },
                    font: {
                        weight: 'bold',
                        size: '10'
                    },
                    formatter: (value) => {
                        return value + '%'
                    }

                }}
            }
            }
                    
                />
                </div>
            
        );
    }
}
export default PieChart;