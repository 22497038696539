import React from "react";
import ReactTable from "react-table-6";
import { connect } from "react-redux";
import AutoSuggestRespChan from "./AutoSuggestRespChan";
class TravauxTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  disableContractor() {
    if (this.props.travInfos.soustraitant.answer == 0) {
      return true;
    }
    return false;
  }
  render() {
    const TheadComponent = (props) => null;

    const columns = [
      { 
        Header: "",
        accessor: "title1",
      },
      {
        Header: "",
        accessor: "title2",
      },
      {
        Header: "",
        accessor: "value1",
        Cell: (props) => {
          if (props.row._index === 0)
            return (
              <AutoSuggestRespChan
                company={this.props.company}
                resp_chan={this.props.travInfos.resp_chan}
                handleChange={this.props.handleChange}
                setRespChan={this.props.setRespChan}
              />
            );
          if (props.row._index == 1 || props.row._index == 4)
            return (
              <div>
                <label>Oui</label>
                <input
                  name={
                    Object.keys(this.props.travInfos)[props.row._index] +
                    "-answer"
                  }
                  value={1}
                  type="checkbox"
                  onChange={this.props.handleCheckChange}
                  checked={
                    Object.values(this.props.travInfos)[props.row._index]
                      .answer != 1
                      ? false
                      : true
                  }
                />
                <label>Non</label>
                <input
                  name={
                    Object.keys(this.props.travInfos)[props.row._index] +
                    "-answer"
                  }
                  value={0}
                  type="checkbox"
                  onChange={this.props.handleCheckChange}
                  checked={
                    Object.values(this.props.travInfos)[props.row._index]
                      .answer != 0
                      ? false
                      : true
                  }
                />
                <label>Observation</label>
                <input
                  name={
                    Object.keys(this.props.travInfos)[props.row._index] +
                    "-observation"
                  }
                  type="checkbox"
                  onChange={this.props.handleCheckChange}
                  checked={
                    Object.values(this.props.travInfos)[props.row._index]
                      .observation
                  }
                />
              </div>
            );
          if (props.row._index == 2)
            return (
              <input
                name={Object.keys(this.props.travInfos)[props.row._index]}
                style={{
                  position: "absolute",
                  width: "55.5%",
                  border: "none",
                  pointerEvents: "visible",
                  backgroundColor: "transparent",
                  textAlign: "left",
                  fontSize: "14px",
                  outline: "none",
                  left: "40.3%",
                  marginTop: "4px",
                }}
                onChange={this.props.handleChange}
                type="text"
                value={Object.values(this.props.travInfos)[props.row._index]}
                autoComplete="off"
              />
            );
          else if (props.row._index == 3)
            return (
              <input
                name={Object.keys(this.props.travInfos)[props.row._index]}
                style={{
                  border: "none",
                  height: "100%",
                  width: "100%",
                  pointerEvents: "visible",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                onChange={this.props.handleChange}
                value={Object.values(this.props.travInfos)[props.row._index]}
                type="date"
                autoComplete="off"
              />
            );
          else
            return (
              <input
                name={Object.keys(this.props.travInfos)[props.row._index]}
                style={{
                  border: "none",
                  height: "100%",
                  width: "100%",
                  pointerEvents: "visible",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                onChange={this.props.handleChange}
                value={Object.values(this.props.travInfos)[props.row._index]}
                autoComplete="off"
              />
            );
        },
      },
      {
        Header: "",
        accessor: "title3",
      },
      {
        Header: "",
        accessor: "value3",
        Cell: (props) => {
          if (props.row._index == 0)
            return (
              <input
                name={Object.keys(this.props.travInfos)[props.row._index + 5]}
                style={{
                  border: "none",
                  height: "100%",
                  width: "100%",
                  pointerEvents: "visible",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                onChange={this.props.handleChange}
                value={
                  Object.values(this.props.travInfos)[props.row._index + 5]
                }
                maxLength={10}
                autoComplete="off"
              />
            );
          if (props.row._index == 1)
            return (
              <input
                name={Object.keys(this.props.travInfos)[props.row._index + 5]}
                style={{
                  border: "none",
                  height: "100%",
                  width: "100%",
                  pointerEvents: "visible",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                onChange={this.props.handleChange}
                value={
                  Object.values(this.props.travInfos)[props.row._index + 5]
                }
                autoComplete="off"
                disabled={this.disableContractor()}
              />
            );
          if (props.row._index === 3)
            return (
              <div>
                <input
                  name={Object.keys(this.props.travInfos)[props.row._index + 4]}
                  style={{
                    border: "none",
                    height: "100%",
                    width: "20%",
                    pointerEvents: "visible",
                    backgroundColor: "transparent",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                  onChange={this.props.handleChange}
                  value={
                    Object.values(this.props.travInfos)[props.row._index + 4]
                  }
                  autoComplete="off"
                />
                <label> Jours </label>
                <input
                  name={Object.keys(this.props.travInfos)[props.row._index + 5]}
                  style={{
                    border: "none",
                    height: "100%",
                    width: "20%",
                    pointerEvents: "visible",
                    backgroundColor: "transparent",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                  onChange={this.props.handleChange}
                  value={
                    Object.values(this.props.travInfos)[props.row._index + 5]
                  }
                  autoComplete="off"
                />
                <label> Mois</label>
              </div>
            );
          if (props.row._index == 4)
            return (
              <div>
                <label>Min : </label>
                <input
                  name={Object.keys(this.props.travInfos)[props.row._index + 5]}
                  style={{
                    border: "none",
                    height: "100%",
                    width: "15%",
                    pointerEvents: "visible",
                    backgroundColor: "transparent",
                    textAlign: "center",
                    fontSize: "14px",
                    marginRight: "10px",
                  }}
                  onChange={this.props.handleChange}
                  value={
                    Object.values(this.props.travInfos)[props.row._index + 5]
                  }
                  autoComplete="off"
                />
                <label>Max : </label>
                <input
                  name={Object.keys(this.props.travInfos)[props.row._index + 6]}
                  style={{
                    border: "none",
                    height: "100%",
                    width: "15%",
                    pointerEvents: "visible",
                    backgroundColor: "transparent",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                  onChange={this.props.handleChange}
                  value={
                    Object.values(this.props.travInfos)[props.row._index + 6]
                  }
                  autoComplete="off"
                />
              </div>
            );
        },
      },
    ];
    const data = [
      {
        title2: "Responsable Chantier :",
        title3: "Téléphone :",
      },
      {
        title2: "Sous-Traitant :",
        title3: "Donneur d'Ordre :",
      },
      {
        title1: "Travaux :",
        title2: "Tâche(s) Réalisée(s) :",
      },
      {
        title2: "Début d'interventions :",
        title3: "Durée :",
      },
      {
        title2: "Connaissance du PGC :",
        title3: "Effectif Prévisionnel :",
      },
    ];
    return (
      <div className="travtable">
        <ReactTable
          data={data}
          columns={columns}
          defaultPageSize={5}
          TheadComponent={TheadComponent}
        />
      </div>
    );
  }
}
export default connect()(TravauxTable);
