import React, { useEffect, useState, useCallback, useRef } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { fetchPreDiuoByDoss } from "../../../redux/actions/preDiuoActions";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getCoverPageData, genPreDiuoPDF, getPreDiuoFile, getDataFromPgc } from "../Utils";
import { constructionSiteConst, intervenantsCont } from "./Const";
import coordogecLogo from './coordogecLogo.jpg';
import moment from "moment";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossPreDiuo from "../../NumDossPreDiuo";
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import YesNoDialog from "../../YesNoDialog";
import "../../../Styles/PreDIUO/Page1.scss"; 

const PreDiuoPage1 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.preDiuo.index);
  const preDiuoValue = useSelector((state) => state.preDiuo.preDiuoValue);
  const preDiuoByDoss = useSelector((state) => state.preDiuo.preDiuoByDoss);
  const lastPreDiuoValueRef = useRef(preDiuoValue);

  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [imgCoverPage, setImgCoverPage] = useState(null);
  const [logoProjectOwner, setLogoProjectOwner] = useState(null);
  const [logoProjectManager, setLogoProjectManager] = useState(null);
  const [logoCoordoCon, setLogoCoordoCon] = useState(null);
  const [logoCoordoRea, setLogoCoordoRea] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  const [constructionSite, setConstructionSite] = useState(constructionSiteConst);
  const [intervenants, setIntervenants] = useState(intervenantsCont);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const setStateField = (e) => {
    const { name, value } = e.target;
    const [section, field] = name.split('.');

    setIntervenants(prevState => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [field]: value
      }
    }));
  };

  const imageHandlers = {
    'cover_page.jpg': setImgCoverPage,
    'logo_project_owner.jpg': setLogoProjectOwner,
    'logo_project_manager.jpg': setLogoProjectManager,
    'logo_coordo_con.jpg': setLogoCoordoCon,
    'logo_coordo_rea.jpg': setLogoCoordoRea
  };

  const resetCompaniesLogo = () => {
    setImgCoverPage(null);
    setLogoProjectOwner(null);
    setLogoProjectManager(null);
    fetch(coordogecLogo)
      .then(response => response.blob())
      .then(blob => {
        const file1 = new File([blob], 'coordogecLogo.jpg', { type: 'image/jpeg' });
        const file2 = new File([blob], 'coordogecLogo.jpg', { type: 'image/jpeg' });

        setLogoCoordoCon(file1);
        setLogoCoordoRea(file2);
      })
      .catch(error => console.error('Error loading default logo:', error));
  };

  const fetchDataFromPgc = async () => {
    if (!dossierValue) return;
    setIsLoading(true);

    const response = await getDataFromPgc(dossierValue);

    if (response) {
      const constructionSiteData = JSON.parse(response.construction_site);
      const projectOwnerData = JSON.parse(response.project_owner);
      const projectManagerData = JSON.parse(response.project_manager);
      const coordoConData = intervenantsCont.coordoCon;
      const coordoReaData = intervenantsCont.coordoRea;

      unstable_batchedUpdates(() => {
        setConstructionSite({
          operation: constructionSiteData.operation || '',
          category: constructionSiteData.category || '',
          adress: constructionSiteData.adress || '',
          cp: constructionSiteData.cp || '',
          city: constructionSiteData.city || ''
        });

        setIntervenants(prevState => ({
          ...prevState,
          projectOwner: {
            name: projectOwnerData.name || '',
            complement: projectOwnerData.complement || '',
            adress: projectOwnerData.adress || '',
            cp: projectOwnerData.cp || '',
            city: projectOwnerData.city || '',
            tel: projectOwnerData.tel || ''
          },
          projectManager: {
            name: projectManagerData.name || '',
            complement: projectManagerData.complement || '',
            adress: projectManagerData.adress || '',
            cp: projectManagerData.cp || '',
            city: projectManagerData.city || '',
            tel: projectManagerData.tel || ''
          },
          coordoCon: {
            name: coordoConData.name,
            complement: coordoConData.complement,
            adress: coordoConData.adress,
            cp: coordoConData.cp,
            city: coordoConData.city,
            mail: coordoConData.mail,
            tel: coordoConData.tel
          },
          coordoRea: {
            name: coordoReaData.name,
            complement: coordoReaData.complement,
            adress: coordoReaData.adress,
            cp: coordoReaData.cp,
            city: coordoReaData.city,
            mail: coordoReaData.mail,
            tel: coordoReaData.tel
          }
        }));
        setDate(moment(new Date()).format('YYYY-MM-DD'));
        resetCompaniesLogo();
      })
    } else {
      unstable_batchedUpdates(() => {
        setConstructionSite({
          operation: '',
          category: '',
          adress: '',
          cp: '',
          city: ''
        });

        setIntervenants(prevState => ({
          ...prevState,
          projectOwner: {
            name: '',
            complement: '',
            adress: '',
            cp: '',
            city: '',
            tel: ''
          },
          projectManager: {
            name: '',
            complement: '',
            adress: '',
            cp: '',
            city: '',
            tel: ''
          },
          coordoCon: {
            name: intervenantsCont.coordoCon.name,
            complement: intervenantsCont.coordoCon.complement,
            adress: intervenantsCont.coordoCon.adress,
            cp: intervenantsCont.coordoCon.cp,
            city: intervenantsCont.coordoCon.city,
            mail: intervenantsCont.coordoCon.mail,
            tel: intervenantsCont.coordoCon.tel
          },
          coordoRea: {
            name: intervenantsCont.coordoRea.name,
            complement: intervenantsCont.coordoRea.complement,
            adress: intervenantsCont.coordoRea.adress,
            cp: intervenantsCont.coordoRea.cp,
            city: intervenantsCont.coordoRea.city,
            mail: intervenantsCont.coordoRea.mail,
            tel: intervenantsCont.coordoRea.tel
          }
        }));
        setDate(moment(new Date()).format('YYYY-MM-DD'));
        resetCompaniesLogo();
      })
    }

    setIsLoading(false);
  };

  const loadData = async () => {
    lastPreDiuoValueRef.current = preDiuoValue;
  
    if (!preDiuoValue) {
      fetchDataFromPgc();
      return;
    }
  
    setIsLoading(true);
    const response = await getCoverPageData(preDiuoValue);
  
    if (lastPreDiuoValueRef.current !== preDiuoValue) {
      setIsLoading(false);
      return;
    }
  
    if (response) {
      const constructionSiteData = JSON.parse(response.construction_site);
      const projectOwnerData = JSON.parse(response.project_owner);
      const projectManagerData = JSON.parse(response.project_manager);
      const coordoConData = JSON.parse(response.coordo_con);
      const coordoReaData = JSON.parse(response.coordo_rea);
  
      unstable_batchedUpdates(() => {
        setDate(response.date);
        setConstructionSite({
          operation: constructionSiteData.operation || '',
          category: constructionSiteData.category || '',
          adress: constructionSiteData.adress || '',
          cp: constructionSiteData.cp || '',
          city: constructionSiteData.city || ''
        });
  
        setIntervenants(prevState => ({
          ...prevState,
          projectOwner: {
            name: projectOwnerData.name || '',
            complement: projectOwnerData.complement || '',
            adress: projectOwnerData.adress || '',
            cp: projectOwnerData.cp || '',
            city: projectOwnerData.city || '',
            mail: projectOwnerData.mail || '',
            tel: projectOwnerData.tel || ''
          },
          projectManager: {
            name: projectManagerData.name || '',
            complement: projectManagerData.complement || '',
            adress: projectManagerData.adress || '',
            cp: projectManagerData.cp || '',
            city: projectManagerData.city || '',
            mail: projectManagerData.mail || '',
            tel: projectManagerData.tel || ''
          },
          coordoCon: {
            name: coordoConData.name || '',
            complement: coordoConData.complement || '',
            adress: coordoConData.adress || '',
            cp: coordoConData.cp || '',
            city: coordoConData.city || '',
            mail: coordoConData.mail || '',
            tel: coordoConData.tel || ''
          },
          coordoRea: {
            name: coordoReaData.name || '',
            complement: coordoReaData.complement || '',
            adress: coordoReaData.adress || '',
            cp: coordoReaData.cp || '',
            city: coordoReaData.city || '',
            mail: coordoReaData.mail || '',
            tel: coordoReaData.tel || ''
          }
        }));
  
        setImgCoverPage(null);
        setLogoProjectOwner(null);
        setLogoProjectManager(null);
        setLogoCoordoCon(null);
        setLogoCoordoRea(null);
  
        if (response.images.length > 0) {
          response.images.forEach((image) => {
            const buffer = new Uint8Array(image.img.buffer.data).buffer;
            const blob = new Blob([buffer]);
            const file = new File([blob], image.name, { type: 'image/png' });
  
            const handler = imageHandlers[image.name];
            if (handler) {
              handler(file);
            }
          });
        }
      });
    }
  
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDataFromPgc();
  }, [dossierValue]);

  useEffect(() => {
    loadData();
  }, [preDiuoValue]);

  const handleDeleteImg = useCallback((name) => {
    switch (name) {
      case "img-cover-page":
        setImgCoverPage(null);
        break;
      case "logo-project-owner":
        setLogoProjectOwner(null);
        break;
      case "logo-project-manager":
        setLogoProjectManager(null);
        break;
      case "logo-coordo-con":
        setLogoCoordoCon(null);
        break;
      case "logo-coordo-rea":
        setLogoCoordoRea(null);
        break;
      default:
        break;
    }
  }, []);

  const handleCreatePreDiuo = async (e) => {
    e.preventDefault();
    setOpenDialogCreate(false);
    setDisableButton(true);
    const newIndex = preDiuoByDoss.length;

    const formData = new FormData();
    formData.append('ndossier', dossierValue);
    formData.append('date', date);
    formData.append('index', newIndex);
    formData.append('constructionSite', JSON.stringify(constructionSite));
    formData.append('intervenants', JSON.stringify(intervenants));

    if (imgCoverPage) formData.append('imgCoverPage', imgCoverPage);
    if (logoProjectOwner) formData.append('logoProjectOwner', logoProjectOwner);
    if (logoProjectManager) formData.append('logoProjectManager', logoProjectManager);
    if (logoCoordoCon) formData.append('logoCoordoCon', logoCoordoCon);
    if (logoCoordoRea) formData.append('logoCoordoRea', logoCoordoRea);

    try {
      const response = await fetch('/api/prediuo/create', {
        enctype: "multipart/form-data",
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        dispatch(fetchPreDiuoByDoss(dossierValue));
      } else {
        console.error('Failed to upload images');
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    } finally {
      setDisableButton(false);
    }
  };

  const handleUpdatePreDiuo = async (e) => {
    e.preventDefault();
    setDisableButton(true);

    const formData = new FormData();
    formData.append('ndossier', dossierValue);
    formData.append('index', index);
    formData.append('preDiuoValue', preDiuoValue);
    formData.append('date', date);
    formData.append('constructionSite', JSON.stringify(constructionSite));
    formData.append('intervenants', JSON.stringify(intervenants));

    if (imgCoverPage) formData.append('imgCoverPage', imgCoverPage);
    if (logoProjectOwner) formData.append('logoProjectOwner', logoProjectOwner);
    if (logoProjectManager) formData.append('logoProjectManager', logoProjectManager);
    if (logoCoordoCon) formData.append('logoCoordoCon', logoCoordoCon);
    if (logoCoordoRea) formData.append('logoCoordoRea', logoCoordoRea);

    try {
      const response = await fetch('/api/prediuo/update', {
        enctype: "multipart/form-data",
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        console.error('Failed to upload images');
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    } finally {
      setDisableButton(false);
    }
  }

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genPreDiuoPDF(preDiuoValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [preDiuoValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPreDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [preDiuoValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPreDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [preDiuoValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPreDiuoFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [preDiuoValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`Pré DIUO`));
  }, [preDiuoValue]);

  const nextPage = () => {
    history.push("/prediuo/page2");
  }

  return (
    <div className="pre-diuo-page1">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Pré DIUO (Page de garde)</h1>
      <NumDossPreDiuo />
      <div className="container">
        <table className="custom-table">
          <tbody>
            <tr className="custom-cell">
              <td>Date</td>
              <td><input type="date" name="date" value={date} onChange={(e) => setDate(e.target.value)} /></td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>CHANTIER</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Dossier</td>
              <td><span>{dossierValue}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Indice</td>
              <td><span>{index}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Catégorie</td>
              <td><span>{constructionSite.category}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Opération</td>
              <td><span>{constructionSite.operation}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td><span>{constructionSite.adress ? `${constructionSite.adress}, ${constructionSite.cp} - ${constructionSite.city}` : ''}</span></td>
            </tr>
            <tr className="custom-cell">
              <td>Image</td>
              <td>
                <input
                  type="file"
                  name="img-cover-page"
                  key={`img-cp`}
                  multiple
                  // disabled= {disableButton}
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setImgCoverPage(e.target.files[0])}
                />
              </td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>MAÎTRE D'OUVRAGE</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Nom</td>
              <td><input type="text" name="projectOwner.name" value={intervenants.projectOwner.name} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Complément</td>
              <td><input type="text" name="projectOwner.complement" value={intervenants.projectOwner.complement} placeholder="Facultatif" onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td><input type="text" name="projectOwner.adress" value={intervenants.projectOwner.adress} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Code postal</td>
              <td><input type="text" name="projectOwner.cp" value={intervenants.projectOwner.cp} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Ville</td>
              <td><input type="text" name="projectOwner.city" value={intervenants.projectOwner.city} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Mail</td>
              <td><input type="text" name="projectOwner.mail" value={intervenants.projectOwner.mail || ''} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Téléphone</td>
              <td><input type="text" name="projectOwner.tel" value={intervenants.projectOwner.tel} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Logo</td>
              <td>
                <input
                  type="file"
                  name="logo-project-owner"
                  key={`logo-pm`}
                  multiple
                  // disabled= {disableButton}
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setLogoProjectOwner(e.target.files[0])}
                />
              </td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>MAÎTRE D'ŒUVRE</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Nom</td>
              <td><input type="text" name="projectManager.name" value={intervenants.projectManager.name} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Complément</td>
              <td><input type="text" name="projectManager.complement" value={intervenants.projectManager.complement} placeholder="Facultatif" onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td><input type="text" name="projectManager.adress" value={intervenants.projectManager.adress} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Code postal</td>
              <td><input type="text" name="projectManager.cp" value={intervenants.projectManager.cp} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Ville</td>
              <td><input type="text" name="projectManager.city" value={intervenants.projectManager.city} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Mail</td>
              <td><input type="text" name="projectManager.mail" value={intervenants.projectManager.mail || ''} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Téléphone</td>
              <td><input type="text" name="projectManager.tel" value={intervenants.projectManager.tel} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Logo</td>
              <td>
                <input
                  type="file"
                  name="logo-project-manager"
                  key={`logo-pm`}
                  multiple
                  // disabled= {disableButton}
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setLogoProjectManager(e.target.files[0])}
                />
              </td>
            </tr>
            <tr className="custom-cell-thead">
              <td className="custom-cell-label" colSpan={2}><strong>COORDONNATEUR SPS</strong></td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>PHASE CONCEPTION</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Entreprise</td>
              <td><input type="text" name="coordoCon.name" value={intervenants.coordoCon.name} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Contact</td>
              <td><input type="text" name="coordoCon.complement" value={intervenants.coordoCon.complement} placeholder="Facultatif" onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td><input type="text" name="coordoCon.adress" value={intervenants.coordoCon.adress} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Code postal</td>
              <td><input type="text" name="coordoCon.cp" value={intervenants.coordoCon.cp} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Ville</td>
              <td><input type="text" name="coordoCon.city" value={intervenants.coordoCon.city} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Mail</td>
              <td><input type="text" name="coordoCon.mail" value={intervenants.coordoCon.mail || ''} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Téléphone</td>
              <td><input type="text" name="coordoCon.tel" value={intervenants.coordoCon.tel} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Logo</td>
              <td>
                <input
                  type="file"
                  name="logo-coordo-con"
                  key={`logo-ccon`}
                  multiple
                  // disabled= {disableButton}
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setLogoCoordoCon(e.target.files[0])}
                />
              </td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>PHASE REALISATION</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Entreprise</td>
              <td><input type="text" name="coordoRea.name" value={intervenants.coordoRea.name} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Contact</td>
              <td><input type="text" name="coordoRea.complement" value={intervenants.coordoRea.complement} placeholder="Facultatif" onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td><input type="text" name="coordoRea.adress" value={intervenants.coordoRea.adress} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Code postal</td>
              <td><input type="text" name="coordoRea.cp" value={intervenants.coordoRea.cp} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Ville</td>
              <td><input type="text" name="coordoRea.city" value={intervenants.coordoRea.city} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Mail</td>
              <td><input type="text" name="coordoRea.mail" value={intervenants.coordoRea.mail || ''} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Téléphone</td>
              <td><input type="text" name="coordoRea.tel" value={intervenants.coordoRea.tel} onChange={setStateField} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Logo</td>
              <td>
                <input
                  type="file"
                  name="logo-coordo-rea"
                  key={`logo-crea`}
                  multiple
                  // disabled= {disableButton}
                  style={{
                    width: "100%",
                    pointerEvents: "visible",
                    fontSize: "14px",
                  }}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setLogoCoordoRea(e.target.files[0])}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="imgs">
          {renderImageItem(imgCoverPage, "img-cover-page", handleDeleteImg, "img page de garde", "Page de garde")}
          {renderImageItem(logoProjectOwner, "logo-project-owner", handleDeleteImg, "logo maitre d ouvrage", "Maître d'ouvrage")}
          {renderImageItem(logoProjectManager, "logo-project-manager", handleDeleteImg, "logo maitre d oeuvre", "Maître d'oeuvre")}
          {renderImageItem(logoCoordoCon, "logo-coordo-con", handleDeleteImg, "logo Coordonnateur Conception", "Coordonnateur Conception")}
          {renderImageItem(logoCoordoRea, "logo-coordo-rea", handleDeleteImg, "logo Coordonnateur Realisation", "Coordonnateur Realisation")}
        </div>
      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpenDialogCreate(true)}
          disabled={disableButton}
        >
          Nouveau PRE DIUO
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleUpdatePreDiuo}
          disabled={disableButton || !preDiuoValue}
        >
          Modifier
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !preDiuoValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleShowPdf()}
          disabled={disableButton || !preDiuoValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={disableButton || !preDiuoValue}
        >
          Page suivante
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      <YesNoDialog
        isOpen={openDialogCreate}
        title={'CRÉATION DIUO'}
        question={`Voulez-vous créer un nouvel indice Pré DIUO du dossier ${dossierValue} ?`}
        handleYesClick={handleCreatePreDiuo}
        handleClose={() => setOpenDialogCreate(false)}
      />
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};
const renderImageItem = (image, name, handleDelete, altText, description) => (
  image && (
    <div className="img-item">
      <IconButton
        className="item-delete"
        style={{
          color: "white",
          position: "absolute",
          top: 0,
          right: 0
        }}
        onClick={() => handleDelete(name)}
      >
        <HighlightOffIcon />
      </IconButton>
      <img
        src={URL.createObjectURL(image)}
        alt={altText}
        style={{ height: "200px" }}
      />
      <p>{description}</p>
    </div>
  )
);

export default PreDiuoPage1;