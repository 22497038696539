import React from 'react';
import { faWindowClose} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDate, verifNotifIcon } from '../../Utils';
import { ToastContainer, toast, cssTransition  } from 'react-toastify';
import {connect} from 'react-redux'
import "react-toastify/dist/ReactToastify.css";
import history from '../../Routes/history';
import GererNotif from '../../GererNotif';
class NotifList extends React.Component{  
    constructor(props){
        super(props);
        this.closeNotifFromIndex = this.closeNotifFromIndex.bind(this);
        //this.changeNotifState = this.changeNotifState.bind(this);

            this.state={
            displayNotif:[],
            notif:[],
            chevronState:"left",
            showToastify : true,
           
            
        }


    }
    //ws = new WebSocket('ws://localhost:5000')
    componentDidMount(){
        //fetch('/notifVu',{method:'POST'})
        this.loadNotif()
    }
    closeNotifFromIndex = index => {
            this.setState({displayNotif:this.createNewDisplay(index)})
        };
    createNewDisplay(index){
        const listDisplay = this.state.displayNotif.map((item, j) => {
            if (j === index) return false; 
            else if(item===true)return true;
            else return false;           
          });
          return listDisplay;
    }
    changeTopValue(){
        var top = 970;
        if(this.state.notif.length === 0) return top;
        else{
            this.state.notif.map((notif,index)=>{
                top = top-90
        })
        return top;
        }
    }
    loadNotif(){
        if(this.state.displayNotif.length ===0){
            const listTemplate = []
            for(var i=0; i<this.props.notifs.length; i++){
                listTemplate.push(true)
                this.setState({displayNotif:this.state.displayNotif.concat(listTemplate)})
            }
        }
    }

    handleClickToastify() { 
           this.setState(state => ({ showToastify: false    })); 
    }


    render(){
        this.loadNotif()
    const {notifs} = this.props
    
        return( 
            
            
            <div>
                {/* { this.state.showToastify ?
                <div ref={this.toastifyNotif} onClick={this.loadToastify}>
                     
                    <div className="notifList" >  
                            { setTimeout(() => { notifs.map((notif, index) =>


                                    toast( 
                                    <div className={this.state.displayNotif[index] ? "notif"+(index+1) : "notif-closed"+(index+1)}  key={notif.id}>
                                    <span className="notifIcon"><FontAwesomeIcon icon={verifNotifIcon(notif.type_notif)}/></span>
                                    <span className={"closeNotif"+ (index+1)} onClick={() => this.closeNotifFromIndex(index)}><FontAwesomeIcon icon={faWindowClose}/></span>
                                    <p><span>{(index+1)+" - " + notif.message}</span></p>
                                    <p>Envoyé par {notif.sender} le {convertDate(notif.date)} à {notif.heure}</p>
                                    
                                    </div>
                                ,  {
                                    toastId: index,  
                                    position: "top-right",
                                    autoClose: 5000 * (index +1),
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    onClick : () => { history.push("/notifications")}
                                    })
                                    )}, 100)                
                            }    
                            <ToastContainer toastStyle={{ backgroundColor: "rgba(255, 127, 80, 0.8)", color : "#FFF", padding : "0px 0px 0px 0px"}}/> 
                        </div>

                        <div className="notifList">    
                            { setTimeout(() => { notifs.map((notif, index) =>


                                    toast( 
                                    <div className={this.state.displayNotif[index] ? "notif"+(index+1) : "notif-closed"+(index+1)}  key={notif.id}>
                                    <span className="notifIcon"><FontAwesomeIcon icon={verifNotifIcon(notif.type_notif)}/></span>
                                    <span className={"closeNotif"+ (index+1)} onClick={() => this.closeNotifFromIndex(index)}><FontAwesomeIcon icon={faWindowClose}/></span>
                                    <p><span>{(index+1)+" - " + notif.message}</span></p>
                                    <p>Envoyé par {notif.sender} le {convertDate(notif.date)} à {notif.heure}</p>
                                    
                                    </div>
                                ,  {
                                    toastId: index,  
                                    position: "top-right",
                                    autoClose: 5000 * (index +1),
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    onClick : () => { history.push("/notifications")}
                                    })
                                    )}, 20000)
                                
                                
                            }

                                
                            <ToastContainer toastStyle={{ backgroundColor: "rgba(255, 127, 80, 0.8)", color : "#FFF", padding : "0px 0px 0px 0px"}}/> 
                        </div>

                        <div className="notifList"> 
                            { setTimeout(() => { notifs.map((notif, index) =>


                                    toast( 
                                    <div className={this.state.displayNotif[index] ? "notif"+(index+1) : "notif-closed"+(index+1)}  key={notif.id}>
                                    <span className="notifIcon"><FontAwesomeIcon icon={verifNotifIcon(notif.type_notif)}/></span>
                                    <span className={"closeNotif"+ (index+1)} onClick={() => this.closeNotifFromIndex(index)}><FontAwesomeIcon icon={faWindowClose}/></span>
                                    <p><span>{(index+1)+" - " + notif.message}</span></p>
                                    <p>Envoyé par {notif.sender} le {convertDate(notif.date)} à {notif.heure}</p>
                                    
                                    </div>
                                ,  {
                                    toastId: index,  
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    onClick : () => { history.push("/notifications")}
                                    })
                                    )}, 40000)
                                
                                
                            }

                                
                            <ToastContainer toastStyle={{ backgroundColor: "rgba(255, 127, 80, 0.8)", color : "#FFF", padding : "0px 0px 0px 0px"}}/>

                            
                        </div>
     
                </div>
             :null } */}
        

                <div ref={this.props.notifWrapper} className="notifWrapper" onClick={this.handleClickToastify}> 
                    <div className="notifList">
                        <p className="notifButton">Gérer les notifications</p>

                        
                        {notifs.map((notif, index) =>
                            
                            <div className={this.state.displayNotif[index] ? "notif"+(index+1) : "notif-closed"+(index+1)} >
                            <span className="notifIcon"><FontAwesomeIcon icon={verifNotifIcon(notif.type_notif)}/></span>
                            <span className={"closeNotif"+ (index+1)} onClick={() => this.closeNotifFromIndex(index)}><FontAwesomeIcon icon={faWindowClose}/></span>
                            <p><span>{(index+1)+" - " + notif.message}</span></p>
                            <p>Envoyé par {notif.sender} le {(notif.date)} à {notif.heure} </p>
                            </div>
                            

                        ) }
 
                    </div>

                      
                            
                </div>

            </div>  


        );
    }
}
const mapStateToProps = state => ({
    notifs: state.notif.items
  });
  export default connect(mapStateToProps)(NotifList)