import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import Header from "../../../Header/Header";
import VICSMenu from "../../VICSMenu";
import DocMenu from "../../../DocMenu";
import GenPdf from "../../../GenPdf";
import history from "../../../Routes/history";
import SignatureCanvas from "react-signature-canvas";
import { Button } from "@material-ui/core";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import CircularProgress from "@material-ui/core/CircularProgress";
import { newEntrepriseValue } from "../../../../redux/actions/entrepriseActions";
import { changeVicValue, fetchCompaniesByDossierLot, changeConventionValue } from "../../../../redux/actions/vicActions";
import { changeTitleName, changePdfFileName } from "../../../../redux/actions/emailAction";
import { getQuestionsPage, updateQuestionsPage8, getConventionValue, getVicValues, genVicPDF, getVicPDF } from "../../Utils"
import "../../../../Styles/VIC/Page-8.scss";

const tasks = [ 
  {
    answer: false,
    comment: `L’entreprise propriétaire tient à disposition sur le site les justificatifs de contrôles et vérifications en cours de validité;`
  },
  {
    answer: false,
    comment: `Les entreprises déclarent avoir souscrit une police d’assurance couvrant les responsabilités civiles et tous les dommages pouvant résulter des activités exercées;`
  },
  {
    answer: false,
    comment: `Les entreprises veilleront à ce que le planning d’utilisation ainsi que les formations légales des entreprises soient à jour et affichées;`
  },
  {
    answer: false,
    comment: `L’entreprise utilisatrice s’engage à respecter les consignes générales de sécurités, les consignes particulières, les consignes spécifiques du site et toutes les règles, codes, lois applicables qu’elle s’engage à appliquer.`
  },
  {
    answer: false,
    comment: `L’entreprise utilisatrice s’engage à respecter les consignes d’utilisation délivrées par le fabricant de l’équipement (notice du constructeur/fabricant) et d’informer l’ensemble de son personnel sur les modalités pratiques propres au chantier et à l’usage de l’équipement ; les recommandations du CRAM.`
  },
  {
    answer: false,
    comment: `L’entreprise utilisatrice a délivré les attestations de compétences à l’utilisation en sécurité d’un matériel de levage à chacun de ses salariés qui ont en outre reçus les informations et les formations adaptées.`
  },
  {
    answer: false,
    comment: `L’entreprise a informé ses salariés que, sans cette autorisation, ils ne pourront pas utiliser l’équipement.`
  },
  {
    answer: false,
    comment: `Si un dysfonctionnement est relevé par l’entreprise utilisatrice, celle-ci s’engage à informer expressément dans un délai de 1 heure l’entreprise propriétaire pour permettre à cette dernière de prendre des mesures correctives qui s’imposent;`
  },
  {
    answer: false,
    comment: `Toute modification de tout ou partie du matériel mis à disposition est interdite;`
  },
  {
    answer: false,
    comment: `Toute cession du matériel dont la société (nommer l’entreprise propriétaire) est propriétaire est interdite;`
  },
  {
    answer: false,
    comment: `Toute mise à disposition du matériel au profil d’une autre entreprise que celle mentionnée à la présente convention est interdite.`
  },
];

const Page8 = ({ vicValue, dossierValue, lotsValue, dispatch, entrepriseValue, companies }) => {
  const ownerSignature = useRef({});
  const userSignature = useRef({});
  const isFirstRender = useRef(true);
  const prevDossierValue = useRef(dossierValue);
  const prevLotsValue = useRef(lotsValue);
  const prevCompanies = useRef(companies);
  const prevEntrepriseValue = useRef(entrepriseValue);
  const [disableButton, setDisableButton] = useState(false);
  const [page4data, setPage4data] = useState([]);
  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [specificTasks, setSpecificTasks] = useState(tasks);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [readAndApprovedOwner, setReadAndApprovedOwner] = useState(false);
  const [readAndApprovedUser, setReadAndApprovedUser] = useState(false);

  // useEffect(() => {
  //   if (!vicValue) {
  //     dispatch(changeConventionValue(''));
  //     history.push("/vic/page-1");
  //   }
  // }, [vicValue]);

  useEffect(() => {
    if (isFirstRender.current) return
    setDisableButton(true);

    const fetchData = async () => {
      if (dossierValue !== prevDossierValue.current || lotsValue !== prevLotsValue.current) {
        await dispatch(fetchCompaniesByDossierLot(dossierValue, lotsValue));
        prevDossierValue.current = dossierValue;
        prevLotsValue.current = lotsValue;
      }
    };

    fetchData();
    setDisableButton(false);
  }, [dossierValue, lotsValue]);

  useEffect(() => {
    if (isFirstRender.current) return

    setDisableButton(true);
    const fetchData = async () => {
      if (companies !== prevCompanies.current) {
        if (companies.length > 0) {
          await dispatch(newEntrepriseValue(companies[0]));
          const result = await getVicValues(dossierValue, lotsValue, companies[0]);
          await dispatch(changeVicValue(result.id));
        } else {
          await dispatch(changeVicValue(""));
        }
        prevCompanies.current = companies;
      }
    };

    fetchData();
    setDisableButton(false);
  }, [companies]);

  useEffect(() => {
    if (isFirstRender.current) return
    setDisableButton(true);

    const fetchData = async () => {
      if (entrepriseValue !== prevEntrepriseValue.current) {
        const result = await getVicValues(dossierValue, lotsValue, entrepriseValue);
        await dispatch(changeVicValue(result.id));
        prevEntrepriseValue.current = entrepriseValue;
      }
    };

    fetchData();
    setDisableButton(false);
  }, [entrepriseValue]);

  useEffect(() => {
    const fetchData = async () => {
      if (!vicValue) return dispatch(changeConventionValue(''));

      setDisableButton(true);
      dispatch(changePdfFileName(""))
      const convention = await getConventionValue(vicValue);
      dispatch(changeConventionValue(convention));
      if (convention === "") history.push("/vic/page-6");

      await getQuestionsPage(4, vicValue).then((data) => {
        setPage4data(data !== null ? data : []);
      })

      const data = await getQuestionsPage(8, vicValue);

      if (data.tasks !== "") {
        const tasksLoaded = JSON.parse(data.tasks);
        const dailySchedule = JSON.parse(data.daily_schedule);

        setSpecificTasks(tasksLoaded);
        setStartTime(dailySchedule.start_time);
        setEndTime(dailySchedule.end_time);
      } else {
        setSpecificTasks(tasks);
        setStartTime('');
        setEndTime('');
        setReadAndApprovedOwner(false);
        setReadAndApprovedUser(false);
      }

    }
    fetchData();
    setDisableButton(false);

    isFirstRender.current = false;
  }, [vicValue]);

  const handleChangeData = (event, index) => {
    const { name, value, checked } = event.target;
    let temp_state = [...specificTasks];
    temp_state[index][name] = name === "answer" ? checked ? 1 : 0 : value;
    setSpecificTasks(temp_state);
  }

  const handleValidClick = async () => {
    setDisableButton(true);
    let formData = new FormData();

    formData.append("vicValue", vicValue);
    formData.append("specificTasks", JSON.stringify(specificTasks));
    formData.append("dailySchedule", JSON.stringify({ start_time: startTime, end_time: endTime }));

    if (!ownerSignature.current?.isEmpty()) {
      formData.append("sigOwner", ownerSignature.current.getTrimmedCanvas().toDataURL());
    } else formData.append("sigOwner", '');

    if (!userSignature.current?.isEmpty()) {
      formData.append("sigUser", userSignature.current.getTrimmedCanvas().toDataURL());
    } else formData.append("sigUser", '');

    updateQuestionsPage8(formData);
    setDisableButton(false);
  }

  const handleGenClick = async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genVicPDF(vicValue);
    if (blob) {
      setFile(blob);
      setIsOpen(true);
    }
    setDisableButton(false);
  }

  const handleShowPdf = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'pdf');
    
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);

      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }

  const handleGetPdfFile = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }

  const handleGetWordFile = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
    }
    setDisableButton(false);
  }

  const prevPage = () => {
    history.push("/vic/page-7");
  };

  const handleChangeTitleName = () => {
    dispatch(changeTitleName(`VIC`));
  };

  return (
    <div className="page8">
      <Header />
      <DocMenu
      file={file}
      getSourceFile={handleGetWordFile}
      getPDF={handleGetPdfFile}
      handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Visite d'Inspection Commune (Page 8)</h1>
      <VICSMenu />
      <div className="data-page8">
        <div className="specific-tasks">
          <h3>Conditions d'utilisations</h3>
          {specificTasks.map(({ answer, comment }, index) => {
            return (
              <div className="specific-task" key={index}>
                <input name='answer' type="checkbox" checked={answer == 1 ? true : false} onChange={(event) => handleChangeData(event, index)} />
                <label>{comment}</label>
              </div>
            );
          })}
          <div style={{ marginTop: "30px" }}>Horaire Journaliers : Heure Début :<input name='startTime' value={startTime} type='time' onChange={(event) => setStartTime(event.target.value)} /> Heure Fin : <input name='endTime' value={endTime} type='time' onChange={(event) => setEndTime(event.target.value)} /></div>

          <div className='signature-container'>
            <div className="signature">
              <div className="signature-items">
                <label>Signature Propriétaire : </label>
                <SignatureCanvas
                  ref={ownerSignature}
                  penColor="black"
                />
                <Button color="primary" variant="contained" onClick={() => ownerSignature.current.clear()}>Effacer</Button>
                <input type='checkbox' checked={readAndApprovedOwner} onChange={() => setReadAndApprovedOwner(!readAndApprovedOwner)} />
                <label> lu et approuvé </label>
              </div>
              <div className="signature-items">
                <label>Signature Utilisatrice : &nbsp;</label>
                <SignatureCanvas
                  ref={userSignature}
                  penColor="black"
                />
                <Button color="primary" variant="contained" onClick={() => userSignature.current.clear()}>Effacer</Button>
                <input type='checkbox' checked={readAndApprovedUser} onChange={() => setReadAndApprovedUser(!readAndApprovedUser)} />
                <label> lu et approuvé </label>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons-container">
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleValidClick()}
            disabled={disableButton}
          >
            Enregistrer
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleGenClick()}
            disabled={vicValue === "" || disableButton}
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleShowPdf()}
            disabled={vicValue === "" || disableButton}
          >
            Afficher le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowLeft />}
            onClick={prevPage}
            disabled={disableButton}
          >
            Page précedente
          </Button>
          {disableButton && <CircularProgress className="spinner" color="success" />}
        </div>
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
        pageNumber={page4data.length > 0 ? 8 : 7}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  vicValue: state.vic.vicValue,
  lotsValue: state.vic.lotsValue,
  companies: state.vic.companies,
  entrepriseValue: state.entreprise.entrepriseValue,
});

export default connect(mapStateToProps)(Page8);
