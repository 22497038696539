export const initialDiagnosisDescription = {
  number: 1,
  mission: '',
  bet: '',
  affair: '',
  date: ''
};

export const preventionOrganizationsCont = {
  laborInspection: { adress: '', representative: '', contact: '' },
  CRAM: { adress: 'CRAM\n17-19 place de l’Argonne\n75019 - PARIS', representative: '', contact: '01 40 05 32 64' },
  OPPBTP: { adress: 'OPPBTP\n1 rue Heyrault\n92660 - BOULOGNE BILLANCOURT', representative: '', contact: '01 46 09 27 00' }
} 