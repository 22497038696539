export const FETCH_CONTACT_BEGIN   = 'FETCH_CONTACT_BEGIN';
export const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS';
export const FETCH_CONTACT_FAILURE = 'FETCH_CONTACT_FAILURE';
export const CHANGE_MO_DETAILS = 'CHANGE_MO_DETAILS';

export function fetchContact() { 
    return dispatch => {
      dispatch(fetchContactBegin());
      return fetch("/api/contact/getAll")
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
          dispatch(fetchContactSuccess(json));
          return json;
        })
        .catch(error => dispatch(fetchContactFailure(error)));
    };
  }

  // Handle HTTP errors since fetch won't.
  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

export const fetchContactBegin = () => ({
  type: FETCH_CONTACT_BEGIN
});

export const fetchContactSuccess = contact => ({
  type: FETCH_CONTACT_SUCCESS,
  payload: { items:contact }
});

export const fetchContactFailure = error => ({
  type: FETCH_CONTACT_FAILURE,
  payload: { error }
});


export const changeMoDetails = moDetails => ({
  type: CHANGE_MO_DETAILS,
  payload: { moDetails }
});