export const maintenanceRecommendationsConst = [
  {
    trade: "V.R.D",
    workDescription: [
      "CANALISATION ET ASSAINISSEMENT\n- Visite des regards\n- Nettoiement des regards de prélèvement et des bacs dégraisseurs",
      "AERATION\n- Vérification de la propreté des prises d'air frais",
      "PLANTATIONS AUTOUR DES IMMEUBLES/Recommandations:\n- Eviter de planter des arbres gros consommateurs d'eau tels que peupliers, saules\n- Eviter de planter des arbres à racines longues (frêne)"
    ],
    frequency: ["1 an\n1 an", "2 ans", ""],
    interventionSheetNo: ["", "", ""],
  },
  {
    trade: "CHARPENTE",
    workDescription: [
      "- S'assurer qu'il n'y a pas d'attaque d'un insecte xylophage si la charpente est en bois résineux\nSi un traitement s'avère nécessaire, il convient de s'adresser à une entreprise spécialisée\n- Les parties de charpentes exposées aux intempéries doivent être entretenues comme les menuiseries extérieures"
    ],
    frequency: ["Pas d'entretien systématique mais surveillance"],
    interventionSheetNo: [""],
  },
  {
    trade: "COUVERTURE",
    workDescription: [ 
      "- S'assurer que les ouvertures de ventilation des combles ne sont pas obstruées remise en place et remplacement éventuel d'éléments de- remise en place et remplacement éventuel d'éléments de couverture (tuiles, ardoises, couvre-joints…)\n- Enlèvement des mousses et plus généralement de la végétation et des débris pouvant nuire au bon fonctionnement de la couverture\n- Maintien en bon fonctionnement des évacuations d'eaux pluviales (balayage des chéneaux, etc…)\n- Maintien en bon état des ouvrages accessoires tels que solins, souches de cheminées, etc.\n- Maintien en bon état des éléments du support de la couverture\n- Maintien de la ventilation de la sous-face des éléments de couverture\nLes travaux seront conformes à la norme C17.100.\n6.1.3.1 Précautions d'emploi -Contact entre matériaux\nAcier galvanisé Contacts interdits (sauf dispositif de fixation): * avec le cuivre * avec le bois de chêne et de châtaignier * avec le plâtre, sauf solins. Contacts tolérés : * mortier de ciment après son durcissement (sauf solins). Acier inoxydable Aucune interdiction Nota : Il est recommandé d'éviter le contact cuivre-acier inoxydable, en milieu marin."
    ],
    frequency: [
      "1 an\n6 mois\n6 mois\n1 an\n1 an\n1 an"
    ],
    interventionSheetNo: [""],
  },
  {
    trade: "TOITURES TERRASSES",
    workDescription: [
      "- Enlèvement des mousses et plus généralement de la végétation et des débris pouvant nuire au bon fonctionnement de la terrasse\n- Maintien en bon état de fonctionnement des évacuations d'eaux pluviales\n- Maintien en bon état des ouvrages accessoires tels que solins, souches de cheminées, larmiers, acrotères, lanterneaux, contre bardages, points d'ancrages divers (antennes, etc.)\n- Maintien à leur emplacement primitif des protections (gravillons, dallettes, etc.)\n- Contrôle de l'état général des relevés"
    ],
    frequency: [
      "6 mois\n6 mois et après tempête\n1 an par un professionnel\n1 an et après tempête\n1 an par un professionnel"
    ],
    interventionSheetNo: [""],
  },
  {
    trade: "DESENFUMAGE",
    workDescription: [
      "- Contrôle des connections et câblages ainsi que le déclencheur manuel et le treuil de commande\n- Test de fonctionnement\n- Nettoyage interne"
    ],
    frequency: [
      "1 an"
    ],
    interventionSheetNo: [""],
  },
  {
    trade: "MENUISERIES EXTERIEURES",
    workDescription: [
      "- Fenêtres, volets persiennes de toute nature : bois, alu, PVC\n- Nettoyage des gorges et peintures\n- Débouchage des trous de buée et de ventilation des feuillures\n- Vérification, réglage, graissage des articles de quincaillerie : paumelles, crémones, serrures, ferme-portes",
      "NOTA : certaines menuiseries extérieures sont munies de garnitures souples d’étanchéité. Il convient de ne pas les peindre, ni les vernir et de veiller à leur bon positionnement"
    ],
    frequency: [
      "1 an\n1 an\n1 an\n2 ans",
      "/"
    ],
    interventionSheetNo: ["", ""],
  },
  {
    trade: "MENUISERIES INTERIEURES",
    workDescription: [
      "- Vérification, réglage et garnissage des articles de quincaillerie paumelles, charnières, rails de portes coulissantes, serrures, ferme-portes",
      "NOTA : Certains articles de quincaillerie, galets par exemple, le plus souvent en matière plastique ou nylon, ne doivent pas être graissés."
    ],
    frequency: [
      "/",
      "/"
    ],
    interventionSheetNo: ["",""],
  },
  {
    trade: "PLOMBERIE / SANITAIRE / CHAUFFAGE / VMC",
    workDescription: [
      "DISTRIBUTION EAU FROIDE ET CHAUDE\n- Manœuvre des robinets d'arrêt et purgeurs\n- Manœuvre des groupes et soupapes de sécurité\n- Vérification des niveaux et de la pression",
      "ROBINETTERIE\n- Manœuvre des mélangeurs, mitigeurs thermostatiques, mécanismes de chasse et vidage d'appareils sanitaires",
      "CHAUFFE-EAU et CHAUDIERE A GAZ\n- Nettoyage corps de chauffe, vérification bloc gaz complet",
      "CHAUFFE-EAU ET CHAUDIERES ELECTRIQUES\n- Vérification bloc résistance et aquastat",
      "EVACUATION EAUX VANNES ET USÉES\n- Exécution chasses (regards et tuyauteries)\n- Pompes de relevage",
      "VMC\n- Centrale de traitement d’air / nettoyage des filtres\n- Changement des filtres"
    ],
    frequency: [
      "1 an\n1 mois",
      "1 an par un professionnel",
      "",
      "",
      "1 an\n1 an",
      "1 mois"
    ],
    interventionSheetNo: ["", "", "", "", "", ""],
  },
  {
    trade: "ELECTRICITE",
    workDescription: [
      "ECLAIRAGE\n- Changement des luminaires et ampoules\n- Changements des piles des BAES"
    ],
    frequency: [
      "1 an\n1 an"
    ],
    interventionSheetNo: [""],
  },
  {
    trade: "PEINTURE",
    workDescription: [
      "NOTA : à l'intérieur, seuls les intérêts esthétiques et de propreté peuvent décider de la périodicité des travaux à réaliser",
      "- Remise en peinture des crépis de façades\n- Vérification des ouvrages de serrurerie et traitement\n- Vérification de la qualité des solins de mastic",
      "MENUISERIES EXTERIEURES PEINTES\n- Vérification des surfaces particulières exposées (assemblages, rejets d'eau, petits bois…)\n- Remise en peinture",
      "MENUISERIES INTERIEURES VERNIES\n- Révision et traitement"
    ],
    frequency: [
      "",
      "5 à 7 ans\n1 à 2 ans\n4 ans",
      "2 ans\n5 ans",
      "2 ans"
    ],
    interventionSheetNo: ["", "", "", ""],
  }
];