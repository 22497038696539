import { CHANGE_DOSSIER_VALUE,
  CHANGE_TYPE_OUVRAGE_VALUE,
  CHANGE_COUT_TRAVAUX_VALUE,
  CHANGE_DUREE_TRAVAUX_VALUE,
  CHANGE_CATEGORIE_OPERATION_VALUE,
  CHANGE_NOMBRE_LOTS_VALUE,
  CHANGE_HAUTEUR_VALUE,
  CHANGE_COMPLEXITE_ARCHITECTURALE_VALUE,
  CHANGE_COMPLEXITE_ENVIRONNEMENTALE_VALUE,
  CHANGE_COMPLEXITE_TECHNIQUE_VALUE,
  CHANGE_REPETITIVITE_VALUE,
    } from '../actions/calcDevisActions';
const initialState = {
    dossier:"",
    ouvrage:"",
    coutTrav:"",
    dureeTrav:"",
    catOp:"",
    nbrLots:"",
    haut:"",
    compArchi:"",
    compEnv:"",
    compTech:"",
    rep:"",
  }
  
  // Use the initialState as a default value
  export default function calcDevisReducer(state = initialState, action) { 
    switch (action.type) {
      case CHANGE_DOSSIER_VALUE: {
        return {
          ...state,
          dossier:action.payload.dossier  
        }
      }
      case CHANGE_TYPE_OUVRAGE_VALUE:{
        return {
            ...state,
            ouvrage:action.payload.ouvrage  
          }
      }
      case CHANGE_COUT_TRAVAUX_VALUE:{
        return {
            ...state,
            coutTrav:action.payload.coutTrav  
          } 
      }
      case CHANGE_DUREE_TRAVAUX_VALUE:{
        return {
            ...state,
            dureeTrav:action.payload.dureeTrav  
          } 
      }
      case CHANGE_CATEGORIE_OPERATION_VALUE:{
        return {
            ...state,
            catOp:action.payload.catOp  
          }
      }
      case CHANGE_NOMBRE_LOTS_VALUE:{
        return {
            ...state,
            nbrLots:action.payload.nbrLots  
          }
      }
      case CHANGE_HAUTEUR_VALUE:{
        return {
            ...state,
            haut:action.payload.haut  
          } 
      }
      case CHANGE_COMPLEXITE_ARCHITECTURALE_VALUE:{
        return {
            ...state,
            compArchi:action.payload.compArchi  
          } 
      }
      case CHANGE_COMPLEXITE_ENVIRONNEMENTALE_VALUE:{
        return {
            ...state,
            compEnv:action.payload.compEnv  
          } 
      }
      case CHANGE_COMPLEXITE_TECHNIQUE_VALUE:{
        return {
            ...state,
            compTech:action.payload.compTech  
          } 
      }
      case CHANGE_REPETITIVITE_VALUE:{
        return {
            ...state,
            rep:action.payload.rep  
          } 
      }
      default:
        return state
    }
  }