import authHeader from "../../Components/Utils";
export const FETCH_NOTIF_BEGIN   = 'FETCH_NOTIF_BEGIN';
export const FETCH_NOTIF_SUCCESS = 'FETCH_NOTIF_SUCCESS';
export const FETCH_NOTIF_FAILURE = 'FETCH_NOTIF_FAILURE';

export function fetchNotifs() { 
    return dispatch => {
      dispatch(fetchNotifBegin());
      return fetch("/api/dashboardData",{headers:authHeader()})
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
          dispatch(fetchNotifSuccess(json.notif));
          return json;
        })
        .catch(error => dispatch(fetchNotifFailure(error)));
    };
  }

  // Handle HTTP errors since fetch won't.
  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

export const fetchNotifBegin = () => ({
  type: FETCH_NOTIF_BEGIN
});

export const fetchNotifSuccess = notif => ({
  type: FETCH_NOTIF_SUCCESS,
  payload: { notif }
});

export const fetchNotifFailure = error => ({
  type: FETCH_NOTIF_FAILURE,
  payload: { error }
});
