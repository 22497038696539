import React from "react";
import authHeader from "../Utils";
const movingWrapperStyles = {
  position: "relative",
  top: "0px",
  display: "inline-block",
};
class ScrollingText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cec: [],
      animationState: true,
      steps: 0,
      actualTop: 0,
    };
    this.movingRef = React.createRef();
    this.animation = null;
  }

  componentDidMount() {
    fetch("/api/dashboardData", { 
      headers: authHeader(),
    })
      .then((res) => res.json())
      .then(
        (res) => this.setState({ cec: res.cec }),
        // console.log(this.state.cec)
      )
      .catch((error) => console.error("Error fetching data:", error));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.cec !== prevState.cec && this.state.animationState) {
      this.moveText();
    }
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.animation);
  }
  moveText = () => {
    if (this.steps === this.state.cec.length) {
      // 1
      this.movingRef.current.style.top = 0;
      this.actualTop = 0;
      this.steps = 0;
    }
    if (this.inc === 13) {
      this.inc = 0;
      this.steps += 1; // 2
      window.cancelAnimationFrame(this.animation);
      this.repeat = window.setTimeout(this.moveText);
      //this.setState({cec:this.getCEC()})
      return;
    }
    const newTop = this.actualTop - 1;
    //this.movingRef.current.style.top = newTop.toString() + 'px'
    this.inc += 1; //6
    this.actualTop = newTop;
    setTimeout(
      function () {
        this.animation = window.requestAnimationFrame(this.moveText);
      }.bind(this),
      40
    );
  };
  getCEC = () => {
    if (this.state.cec.length === 0) {
      return this.state.cec;
    } else {
      const oldCEC = this.state.cec;
      const newCEC = oldCEC;
      if (newCEC.length > 200) {
        return newCEC;
      } else {
        Array.prototype.push.apply(newCEC, oldCEC);
        Array.prototype.push.apply(newCEC, oldCEC);
        // iterator++
        return newCEC;
      }
    }
  };
  stopAnimation() {
    this.setState({ animationState: false });
  }
  restartAnimation() {
    this.setState({ animationState: true });
  }
  render() {
    return (
      <div /*onMouseEnter={this.stopAnimation} onMouseLeave={this.restartAnimation}*/
      >
        <ul
          className="dossiers"
          style={movingWrapperStyles}
          ref={this.movingRef}
        >
          {this.getCEC().map((cec, index) => (
            <li key={"list-" + index}>
              <span className="iddossier">Dossier {cec.iddossier}</span>
              <span className="item">{cec.item}</span>
              <span className="datedeb">{cec.datedebut.substring(0, 10)}</span>
              <span className="datefin">{cec.datefin.substring(0, 10)}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
export default ScrollingText;
