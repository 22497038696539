/**
 * Renvoie les données du dernier devis réalisation validé
 * @param {[]} devisrea 
 * @param {number} dossierValue 
 * @returns 
 */
export const getDevisRea = (devisrea, dossierValue) => {
  var arr = []
  for (let i = 0; i < devisrea.length; i++) {
    if (devisrea[i].ndossier == dossierValue && devisrea[i].validation > 0) {
      arr.push(devisrea[i])
    }
  }
  var max = Math.max.apply(Math, arr.map(item => item.validation))
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].validation == max) {
      return arr[i]
    }
  }
  return ""
}

/**
 * Renvoie les données du dernier devis conception validé
 * @param {[]} deviscon 
 * @param {number} dossierValue 
 * @returns 
 */
export const getDevisCon = (deviscon, dossierValue) => { 
  var arr = []
  for (let i = 0; i < deviscon.length; i++) {
    if (deviscon[i].ndossier == dossierValue && deviscon[i].validation > 0) {
      arr.push(deviscon[i])
    }
  }
  var max = Math.max.apply(Math, arr.map(item => item.validation))
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].validation == max) {
      return arr[i]
    }
  }
  return ""
}

/**
 * Renvoie le dossier selectionné par l'utilisateur
 * @param {[]} dossier 
 * @param {number} dossierValue 
 * @returns 
 */
export const getDossier = (dossier, dossierValue) => {
  for (let i = 0; i < dossier.length; i++) {
    if (dossier[i].ndossier == dossierValue) {
      return dossier[i]
    }
  }
  return ""
}

/**
 * Renvoie le nombre d'échéances et leur fréquences pour la partie réalisation du dossier choisi
 * @param {[]} devisrea 
 * @param {[]} dossier 
 * @param {number} dossierValue 
 * @returns 
 */
export const getEchNum = (devisrea, dossier, dossierValue) => {
  if (getDevisRea(devisrea, dossierValue).duree < 6 && getDossier(dossier, dossierValue).domaine == "Privé") {
    return getDevisRea(devisrea, dossierValue).duree + " échéances Mensuelles"
  }
  else if (getDevisRea(devisrea, dossierValue).duree >= 6 && getDossier(dossier, dossierValue).domaine == "Privé") {
    return Math.ceil(getDevisRea(devisrea, dossierValue).duree / 2) + " échéances Bimensuelles"
  }
  else {
    return Math.ceil(getDevisRea(devisrea, dossierValue).duree / 3) + " échéances Trimensuelles"
  }
}

/**
 * Renvoie la durée de l'opération, le nombre de mois entre les échéances ainsi que le nombre de jours entre les échéances
 * @param {[]} devisrea 
 * @param {[]} dossier 
 * @param {number} dossierValue 
 * @returns 
 */
export const getEchNum2 = (devisrea, dossier, dossierValue) => {
  if (getDevisRea(devisrea, dossierValue).duree < 6 && getDossier(dossier, dossierValue).domaine == "Privé") {
    return [getDevisRea(devisrea, dossierValue).duree, 1, 30]
  }
  else if (getDevisRea(devisrea, dossierValue).duree >= 6 && getDossier(dossier, dossierValue).domaine == "Privé") {
    return [getDevisRea(devisrea, dossierValue).duree, 2, 60]
  }
  else {
    return [getDevisRea(devisrea, dossierValue).duree, 1.5, 45]
  }
}

/**
 * Renvoie les dates des échéances du dossier choisi
 * @param {[]} echeances 
 * @param {number} dossierValue 
 * @returns 
 */
export const getEch = (echeances, dossierValue) => {
  for (let i = 0; i < echeances.length; i++) {
    if (echeances[i].ndossier == dossierValue) {
      return echeances[i]
    }
  }
  return ""
}


export function contactExists(contactName, contacts) {
  for (let i = 0; i < contacts.length; i++) {
    if (contactName == contacts[i].nom) {
      return true
    }
  }
  return false
}


export function getContactInfo(contacts, idcontact) {
  for (let i = 0; i < contacts.length; i++) {
    if (idcontact == contacts[i].idcontact) {
      return contacts[i]
    }
  }
}

export const bred = {
  name: "BRED BANQUE POPULAIRE",
  code_bank: "10107",
  code_guichet: "00237",
  code_bic: "BREDFRPPXXX",
  cle: "42",
  num: "00529016611",
};

export const ca = {
  name: "CREDIT AGRICOLE",
  code_bank: "18706",
  code_guichet: "00000",
  code_bic: "AGRIFRPP887",
  cle: "32",
  num: "97521129119",
};