import React from "react";
import { connect } from "react-redux"
import { Button,Popper } from "@material-ui/core";
class PopperLevels extends React.Component{
    constructor(props){
        super(props)
        this.state={
        }
    }
    render(){
        return(
            <Popper
                open={this.props.open}
                anchorEl={this.props.anchorEl}
                placement={"right"}
                >
                <div ref={this.props.levelsWrapper} className="popover-levels">
                    <Button variant="contained" style={{backgroundColor:"green"}}
                     onClick={() => this.props.handleLevelClick("yes",this.props.anchorEl.name)}>
                        OUI
                    </Button>
                    <Button variant="contained" style={{backgroundColor:"red"}}
                     onClick={() => this.props.handleLevelClick("no",this.props.anchorEl.name)}>
                        NON
                    </Button>
                </div>
            </Popper>
        )
    }
}
const mapStateToProps = state => ({ 
	dossierValue: state.devis.dossierValue,
	dossier : state.dossier.items,
  });
  export default connect(mapStateToProps)(PopperLevels);