import React from 'react';
import { Button } from '@material-ui/core';
import "../Chatbot/Chatbot.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import history from '../Routes/history';
import "../Chatbot/Chatbot.scss";
import Header from '../Header/Header';


//import { Button } from '@material-ui/core';

class Chatbott extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    clickToChat = () => {
        history.push("/Chat");

    } 
    render() {
        return (
            <div>
                <Header />

                <div onClick={this.clickToChat} className="message-bot-container">
                    <div className="message-bot">
                        <div className="title-chat">
                            <p> Besoin d'aide ? </p >
                        </div>
                        <span ><FontAwesomeIcon icon={faRobot} /></span>

                    </div>
                </div>
            </div>

        );
    }
}
export default Chatbott; 