export const constructionSiteConst = {
  operation: '',
  category: '',
  adress: '',
  cp: '',
  city: ''
};

export const intervenantsCont = {
  projectOwner: { name: '', complement: '', adress: '', cp: '', city: '', mail: '', tel: '' },
  projectManager: { name: '', complement: '', adress: '', cp: '', city: '', mail: '', tel: '' },
  coordoCon: { name: 'COORDOGEC', complement: 'Monsieur Jean-Marie SAINT-GERMAIN', adress: '4 rue des Artisans', cp: '93160', city: 'NOISY LE GRAND', mail: 'coordogec@coordogec.fr', tel: '01 43 03 63 17 – 06 80 68 14 82' },
  coordoRea: { name: 'COORDOGEC', complement: 'Monsieur Jean-Marie SAINT-GERMAIN', adress: '4 rue des Artisans', cp: '93160', city: 'NOISY LE GRAND', mail: 'coordogec@coordogec.fr', tel: '01 43 03 63 17 – 06 80 68 14 82' }
} 