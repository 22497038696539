import React from "react";
import BaseTable, { Column } from "react-base-table";
import "react-base-table/styles.css";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { connect } from "react-redux";
import { fetchRjFrCategories} from "../../redux/actions/rjActions"

class RjEmploymentLawTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() { 
    this.props.dispatch(fetchRjFrCategories())
  }

  render() {
    const data = this.props.listFactors.length > 0 ? this.props.listFactors.slice(1) : [];
    const column = [
      {
        key: "num-cat",
        dataKey: "num-cat",
        width: 60,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <p key={`num-cat-${rowIndex}`}>{rowIndex+1}</p>
        ),
      },
      {
        title: "Facteur de risque",
        key: "name",
        dataKey: "name",
        width: 450,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <div key={`name-${rowIndex}`}>{rowData.name}</div>
        ),
      },
      {
        key: "edit",
        width: 60,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <IconButton
            key={`edit-${rowIndex}`}
            className="edit-employment-law"
            aria-label="edit-employment-law"
            size="small"
            style={{ backgroundColor: "transparent" }}
            onClick={() => this.props.getFactorInfos(rowData)}
          >
            <EditIcon />
          </IconButton>
        ),
      },
      {
        key: "del",
        width: 60,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <IconButton
            key={`del-${rowIndex}`}
            className="del-employment-law"
            aria-label="del-employment-law"
            size="small"
            style={{ backgroundColor: "transparent" }}
            onClick={() => this.props.handleClickOpenDel(rowData)}
          >
            <Delete />
          </IconButton>
        ),
      },
    ];
    return (
      <div className="display-categories">
        <BaseTable
          columns={column}
          width={630}
          headerHeight={50}
          rowHeight={50}
          height={data.length * 50 + 50}
          data={data}
          rowStyle={{
            height: "50px",
          }}
        ></BaseTable>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  listFactors: state.rj.listFactors,
});
export default connect(mapStateToProps)(RjEmploymentLawTable);
