import { FETCH_USER_BEGIN, FETCH_USER_SUCCESS, FETCH_USER_FAILURE} from '../actions/userActions';
const initialState = {
    items: [],
    loading:false,
    error:null
  }
  
  // Use the initialState as a default value
  export default function userReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_USER_BEGIN: {
        return {
          ...state,
          loading:true,
          error:null  
        }
      }
      case FETCH_USER_SUCCESS:{ 
          return {
              ...state,
              loading:false,
              items: action.payload.user,
          }
      }
      case FETCH_USER_FAILURE:{
        return {
            ...state,
            loading: false,
            error: action.payload.error,
            items: []
          }; 
      }
      default:
        return state
    }
  }