import React from 'react';
import { connect } from "react-redux";
import { Button, TextField, InputAdornment } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CircularProgress from "@material-ui/core/CircularProgress";
import '../../Styles/Mail/MailParams.scss';
class MailParams extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showPass: false,
            attachment: null,
            showPassword: false,
        }
    }

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    }; 
    render() {
        const { showPassword } = this.state;

        return (
            <div className={"container"}>
                <div className={"new-msg"}>
                    <TextField
                        className={"text-field"}
                        label="Expéditeur"
                        variant="outlined"
                        type="email"
                        size="small"
                        value={this.props.sender} name="sender"
                        onChange={event => this.props.handleChange(event)}
                    />
                    <TextField
                        className={"text-field"}
                        label="Mot de passe"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        size="small"
                        name="password"
                        value={this.props.password}
                        onChange={event => this.props.handleChange(event)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        className={"password-toggle-btn"}
                                        aria-label="toggle password visibility"
                                        onClick={this.togglePasswordVisibility}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        className={"text-field"}
                        label="Destinataire"
                        variant="outlined"
                        type="email"
                        size="small"
                        name="to"
                        value={this.props.to}
                        onChange={event => this.props.handleChange(event)}
                        required
                    />
                    <TextField
                        className={"text-field"}
                        label="Cc"
                        variant="outlined"
                        type="email"
                        size="small"
                        name='cc'
                        value={this.props.cc}
                        onChange={event => this.props.handleChange(event)}
                    />
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <TextField
                            className={"text-field"}
                            label="Cci"
                            variant="outlined"
                            type="email"
                            size="small"
                            name='cci'
                            value={this.props.cci}
                            onChange={event => this.props.handleChange(event)}
                        />
                        <span style={{ color: "gray", marginLeft: "10px" }}>Veuillez séparer vos destinataires par une virgule</span>
                    </div>
                    <TextField
                        className={"text-field"}
                        label="Objet"
                        variant="outlined"
                        size="small"
                        name="subject"
                        value={this.props.subject}
                        onChange={event => this.props.handleChange(event)}
                    />
                    <TextField
                        className={"msg-field"}
                        label="Message"
                        variant="outlined"
                        multiline
                        rows={10}
                        name="text"
                        value={this.props.text}
                        onChange={event => this.props.handleChange(event)}
                    />
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <TextField
                            className={"text-field"}
                            label="Nom du fichier à envoyer"
                            variant="outlined"
                            size="small"
                            name="filename"
                            value={this.props.filename}
                            InputProps={{
                                endAdornment: (
                                    this.props.attachment !== null ? <AttachFile /> : null
                                ),
                            }}
                            onChange={event => this.props.handleChange(event)}
                            required
                        />
                        <div className='group-btn'>
                            {this.props.disableButton && (
                                <CircularProgress className="spinner" color="inherit" />
                            )}
                            <Button disabled={this.props.filename === "" || this.props.to === "" || this.props.sender === "" || this.props.password === "" || this.props.disableButton} variant="contained" onClick={this.props.handleOpenDialog}> Envoyer</Button>
                            <Button variant="contained" onClick={this.props.showPDF} disabled={this.props.typeFile !== "Pdf" || this.props.disableButton}> Afficher Doc</Button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    attachment: state.email.file,
    typeFile: state.email.typefile
});
export default connect(mapStateToProps)(MailParams)