import React, { useState, useEffect } from 'react';
import '../Styles/Notifications.scss';
import { connect } from "react-redux";
import axios from "axios";
import check from "../Styles/Pictures/check.png";
import "../Styles/GererNotif.scss";
import Chatbott from './Dashboard/Chatbot.js';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from './Header/Header';
import { convertDate, verifNotifType, verifNotifIcon } from './Utils';
import history from './Routes/history';
import Button from '@material-ui/core/Button';
import "../Styles/GererNotif.scss";
import { useParams } from 'react-router-dom'

function GererNotif() { 
    const [data, setData] = useState([]);
    const { idnotif } = useParams()

    useEffect(() => {
        axios.get('http://localhost:3000/all/notifMesssages',)
            .then((res) => {
                setData(res.data)
                // console.log('toutes les notifications sont bonnes ');
            })
            .catch((err) => console.log(err));
    }, []);


    /*handleMessage(event) {
        axios.get("/notifMesssage", { message: this.state.message })
            .then((res) => {
                console.log('/notifMesssage');
            })
        //event.preventDefault();
    }
    */


    return (
        <>
            <div >
                <Header />
                <div className='handleNotifs' style={{}} >
                    {data.map((notif, index) => {
                        return <div key={index}>
                            <div className='notif'>
                                <div className='index'>{index + 1}</div>
                                <div className='notifTitle' style={{ color: "green" }}> {notif.type}</div>
                                <h4 className='' style={{ padding: "1px" }}>Envoyé par [ {notif.sender} ]</h4>
                                <div className='messagee' style={{ color: "black", fontFamily: "cursive", padding: "5px" }}>Message : {notif.message}</div>
                                <div className='date' style={{ padding: "5px" }}>Fait le {convertDate(notif.date)}</div>
                                <div className='heure' style={{ padding: "5px" }}>à {notif.heure}</div>
                                <span>{notif.vu == 1 ? <img className="" style={{ width: "50px" }} src={check} alt=""></img> : ""}</span>

                                <FontAwesomeIcon icon={verifNotifIcon(notif.type_notif)} />
                                <span> {notif.type == "Relance" ? <Button onClick={() => (history.push("/devis/pdf/mail"))}
                                    className="demandeButton">Répondre à la demande</Button> : ""}</span>
                                <span> {notif.type == "information" ? <Button onClick={() => (history.push("/devis/pdf/mail"))}
                                    className="demandeButton">Répondre à la demande</Button> : ""}</span>
                                <span> {notif.type == "Demande" ? <Button onClick={() => (history.push("/devis/pdf/mail"))}
                                    className="demandeButton">Répondre à la demande</Button> : ""}</span>

                            </div>
                        </div>

                    })}
                    {/* <Button style={{ backgroundColor: '#ff8c69', color: '#FFFFFF' }} className='buttonmail'
                        variant="contained" color="primary" onClick={() => (history.push("/devis/pdf/mail"))}>Répondre par mail</Button> */}
                </div>
            </div>
            {/* <div className="chat" style={{ width: "220px" }}>
                    <Chatbott />
                </div> */}

        </>

    )

}


const mapStateToProps = state => ({
    notifs: state.notif.items
});
export default connect(mapStateToProps)(GererNotif);


