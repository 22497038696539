export const titlesRealisation = [
  "Inspections Communes avec les entreprises",
  "Harmonisation des P.P.S.P.S./Mise à jour du P.G.C",
  "Tenue Registre Journal",
  "Visites de chantier (Nombre de visite)",
  "Réunions de C.I.S.S.C.T",
  "Participation au rendez-vous de chantier",
  "Récolement des pièces liées au D.I.U.O",
  "Mise à jour du D.I.U.O final",
  "Diffusion du P.G.C et du D.I.U.O"
]

export const bred = {
  name: "BRED BANQUE POPULAIRE",
  code_bank: "10107",
  code_guichet: "00237",
  code_bic: "BREDFRPPXXX",
  cle: "42",
  num: "00529016611",
};

export const ca = {
  name: "CREDIT AGRICOLE",
  code_bank: "18706",
  code_guichet: "00000",
  code_bic: "AGRIFRPP887",
  cle: "32",
  num: "97521129119",
}; 

export const getDossier = (dossier, dossierValue) => {
  const foundDossier = dossier.find(item => item.ndossier == dossierValue);

  return foundDossier || null;
}

export function getContactInfos(contacts, idcontact) {
  return contacts.find(contact => contact.idcontact === idcontact) || null;
}

export function getContactAmendmentInfos(amendments, dossierValue, contactAmendmentIndex) {
  const foundAmendment = amendments.find(item => item.ndossier == dossierValue && item.amendment_number == contactAmendmentIndex);
  return { ...foundAmendment } || null
}

export function getLastContractAmendmentInfos(amendments, dossierValue) {
  const foundAmendment = amendments.filter(item => item.ndossier == dossierValue).sort((a, b) => b.amendment_number - a.amendment_number);
  return { ...foundAmendment[0] } || null
}

export function getLastInfoEcheance(echeances, dossierValue) {
  const dossierItems = echeances.filter(item => item.ndossier === dossierValue);

  if (dossierItems.length === 0) {
    return null;
  }

  dossierItems.sort((a, b) => b.amendment - a.amendment);

  const payment_method = dossierItems[0].payment_method;
  const datesArray = JSON.parse(dossierItems[0].dates);
  if (datesArray.length === 0) {
    return null;
  }

  datesArray.sort((a, b) => new Date(b.dateech) - new Date(a.dateech));

  const lastDateRea = datesArray[0].dateech;
  return { lastDateRea, payment_method };
}

export function getTauxhRea(devisRea, dossierValue) {
  const foundDevis = devisRea.filter(item => item.ndossier == dossierValue && item.validation == 1);
  if (foundDevis.length === 0) {
    return null;
  }

  foundDevis.sort((a, b) => b.nprop - a.nprop);
  return foundDevis[0].tauxh;
}

export function getNbAmendments(amendments, dossierValue) {
  const foundAmendment = amendments.filter(item => item.ndossier == dossierValue);
  return foundAmendment ? foundAmendment.length : 0
}

export function getDevisRea(devisRea, dossierValue) {
  const filteredDevis = devisRea.filter(item => item.ndossier == dossierValue && item.validation == 1)
  if (filteredDevis.length === 0) {
    return null;
  }

  filteredDevis.sort((a, b) => b.nprop - a.nprop);
  return filteredDevis[0];
}

export function formatDate(inputDate) {
  if (inputDate === null || inputDate === "" || inputDate === undefined) {
    return "";
  }
  const parts = inputDate.split('-');
  const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
  return formattedDate;
}

export const genContractFacAmendment = async (requestOptions) => {
  return await fetch("/api/facamendment/genContract", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const getContractFacAmendment = async (dossierValue, contractFacAmendmentIndex, type) => {
  try {
    const res = await fetch("/api/facamendment/getContract", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, amendment_index: contractFacAmendmentIndex, type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const genFacAmendment = async (requestOptions) => {
  return await fetch("/api/facamendment/genFacAmendment", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const getFacAmendment = async (dossierValue, customerCode, type) => {
  try {
    const res = await fetch("/api/facamendment/getFacAmendment", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, customerCode, type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const genFacAmendmentRecap = async (requestOptions) => {
  return await fetch("/api/facamendment/genFacAmendmentRecap", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const getFacAmendmentRecap = async (dossierValue, refFacture, type) => {
  try {
    const res = await fetch("/api/facamendment/getFacAmendmentRecap", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, refFacture, type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const genFacAmendmentReminder = async (requestOptions) => {
  return await fetch("/api/facamendment/genFacAmendmentReminder", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const getFacAmendmentReminder = async (dossierValue, firstReminder, refReminder, type) => {
  try {
    const res = await fetch("/api/facamendment/getFacAmendmentReminder", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, first_reminder: firstReminder, ref_reminder: refReminder, type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const genFacAmendmentReminderTable = async (requestOptions) => {
  return await fetch("/api/facamendment/genFacAmendmentReminderTable", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};



export const getFacAmendmentReminderTable = async (dossierValue) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({ ndossier: dossierValue }),
  };
  return await fetch("/api/facamendment/getFacAmendmentReminderTable", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};
export const getRefFacture = (inputString) => {
  const match = inputString.match(/AR\d+/);
  return match[0]
}

export const genFacAmendmentTable = async (requestOptions) => {
  return await fetch("/api/facamendment/genFacAmendmentTable", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const getFacAmendmentTable = async (dossierValue, type) => {
  try {
    const res = await fetch("/api/facamendment/getFacAmendmentTable", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier: dossierValue, type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const genSyntheseTableAvenant = async (requestOptions) => {
  return await fetch("/api/facamendment/genSyntheseTableAvenant", requestOptions)
    .then((res) => res.blob())
    .catch((err) => {
      console.log(err);
    });
};

export const getSyntheseTableAvenant = async (type) => {
  try {
    const res = await fetch("/api/facamendment/getSyntheseTableAvenant", {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ type }),
    });
    const blob = await res.blob();
    const fileName = res.headers.get('X-FileName');
    return { blob, fileName };
  } catch (err) {
    console.log(err);
    return null;
  }
};