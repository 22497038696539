import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import LoadingOverlay from "../../LoadingOverlay";
import 'moment/locale/fr';
import Header from "../../Header/Header";
import NumsDoss from "../../NumsDoss";
import AppointmentForm from './AppointmentForm';
import AppointmentList from './AppointmentList';
import { appointmentConst } from './Const';
import InformationDialog from '../../InformationDialog';
import YesNoDialog from '../../YesNoDialog';
import { getDossierInfos, getAppointments, addAppointment, updateAppointment, deleteAppointment } from '../Utils';
import authHeader from '../../Utils';
import history from '../../Routes/history';
import "../../../Styles/Calendar/Appointment.scss";
import EmailEditor from './EmailEditor';
import ConfirmationAppointment from '../ConfirmationAppointment';

const Appointment = () => { 
  const dossierValue = useSelector((state) => state.devis.dossierValue);

  const [sendEmail, setSendEmail] = useState(false);
  const [dossierInfos, setDossierInfos] = useState({});
  const [intervenant, setIntervenant] = useState(null);
  const [userId, setUserId] = useState(null);
  const [newAppointment, setNewAppointment] = useState(appointmentConst);
  const [deleteId, setDeleteId] = useState(null);
  const [isAdd, setIsAdd] = useState(true);
  const [isConfirmationAppointment, setIsConfirmationAppointment] = useState(false);

  const [lotList, setLotList] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDelDialog, setOpenDelDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc');

  const handleGetAllLots = async () => {
    setIsLoading(true);
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const res = await fetch("/api/rj/getAllLots", requestOptions);
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const data = await res.json();
      setLotList(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des lots:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    const user = authHeader();
    if (user['x-access-token'] === undefined) {
      history.push('/login');
      return;
    }
    setUserId(user.id);

    if (!dossierValue) return;
    if (lotList.length === 0) await handleGetAllLots();

    const result = await getAppointments(dossierValue, user.id);
    setAppointments(result);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [dossierValue, lotList.length]);

  useEffect(() => {
    const fetchData = async () => {
      if (!dossierValue) return;
      setIsLoading(true);

      try {
        const result = await getDossierInfos(dossierValue);
        setDossierInfos(result);
      } catch (error) {
        console.error("Erreur dans useEffect:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dossierValue]);


  const handleSortAppointments = (option) => {
    let sortedAppointments = [];

    if (option === 'appointment') {
      sortedAppointments = [...appointments].sort((a, b) => {
        if (!a.appointment_start && !b.appointment_start) {
          return 0;
        }
        if (!a.appointment_start) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        if (!b.appointment_start) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        return sortOrder === 'asc'
          ? new Date(a.appointment_start) - new Date(b.appointment_start)
          : new Date(b.appointment_start) - new Date(a.appointment_start);
      });
    } else {
      sortedAppointments = [...appointments].sort((a, b) => {
        if (!a.intervention_date && !b.intervention_date) {
          return 0;
        }
        if (!a.intervention_date) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        if (!b.intervention_date) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        return sortOrder === 'asc'
          ? new Date(a.intervention_date) - new Date(b.intervention_date)
          : new Date(b.intervention_date) - new Date(a.intervention_date);
      });
    }
    setAppointments(sortedAppointments);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const extractEmails = (inputString) => {
    const emailRegex = /[\w.-]+@[\w-]+(?:\.[\w-]{2,})+/g;
    const emails = inputString.match(emailRegex);
  
    if (emails) {
      return emails.join(", ");
    } else {
      return "";
    }
  }

  const handleAddAppointment = async () => {
    const { type } = newAppointment;
    if (!type) {
      setOpenDialog(true);
      return
    }
    const newAppointmentFormatedMails = {
      ...newAppointment,
      mail: extractEmails(newAppointment.mail)
    }

    setIsLoading(true);
    await addAppointment(dossierValue, newAppointmentFormatedMails, userId);
    const result = await getAppointments(dossierValue, userId);
    setAppointments(result);
    setNewAppointment(appointmentConst)
    setIsLoading(false);
  };

  const handleUpdateAppointment = async () => {
    const { type } = newAppointment;
    if (!type) {
      setOpenDialog(true);
      return
    }
    const newAppointmentFormatedMails = {
      ...newAppointment,
      mail: extractEmails(newAppointment.mail)
    }
    setIsLoading(true);
    await updateAppointment(newAppointmentFormatedMails);
    const result = await getAppointments(dossierValue, userId);
    setAppointments(result);
    setNewAppointment(appointmentConst);
    setIsAdd(true);
    setIsLoading(false);
  };

  const handleDeleteAppointment = async () => {
    setOpenDelDialog(false);
    setIsLoading(true);
    await deleteAppointment(deleteId);
    const result = await getAppointments(dossierValue, userId);
    setAppointments(result);
    await setIsLoading(false);
  }

  const handleCloseOpenDialog = () => setOpenDialog(false)
  const handleCloseOpenDelDialog = () => {
    setDeleteId(null);
    setOpenDelDialog(false)
  }

  return (
    <div className="appointment">
      {isLoading && <LoadingOverlay />}
      <Header />
      <h1 className="title">
        {sendEmail ? isConfirmationAppointment ? 'Envoi de la confirmation du rendez-vous' : 'Envoi de mail' : 'Tableau des intervenants'}
      </h1>
      {!sendEmail && <NumsDoss />}
      <div className="container">
        {!sendEmail ?
          <>
            <AppointmentForm
              handleAddAppointment={handleAddAppointment}
              handleUpdateAppointment={handleUpdateAppointment}
              isAdd={isAdd}
              newAppointment={newAppointment}
              setNewAppointment={setNewAppointment}
              lotList={lotList}
              setIsAdd={setIsAdd}
            />
            <AppointmentList
              appointments={appointments}
              setOpenDelDialog={setOpenDelDialog}
              setIsAdd={setIsAdd}
              setDeleteId={setDeleteId}
              setNewAppointment={setNewAppointment}
              handleSortAppointments={handleSortAppointments}
              setIntervenant={setIntervenant}
              setIsConfirmationAppointment={setIsConfirmationAppointment}
              setSendEmail={setSendEmail}
            />
          </> : isConfirmationAppointment ?
            <ConfirmationAppointment
              fetchData={fetchData}
              setIsLoading={setIsLoading}
              intervenant={intervenant}
              dossierInfos={dossierInfos}
              setIsConfirmationAppointment={setIsConfirmationAppointment}
              isConfirmationAppointment={isConfirmationAppointment}
              setSendEmail={setSendEmail}
            /> :
            <EmailEditor
              fetchData={fetchData}
              setIsLoading={setIsLoading}
              intervenant={intervenant}
              dossierInfos={dossierInfos}
              setSendEmail={setSendEmail}
            />}

      </div>
      <InformationDialog
        isOpen={openDialog}
        title={'INFORMATION'}
        information={'Veuillez remplir tous les champs'}
        handleClose={handleCloseOpenDialog}
      />
      <YesNoDialog
        isOpen={openDelDialog}
        title={"SUPPRESSION DE L'ITERVENANT"}
        question={`Êtes-vous sûr de vouloir supprimer cet itervenant ?`}
        handleYesClick={handleDeleteAppointment}
        handleClose={handleCloseOpenDelDialog}
      />
    </div>
  );
};

export default Appointment;
