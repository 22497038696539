import authHeader from "../../Components/Utils";
export const FETCH_VIC_BEGIN = 'FETCH_VIC_BEGIN';
export const FETCH_VIC_SUCCESS = 'FETCH_VIC_SUCCESS';
export const FETCH_VIC_FAILURE = 'FETCH_VIC_FAILURE';
export const FETCH_VICS_BEGIN = 'FETCH_VICS_BEGIN';
export const FETCH_VICS_SUCCESS = 'FETCH_VICS_SUCCESS';
export const FETCH_VICS_FAILURE = 'FETCH_VICS_FAILURE';
export const FETCH_COMPANIES_BEGIN = 'FETCH_COMPANIES_BEGIN';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';
export const FETCH_LOTS_BEGIN = 'FETCH_LOTS_BEGIN';
export const FETCH_LOTS_SUCCESS = 'FETCH_LOTS_SUCCESS';
export const FETCH_LOTS_FAILURE = 'FETCH_LOTS_FAILURE';
export const FETCH_ALLLOTS_BEGIN = 'FETCH_ALLLLOTS_BEGIN';
export const FETCH_ALLLOTS_SUCCESS = 'FETCH_ALLLLOTS_SUCCESS';
export const FETCH_ALLLOTS_FAILURE = 'FETCH_ALLLLOTS_FAILURE';
export const FETCH_ALLLOTITEMS_BEGIN = 'FETCH_ALLLLOTITEMS_BEGIN';
export const FETCH_ALLLOTITEMS_SUCCESS = 'FETCH_ALLLLOTITEMS_SUCCESS';
export const FETCH_ALLLOTITEMS_FAILURE = 'FETCH_ALLLLOTITEMS_FAILURE';
export const CHANGE_LOT_VALUE = 'CHANGE_LOT_VALUE';
export const CHANGE_LOTS_VALUE = 'CHANGE_LOTS_VALUE';
export const CHANGE_VIC_VALUE = 'CHANGE_VIC_VALUE';
export const CHANGE_CONVENTION_VALUE = 'CHANGE_CONVENTION_VALUE';

export function fetchVIC(dossierValue) { 
  return dispatch => {
    dispatch(fetchVicBegin());
    return fetch(`/api/vic/getAll?ndossier=${dossierValue}`)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchVicSuccess(json));
        return json;
      })
      .catch(error => dispatch(fetchVicFailure(error)));
  };
}

export function fetchLots() {
  return dispatch => {
    dispatch(fetchLotsBegin());
    return fetch("/api/vic/lots")
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchLotsSuccess(json));
        return json;
      })
      .catch(error => dispatch(fetchLotsFailure(error)));
  };
}

export function fetchAllLots() {
  return dispatch => {
    dispatch(fetchAllLotsBegin());
    return fetch("/api/vic/allLots")
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchAllLotsSuccess(json));
        return json;
      })
      .catch(error => dispatch(fetchAllLotsFailure(error)));
  };
}

export function fetchAllLotItems() {
  return dispatch => {
    dispatch(fetchAllLotItemsBegin());
    return fetch("/api/vic/allLots")
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        const formatedJson = json.map(item => {
          const { questions, ...rest } = item;
          return rest;
        })
        dispatch(fetchAllLotItemsSuccess(formatedJson));
        return formatedJson;
      })
      .catch(error => dispatch(fetchAllLotItemsFailure(error)));
  };
}

export function fetchVics() {
  return dispatch => {
    dispatch(fetchVicsBegin());
    return fetch("/api/vic/getAllVics")
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchVicsSuccess(json));
        return json;
      })
      .catch(error => dispatch(fetchVicsFailure(error)));
  }
}

export function fetchCompaniesByDossierLot(dossierValue, lotsValue) {
  return dispatch => {
    dispatch(fetchCompaniesBegin());
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: dossierValue,
        idLot: lotsValue
      })
    };

    return fetch("/api/vic/getCompaniesByNdossierLot", requestOptions)
      .then(response => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Ressource non trouvée");
          } else {
            throw new Error("Échec de la requête: " + response.statusText);
          }
        }
        return response.json();
      })
      .then(json => {
        dispatch(fetchCompaniesSuccess(json));
        return json;
      })
      .catch(error => {
        dispatch(fetchCompaniesFailure(error));
        console.error(error);
      });
  };
}


export function changeVicValue(newValue) {
  return dispatch => dispatch(newVicValue(newValue))
}

export function changeConventionValue(convValue) {
  return dispatch => dispatch(newConventionValue(convValue))
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function newLotValue(newValue) {
  return dispatch => dispatch(changeLotValue(newValue))
}

export function newLotsValue(newValue) {
  return dispatch => dispatch(changeLotsValue(newValue))
}

export const fetchVicBegin = () => ({
  type: FETCH_VIC_BEGIN
});

export const fetchVicSuccess = (vic) => ({
  type: FETCH_VIC_SUCCESS,
  payload: { items: vic }
});

export const fetchVicFailure = error => ({
  type: FETCH_VIC_FAILURE,
  payload: { error }
});

export const fetchVicsBegin = () => ({
  type: FETCH_VICS_BEGIN
});

export const fetchVicsSuccess = (vics) => ({
  type: FETCH_VICS_SUCCESS,
  payload: { vics }
});

export const fetchVicsFailure = error => ({
  type: FETCH_VICS_FAILURE,
  payload: { error }
});

export const fetchCompaniesBegin = () => ({
  type: FETCH_COMPANIES_BEGIN
});

export const fetchCompaniesSuccess = (companies) => ({
  type: FETCH_COMPANIES_SUCCESS,
  payload: { companies }
});

export const fetchCompaniesFailure = error => ({
  type: FETCH_COMPANIES_FAILURE,
  payload: { error }
});

export const fetchLotsBegin = () => ({
  type: FETCH_LOTS_BEGIN
});

export const fetchLotsSuccess = (lots) => ({
  type: FETCH_LOTS_SUCCESS,
  payload: { lots: lots, lotValue: lots[0].code }
});

export const fetchLotsFailure = error => ({
  type: FETCH_LOTS_FAILURE,
  payload: { error }
});

export const fetchAllLotsBegin = () => ({
  type: FETCH_ALLLOTS_BEGIN
});

export const fetchAllLotsSuccess = (lots) => ({
  type: FETCH_ALLLOTS_SUCCESS,
  payload: { lots: lots }
});

export const fetchAllLotsFailure = error => ({
  type: FETCH_ALLLOTS_FAILURE,
  payload: { error }
});

export const fetchAllLotItemsBegin = () => ({
  type: FETCH_ALLLOTITEMS_BEGIN
});

export const fetchAllLotItemsSuccess = (lots) => ({
  type: FETCH_ALLLOTITEMS_SUCCESS,
  payload: { lots: lots }
});

export const fetchAllLotItemsFailure = error => ({
  type: FETCH_ALLLOTITEMS_FAILURE,
  payload: { error }
});

export const changeLotValue = lotValue => ({
  type: CHANGE_LOT_VALUE,
  payload: { lotValue }
});

export const changeLotsValue = value => ({
  type: CHANGE_LOTS_VALUE,
  payload: { value }
});

export const newVicValue = vicValue => ({
  type: CHANGE_VIC_VALUE,
  payload: { vicValue }
});

export const newConventionValue = convValue => ({
  type: CHANGE_CONVENTION_VALUE,
  payload: { convValue }
});
