import React, { useState } from 'react';
import { Button, TextField, InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { format } from 'date-fns';
import EmailTemplate from '../EmailTemplate';
import InformationDialog from '../../InformationDialog';
import ReactQuill from 'react-quill';

const extractEmails = (inputString) => {
  const emailRegex = /[\w.-]+@[\w-]+(?:\.[\w-]{2,})+/g;
  const emails = inputString.match(emailRegex);

  if (emails) {
    return emails.join(", ");
  } else {
    return "";
  } 
}

// Construire l'e-mail complet avec le header, le footer et le contenu
const buildCompleteEmail = (contentHtml, title) => {
  return `
    <!DOCTYPE html>
    <html lang="fr">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>${title}</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          background-color: #f4f4f4;
          margin: 0;
          padding: 0;
        }
        .container {
          max-width: 600px;
          margin: 0 auto;
          background-color: #ffffff;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        .header {
          text-align: center;
          padding: 20px 0;
          background-color: #ff7543;
          color: white;
          border-radius: 10px 10px 0 0;
        }
        .content {
          padding: 20px;
        }
        .footer {
          padding: 20px;
          background-color: #f4f4f4;
          border-radius: 0 0 10px 10px;
        }
      </style>
    </head>
    <body>
      <div class="container">
        <div class="header">
          <h1>${title}</h1>
        </div>
        <div class="content">
          ${contentHtml}
        </div>
        <div class="footer">
          <table width="100%" cellpadding="0" cellspacing="0">
            <tr>
              <td style="font-family: Arial, sans-serif; font-size: 14px; color: #333333;">
                Jean-Marie SAINT-GERMAIN<br>Directeur de l'agence<br>4, RUE DES ARTISANS<br>93160 NOISY-LE-GRAND<br>RCS BOBIGNY B 524 291 333<br>Tel : +33 1 43 03 63 17<br>Port : +33 6 80 68 14 82<br><a href="http://www.coordogec.com" target="_blank">http://www.coordogec.com</a>
              </td>
              <td align="right">
                <img src="https://www.coordogec.fr/wp-content/themes/coordogec/assets/images/coordogec.jpg" alt="Logo Coordogec" style="width: 100px; height: 100px;">
              </td>
            </tr>
          </table>
        </div>
      </div>
    </body>
    </html>
  `;
};


const EmailEditor = ({ fetchData, setIsLoading, intervenant, dossierInfos, setSendEmail }) => {
  const msgMailSent = `Votre mail a bien été envoyé avec succès.`
  const initialContent = intervenant.type.trim().toLowerCase().includes('vic') ? intervenant.ppsps ? `<p>Bonjour,</p>
<p>Nous vous confirmons la bonne réception de votre Plan Particulier de Sécurité et de Protection de la Santé (PPSPS) conformément à l'article N° <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000018529460" target="_blank">R 4532-56</a> du Code du travail. Nous vous remercions pour votre diligence à ce sujet.</p>
<p>Afin de préparer la Visite d'Inspection Commune (VIC) en amont de votre intervention, nous vous invitons à prendre contact avec COORDOGEC par e-mail à l'adresse <a href="mailto:coordogec@coordogec.fr">coordogec@coordogec.fr</a> ou par téléphone au <a href="tel:+33680681482">+33 6 80 68 14 82</a>.</p>
<p>Selon le planning envoyé par le MOE ou le pilote du chantier susvisé, votre intervention est estimée à environ un mois, le <b>${format(intervenant.intervention_date, 'dd/MM/yyyy')}</b>.</p>
<p>Nous restons, bien entendu, à votre disposition pour toute question ou complément d'information.</p>
<p>Bien cordialement,</p>` :
    `<p>Bonjour,</p>
<p>Conformément à l'article N° <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000018529564" target="_blank">R 4532-13</a> du Code du travail, nous vous invitons à prendre contact avec COORDOGEC via l'adresse mail <a href="mailto:coordogec@coordogec.fr">coordogec@coordogec.fr</a> ou nous contacter par téléphone au <a href="tel:+33680681482">+33 6 80 68 14 82</a>.</p>
<p>En effet, suivant le planning envoyé par le MOE ou le pilote du chantier susvisé, votre intervention semble proche. L'estimation est de un mois <b>${format(intervenant.intervention_date, 'dd/MM/yyyy')}</b>.</p>
<p>Par ailleurs, conformément à l'article N° <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000018529460" target="_blank">R 4532-56</a> :</p>
<p>L'entrepreneur tenu de remettre un plan particulier de sécurité et de santé <b>PPSPS</b> au coordonnateur ou au maître d'ouvrage, en application du premier alinéa de l'article N° <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000006903271" target="_blank">L 4532-9</a>, dispose de trente jours à compter de la réception du contrat signé par le maître de l'ouvrage pour établir ce plan.</p>
<p>Il est essentiel que ces deux événements doivent être respectés avant toute intervention de votre part.</p>
<p>Nous restons, bien entendu, à votre disposition pour toute question ou complément d'information.</p>
<p>Bien cordialement,</p>` : `<p>Bonjour,</p>`;

  const [content, setContent] = useState(initialContent);
  const [emailTitle, setEmailTitle] = useState(intervenant.type.trim().toLowerCase().includes('vic') ? `Contact pour préparer votre VIC pour le lot ${intervenant?.lot}`: 'Contact');
  const [subject, setSubject] = useState(`DOSSIER N° ${dossierInfos.ndossier.toString().split("").join("-")} : ${intervenant.type.trim().toLowerCase().includes('vic') ? `LOT ${intervenant?.lot.toUpperCase()} - `: ''}${dossierInfos.operation.toUpperCase()}, ${dossierInfos.adresse_op.toUpperCase()}, ${dossierInfos.cp_op} - ${dossierInfos.ville_op.toUpperCase()}`);
  const [toEmail, setToEmail] = useState(intervenant.mail);
  const [ccEmail, setCcEmail] = useState('coordogec@coordogec.fr, jeannette.wonje@coordogec.fr, syrinemkaddem@coordogec.fr');
  const [bccEmail, setBccEmail] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [msgDialog, setMsgDialog] = useState('');

  const handleContentChange = (value) => {
    setContent(value);
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link'
  ];

  const handleSendEmail = async () => {
    setIsLoading(true);
    const fullEmail = buildCompleteEmail(content, emailTitle);

    try {
      const response = await fetch('/api/mail/sendMailIntervenant', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          idIntervenant: intervenant.id,
          to: extractEmails(toEmail),
          cc: extractEmails(ccEmail),
          bcc: extractEmails(bccEmail),
          from: senderEmail,
          password: password,
          subject: subject,
          text: fullEmail,  // Version texte brut
          html: fullEmail,  // Version HTML complète de l'e-mail
        }),
      });

      if (response.ok) {
        setIsLoading(false);
        setMsgDialog(msgMailSent);
        setOpenDialog(true);
      } else {
        setIsLoading(false);
        setMsgDialog('Erreur lors de l\'envoi du mail.');
        setOpenDialog(true);
      }
    } catch (error) {
      console.error('Erreur:', error);
      alert('Une erreur est survenue lors de l\'envoi de l\'email.');
    }
  };

  return (
    <div className="email-editor">
      <div className="button-msg">
        <TextField
          className={"text-field"}
          label="Expéditeur"
          variant="outlined"
          type="email"
          size="small"
          value={senderEmail}
          onChange={(e) => setSenderEmail(e.target.value)}
          required
        />
        <TextField
          className={"text-field"}
          label="Mot de passe"
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          size="small"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={"password-toggle-btn"}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(prev => !prev)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
        />
        <TextField
          className={"text-field"}
          label="Destinataire"
          variant="outlined"
          type="email"
          size="small"
          name="to"
          value={toEmail}
          onChange={(e) => setToEmail(e.target.value)}
          required
        />
        <TextField
          className={"text-field"}
          label="Cc"
          variant="outlined"
          type="email"
          size="small"
          name="to"
          value={ccEmail}
          onChange={(e) => setCcEmail(e.target.value)}
          required
        />
        <TextField
          className={"text-field"}
          label="Cci"
          variant="outlined"
          type="email"
          size="small"
          name="bcc"
          value={bccEmail}
          onChange={(e) => setBccEmail(e.target.value)}
          required
        />
        <TextField
          className={"text-field"}
          label="Objet"
          variant="outlined"
          size="small"
          name="subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
        />
      </div>
      <EmailTemplate
        content={<div dangerouslySetInnerHTML={{ __html: content }} />}
        title={emailTitle}
      />
      <TextField
        label="Modifier le titre"
        multiline
        variant="outlined"
        fullWidth
        value={emailTitle}
        onChange={(e) => setEmailTitle(e.target.value)}
        style={{ marginTop: '10px' }}
      />
      <ReactQuill
        value={content}
        onChange={handleContentChange}
        modules={modules}
        formats={formats}
        style={{ width: '640px', marginTop: '10px', marginBottom: '50px', height: '300px' }}
      />
      <div className='btn-container'>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSendEmail}
          disabled={!toEmail || !senderEmail || !password}
        >
          Envoyer
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setSendEmail(false)}
        >
          Annuler
        </Button>
      </div>
      <InformationDialog
        isOpen={openDialog}
        title={'ENVOI DE MAIL'}
        information={msgDialog}
        handleClose={async () => {
          await setOpenDialog(false)
          if (msgDialog === msgMailSent) {
            await fetchData()
            await setSendEmail(false)
          }
        }}
      />
    </div>
  );
};

export default EmailEditor;
