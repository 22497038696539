import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import history from '../../../Routes/history';
import { fetchNewPropo } from '../../../../redux/actions/devisActions';

class DialogDevis extends React.Component {
  constructor(props){
    super(props)
    this.state={
      isOpen:true,
    }
    this.handleCloseProcess=this.handleCloseProcess.bind(this)
    this.handleOpen=this.handleOpen.bind(this)
    this.handleChange=this.handleChange.bind(this)
  }
  handleChange(event){ 
    this.setState({[event.target.name]:event.target.value})
  }
  handleCloseProcess(){
    this.setState({isOpen:false})
    this.props.closeDialog()
}
  handleOpen(){
    this.setState({isOpen:true})
}
/**
 * Créer une nouvelle proposition pour le dossier choisi
 */
async handleNewPropo() {
  const { dossierValue } = this.props;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify({ ndossier: dossierValue })
  };

  try {
    const response = await fetch("/api/devis/createNewPropo", requestOptions);
    const result = await response.json();

    if (result === true) {
      await this.props.dispatch(fetchNewPropo(dossierValue));
      await history.push("/devis/conception");
    } else {
      this.setState({ openDialogNprop: true });
    }
  } catch (err) {
    console.log(err);
  }
}

    render() {
      return (
        <div>
        <Dialog open={this.state.isOpen} onClose={this.handleCloseProcess} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Modification Devis Validé</DialogTitle>
          <DialogContent>
            <DialogContentText>
                Voulez-Vous créer une nouvelle proposition de devis 
                pour le dossier N°{this.props.dossierValue} malgré la validation de celui-ci ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseProcess} color="primary">
              Annuler la Modification
            </Button>
            <Button onClick={this.handleCloseProcess} color="primary">
              Non
            </Button>
            <Button onClick={this.handleNewPropo.bind(this)} color="primary">
              Oui
            </Button>
          </DialogActions>
        </Dialog>  
        </div>       
      )
    }
  }
  const mapStateToProps = state => ({
    devisrea : state.devis.devisrea,
    deviscon : state.devis.deviscon,
    dossierValue : state.devis.dossierValue,
    nprop : state.devis.nprop,
    attachment: state.email.file
  });
export default connect(mapStateToProps)(DialogDevis)