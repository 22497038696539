import React from "react";
import "../../../Styles/RJ/Contacts.scss";
import Header from "../../Header/Header";
import DocMenu from '../../DocMenu';
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import GenPdf from "../../GenPdf";
import { genPDF, getPDF } from "../Utils";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import RJMenu from "../RJMenu";
import { fetchRJ, newRjValue } from "../../../redux/actions/rjActions";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import ContactsTable from "./ContactsTable";
import DisplayContacts from "./DisplayContacts";
import CircularProgress from '@material-ui/core/CircularProgress';

class Contacts extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      advancement: 0,
      travauxList: [],
      anchorEl: null,
      open: false,
      index: "",
      lot: "",
      travaux: "",
      name_company: "",
      adress: "",
      city: "",
      cp: "",
      name_contact: "",
      mobile: "",
      tel: "",
      mail: "",
      vic: "",
      ppsps: "",
      disableButton: false,
      contactsArray: [],
      isOpen: false,
      openDialog: false,
      indexDel: 0
    };
    this.addContact = this.addContact.bind(this);
    this.levelsWrapper = React.createRef();
  }

  componentDidMount() {
    this.props.dispatch(changePdfFileName(""))
    document.addEventListener("mousedown", this.handleClickOutside);
    const { rjValue } = this.props;

    if (!rjValue || !rjValue.observations) {
      this.firstPage();
    } else if (JSON.parse(rjValue.observations)) {
      this.handleGetAllLots();
      this.initializeState();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }


  componentDidUpdate(prevProps, prevState) {
    const { rjValue } = this.props;
    if (this.props.rj.length === 0) {
      this.firstPage()
    }
    if (rjValue !== undefined && rjValue !== prevProps.rjValue
    ) {
      this.initializeState();
    }
  }

  async handleGetAllLots() {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const res = await fetch("/api/rj/getAllLots", requestOptions);
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const data = await res.json();
      this.setState({ travauxList: data });
    } catch (error) {
      console.error('Erreur lors de la récupération des lots:', error);
    }
  }

  initializeState() {
    this.props.dispatch(changePdfFileName(""))
    const { rjValue } = this.props;
    const { advancement, contacts } = rjValue;

    this.setState({
      advancement: advancement,
      contactsArray: contacts ? JSON.parse(contacts) : []
    });
  }

  handleClickOpen = (index) => {
    this.setState({ openDialog: true, indexDel: index });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleChangeContact = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClickOutside = (event) => {
    if (this.levelsWrapper && this.levelsWrapper.current !== null && !this.levelsWrapper.current.contains(event.target)) {
      this.hidLevels()
    }
  }

  delLevel = (event) => {
    this.setState({ [event.currentTarget.name]: "" });
    this.hidLevels();
  };

  showLevels = (event, index) => {
    this.setState({ anchorEl: event.currentTarget, open: true, index: index });
  };

  hidLevels = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleLevelClick = (type, item) => {
    this.setState({ [item]: type });
    this.hidLevels();
  };

  parseLotNumber(lot) {
    const lastChar = lot.charAt(lot.length - 1);
    return isNaN(+lastChar) ? +lot.slice(0, -1) : +lot;
  }

  addContact() {
    const newContact = {
      lot: this.state.lot.trim(),
      travaux: this.state.travaux.trim(),
      name_company: this.state.name_company.trim(),
      adress: this.state.adress.trim(),
      city: this.state.city.trim(),
      cp: this.state.cp.trim(),
      name_contact: this.state.name_contact.trim(),
      tel: this.state.tel.trim(),
      mobile: this.state.mobile.trim(),
      mail: this.state.mail.trim(),
      vic: this.state.vic,
      ppsps: this.state.ppsps,
    };
    const newContactsArray = [...this.state.contactsArray];
    const lotContactToAdd = this.parseLotNumber(newContact.lot);

    const index = newContactsArray.findIndex(contact => {
      const currentLot = this.parseLotNumber(contact.lot);
      return currentLot > lotContactToAdd;
    });

    if (index >= 0) {
      newContactsArray.splice(index, 0, newContact);
    } else {
      newContactsArray.push(newContact);
    }

    this.setState({ contactsArray: newContactsArray });
    this.initializeCurrentContact();
  }


  delContact = () => {
    this.handleClose()
    let newContactsArray = [...this.state.contactsArray];
    newContactsArray.splice(this.state.indexDel, 1);
    this.setState({ contactsArray: newContactsArray });
  }

  initializeCurrentContact() {
    this.setState({
      lot: "",
      travaux: "",
      name_company: "",
      adress: "",
      city: "",
      cp: "",
      name_contact: "",
      mobile: "",
      tel: "",
      mail: "",
      vic: "",
      ppsps: "",
    });
  }

  handleValidClick = async () => {
    this.setState({ disableButton: true });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ndossier: this.props.dossierValue,
        numero: this.props.rjValue.numero,
        contacts: this.state.contactsArray
      }),
    };
    this.setState({ loading: true });
    await fetch("/api/rj/contactsUpdate", requestOptions)
      .then(async (res) => {
        if (res.ok) {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await this.props.dispatch(fetchRJ(this.props.dossierValue))
    await this.setState({ disableButton: false });
  };

  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  // filterRJ = () => {
  //   return this.props.rj.filter((rj) => {
  //     if (rj.ndossier == this.props.dossierValue) return true;
  //     else return false;
  //   });
  // };

  checkRjPrev = () => {
    const { rjValue, disableButton } = this.props;
    const allRj = this.props.rj;

    if (!rjValue || disableButton || (allRj.length > 0 && allRj[allRj.length - 1].numero !== rjValue.numero)) {
      return true;
    }

    return false;
  }


  handleGenClick = async () => {
    this.setState({ disableButton: true })
    this.setOpen(true);
    this.setState({ file: null });
    let blob = await genPDF(this.props.dossierValue, this.props.rjValue.numero);
    if (blob) {
      this.setState({ file: blob });
    }
    await this.setState({ disableButton: false })
  };

  handleShowPdf = async () => {
    this.setState({ disableButton: true })
    this.setOpen(true);
    this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, this.props.rjValue.numero, 'pdf');
    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    } else this.setOpen(false);
    await this.setState({ disableButton: false })
  };

  handleGetPdfFile = async () => {
    this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, this.props.rjValue.numero, 'pdf');

    if (response.blob) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    this.setState({ file: null });
    let response = await getPDF(this.props.dossierValue, this.props.rjValue.numero, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  firstPage = () => {
    history.push("/rj/evaluation");
  };
  nextPage = () => {
    history.push("/rj/suivi-effectifs");
  };
  prevPage = () => {
    history.push("/rj/observations");
  };

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`Registre Journal`))
  }

  render() {
    const { rjValue } = this.props;
    const { travauxList, anchorEl, file, isOpen, open, index, indexDel, advancement, contactsArray, disableButton, openDialog } = this.state;
    const currentContact = [
      {
        lot: this.state.lot,
        travaux: this.state.travaux,
        name_company: this.state.name_company,
        adress: this.state.adress,
        city: this.state.city,
        cp: this.state.cp,
        name_contact: this.state.name_contact,
        mobile: this.state.mobile,
        tel: this.state.tel,
        mail: this.state.mail,
        vic: this.state.vic,
        ppsps: this.state.ppsps,
      },
    ];
    return (
      <div className="rj-contact">
        <Header />
        <DocMenu
          file={file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Registre Journal (Contacts)</h1>
        <RJMenu
          advancement={advancement}
          handleResetValueArray={""}
        />
        <div className="data">
          <ContactsTable
            travauxList={travauxList}
            contact={currentContact}
            anchorEl={anchorEl}
            open={open}
            index={index}
            handleChangeContact={this.handleChangeContact}
            handleLevelClick={this.handleLevelClick}
            levelsWrapper={this.levelsWrapper}
            addContact={this.addContact}
            showLevels={this.showLevels}
            delLevel={this.delLevel}
            hidLevels={this.hidLevels}
          />
          {contactsArray.length > 0 && <DisplayContacts
            handleClickOpen={this.handleClickOpen}
            contactsArray={contactsArray}
          />}
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleValidClick}
            disabled={this.checkRjPrev() || disableButton}
          >
            Enregistrer
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleGenClick}
            disabled={disableButton}
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleShowPdf}
            disabled={disableButton}
          >
            Afficher le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowLeft />}
            onClick={this.prevPage}
            disabled={disableButton}
          >
            Page précedente
          </Button>
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowRight />}
            onClick={this.nextPage}
            disabled={disableButton}
          >
            Page suivante
          </Button>
          {disableButton && <CircularProgress className="spinner" color="inherit" />}
          <div>
            <Dialog open={openDialog} onClose={this.handleClose}>
              <DialogTitle>SUPPRESSION DU CONTACT</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Êtes vous sûr de vouloir supprimer le contact «{contactsArray[indexDel] !== undefined ? contactsArray[indexDel].name_contact : ""}» de l'entreprise «{contactsArray[indexDel] !== undefined ? contactsArray[indexDel].name_company : ""}» ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={this.delContact} style={{ color: "white", backgroundColor: "#FF7F50" }}>
                  Oui
                </Button>
                <Button variant="contained" onClick={this.handleClose} style={{ color: "white", backgroundColor: "#F5B039" }}>
                  Non
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <GenPdf
          file={file}
          isOpen={isOpen}
          closeDoc={() => this.setOpen(false)}
          scale={1}
          pageNumber={rjValue !== undefined ? rjValue.nbfloor + 3 : 4}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  rj: state.rj.items,
  rjValue: state.rj.rjValue,
  photos: state.rj.photos,
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
});
export default connect(mapStateToProps)(Contacts);
