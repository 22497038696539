class MessageParser {
    constructor(actionProvider) {
        this.actionProvider = actionProvider;

    }

    parse(message) {
        console.log(message);
        const lowercase = message.toLowerCase();

        if (lowercase.includes("facture")) {
            this.actionProvider.greet();
        }

        if (lowercase.includes("Facture") || lowercase.includes("Fac")) {
            this.actionProvider.handleJavascriptQuiz();
        }
    }
} 

export default MessageParser;