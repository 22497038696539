import React from 'react';
import Chart from './Chart.js';
import PieChart from './PieChart.js';
import '../../Styles/Dashboard/Connexion.scss';
import ScrollingText from './ScrollingText.js';
import DashboardCal from './Calendar.js';
import ActTable from './ActTable.js';
import OppTable from './OppTable.js';
import TelTable from './TelTable.js';
import FacTable from './FacTable.js';
import LoadingOverlay from '../LoadingOverlay';
import Header from '../Header/Header';
import moment from "moment";
import axios from "axios";
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import authHeader, { convertDate, verifNotifIcon } from '../Utils';
import '../../Styles/Dashboard/NotifList.scss';
import history from '../Routes/history';

class Dashboard extends React.Component {
    state = { idnotif: "" }; 
    constructor(props) {
        super(props);
        this.changeNotifState = this.changeNotifState.bind(this);
        this.state = {
            rdv: [],
            facture: [],
            activites: [],
            opportunites: [],
            gestdoc: [],
            notif: [],
            dl: [],
            displayNotif: [],
            notifNonVue: [],
            date: new Date(),
            tooltip: {
                visible: false,
                content: '',
                x: 0,
                y: 0
            },
            isLoading: false
        }
    }

    showTooltip = (event, content) => {
        if (content.id) {
            this.setState({
                tooltip: {
                    visible: true,
                    content: `<b>Dossier</b>: ${content.ndossier}<br><b>Lot</b>: ${content.lot}<br><b>Entreprise</b>: ${content.company}<br><b>Contact</b>: <span>${content.representative}</span><br><b>Téléphone</b>: <span>${content.tel}</span><br><b>Email</b>: <span>${content.mail}</span>`,
                    x: event.clientX,
                    y: event.clientY
                }
            });
        } else {
            this.setState({
                tooltip: {
                    visible: true,
                    content: `<b>Titre</b>: ${content.title}<br><b>RDV</b>: ${format(new Date(content.start), 'HH:mm')}<br><b>Description</b>: ${content.description}`,
                    x: event.clientX,
                    y: event.clientY
                }
            });
        }
    }

    hideTooltip = () => {
        this.setState({
            tooltip: {
                visible: false,
                content: '',
                x: 0,
                y: 0
            }
        });
    }
    passChantier() {
        const chantier = this.state.cec.map(cec => cec);
        return chantier;
    }
    /**
     * Change l'état de la notif afin de la faire apparaître
     */
    changeNotifState() {
        const notifwrapper = this.notifWrapper.current;
        notifwrapper.classList.toggle("is-notif-enabled");
    }
    /**
     * Permet de récupérer la date en paramètre et l'ajouter en tant qu'état
     * @param {Date} fetchedDate 
     */
    getDate = fetchedDate => {
        this.setState({ date: fetchedDate })
    }

    fetchAppointments = async () => {
        this.setState({ isLoading: true })
        const user = authHeader()

        if (user['x-access-token'] === undefined) {
            history.push('/login')
            return
        }
        const idUser = user.id
        const today = this.state.date

        try {
            const response = await axios.get('/api/getAllAppointments', {
                params: {
                    idUser,
                    today,
                },
            });
            this.setState({ rdv: response.data });
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({ isLoading: false })
        }
    }


    fetchData = async () => {
        const user = authHeader()

        if (user['x-access-token'] === undefined) {
            history.push('/login')
            return
        }

        await fetch('/api/dashboardData', {
            headers: user,
        })
            .then(res => res.json())
            .then(res => {
                this.setState({
                    facture: res.facture,
                    dl: res.documents,
                    opportunites: res.opportunites,
                    activites: res.activites,
                    notif: res.notif,
                    notifNonVue: res.notifNonVue
                });
            })
            .catch(err => console.log(err))

    }
    /**
     * Initialise les données du Dashboard en récupérant les données vià un fetch
     */
    componentDidMount() {
        this.fetchAppointments();
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        const currentDate = new Date(this.state.date);
        const prevDate = new Date(prevState.date);

        if (currentDate.getMonth() !== prevDate.getMonth() || currentDate.getFullYear() !== prevDate.getFullYear()) {
            this.fetchAppointments();
        }
    }

    seenLater() {
        {
            toast('Basic notif !', { position: toast.POSITION.TOP_LEFT })
        }

    }

    /**
 * Permet de récupérer les notifications afin de les ajouter à un état
 */
    loadNotif() {
        if (this.state.displayNotif.length === 0) {
            const listTemplate = []
            for (var i = 0; i < this.state.notif.length; i++) {

                listTemplate.push(true)
                this.setState({ displayNotif: this.state.displayNotif.concat(listTemplate) })
            }
        }
    }

    stripHtml(html) {
        if (!html) {
            return '';
        }

        const tmp = document.createElement('div');
        tmp.innerHTML = html;

        const textContent = tmp.textContent || tmp.innerText || '';

        return textContent
            .replace(/\s+/g, ' ')
            .trim();
    }

    render() {
        this.state.date.setHours(1)
        this.loadNotif()

        return (

            <div className="connexion">

                {this.state.isLoading && <LoadingOverlay />}
                <Header location={this.props.location} />

                <div className="notifList" style={{ width: "50%" }}>

                    {setTimeout(() => {

                        this.state.notif.map((notif, index) =>

                            toast(
                                <>
                                    <div className={(this.state.displayNotif[index] ? "notif" + (index + 1) : "notif-closed" +
                                        (index + 1))} key={notif.id} onClick={() => history.push("/notifications/" + notif.idnotif)}>
                                        <span className="notifIcon"><FontAwesomeIcon icon={verifNotifIcon(notif.type_notif)} /></span>
                                        <span className={"closeNotif" + (index + 1)} onClick={() => this.closeNotifFromIndex(index)}><FontAwesomeIcon icon={faWindowClose} /></span>
                                        <p><span>{(index + 1) + " - " + notif.message}</span></p>
                                        <p>Envoyé par {notif.sender} le {convertDate(notif.date)} à {notif.heure}</p>
                                        {/* <Button variant="contained" color="primary" onClick={this.handleGenClick.bind(this)}>Générer le contrat</Button> */}
                                    </div>
                                    <button style={{ height: "20px", width: "120px", fontSize: "12px", marginRight: "46px", color: "white", backgroundColor: "black", padding: "3px" }}
                                        onClick={() => axios.post('/notifMesssageVu/' + notif.idnotif)} >  Marquer comme Lu  </button>

                                    <button
                                        onClick={() =>
                                            setTimeout(function () {
                                                toast(notif.message + ' ' + ' Envoyé par ' + notif.sender + ' ' + ' le ' +
                                                    convertDate(notif.date)
                                                    + ' ' + ' à ' + notif.heure, {
                                                    position: toast.POSITION.TOP_LEFT, onClick: () => {
                                                        history.push("/notifications/" + notif.idnotif);
                                                    }
                                                }, {
                                                    className: 'seen-later'
                                                }
                                                )
                                            }, 3000)

                                        }
                                        // onClick={() => 
                                        //     axios.get('/notifLater/:idnotif' + notif.idnotif)} 
                                        style={{ height: "20px", width: "120px", fontSize: "12px", color: "white", backgroundColor: "black", padding: "3px" }}>
                                        Voir plus tard </button>
                                </>
                                , {
                                    toastId: index,
                                    position: "top-right",
                                    autoClose: 0 * (index + 1),
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    // onClick: () => {
                                    //     history.push("/notifications/" + notif.idnotif);
                                    // }
                                },
                            )


                        )

                    }, 0)

                    };

                    <ToastContainer toastStyle={{ backgroundColor: "rgba(255, 127, 80, 0.8)", color: "#FFF", padding: "0px" }}
                        limit={3} />
                </div>

                <div className="dashboard">
                    <div className="rdv">
                        <label className="titrerdv">Rendez-Vous</label>
                        <div className="allRDVs">
                            {this.state.rdv.filter(rdv =>
                                format(new Date(this.state.date), 'yyyy-MM-dd') === format(new Date(rdv.start), 'yyyy-MM-dd')
                            ).length > 0 ? (
                                this.state.rdv.map((rdv, i) =>
                                    format(new Date(this.state.date), 'yyyy-MM-dd') === format(new Date(rdv.start), 'yyyy-MM-dd') ? (
                                        rdv.id ? (
                                            <p key={i} onMouseOver={(e) => this.showTooltip(e, rdv)} onMouseOut={this.hideTooltip}>
                                                {rdv.ndossier + " - "}
                                                <span className="rdv-type">{rdv.type}</span>
                                                {rdv.lot.trim() && <span className="rdv-lot">{rdv.lot}</span>}
                                                <span className="rdv-time">{moment.utc(rdv.start).format('HH:mm')}</span>
                                            </p>
                                        ) : (
                                            <p key={i} onMouseOver={(e) => this.showTooltip(e, rdv)} onMouseOut={this.hideTooltip}>
                                                <span className="rdv-title">{rdv.title?.length > 20 ? rdv.title.substring(0, 20) + "..." : rdv.title}</span>
                                                {(rdv.description && rdv.description.trim()) &&
                                                    <span className="rdv-description">{rdv.description?.length > 20 ? this.stripHtml(rdv.description.substring(0, 20)) + "..." : this.stripHtml(rdv.description)}</span>
                                                }
                                                <span className="rdv-time">{format(new Date(rdv.start), 'HH:mm')}</span>
                                            </p>
                                        )
                                    ) : null
                                )
                            ) : (
                                <p className="no-rdv">Aucun rendez-vous prévu.</p>
                            )}
                        </div>
                        {this.state.tooltip.visible && (
                            <div
                                className="tooltip"
                                style={{ left: this.state.tooltip.x, top: this.state.tooltip.y - 20 }}
                                dangerouslySetInnerHTML={{ __html: this.state.tooltip.content }}
                            />
                        )}
                    </div>
                    <div className="calendar">
                        <label className="titrecal">Calendrier</label>
                        <DashboardCal date={this.getDate} />
                    </div>
                    <div className="pendingChantier">
                        <label className="titrechantier">Chantier en cours</label>
                        <div className="chantierHeader">
                            <span>Numéro de Dossier</span>
                            <span>Item</span>
                            <span>Date de début</span>
                            <span>Date de fin</span>
                        </div>
                        <ScrollingText />
                    </div>
                    <div className="graphChantier">
                        <label className="titregraph">Chantier par Représentant et par phase</label><br />
                        <Chart date={this.state.date} />
                    </div>
                    <div className="graphs">
                        <label className="titregraphs">Graphiques</label><br />
                        <PieChart />
                    </div>
                    <div className="docs">
                        <div className="titredocs">Documents</div>
                        <div className="facture">
                            <label className="faclabel">Facture : </label>
                            <FacTable data={this.state.facture} />
                        </div>
                        <div className="gestdoc">
                            <label className="gestlabel">Téléchargements :</label><br />
                            <TelTable data={this.state.dl} />
                        </div>
                        <div className="activities">
                            <label className="actlabel">Activités :</label>
                            <ActTable data={this.state.activites} />
                        </div>
                        <div className="opportunity">
                            <label className="opplabel">Opportunités :</label><br />
                            <OppTable data={this.state.opportunites} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Dashboard;


